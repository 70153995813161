import React, { Component } from "react";
import "../style/users.scss";
import Cards from "../components/cards";
import defaultIcon from "../style/img/defaultUser.png";
import Loading from "../components/loading";
import { AppContext } from "../appcontext";
import { FiPlus } from "react-icons/fi";
import { FiGrid } from "react-icons/fi";
import { FiList } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import { withAlert } from "react-alert";
import cx from "classnames";
import { CSSTransition } from "react-transition-group";
import AddUser from "../components/addUser";
import { Stats } from "react-instantsearch-dom";
import StatsCards from "../components/statscards";
import { BsGraphUp } from "react-icons/bs";
import StructuredCards from "../components/structuredCards";
import moment from "moment";

class OurStructure extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      usersBySkills: [],
      searchValue: "",
      showUsers: false,
      showManagers: false,
      showAdmins: false,
      showSysAdmins: false,
      showPending: true,
      query: "",
      results: [],
      showForm: false,
      showNewUser: false,
      showProfile: false,
      showPersonal: true,
      showTasks: false,
      showAssign: false,
      usersList: [],
      pendingUsers: [],
      toggledUser: {
        username: "default default",
        position: "",
        contact: "",
      },
      currentTab: "Details",
      registerUserData: {
        firstname: "",
        lastname: "",
        email: "",
        usertype: "1",
        position: "",
        valid: true,
      },
      newUserSuccessful: "",
      isLoading: true,
      loggedInUser: {},
      managerList: [],
      userList: [],
      adminList: [],
      sysAdminList: [],
      pendingList: [],
      view: "grid",
      filter: "First Name",
      newUsers: [
        {
          firstname: "",
          lastname: "",
          email: "",
          usertype: "1",
          position: "",
          valid: true,
        },
      ],
      statsusers: [],
      groupedby: "User Type",
      usersbycustomer: [],
    };
    this.onSubmitNewUser = this.onSubmitNewUser.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunctionForm, false);
  }

  getSearchedUsers = (e) => {
    this.setState({
      searchValue: e.target.value,
    });

    e.target.value = e.target.value.toLowerCase();
    var space = [];
    var statsusers = [];
    space = e.target.value.split(" ");

    //Filtering functions
    //filter user type
    const get_user_types = (list, criteria) => {
      let users = [];
      users = list.filter((user) => {
        if (user.usertype === criteria) return user;
      });
      return users;
    };

    //filter by search value
    const get_user_search = (list, search_value) => {
      let users = [];
      users = list.filter((user) => {
        let concat_keys = user.firstname + " " + user.lastname;
        if (concat_keys.toLowerCase().indexOf(search_value) !== -1) return user;
        else if (user.skills)
          if (user.skills.length > 0)
            if (
              user.skills.some((skill) =>
                typeof skill.name === "string"
                  ? skill.name.toLowerCase().indexOf(search_value) !== -1
                  : null
              )
            )
              return user;
      });
      return users;
    };

    if (this.state.groupedby === "User Type") {
      this.setState({
        userList: [],
        adminList: [],
        managerList: [],
        sysAdminList: [],
        pendingList: [],
      });
      var user = [];
      var admins = [];
      var sysadmins = [];
      var managers = [];
      var pending = [];
      if (e.target.value === "") {
        user = get_user_types(this.state.usersList, 1);
        managers = get_user_types(this.state.usersList, 2);
        admins = get_user_types(this.state.usersList, 3);
        sysadmins = get_user_types(this.state.usersList, 4);
        statsusers = user.concat(managers, admins, sysadmins);
        for (let z = 0; z < this.state.pendingUsers.length; z++) {
          pending.push(this.state.pendingUsers[z]);
        }
      } else if (space.length > 0) {
        let searched_array = get_user_search(this.state.usersList, space);
        user = get_user_types(searched_array, 1);
        managers = get_user_types(searched_array, 2);
        admins = get_user_types(searched_array, 3);
        sysadmins = get_user_types(searched_array, 4);
        statsusers = statsusers.concat(this.state.userList);
        this.state.pendingUsers.forEach(function (person) {
          if (person.firstname.toLowerCase().indexOf(e.target.value) !== -1) {
            pending.push(person);
          }
        });
        this.setState({
          showUsers: user.length === 0 ? false : true,
          showAdmins: admins.length === 0 ? false : true,
          showSysAdmins: sysadmins.length === 0 ? false : true,
          showManagers: managers.length === 0 ? false : true,
          showPending: pending.length === 0 ? false : true,
        });
      }
      // else {
      //      this.state.usersList.forEach(function (person) {
      //           if (person.firstname.toLowerCase().indexOf(e.target.value) !== -1 || person.lastname.toLowerCase().indexOf(e.target.value) !== -1) {
      //                if (person.usertype === 1) {
      //                     user.push(person);
      //                }
      //                else if (person.usertype === 2) {
      //                     managers.push(person);
      //                }
      //                else if (person.usertype === 3) {
      //                     admins.push(person);
      //                }
      //                else if (person.usertype === 4) {
      //                     sysadmins.push(person);
      //                }
      //                statsusers.push(person)

      //           }
      //      });
      //      this.state.pendingUsers.forEach(function (person) {
      //           if (person.firstname.toLowerCase().indexOf(e.target.value) !== -1) {
      //                pending.push(person)
      //           }
      //      })
      //      this.setState({
      //           showUsers: user.length === 0 ? false : true,
      //           showAdmins: admins.length === 0 ? false : true,
      //           showSysAdmins: sysadmins.length === 0 ? false : true,
      //           showManagers: managers.length === 0 ? false : true,
      //           showPending: pending.length === 0 ? false : true
      //      })
      // }
      this.setState({
        userList: user,
        managerList: managers,
        adminList: admins,
        sysAdminList: sysadmins,
        pendingList: pending,
        statsusers: statsusers,
      });
    } else if (this.state.groupedby === "Customer") {
      this.setState({
        usersbycustomer: [],
      });
      var usersbycustomer = [];
      if (space.length > 0) {
        if (e.target.value === "") {
          for (let z = 0; z < this.state.usersbycustomeroriginal.length; z++) {
            usersbycustomer.push(this.state.usersbycustomeroriginal[z]);
          }
        } else {
          this.state.usersbycustomeroriginal.forEach(function (customer) {
            var users = [];
            customer.users.forEach((person) => {
              if (
                person.firstname.toLowerCase().indexOf(space[0]) !== -1 &&
                person.lastname.toLowerCase().indexOf(space[1]) !== -1
              ) {
                users.push(person);
              }
            });
            customer.users = users;
            usersbycustomer.push(customer);
          });
        }
      } else {
        if (e.target.value === "") {
          this.state.usersbycustomer.forEach((customer) => {
            customer.users = this.state.usersList.filter(({ _id }) =>
              customer.resources.includes(_id)
            );
            usersbycustomer.push(customer);
          });
        } else {
          this.state.usersbycustomer.forEach((customer) => {
            var foundusers = this.state.usersList.filter(function (person) {
              return (
                person.firstname.toLowerCase().indexOf(e.target.value) !== -1 ||
                person.lastname.toLowerCase().indexOf(e.target.value) !== -1
              );
            });
            let users = [];
            customer.resources.forEach((id) => {
              foundusers.forEach((person) => {
                if (id === person._id) users.push(person);
              });
            });
            customer.users = users;
            usersbycustomer.push(customer);
          });
        }
      }
      this.setState({
        usersbycustomer: usersbycustomer,
      });
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunctionForm, false);
    this.setState({
      isLoading: true,
      loggedInUser: this.context.user,
    });
    const alert = this.props.alert;
    this.context
      .sendrequest(
        "getusers",
        null,
        (boolean) => this.setState({ isLoading: boolean }),
        alert.error
      )
      .then(async (response) => {
        if (response)
          if (response.data.success) {
            this.setState({
              usersList: response.data.data,
            });
            let usersBySkills = [{ customername: "Unskilled", users: [] }];
            for (let z = 0; z < this.state.usersList.length; z++) {
              if (this.state.usersList[z].usertype === 1) {
                this.state.userList.push(this.state.usersList[z]);
              } else if (this.state.usersList[z].usertype === 2) {
                this.state.managerList.push(this.state.usersList[z]);
              } else if (this.state.usersList[z].usertype === 3) {
                this.state.adminList.push(this.state.usersList[z]);
              } else if (this.state.usersList[z].usertype === 4) {
                this.state.sysAdminList.push(this.state.usersList[z]);
              }
              this.state.statsusers.push(this.state.usersList[z]);
              try {
                if (this.state.usersList[z].skills) {
                  if (this.state.usersList[z].skills.length > 0) {
                    this.state.usersList[z].skills.forEach((element) => {
                      if (element.name) {
                        if (
                          usersBySkills.some(
                            (userskill) =>
                              userskill.customername.toLowerCase() ===
                              element.name.toLowerCase()
                          )
                        ) {
                          for (let a = 0; a < usersBySkills.length; a++) {
                            if (
                              usersBySkills[a].customername.toLowerCase() ==
                              element.name.toLowerCase()
                            )
                              usersBySkills[a].users.push(
                                this.state.usersList[z]
                              );
                          }
                        } else {
                          usersBySkills.push({
                            customername:
                              element.name.charAt(0).toUpperCase() +
                              element.name.slice(1),
                            users: [this.state.usersList[z]],
                          });
                        }
                      } else {
                        for (let a = 0; a < usersBySkills.length; a++) {
                          if (usersBySkills[a].customername === "Unskilled")
                            usersBySkills[a].users.push(
                              this.state.usersList[z]
                            );
                        }
                      }
                    });
                  } else {
                    for (let a = 0; a < usersBySkills.length; a++) {
                      if (usersBySkills[a].customername === "Unskilled")
                        usersBySkills[a].users.push(this.state.usersList[z]);
                    }
                  }
                } else {
                  for (let a = 0; a < usersBySkills.length; a++) {
                    if (usersBySkills[a].customername === "Unskilled")
                      usersBySkills[a].users.push(this.state.usersList[z]);
                  }
                }
              } catch (error) {}
            }
            usersBySkills.sort((a, b) =>
              a.customername > b.customername ? 1 : -1
            );
            this.setState({
              usersBySkills: usersBySkills,
            });
            let usersbycustomer = [];
            await this.context
              .sendrequest(
                "getcustomers",
                null,
                (boolean) => this.setState({ isLoading: boolean }),
                this.props.alert.error
              )
              .then((response) => {
                if (response)
                  if (response.data.success) {
                    response.data.data.forEach((cust) => {
                      cust.users = [];
                      this.state.usersList.forEach((element) => {
                        cust.resources.filter(function (id) {
                          if (id === element._id) cust.users.push(element);
                        });
                      });
                      usersbycustomer.push(cust);
                    });
                    this.setState({
                      usersbycustomer: usersbycustomer,
                      usersbycustomeroriginal: usersbycustomer,
                    });
                  }
              });
            await this.context
              .sendrequest(
                "getpendingusers",
                null,
                (boolean) => this.setState({ isLoading: boolean }),
                this.props.alert.error
              )
              .then((response) => {
                if (response)
                  if (response.data.success && response.data.data[0]) {
                    let pendingTemp = [];
                    response.data.data.forEach(function (person) {
                      pendingTemp.push(person);
                    });
                    this.setState({
                      pendingList: pendingTemp,
                      pendingUsers: response.data.data,
                      isLoading: false,
                    });
                    this.filterUsers("First Name");
                  } else if (response.data.success && !response.data.data[0]) {
                    this.setState({
                      isLoading: false,
                    });
                  } else {
                    this.setState({
                      isLoading: false,
                    });
                    this.handleError(
                      "The pending users have not been loaded. please try again"
                    );
                  }
              });
          }
      });
  }

  onNameChange = (event) => {
    this.setState({
      registerUserData: {
        firstname: event.target.value,
        lastname: this.state.registerUserData.lastname,
        email: this.state.registerUserData.email,
        usertype: this.state.registerUserData.usertype,
        position: this.state.registerUserData.position,
        valid: this.state.registerUserData.valid,
      },
    });
  };

  onLastNameChange = (event) => {
    this.setState({
      registerUserData: {
        firstname: this.state.registerUserData.firstname,
        lastname: event.target.value,
        email: this.state.registerUserData.email,
        usertype: this.state.registerUserData.usertype,
        position: this.state.registerUserData.position,
        valid: this.state.registerUserData.valid,
      },
    });
  };

  onEmailChange = (event) => {
    const email = event.target.value;
    const emailValid = this.validateEmail(email);

    this.setState({
      registerUserData: {
        email: event.target.value,
        firstname: this.state.registerUserData.firstname,
        lastname: this.state.registerUserData.lastname,
        usertype: this.state.registerUserData.usertype,
        position: this.state.registerUserData.position,
        valid: emailValid,
      },
    });
  };
  validateEmail(email) {
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleValid(event) {
    this.setState({
      registerUserData: {
        valid: event.target.value,
        email: this.state.registerUserData.email,
        usertype: this.state.registerUserData.usertype,
        position: this.state.registerUserData.position,
        firstname: this.state.registerUserData.firstname,
        lastname: this.state.registerUserData.lastname,
      },
    });
  }

  onUserTypeChange = (type) => {
    this.setState({
      registerUserData: {
        usertype: type,
        email: this.state.registerUserData.email,
        firstname: this.state.registerUserData.firstname,
        lastname: this.state.registerUserData.lastname,
        position: this.state.registerUserData.position,
        valid: this.state.registerUserData.valid,
      },
    });
  };

  onPositionChange = (event) => {
    this.setState({
      registerUserData: {
        position: event.target.value,
        usertype: this.state.registerUserData.usertype,
        email: this.state.registerUserData.email,
        firstname: this.state.registerUserData.firstname,
        lastname: this.state.registerUserData.lastname,
        valid: this.state.registerUserData.valid,
      },
    });
  };

  toggleAddUserButton = () => {
    this.setState({
      showForm: !this.state.showForm,
      newUsers: [
        {
          usertype: "",
          email: "",
          firstname: "",
          lastname: "",
          position: "",
          valid: true,
        },
      ],
      usertype: "",
      email: "",
      firstname: "",
      lastname: "",
      position: "",
    });
  };

  currentUser = (employee) => {
    this.setState({
      toggledUser: employee,
    });
  };
  escFunctionForm = (event) => {
    if (event.keyCode === 27) {
      this.setState({
        showForm: false,
        usertype: "",
        email: "",
        firstname: "",
        lastname: "",
        position: "",
      });
    }
  };
  notification(message) {
    const alert = this.props.alert;
    alert.success(message);
  }
  handleError(message) {
    const alert = this.props.alert;
    alert.error(message);
  }
  handleReqiuredError = () => {
    const alert = this.props.alert;
    alert.error("All fields are required");
  };
  errorEmail = () => {
    const alert = this.props.alert;
    alert.error("invalid email address");
  };

  onSubmitNewUser = (event) => {
    event.preventDefault();
    if (this.state.registerUserData.firstname.trim() === "")
      this.props.alert.error("first name reqeuired");
    if (this.state.registerUserData.lastname.trim() === "")
      this.props.alert.error("last name reqeuired");
    if (this.state.registerUserData.email.trim() === "")
      this.props.alert.error("email reqeuired");
    else if (!this.validateEmail(this.state.registerUserData.email))
      this.props.alert.error("Email Format incorrect");
    if (this.state.registerUserData.position.trim() === "")
      this.props.alert.error("position required");
    else {
      this.setState({
        isLoading: true,
      });
      var newData = {
        firstname: this.state.registerUserData.firstname,
        lastname: this.state.registerUserData.lastname,
        email: this.state.registerUserData.email,
        position: this.state.registerUserData.position,
        usertype: this.state.registerUserData.usertype,
      };

      this.context
        .sendrequest(
          "createuserdirectly",
          newData,
          (boolean) => this.setState({ isLoading: boolean }),
          this.props.alert.error
        )
        .then((response) => {
          if (response) {
            if (response.data.success === true) {
              this.setState({
                newUserSuccessful: true,
                showForm: false,
              });

              const createdUser = response.data.data;
              console.log(createdUser);

              if (createdUser.usertype === 1) {
                this.setState({
                  userList: [...this.state.userList, createdUser],
                });
              } else if (createdUser.usertype === 2) {
                this.setState({
                  managerList: [...this.state.managerList, createdUser],
                });
              } else if (createdUser.usertype === 3) {
                this.setState({
                  adminList: [...this.state.adminList, createdUser],
                });
              }
              this.setState({
                isLoading: false,
                usersList: [...this.state.usersList, createdUser],
              });
            } else if (response.data.success === false) {
              this.setState({
                newUserSuccessful: false,
                isLoading: false,
                showForm: false,
              });
              this.handleError(response.data.message);
            }
          } else
            this.setState({
              newUserSuccessful: false,
              isLoading: false,
              // showForm: false,
            });
        })
        .catch((e) => {
          this.setState({
            newUserSuccessful: false,
            isLoading: false,
            // showForm: false,
          });
        });
    }
  };
  onChangeView = (view) =>
    this.setState({
      view: view,
      searchValue: "",
    });
  filterUsers = (value) => {
    var e = {
      target: {
        value: value,
      },
    };
    this.setState({
      filter: e.target.value,
    });
    if (this.state.groupedby === "Customer") {
      var statsusers = [];
      let filter = e.target.value;
      let customers = [];
      if (filter === "First Name") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.firstname > b.firstname
              ? 1
              : a.firstname === b.firstname
              ? a.lastname > b.lastname
                ? 1
                : -1
              : -1
          );
        });
      } else if (filter === "Last Name") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.lastname > b.lastname
              ? 1
              : a.lastname === b.lastname
              ? a.firstname > b.firstname
                ? 1
                : -1
              : -1
          );
        });
      } else if (filter === "Username") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) => (a.email > b.email ? 1 : -1));
        });
      } else if (filter === "Position") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.position > b.position
              ? 1
              : a.position === b.position
              ? a.firstname > b.firstname
                ? 1
                : -1
              : -1
          );
        });
      } else if (filter === "Last Activity") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) => {
            if (moment(a.lastSeen.date) > moment(b.lastSeen.date)) return -1;
            else if (moment(a.lastSeen.date) < moment(b.lastSeen.date))
              return 1;
            else if (
              a.hoursWork.today.totalHours > b.hoursWork.today.totalHours
            )
              return -1;
          });
        });
      } else if (filter === "This Week") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.hoursWork.thisWeek.totalHours > b.hoursWork.thisWeek.totalHours
              ? -1
              : 1
          );
        });
      } else if (filter === "Last Week") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.hoursWork.lastWeek.totalHours > b.hoursWork.lastWeek.totalHours
              ? -1
              : 1
          );
        });
      } else if (filter === "This Month") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.hoursWork.thisMonth.totalHours > b.hoursWork.thisMonth.totalHours
              ? -1
              : 1
          );
        });
      } else if (filter === "Last Month") {
        this.state.usersbycustomer.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.hoursWork.lastMonth.totalHours > b.hoursWork.lastMonth.totalHours
              ? -1
              : 1
          );
        });
      }
    } else if (this.state.groupedby === "Skills") {
      var statsusers = [];
      let filter = e.target.value;
      let customers = [];
      if (filter === "First Name") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.firstname > b.firstname
              ? 1
              : a.firstname === b.firstname
              ? a.lastname > b.lastname
                ? 1
                : -1
              : -1
          );
        });
      } else if (filter === "Last Name") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.lastname > b.lastname
              ? 1
              : a.lastname === b.lastname
              ? a.firstname > b.firstname
                ? 1
                : -1
              : -1
          );
        });
      } else if (filter === "Username") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.sort((a, b) => (a.email > b.email ? 1 : -1));
        });
      } else if (filter === "Position") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.position > b.position
              ? 1
              : a.position === b.position
              ? a.firstname > b.firstname
                ? 1
                : -1
              : -1
          );
        });
      } else if (filter === "Last Activity") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.forEach((u) => {
            if (u.lastSeen.date === "") {
              console.log(u.lastSeen);
            }
          });
          customer.users.sort((a, b) => {
            try {
              if (moment(a.lastSeen.date) > moment(b.lastSeen.date)) return -1;
              else if (moment(a.lastSeen.date) < moment(b.lastSeen.date))
                return 1;
              else if (
                a.hoursWork.today.totalHours > b.hoursWork.today.totalHours
              )
                return -1;
            } catch (err) {
              return 1;
            }
          });
        });
      } else if (filter === "This Week") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.hoursWork.thisWeek.totalHours > b.hoursWork.thisWeek.totalHours
              ? -1
              : 1
          );
        });
      } else if (filter === "Last Week") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.hoursWork.lastWeek.totalHours > b.hoursWork.lastWeek.totalHours
              ? -1
              : 1
          );
        });
      } else if (filter === "This Month") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.hoursWork.thisMonth.totalHours > b.hoursWork.thisMonth.totalHours
              ? -1
              : 1
          );
        });
      } else if (filter === "Last Month") {
        this.state.usersBySkills.forEach((customer) => {
          customer.users.sort((a, b) =>
            a.hoursWork.lastMonth.totalHours > b.hoursWork.lastMonth.totalHours
              ? -1
              : 1
          );
        });
      }
    } else {
      var user = [];
      var admins = [];
      var sysadmins = [];
      var managers = [];
      var pending = [];

      let filter = e.target.value;

      if (filter === "First Name") {
        this.state.userList.sort((a, b) =>
          a.firstname > b.firstname
            ? 1
            : a.firstname === b.firstname
            ? a.lastname > b.lastname
              ? 1
              : -1
            : -1
        );
        this.state.managerList.sort((a, b) =>
          a.firstname > b.firstname
            ? 1
            : a.firstname === b.firstname
            ? a.lastname > b.lastname
              ? 1
              : -1
            : -1
        );
        this.state.adminList.sort((a, b) =>
          a.firstname > b.firstname
            ? 1
            : a.firstname === b.firstname
            ? a.lastname > b.lastname
              ? 1
              : -1
            : -1
        );
        this.state.sysAdminList.sort((a, b) =>
          a.firstname > b.firstname
            ? 1
            : a.firstname === b.firstname
            ? a.lastname > b.lastname
              ? 1
              : -1
            : -1
        );
        this.state.pendingList.sort((a, b) =>
          a.firstname > b.firstname
            ? 1
            : a.firstname === b.firstname
            ? a.lastname > b.lastname
              ? 1
              : -1
            : -1
        );
      } else if (filter === "Last Name") {
        this.state.userList.sort((a, b) =>
          a.lastname > b.lastname
            ? 1
            : a.lastname === b.lastname
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
        this.state.managerList.sort((a, b) =>
          a.lastname > b.lastname
            ? 1
            : a.lastname === b.lastname
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
        this.state.adminList.sort((a, b) =>
          a.lastname > b.lastname
            ? 1
            : a.lastname === b.lastname
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
        this.state.sysAdminList.sort((a, b) =>
          a.lastname > b.lastname
            ? 1
            : a.lastname === b.lastname
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
      } else if (filter === "Username") {
        this.state.userList.sort((a, b) => (a.email > b.email ? 1 : -1));
        this.state.managerList.sort((a, b) => (a.email > b.email ? 1 : -1));
        this.state.adminList.sort((a, b) => (a.email > b.email ? 1 : -1));
        this.state.sysAdminList.sort((a, b) => (a.email > b.email ? 1 : -1));
        this.state.pendingList.sort((a, b) => (a.email > b.email ? 1 : -1));
      } else if (filter === "Position") {
        this.state.userList.sort((a, b) =>
          a.position > b.position
            ? 1
            : a.position === b.position
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
        this.state.managerList.sort((a, b) =>
          a.position > b.position
            ? 1
            : a.position === b.position
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
        this.state.adminList.sort((a, b) =>
          a.position > b.position
            ? 1
            : a.position === b.position
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
        this.state.sysAdminList.sort((a, b) =>
          a.position > b.position
            ? 1
            : a.position === b.position
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
        this.state.pendingList.sort((a, b) =>
          a.position > b.position
            ? 1
            : a.position === b.position
            ? a.firstname > b.firstname
              ? 1
              : -1
            : -1
        );
      } else if (filter === "Last Activity") {
        this.state.userList.sort((a, b) => {
          if (moment(a.lastSeen.date) > moment(b.lastSeen.date)) return -1;
          else if (moment(a.lastSeen.date) < moment(b.lastSeen.date)) return 1;
          else if (a.hoursWork.today.totalHours > b.hoursWork.today.totalHours)
            return -1;
        });
        this.state.managerList.sort((a, b) => {
          if (moment(a.lastSeen.date) > moment(b.lastSeen.date)) return -1;
          else if (moment(a.lastSeen.date) < moment(b.lastSeen.date)) return 1;
          else if (a.hoursWork.today.totalHours > b.hoursWork.today.totalHours)
            return -1;
        });
        this.state.adminList.sort((a, b) => {
          if (moment(a.lastSeen.date) > moment(b.lastSeen.date)) return -1;
          else if (moment(a.lastSeen.date) < moment(b.lastSeen.date)) return 1;
          else if (a.hoursWork.today.totalHours > b.hoursWork.today.totalHours)
            return -1;
        });
        this.state.sysAdminList.sort((a, b) => {
          if (moment(a.lastSeen.date) > moment(b.lastSeen.date)) return -1;
          else if (moment(a.lastSeen.date) < moment(b.lastSeen.date)) return 1;
          else if (a.hoursWork.today.totalHours > b.hoursWork.today.totalHours)
            return -1;
        });
      } else if (filter === "This Week") {
        this.state.userList.sort((a, b) =>
          a.hoursWork.thisWeek.totalHours > b.hoursWork.thisWeek.totalHours
            ? -1
            : 1
        );
        this.state.managerList.sort((a, b) =>
          a.hoursWork.thisWeek.totalHours > b.hoursWork.thisWeek.totalHours
            ? -1
            : 1
        );
        this.state.adminList.sort((a, b) =>
          a.hoursWork.thisWeek.totalHours > b.hoursWork.thisWeek.totalHours
            ? -1
            : 1
        );
        this.state.sysAdminList.sort((a, b) =>
          a.hoursWork.thisWeek.totalHours > b.hoursWork.thisWeek.totalHours
            ? -1
            : 1
        );
      } else if (filter === "Last Week") {
        this.state.userList.sort((a, b) =>
          a.hoursWork.lastWeek.totalHours > b.hoursWork.lastWeek.totalHours
            ? -1
            : 1
        );
        this.state.managerList.sort((a, b) =>
          a.hoursWork.lastWeek.totalHours > b.hoursWork.lastWeek.totalHours
            ? -1
            : 1
        );
        this.state.adminList.sort((a, b) =>
          a.hoursWork.lastWeek.totalHours > b.hoursWork.lastWeek.totalHours
            ? -1
            : 1
        );
        this.state.sysAdminList.sort((a, b) =>
          a.hoursWork.lastWeek.totalHours > b.hoursWork.lastWeek.totalHours
            ? -1
            : 1
        );
      } else if (filter === "This Month") {
        this.state.userList.sort((a, b) =>
          a.hoursWork.thisMonth.totalHours > b.hoursWork.thisMonth.totalHours
            ? -1
            : 1
        );
        this.state.managerList.sort((a, b) =>
          a.hoursWork.thisMonth.totalHours > b.hoursWork.thisMonth.totalHours
            ? -1
            : 1
        );
        this.state.adminList.sort((a, b) =>
          a.hoursWork.thisMonth.totalHours > b.hoursWork.thisMonth.totalHours
            ? -1
            : 1
        );
        this.state.sysAdminList.sort((a, b) =>
          a.hoursWork.thisMonth.totalHours > b.hoursWork.thisMonth.totalHours
            ? -1
            : 1
        );
      } else if (filter === "Last Month") {
        this.state.userList.sort((a, b) =>
          a.hoursWork.lastMonth.totalHours > b.hoursWork.lastMonth.totalHours
            ? -1
            : 1
        );
        this.state.managerList.sort((a, b) =>
          a.hoursWork.lastMonth.totalHours > b.hoursWork.lastMonth.totalHours
            ? -1
            : 1
        );
        this.state.adminList.sort((a, b) =>
          a.hoursWork.lastMonth.totalHours > b.hoursWork.lastMonth.totalHours
            ? -1
            : 1
        );
        this.state.sysAdminList.sort((a, b) =>
          a.hoursWork.lastMonth.totalHours > b.hoursWork.lastMonth.totalHours
            ? -1
            : 1
        );
      }
    }
  };

  deleteNewUser = (index) => {
    // var element = document.getElementById(index)
    // element.parentNode.removeChild(element);
    var array = this.state.newUsers;
    array.splice(index, 1);
    this.setState({
      newUsers: array,
    });
  };

  deleteEmpty = (index) => {
    var array = this.state.newUsers;
    // if (array[index].firstname === "")
    //      if (array[index].email === "")
    //           if (array[index].position === "")
    if (array[index - 1])
      if (array[index - 1].firstname === "")
        if (array[index - 1].email === "")
          if (array[index - 1].position === "") {
            this.deleteNewUser(index - 1, 1);
          }
  };

  defaultView = () => {
    if (this.state.groupedby === "User Type")
      return (
        <>
          <h1
            id="headingforsub"
            onClick={() => this.setState({ showUsers: !this.state.showUsers })}
          >
            Users
            <div className="headingButton">
              ({this.state.userList.length})&nbsp;&nbsp;
              {this.state.showUsers ? <FiChevronDown /> : <FiChevronLeft />}
            </div>
          </h1>

          <section
            className={cx("toggler", {
              "toggler--active": this.state.showUsers,
            })}
          >
            <CSSTransition
              in={this.state.showUsers}
              appear={true}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div
                className="structureContainer"
                style={{
                  flexDirection: this.state.view === "list" ? "column" : "row",
                }}
              >
                {this.state.userList.length > "0" ? (
                  this.state.view === "stats" ? (
                    <div className="statscards">
                      <StatsCards
                        users={this.state.userList}
                        filter={this.state.filter}
                      />
                    </div>
                  ) : (
                    <Cards
                      whichUsers="currentUsers"
                      toggleCurrentUserDetails={this.currentUser}
                      toggleShowProfile={this.viewProfile}
                      users={this.state.userList}
                      defaultIcon={defaultIcon}
                      state={this.state}
                      pathname="ourStructure"
                      view={this.state.view}
                    />
                  )
                ) : (
                  <section>
                    <h4>There are no users to display</h4>
                  </section>
                )}
              </div>
            </CSSTransition>
          </section>

          <h1
            id="headingforsub"
            onClick={() =>
              this.setState({ showManagers: !this.state.showManagers })
            }
          >
            Managers
            <div className="headingButton">
              ({this.state.managerList.length})&nbsp;&nbsp;
              {this.state.showManagers ? <FiChevronDown /> : <FiChevronLeft />}
            </div>
          </h1>
          <CSSTransition
            in={this.state.showManagers}
            appear={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div
              className="structureContainer"
              style={{
                flexDirection: this.state.view === "list" ? "column" : "row",
              }}
            >
              {this.state.managerList.length > "0" ? (
                this.state.view === "stats" ? (
                  <div className="statscards">
                    <StatsCards
                      users={this.state.managerList}
                      filter={this.state.filter}
                    />
                  </div>
                ) : (
                  <Cards
                    whichUsers="currentUsers"
                    toggleCurrentUserDetails={this.currentUser}
                    toggleShowProfile={this.viewProfile}
                    users={this.state.managerList}
                    defaultIcon={defaultIcon}
                    state={this.state}
                    pathname="ourStructure"
                    view={this.state.view}
                  />
                )
              ) : (
                <section>
                  <h4>There are no managers to display</h4>
                </section>
              )}
            </div>
          </CSSTransition>

          <h1
            id="headingforsub"
            onClick={() =>
              this.setState({ showAdmins: !this.state.showAdmins })
            }
          >
            Administrators
            <div className="headingButton">
              ({this.state.adminList.length})&nbsp;&nbsp;
              {this.state.showAdmins ? <FiChevronDown /> : <FiChevronLeft />}
            </div>
          </h1>

          <CSSTransition
            in={this.state.showAdmins}
            appear={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div
              className="structureContainer"
              style={{
                flexDirection: this.state.view === "list" ? "column" : "row",
              }}
            >
              {this.state.adminList.length > "0" ? (
                this.state.view === "stats" ? (
                  <div className="statscards">
                    <StatsCards
                      users={this.state.adminList}
                      filter={this.state.filter}
                    />
                  </div>
                ) : (
                  <Cards
                    whichUsers="currentUsers"
                    toggleCurrentUserDetails={this.currentUser}
                    toggleShowProfile={this.viewProfile}
                    users={this.state.adminList}
                    defaultIcon={defaultIcon}
                    state={this.state}
                    pathname="ourStructure"
                    view={this.state.view}
                  />
                )
              ) : (
                <section>
                  <h4>There are no administrators to display</h4>
                </section>
              )}
            </div>
          </CSSTransition>

          <h1
            id="headingforsub"
            onClick={() =>
              this.setState({ showPending: !this.state.showPending })
            }
          >
            Pending Users
            <div className="headingButton">
              ({this.state.pendingList.length})&nbsp;&nbsp;
              {this.state.showPending ? <FiChevronDown /> : <FiChevronLeft />}
            </div>
          </h1>
          <CSSTransition
            in={this.state.showPending}
            appear={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div
              className="structureContainer"
              style={{
                flexDirection: this.state.view === "list" ? "column" : "row",
              }}
            >
              {this.state.pendingList.length !== 0 ? (
                <Cards
                  users={this.state.pendingList}
                  defaultIcon={defaultIcon}
                  view={this.state.view}
                  whichUsers="penging"
                />
              ) : (
                <section>
                  <h4>There are no pending users to display</h4>
                </section>
              )}
            </div>
          </CSSTransition>
        </>
      );
    else if (this.state.groupedby === "Customer") {
      return this.state.usersbycustomer.map((customer) => {
        return (
          <StructuredCards
            state={this.state}
            customer={customer}
            currentUser={this.currentUser}
            viewProfile={this.viewProfile}
            filter={this.state.filter}
          />
        );
      });
    } else if (this.state.groupedby === "Skills") {
      return this.state.usersBySkills.map((customer) => {
        return (
          <StructuredCards
            state={this.state}
            customer={customer}
            currentUser={this.currentUser}
            viewProfile={this.viewProfile}
            filter={this.state.filter}
          />
        );
      });
    }
  };

  showstatsview = () => {
    this.filterUsers("Last Activity");
    this.setState({
      view: "stats",
    });
  };

  groupby = (value) => {
    this.state.groupedby = value;

    this.filterUsers(this.state.filter);
  };

  render() {
    var { isLoading, newUsers } = this.state;
    const newuserlength = newUsers.length;
    if (isLoading) {
      return (
        <div className="pageContainer">
          <div className="loginContainer">
            <Loading />
          </div>
        </div>
      );
    } else {
      return (
        <>
          {this.state.showForm ? (
            <div id="AddUserForm">
              <form>
                <p className="adduser">
                  Add User
                  <button
                    className="cancelButton"
                    onClick={this.toggleAddUserButton}
                  >
                    X
                  </button>
                </p>
                <input
                  type="text"
                  placeholder="First name"
                  value={this.state.registerUserData.firstname}
                  onChange={this.onNameChange}
                />
                <input
                  type="text"
                  placeholder="Last name"
                  value={this.state.registerUserData.lastname}
                  onChange={this.onLastNameChange}
                />
                <input
                  type="text"
                  placeholder="Email"
                  value={this.state.registerUserData.email}
                  onChange={this.onEmailChange}
                />
                <input
                  type="text"
                  placeholder="Position"
                  value={this.state.registerUserData.position}
                  onChange={this.onPositionChange}
                />
                <div className="userTypeOptions">
                  <RadioGroup
                    value={this.state.registerUserData.usertype}
                    onChange={this.onUserTypeChange}
                    horizontal
                  >
                    <RadioButton value="1" pointColor="#00dd21">
                      User
                    </RadioButton>
                    <RadioButton value="2" pointColor="#00dd21">
                      Manager
                    </RadioButton>
                    {this.context.user.usertype > 2 ? (
                      <RadioButton value="3" pointColor="#00dd21">
                        Admin
                      </RadioButton>
                    ) : null}
                  </RadioGroup>
                </div>
                <button
                  className="AddUserSubmit"
                  type="button"
                  onClick={this.onSubmitNewUser}
                >
                  Submit
                </button>
              </form>
            </div>
          ) : null}
          <div className="pageContainer">
            <CSSTransition
              in={false}
              appear={true}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div className="addUsersContainer">
                <div className="addUsersCardsContainer">
                  <div className="addUsersCardsContainerHeader">
                    <h4>Add Users</h4>
                    <div className="addUsersContainerClose">
                      <button type="button" onClick={this.toggleAddUserButton}>
                        x
                      </button>
                    </div>
                  </div>
                  <div className="userCards">
                    {this.state.newUsers.map((user, i) => {
                      if (this.state.newUsers.length === i + 1)
                        return (
                          <AddUser
                            id={i}
                            context={this.context}
                            user={user}
                            deleteNewUser={() => this.deleteNewUser(i)}
                            showdelete={false}
                            deleteEmpty={() => this.deleteEmpty(i)}
                            lastItemActive={() => {
                              var newUsers = this.state.newUsers;
                              newUsers.push({
                                firstname: "",
                                lastname: "",
                                email: "",
                                position: "",
                                usertype: "1",
                                valid: true,
                              });
                              this.setState({
                                newUsers: newUsers,
                              });
                            }}
                            newUsers={this.state.newUsers}
                          />
                        );
                      else
                        return (
                          <AddUser
                            id={i}
                            context={this.context}
                            user={user}
                            deleteEmpty={() => this.deleteEmpty(i)}
                            deleteNewUser={this.deleteNewUser}
                            newUsers={this.state.newUsers}
                            showdelete={
                              this.state.newUsers.length === 1 ? false : true
                            }
                          />
                        );
                    })}
                  </div>
                  <div className="addUserMenu">
                    <button type="button">Submit</button>
                  </div>
                </div>
              </div>
            </CSSTransition>
            <section className="menuUsers">
              {this.context.user.usertype > 1 ? (
                <button onClick={() => this.showstatsview()}>
                  <BsGraphUp />
                </button>
              ) : null}
              <button onClick={() => this.onChangeView("grid")}>
                <FiGrid title="Grid view" />
              </button>
              <button onClick={() => this.onChangeView("list")}>
                <FiList title="List view" />
              </button>
              {this.context.user.usertype === 1 ? null : (
                <button title="Add user" onClick={this.toggleAddUserButton}>
                  <FiPlus />
                </button>
              )}
            </section>
            <h1 id="heading">
              All Users
              <div className="searchContainer">
                <span className="groupby"> Group by:</span>
                <div class="dropdown">
                  <button className="dropbtn">
                    <h1>{this.state.groupedby}</h1>
                  </button>
                  <div className="dropdown-content">
                    <div
                      id="Customers"
                      style={{
                        background:
                          this.state.groupedby === "User Type"
                            ? "#ccc"
                            : "#f1f1f1",
                      }}
                      onClick={() => this.groupby("User Type")}
                      value="User Type"
                    >
                      User Type
                    </div>
                    <div
                      id="Customers"
                      style={{
                        background:
                          this.state.groupedby === "Customer"
                            ? "#ccc"
                            : "#f1f1f1",
                      }}
                      onClick={() => this.groupby("Customer")}
                      value="Customer"
                    >
                      Customer
                    </div>
                    <div
                      id="Customers"
                      style={{
                        background:
                          this.state.groupedby === "Skills"
                            ? "#ccc"
                            : "#f1f1f1",
                      }}
                      onClick={() => this.groupby("Skills")}
                      value="Skills"
                    >
                      Skills
                    </div>
                  </div>
                </div>
                <span className="groupby"> Sort by:</span>
                <div class="dropdown">
                  <button className="dropbtn">
                    <h1>{this.state.filter}</h1>
                  </button>
                  {this.state.view === "stats" ? (
                    <div className="dropdown-content">
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "First Name"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("Last Activity")}
                        value="Last Activity"
                      >
                        Last Activity
                      </div>
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "Last Name"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("This Week")}
                        value="This Week"
                      >
                        This Week
                      </div>
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "Username"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("Last Week")}
                        value="Last Week"
                      >
                        Last Week
                      </div>
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "Position"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("This Month")}
                        value="This Month"
                      >
                        This Month
                      </div>
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "Position"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("Last Month")}
                        value="Last Month"
                      >
                        Last Month
                      </div>
                    </div>
                  ) : (
                    <div className="dropdown-content">
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "First Name"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("First Name")}
                        value="First Name"
                      >
                        First Name
                      </div>
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "Last Name"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("Last Name")}
                        value="Last Name"
                      >
                        Last Name
                      </div>
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "Username"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("Username")}
                        value="Username"
                      >
                        Username
                      </div>
                      <div
                        id="Customers"
                        style={{
                          background:
                            this.state.filter === "Position"
                              ? "#ccc"
                              : "#f1f1f1",
                        }}
                        onClick={() => this.filterUsers("Position")}
                        value="Position"
                      >
                        Position
                      </div>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Search..."
                  value={this.state.searchValue}
                  onChange={this.getSearchedUsers}
                />
                <div className="searchIcon">
                  <FiSearch />
                </div>
              </div>
            </h1>
            <hr id="line" />
            <this.defaultView />
          </div>
        </>
      );
    }
  }
}
export default withAlert()(OurStructure);
