import React, { Component } from 'react';
import '../style/resetpassword.scss';
import axios from 'axios';
import { AppContext } from '../appcontext';
import { Redirect } from 'react-router-dom';
import { withAlert } from 'react-alert';
import Loading from '../components/loading';

const qs = require('query-string');

class passwordReset extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            confirmpassword: '',
            isTokenValid: false,
            email: '',
            isLoading: false,
            resetCompleted: false,
        }
    }
    sendrequest = () => this.context.sendrequest

    componentDidMount() {
        this.setState({ isLoading: true });
        var data = qs.stringify({
            firstname: this.props.match.params.firstname,
            token: this.props.match.params.token
        })
        axios.post('http://localhost/api:5000/verifyresettoken', data).then(response => {
            if (response.data.success === true) {
                this.setState({
                    firstname: this.props.match.params.firstname
                })
                this.setState({ isLoading: false, isTokenValid: true })
            } else {
                this.setState({ isLoading: false })
            }
        })
    }

    handleResetPassword = async () => {
        this.setState({ isLoading: true });
        if (this.state.password === "" || this.state.confirmpassword === "") {
            const alert = this.props.alert
            alert.error("Both fields are required")
        }
        else if (this.state.password !== this.state.confirmpassword) {
            const alert = this.props.alert
            alert.error("Passwords don't match")
        }
        else {
            var data = qs.stringify({
                password: this.state.password,
                confirmpassword: this.state.confirmpassword,
                token: this.props.match.params.token,
                firstname: this.state.firstname
            })
            await axios.post(`${process.env.REACT_APP_BASE_URL}/resetpassword`, data)
                .then(response => {
                    if (response.data.success === true) {
                        this.setState({
                            resetCompleted: true,
                            isLoading: false
                        })
                        const alert = this.props.alert
                        alert.success(response.data.message)
                    }
                    else {
                        this.setState({
                            resetCompleted: true,
                            isLoading: false, isTokenValid: false
                        })
                        // const alert = this.props.alert
                        // alert.error(response.data.message)
                    }
                }).catch(error => {
                    console.error('timeout exceeded')
                    this.setState({
                        isLoading: false
                    })
                    // this.context.logout()
                    const alert = this.props.alert
                    alert.error("Something went wrong. Please try again")
                })
        }
    }



    onTextboxChangePassword = (event) => {
        this.setState({
            password: event.target.value,
        })
    }

    onTextboxChangeConfirmPassword = (event) => {
        this.setState({
            confirmpassword: event.target.value,
        })
    }
    onShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }
    render() {
        if (this.state.isLoading && !this.state.password === "" && !this.state.confirmpassword === "" && this.state.confirmpassword === this.state.password)
            return <Loading />
        else {
            if (!this.state.resetCompleted)
                return (
                    <body>
                        <div className="backImage">
                            <div>
                                <img src={require('../style/img/header-logo.png')} alt="Condor Green Logo" />
                            </div>
                        </div>
                        <div className="resetpassoword">
                            <form>
                                <h4>Create new password</h4>
                                <input type="password" placeholder="Password" onChange={this.onTextboxChangePassword} id="passwordField" required />
                                <input type="password" placeholder="Confirm password" onChange={this.onTextboxChangeConfirmPassword} id="confrimpasswordField" required />
                                <button className="AddUserSubmit" type="button" onClick={this.handleResetPassword}>Submit</button>
                            </form>
                        </div>

                    </body>

                )
            else
                return <Redirect to="/" />
        }
    }
}
export default withAlert()(passwordReset)   