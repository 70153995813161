import React, { Component } from 'react';
import Trash from '../style/img/trash.png';
import ImageUploader from "react-images-upload";
import "../style/profile.scss";
import Loading from '../components/loading';
import axios from 'axios';
import { AppContext } from '../appcontext';
import "@kenshooui/react-multi-select/dist/style.css"
import * as  Cookies from 'js-cookie';
import defaultImage from '../style/img/default-user-image.jpeg';
import { FiEdit3 } from "react-icons/fi";
import { FiPhoneCall } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { FiMapPin } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withAlert } from 'react-alert';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import SkillCards from '../components/skillcards';
import { MdAssignmentReturn } from "react-icons/md";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"

class Profile extends Component {
     static contextType = AppContext;
     constructor(props) {
          super(props);
          this.state = {
               readonly: true,
               showSaved: false,
               isLoading: true,
               user: {},
               showUpdateForm: false,
               picturesChosen: 0,
               updated: {},
               editemail: false,
               oldemail: "",
               showEditButton: false,
               alternativecontact: {},
               password: "",
               position: "",
               email: "",
               firstname: "",
               lastname: "",
               bio: "",
               skills: [],
               image: "",
               active: 1,
               landLine: [],
               mobile: [],
               fax: [],
               homeaddress: "",
               usertype: 1,
               _id: "",
               __v: 0,
               oldPassword: "",
               newPassword: "",
               confirmPassword: "",
               oldemail: "",
               isadmin: false,
               admin: {},
               showcustomers: false,
               customers: [],
               resourcecustomers: [],
               resourcecustomersprev: []
          }
          this.profileImage = [];
     }
     onActiveChange = (value) => {
          this.setState({
               active: parseInt(value)
          })
     }
     onDrop = (pictureFiles, pictureDataURLs) => {
          this.setState({
               picturesChosen: 1
          })
          this.profileImage = pictureDataURLs
          if (this.profileImage.length > 1) {
               this.profileImage.shift()
          }

     }
     escFunctionForm = (event) => {
          if (event.keyCode === 27) {
               this.setState({
                    showUpdateForm: false
               })
          }
     }
     componentWillUnmount() {
          document.removeEventListener("keydown", this.escFunctionForm, false);
          localStorage.removeItem("previous-selected-user");
     }
     componentDidMount() {
          document.addEventListener("keydown", this.escFunctionForm, false);
          this.setState({
               isLoading: true,
          })
          var LS = localStorage.getItem("previous-selected-user");
          LS = JSON.parse(LS)
          var prevprops = {
               employee: LS ? LS.employee : this.props.employee,
               fromUsers: LS ? LS.fromUsers : this.props.fromUsers
          }
          // localStorage.setItem("previous-selected-user", JSON.stringify(prevprops));
          if (prevprops.employee) {
               this.previousprofile = prevprops.employee;
               this.previousprofile.skills = prevprops.employee.skills ? prevprops.employee.skills.length > 0 ? prevprops.employee.skills : [] : []
               this.profileImage[0] = prevprops.employee.image;
               this.setState({
                    alternativecontact: {},
                    password: "",
                    position: prevprops.employee.position,
                    email: prevprops.employee.email,
                    firstname: prevprops.employee.firstname,
                    lastname: prevprops.employee.lastname,
                    bio: prevprops.employee.bio,
                    skills: prevprops.employee.skills ? prevprops.employee.skills.length > 0 ? prevprops.employee.skills : [] : [],
                    image: prevprops.employee.image,
                    active: this.previousprofile.active,
                    landLine: prevprops.employee.landline,
                    mobile: typeof prevprops.employee.mobile === "string" ? [prevprops.employee.mobile, ""] : prevprops.employee.mobile,
                    fax: prevprops.employee.fax,
                    homeaddress: prevprops.employee.homeaddress,
                    usertype: prevprops.employee.usertype,
                    _id: prevprops.employee._id,
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                    oldemail: "",
                    fromUsers: prevprops.fromUsers,
                    showEditButton: prevprops.employee.email === this.context.user.email || this.context.user.usertype > 1 ? true : false,
                    picturesChosen: prevprops.employee.image ? 1 : 0,
                    isLoading: false,
                    isadmin: this.context.user.usertype > 1 ? 1 : 0,
               })
               this.state.active = this.previousprofile.active
               this.state.isadmin = this.context.user.usertype > 1 ? 1 : 0;
               prevprops = {
                    employee: {
                         position: prevprops.employee.position,
                         email: prevprops.employee.email,
                         firstname: prevprops.employee.firstname,
                         lastname: prevprops.employee.lastname,
                         bio: prevprops.employee.bio,
                         skills: prevprops.employee.skills ? prevprops.employee.skills.length > 0 ? prevprops.employee.skills : [] : [],
                         image: prevprops.employee.image,
                         active: prevprops.employee.active,
                         landLine: prevprops.employee.landline,
                         mobile: typeof prevprops.employee.mobile === "string" ? [prevprops.employee.mobile, ""] : prevprops.employee.mobile,
                         fax: prevprops.employee.fax,
                         homeaddress: prevprops.employee.homeaddress,
                         usertype: prevprops.employee.usertype,
                         _id: prevprops.employee._id,
                    }
               }
               localStorage.setItem("previous-selected-user", JSON.stringify(prevprops));
          }
          else {
               this.setState({
                    alternativecontact: {},
                    password: "",
                    position: this.context.user.position,
                    email: this.context.user.email,
                    firstname: this.context.user.firstname,
                    lastname: this.context.user.lastname,
                    bio: this.context.user.bio,
                    skills: this.context.user.skills ? this.context.user.skills : [],
                    image: this.context.user.image,
                    active: this.context.user.active,
                    landLine: this.context.user.landline,
                    mobile: typeof this.context.user.mobile === "string" ? [this.context.user.mobile, ""] : this.context.user.mobile,
                    fax: this.context.user.fax,
                    homeaddress: this.context.user.homeaddress,
                    usertype: this.context.user.usertype,
                    _id: this.context.user._id,
               })
               this.context.sendrequest("getuser", null, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                    .then(response => {
                         if (response)
                              if (response.data.success) {
                                   this.previousprofile = response.data.user;
                                   this.previousprofile.oldPassword = "";
                                   this.previousprofile.newPassword = "";
                                   this.previousprofile.confirmPassword = "";
                                   this.previousprofile.oldemail = response.data.user.email;
                                   this.setState({
                                        updated: this.previousprofile,
                                        isadmin: this.context.user.usertype > 1 ? true : false,
                                        picturesChosen: this.previousprofile.image > "" ? 1 : 0,
                                        isLoading: false,
                                        showEditButton: true,
                                   })
                                   prevprops = {
                                        employee: this.previousprofile,
                                   }
                                   this.profileImage[0] = this.previousprofile.image
                                   localStorage.setItem("previous-selected-user", JSON.stringify(prevprops));
                              }
                    })

               }
               
          if(this.props.employee.email === this.context.user.email || this.context.user.usertype > 1){
               this.setState({
                    readonly : false
               })
          }
     }


     getUsertype = (usertype) => {
          if (usertype === 1)
               return "User"
          else if (usertype === 2)
               return "Manager"
          else if (usertype === 3)
               return "Administrator"
          else if (usertype === 4)
               return "System Administrator"
          else
               return "User"
     }

     onBioChange = (e) => {
          this.setState({
               bio: e.target.value
          })
     }
     onFaxChange = (e) => {
          this.setState({
               fax: e.target.value
          })
     }
     onEmailChange = (e) => {
          this.setState({
               email: e.target.value,
               editemail: true,
               oldemail: this.state.email,
          })
     }
     onMobileChangePrimary = (e) => {
          this.setState({
               mobile: [e.target.value, this.state.mobile[1]]
          })
     }
     onMobileChangeSecondary = (e) => {
          this.setState({
               mobile: [this.state.mobile[0], e.target.value,],
          })
     }
     onSkillsChange = (e) => {
          let newskills = e.target.value.trim().split(',');
          this.setState({
               skills: newskills
          })
     }
     onLandlineChange = (e) => {
          this.setState({
               landline: e.target.value,
          })
     }
     onLastNameChange = (e) => {
          this.setState({
               lastname: e.target.value,
          })
     }
     onPositionChange = (e) => {
          this.setState({
               position: e.target.value,
          })
     }
     onFirstNameChange = (e) => {
          this.setState({
               firstname: e.target.value,
          })
     }
     onHomeaddressChange = (e) => {
          this.setState({
               homeaddress: e.target.value,
          })
     }
     onCurrentPassword = (e) => {
          this.setState({
               oldPassword: e.target.value,
          })
     }
     onChangePasswordConfirm = (e) => {
          this.setState({
               confirmPassword: e.target.value,
          })
     }
     onNewPassword = (e) => {
          this.setState({
               newPassword: e.target.value,
          })
     }
     setRedirect = () => {
          if (this.state.Heading === "Profile") {
               this.toggleShowDetails()
          }
          else {
               this.setState({
                    redirect: true
               })
          }
     }
     renderRedirect = () => {
          if (this.state.redirect) {
               return <Redirect to={`/${this.context.user.firstname}/users`} />
          }
     }

     notification(message) {
          const alert = this.props.alert
          alert.success(message)
     }

     error(message) {
          const alert = this.props.alert
          alert.error(message)
     }

     onUpdateUser = async () => {
          this.setState({
               isLoading: true
          })
          if (this.state.firstname.trim() === "")
               this.error("First name required");
          else if (this.state.lastname.trim() === "")
               this.error("Last name required");
          else if (this.state.email.trim() === "")
               this.error("Email required");

          else if (this.state.oldPassword.trim() > "" && this.state.newPassword.trim() > "" && this.state.confirmPassword.trim() === "") {
               if (this.state.newPassword.length < 5)
                    this.error("Password too short");
               else
                    this.error("Please confirm the password");
          }
          // else if (this.state.oldPassword.trim() === "")
          //      this.error("Password required");
          else if (this.state.newPassword !== this.state.confirmPassword)
               this.error("Passwords do not match");
          else {
               // var skills = this.state.skills[0] ? this.state.skills : [this.state.skills]
               // for (let a = 0; a < skills.length; a++) {
               //      skills[a] = JSON.stringify({
               //           name: skills[a].name,
               //           years: skills[a].years,
               //           rating: skills[a].rating
               //      })
               // }
               // this.setState({
               //      skills: skills
               // })
               var data = {
                    bio: this.state.bio,
                    fax: this.state.fax,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    skills: this.state.skills[0] ? JSON.stringify(this.state.skills) : this.state.skills.length === 0 ? JSON.stringify([]) : JSON.stringify([this.state.skills]),
                    landline: this.state.landline,
                    position: this.state.position,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    homeaddress: this.state.homeaddress,
                    editemail: this.state.editemail,
                    oldemail: this.state.email,
                    oldpassword: this.state.oldPassword,
                    confirmpassword: this.state.confirmPassword,
                    password: this.state.newPassword,
                    image: this.state.picturesChosen === 1 ? this.profileImage[0] : "",
                    isadmin: this.state.isadmin,
                    admin: this.state.isadmin ? this.context.user.email : "",
                    active: this.state.active,
                    _id: this.state._id,
                    fromTimeSheet : false
               }
               await this.context.sendrequest("updateuser", data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                    .then(response => {
                         if (response)
                              if (response.data.success) {
                                   this.setState({
                                        showSaved: true,    
                                        showUpdateForm: false,
                                        picturesChosen: response.data.data.image > "" ? 1 : 0,
                                        alternativecontact: response.data.data.alternativecontact,
                                        oldemail: this.state.email,
                                        oldPassword: "",
                                        confirmPassword: "",
                                        newPassword: "",
                                        position: response.data.data.position,
                                        email: response.data.data.email,
                                        firstname: response.data.data.firstname,
                                        lastname: response.data.data.lastname,
                                        bio: response.data.data.bio,
                                        skills: response.data.data.skills ? response.data.data.skills[0] ? response.data.data.skills : response.data.data.skills.name ? [response.data.data.skills] : [] : [],
                                        image: response.data.data.image,
                                        active: response.data.data.active,
                                        landLine: response.data.data.landline,
                                        mobile: typeof response.data.data.mobile === "string" ? [response.data.data.mobile, ""] : response.data.data.mobile,
                                        fax: response.data.data.fax,
                                        homeaddress: response.data.data.homeaddress,
                                        usertype: response.data.data.usertype,
                                        _id: response.data.data._id,
                                        picturesChosen: this.previousprofile.image > "" ? 1 : 0,
                                        isadmin: this.context.user.usertype > 1 ? 1 : 0
                                   })
                                   var prevprops = {
                                        employee: {
                                             alternativecontact: response.data.data.alternativecontact,
                                             password: "",
                                             position: response.data.data.position,
                                             email: response.data.data.email,
                                             firstname: response.data.data.firstname,
                                             lastname: response.data.data.lastname,
                                             bio: response.data.data.bio,
                                             skills: response.data.data.skills ? response.data.data.skills : [],
                                             image: response.data.data.image,
                                             active: response.data.data.active,
                                             landLine: response.data.data.landline,
                                             mobile: typeof response.data.data.mobile === "string" ? [response.data.data.mobile, ""] : response.data.data.mobile,
                                             fax: response.data.data.fax,
                                             homeaddress: response.data.data.homeaddress,
                                             usertype: response.data.data.usertype,
                                             _id: response.data.data._id,
                                        },
                                        fromUsers: localStorage.getItem("previous-selected-user") ? JSON.parse(localStorage.getItem("previous-selected-user")).fromUsers : false
                                   }
                                   this.previousprofile = {
                                        alternativecontact: response.data.data.alternativecontact,
                                        password: "",
                                        position: response.data.data.position,
                                        email: response.data.data.email,
                                        firstname: response.data.data.firstname,
                                        lastname: response.data.data.lastname,
                                        bio: response.data.data.bio,
                                        skills: response.data.data.skills ? response.data.data.skills : [],
                                        image: response.data.data.image,
                                        active: response.data.data.active,
                                        landLine: response.data.data.landline,
                                        mobile: typeof response.data.data.mobile === "string" ? [response.data.data.mobile, ""] : response.data.data.mobile,
                                        fax: response.data.data.fax,
                                        homeaddress: response.data.data.homeaddress,
                                        usertype: response.data.data.usertype,
                                        _id: response.data.data._id,
                                   }
                                   this.profileImage[0] = response.data.data.image;
                                   localStorage.removeItem("previous-selected-user")
                                   localStorage.setItem("previous-selected-user", JSON.stringify(prevprops));
                                   this.setState({
                                        isLoading: false,
                                   })
                                   this.notification(response.data.message)
                                   setTimeout(() => {
                                        this.setState({
                                             showSaved: false
                                        })
                                      }, 5000);
                              }
                              else {
                                   this.setState({
                                        showUpdateForm: true,
                                   })
                                   this.error(response.data.message)
                              }
                    })
                    .catch(
                         this.setState({
                              isLoading: false
                         })
                    )
          }
          this.setState({
               isLoading: false
          })
     }

     mapSkills() {
          if (this.previousprofile.skills) {
               if (typeof this.previousprofile.skills === "string") {
               }
               else {
                    return this.previousprofile.skills.map(skill => {
                         return <label>{skill.name ? " " + skill.name + " " : " " + skill + " "}</label>
                    })
               }
          }
          else {
               return ""
          }
     }

     arrayforMulti(array) {
          let newArray = []
          array = array ? array : [];
          if (array[0]) {
               if (array[0].customername) {
                    for (let a = 0; a < array.length; a++) {
                         newArray.push({
                              id: array[a]._id,
                              label: array[a].customername,
                              object: array[a]
                         })
                    }
               }
               else if (array[0].firstname) {
                    for (let a = 0; a < array.length; a++) {
                         newArray.push({
                              id: array[a]._id,
                              label: array[a].firstname + " " + array[a].lastname,
                              object: array[a]
                         })
                    }
               }
               else {
                    for (let a = 0; a < array.length; a++) {
                         newArray.push({
                              id: array[a]._id,
                              label: array[a].name,
                              object: array[a]
                         })
                    }
               }
               return newArray
          }
     }
     Onshowcustomers = async () => {
          await this.context.sendrequest("getcustomers", null, () => this.setState({ isLoading: false }), this.props.alert.error)
               .then(async res => {
                    if (res)
                         if (res.data.success)
                              if (res.data.data) {
                                   res.data.data.sort((a, b) => a.customername > b.customername ? 1 : -1)
                                   this.setState({
                                        customers: res.data.data,
                                   })
                                   await this.context.sendrequest("getcustomer/resourcecustomers", { resourceId: this.previousprofile._id }, () => this.setState({ isLoading: false }), this.props.alert.error)
                                        .then(res => {
                                             if (res)
                                                  if (res.data.success)
                                                       if (res.data.data) {
                                                            res.data.data.sort((a, b) => a.customername > b.customername ? 1 : -1)
                                                            this.setState({
                                                                 resourcecustomers: res.data.data,
                                                                 resourcecustomersprev: this.arrayforMulti(res.data.data),
                                                                 showcustomers: true
                                                            })
                                                       }
                                        })

                              }
               })
     }

     Assignuser = (customers) => {
          //assign
          if (customers.length > this.state.resourcecustomersprev.length) {
               customers.forEach(async customer => {
                    if (this.state.resourcecustomersprev.includes(customer)) {
                         customer.object.resources.push(this.previousprofile._id)
                         var data = {
                              customername: customer.object.customername,
                              description: customer.object.description,
                              building: customer.object.building,
                              area: customer.object.area,
                              street: customer.object.street,
                              region: customer.object.region,
                              code: customer.object.code,
                              country: customer.object.country,
                              industry: customer.object.industry,
                              active: customer.object.active,
                              logo: customer.object.logo,
                              _id: customer.object._id,
                              contacts: customer.object.contacts,
                              resources: customer.object.resources
                         }
                         // await this.context.sendrequest('updatecustomer', data)
                    }
               })
          }
          //remove
          else {
               this.state.resourcecustomersprev.forEach(async customer => {
                    if (customers.includes(customer)) {
                         customer.object.resources = customer.object.resources.sort((x, y) => { return x.name === this.previousprofile._id ? -1 : y.name === this.previousprofile._id ? 1 : 0; });
                         var data = {
                              customername: customer.object.customername,
                              description: customer.object.description,
                              building: customer.object.building,
                              area: customer.object.area,
                              street: customer.object.street,
                              region: customer.object.region,
                              code: customer.object.code,
                              country: customer.object.country,
                              industry: customer.object.industry,
                              active: customer.object.active,
                              logo: customer.object.logo,
                              _id: customer.object._id,
                              contacts: customer.object.contacts,
                              resources: customer.object.resources
                         }
                         // await this.context.sendrequest('updatecustomer', data)
                    }
               })
          }
     }
     render() {
          if (this.state.isLoading) {
               return <Loading />
          }
          // <div className="mainmenu">
          //      {/* <button onClick={() => this.Onshowcustomers()} title="Edit profile">
          //           <MdAssignmentReturn />
          //      </button> */}
          //      {
          //           this.state.showEditButton ?
          //                <button onClick={() => this.setState({ showUpdateForm: true })} title="Edit profile">
          //                     <FiEdit3 />
          //                </button>
          //                : null
          //      }
          // </div>
          else {
               return (
                    <div className="pageContainer">
                         {/* commentend sections belongs here */}
                         <CSSTransition
                              in={this.state.showcustomers}
                              appear={true}
                              timeout={300}
                              classNames="fade"
                              unmountOnExit
                         >
                              <div className="showcustomers"
                                   onClick={(e) => {
                                        if (e.target.className === "showcustomers fade-enter-done") {
                                             this.setState({ showcustomers: false })
                                        }
                                   }}
                              >
                                   <div className="showcustomerscont">
                                        <MultiSelect
                                             items={this.arrayforMulti(this.state.customers)}
                                             // showSelectedItems={true}
                                             onChange={this.Assignuser}
                                             showSelectAll={true}
                                             selectedItems={this.arrayforMulti(this.state.resourcecustomers)}
                                        />
                                   </div>
                              </div>
                         </CSSTransition>
                         {this.renderRedirect()}
                         <h1 id="heading">
                              {/* {
                                   this.previousprofile.email !== this.context.user.email ?
                                        <button className="backButton" onClick={this.setRedirect}>
                                             <IoIosArrowBack title="Back" />
                                        </button> : null
                              } */}
                               Profile | {this.previousprofile.firstname} {this.previousprofile.lastname}
                               <CSSTransition
                              in={this.state.showSaved}
                              appear={true}
                              timeout={300}
                              classNames="fade"
                              unmountOnExit
                         >
                              <div className="Saved">Saved !</div>
                         </CSSTransition>
                         </h1>
                         <hr id="line" />
                         <CSSTransition
                              in={true}
                              appear={true}
                              timeout={300}
                              classNames="fade"
                              unmountOnExit
                         >
                              <div className="popUpWindows">
                                   {
                                        this.state.readonly ?
                                   <section>
                                        {/* <div className="updateFormHeader">
                                             <p>Update my profile <button className="CloseCus" onClick={() => this.setState({ showUpdateForm: false })}>X</button></p>
                                        </div> */}
                                        <div className="updateFormContent">
                                             <form className="updateformContainer">
                                                  <input type="text" placeholder="First Name" value={this.state.firstname} onChange={this.onFirstNameChange} readOnly/>
                                                  <input type="text" placeholder="Last Name" value={this.state.lastname} onChange={this.onLastNameChange} readOnly/>
                                                  {
                                                       this.context.user.usertype > 1 ?
                                                            <input type="text" placeholder="Position" value={this.state.position} onChange={this.onPositionChange} readOnly/> :
                                                            <input type="text" placeholder="Position" value={this.state.position} disabled readOnly/>
                                                  }
                                                  {/* <input type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} /> */}
                                                  <input type="text" placeholder="Email" value={this.state.email} disabled readOnly/>
                                                  <input type="text" placeholder="Landline" value={this.state.landline} onChange={this.onLandlineChange} readOnly/>
                                                  <input type="text" placeholder="Primary Mobile" value={this.state.mobile[0]} onChange={this.onMobileChangePrimary} readOnly/>
                                                  <input type="text" placeholder="Secondary Mobile" value={this.state.mobile[1]} onChange={this.onMobileChangeSecondary} readOnly/>
                                                  <input type="text" placeholder="Fax" value={this.state.fax} onChange={this.onFaxChange} readOnly />
                                                  <input type="text" placeholder="Address" value={this.state.homeaddress} onChange={this.onHomeaddressChange}readOnly />
                                                  <br/>
                                                  {
                                                       this.context.user.usertype > 1 ?
                                                            <div className="userTypeOptions">
                                                                 <RadioGroup  value={this.state.active+""} horizontal >
                                                                      <RadioButton value={"1"} pointColor="#00dd21">
                                                                           Active
                                                       </RadioButton>
                                                                      <RadioButton value={"2"} pointColor="red">
                                                                           Inactive
                                                       </RadioButton>
                                                                 </RadioGroup>
                                                            </div>
                                                            : null
                                                  }
                                                  <br/>
                                                  {/* {
                                                  this.context.user.usertype > 1 ?
                                                       <div className="userTypeOptions">
                                                            <RadioGroup onChange={this.onActiveChange} value={this.state.usertype+""} horizontal>
                                                                 <RadioButton value={"1"} pointColor="#00dd21">
                                                                      User
                                                                 </RadioButton>
                                                                 <RadioButton value={"2"} pointColor="#00dd21">
                                                                      Manager
                                                                 </RadioButton>
                                                                 <RadioButton value={"2"} pointColor="#00dd21">
                                                                      Administrator
                                                                      </RadioButton>
                                                            </RadioGroup>
                                                       </div>
                                                       : null
                                                  } */}
                                             </form>
                                             <div className="updateFormProfile">
                                                  <section>
                                                       <div className="skillMatrix">
                                                       <h2>Skills Matrix</h2>  
                                                       </div>
                                                       
                                                       <div className="skillContainer">
                                                            <SkillCards 
                                                                 skills={this.state.skills} 
                                                                 onChangeSkills={(skills) => this.setState({ skills: skills })} 
                                                                 readonly={this.state.readonly}
                                                             />
                                                       </div>
                                                  </section>
                                                  <div className="rightside">
                                                       <div className="UploadImage">
                                                            {
                                                                 this.state.picturesChosen === 0 ?
                                                                      <ImageUploader
                                                                           withIcon={false}
                                                                           withPreview={false}
                                                                           label=""
                                                                           buttonText="Add Image"
                                                                           onChange={this.onDrop}
                                                                           imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg", ".jpeg"]}
                                                                          // maxFileSize={1048576}
                                                                          maxFileSize={4194304}
                                                                           fileSizeError=" file size is too big"
                                                                           className="ImageUploader"
                                                                           singleImage={true}
                                                                      />
                                                                      :
                                                                      <div className="logo" >
                                                                           <div className="remove">
                                                                           </div>
                                                                           <img src={this.profileImage[0]} alt="cutsomer" />
                                                                      </div>
                                                            }
                                                       </div>
                                                       <input type="password" placeholder="New Passsword" value={this.state.newPassword} onChange={this.onNewPassword} autoComplete="false" readonly/>
                                                       <input type="password" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={this.onChangePasswordConfirm} autoComplete="false" readonly/>
                                                       <textarea value={this.state.bio} placeholder="Bio" onChange={this.onBioChange} ></textarea>
                                                       {/* {
                                                            this.state.newPassword.trim() > "" ?
                                                                 <input type="password" placeholder="Current Password" value={this.state.oldPassword} onChange={this.onCurrentPassword} autoComplete="false" />
                                                                 : null
                                                       } */}
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="SubmitContainer">
                                        </div>
                                   </section>
                                   : 
                                   <section>
                                        {/* <div className="updateFormHeader">
                                             <p>Update my profile <button className="CloseCus" onClick={() => this.setState({ showUpdateForm: false })}>X</button></p>
                                        </div> */}
                                        <div className="updateFormContent">
                                             <form className="updateformContainer">
                                                  <input type="text" placeholder="First Name" value={this.state.firstname} onChange={this.onFirstNameChange} />
                                                  <input type="text" placeholder="Last Name" value={this.state.lastname} onChange={this.onLastNameChange} />
                                                  {
                                                       this.context.user.usertype > 1 ?
                                                            <input type="text" placeholder="Position" value={this.state.position} onChange={this.onPositionChange} /> :
                                                            <input type="text" placeholder="Position" value={this.state.position} disabled />
                                                  }
                                                  {/* <input type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} /> */}
                                                  <input type="text" placeholder="Email" value={this.state.email} disabled />
                                                  <input type="text" placeholder="Landline" value={this.state.landline} onChange={this.onLandlineChange} />
                                                  <input type="text" placeholder="Primary Mobile" value={this.state.mobile[0]} onChange={this.onMobileChangePrimary} />
                                                  <input type="text" placeholder="Secondary Mobile" value={this.state.mobile[1]} onChange={this.onMobileChangeSecondary} />
                                                  <input type="text" placeholder="Fax" value={this.state.fax} onChange={this.onFaxChange} />
                                                  <input type="text" placeholder="Address" value={this.state.homeaddress} onChange={this.onHomeaddressChange} />
                                                  <br/>
                                                  {
                                                       this.context.user.usertype > 1 ?
                                                            <div className="userTypeOptions">
                                                                 <RadioGroup onChange={this.onActiveChange} value={this.state.active+""} horizontal>
                                                                      <RadioButton value={"1"} pointColor="#00dd21">
                                                                           Active
                                                       </RadioButton>
                                                                      <RadioButton value={"2"} pointColor="red">
                                                                           Inactive
                                                       </RadioButton>
                                                                 </RadioGroup>
                                                            </div>
                                                            : null
                                                  }
                                                  <br/>
                                                  {/* {
                                                  this.context.user.usertype > 1 ?
                                                       <div className="userTypeOptions">
                                                            <RadioGroup onChange={this.onActiveChange} value={this.state.usertype+""} horizontal>
                                                                 <RadioButton value={"1"} pointColor="#00dd21">
                                                                      User
                                                                 </RadioButton>
                                                                 <RadioButton value={"2"} pointColor="#00dd21">
                                                                      Manager
                                                                 </RadioButton>
                                                                 <RadioButton value={"2"} pointColor="#00dd21">
                                                                      Administrator
                                                                      </RadioButton>
                                                            </RadioGroup>
                                                       </div>
                                                       : null
                                                  } */}
                                             </form>
                                             <div className="updateFormProfile">
                                                  <section>
                                                       <div className="skillMatrix">
                                                       <h2>Skills Matrix</h2>  
                                                       </div>
                                                       
                                                       <div className="skillContainer">
                                                            <SkillCards skills={this.state.skills} onChangeSkills={(skills) => this.setState({ skills: skills })} readonly={this.state.readonly}/>
                                                       </div>
                                                  </section>
                                                  <div className="rightside">
                                                       <div className="UploadImage">
                                                            {
                                                                 this.state.picturesChosen === 0 ?
                                                                      <ImageUploader
                                                                           withIcon={false}
                                                                           withPreview={false}
                                                                           label=""
                                                                           buttonText="Add Image"
                                                                           onChange={this.onDrop}
                                                                           imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg", ".jpeg"]}
                                                                          // maxFileSize={1048576}
                                                                          maxFileSize={4194304}
                                                                           fileSizeError=" file size is too big"
                                                                           className="ImageUploader"
                                                                           singleImage={true}
                                                                      />
                                                                      :
                                                                      <div className="logo" >
                                                                           <div className="remove">
                                                                                <img src={Trash} alt="trash" onClick={() => this.setState({ picturesChosen: 0 })} />
                                                                           </div>
                                                                           <img src={this.profileImage[0]} alt="cutsomer" />
                                                                      </div>
                                                            }
                                                       </div>
                                                       <input type="password" placeholder="New Passsword" value={this.state.newPassword} onChange={this.onNewPassword} autoComplete="false" />
                                                       <input type="password" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={this.onChangePasswordConfirm} autoComplete="false" />
                                                       <textarea value={this.state.bio} placeholder="Bio" onChange={this.onBioChange}></textarea>
                                                       {/* {
                                                            this.state.newPassword.trim() > "" ?
                                                                 <input type="password" placeholder="Current Password" value={this.state.oldPassword} onChange={this.onCurrentPassword} autoComplete="false" />
                                                                 : null
                                                       } */}
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="SubmitContainer">
                                             {
                                                 this.state.readonly == false ? 
                                             <button className="CustomerSubmit" type="button" onClick={() => this.onUpdateUser()}>Submit</button>
                                             :null
          }
                                        </div>
                                   </section>
     }
                              </div>
                         </CSSTransition>
                         {/* <div className="myprofile">
                              <div className="cardprofile">
                                   <div className="cardtop">
                                        <img src={this.previousprofile.image > "" ? this.previousprofile.image : defaultImage} alt='Profile' />
                                   </div>
                              </div>
                              <div className="forDescprofile" >
                                   <div className="userName">
                                        <h1>{this.previousprofile.firstname} {this.previousprofile.lastname} </h1>
                                   </div>
                                   <div className="userPosition">
                                        <h2>{this.previousprofile.position}</h2>
                                        <label>-</label>
                                        <h2>{this.getUsertype(this.previousprofile.usertype)}</h2>
                                        <label>-</label>
                                        <h2 style={{
                                             color: parseInt(this.previousprofile.active) === 1 || this.previousprofile.active === 1 ? "lime" : "red"
                                        }}>
                                             {
                                                  parseInt(this.previousprofile.active) === 1 || this.previousprofile.active === 1 ? "Active" : "Inactive"
                                             }
                                        </h2>
                                   </div>
                                   <hr id="line" />
                                   <h2 className="skills">
                                        {this.mapSkills()}
                                   </h2>
                                   <div className="bio">
                                        <h3>{this.previousprofile.bio}</h3>
                                   </div>
                                   <div className="homeAddress">
                                        <div className="mapIcon">
                                             <p>&nbsp;&nbsp;<FiMapPin /> {this.previousprofile.homeaddress}</p>
                                        </div>
                                        <div className="callIcon">
                                             <p><FiPhoneCall />&nbsp;&nbsp; {this.previousprofile.mobile[0]}</p>
                                        </div>
                                        <div className="emailIcon">
                                             <p> <FiMail />&nbsp;&nbsp;{this.previousprofile.email}</p>
                                        </div>
                                   </div>
                              </div>

                         </div> */}

                    </div>
               );
          }
     }
}

export default withAlert()(Profile);
