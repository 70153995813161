import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import '../style/reports.scss';
import Loading from '../components/loading';
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronsLeft } from "react-icons/fi";
import { FiChevronsRight } from "react-icons/fi";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
import Calendar from 'react-calendar';
import { CSSTransition } from 'react-transition-group';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { withAlert } from 'react-alert';
import jsPDF from 'jspdf';
import { AppContext } from '../appcontext'
import axios from 'axios';
import { act } from 'react-dom/test-utils';
import moment from 'moment';
class Reports extends PureComponent {
    pdfExportComponent;
    static contextType = AppContext;

    constructor() {
        super();
        let formattedDate = new Date();
        this.state = {
            showChooseLoading: false,
            showprompt: false,
            serviceview: "column",
            activityview: "column",
            resourceview: "column",
            Loading: true,
            numPages: null,
            showResourceReport: false,
            pageNumber: 1,
            customers: [],
            services: [],
            activities: [],
            users: [],
            selectedperiod: "Today",
            startdate: formattedDate.getFullYear() + '-' + ((formattedDate.getMonth() + 1) < 10 ? '0' + (formattedDate.getMonth() + 1) : (formattedDate.getMonth() + 1)) + '-' + (formattedDate.getDate() < 10 ? "0" + formattedDate.getDate() : formattedDate.getDate()),
            enddate: formattedDate.getFullYear() + '-' + ((formattedDate.getMonth() + 1) < 10 ? '0' + (formattedDate.getMonth() + 1) : (formattedDate.getMonth() + 1)) + '-' + (formattedDate.getDate() < 10 ? "0" + formattedDate.getDate() : formattedDate.getDate()),
            periodintervals: ["Today", "Yesterday", "This Week", "Last Week", "This Month", "Last Month"],
            showCalendarStartDate: false,
            showCalendarEndDate: false,
            index: 0,
            showReportPreview: false,
            reportCustomerFullObject: {},
            reportCustomerObject: {},
            reportUserObject: {},
            reportServiceObject: {},
            reportActivityObject: {},
            reportEntriesObject: {},
            usePreviousReport: false,
        }
        this.selectedCustomers = [];
        this.selectedServices = [];
        this.selectedActivities = [];
        this.selectedUsers = [];
    }
    onShowResourceReport = () => { this.setState({ showResourceReport: !this.state.showResourceReport }) }
    handlePDF = () => {
        var doc = new jsPDF('p', 'pt', 'a4');
        doc.fromHTML(document.getElementById('ServiceReport'), {
            onrendered(canvas) {
                doc.fromHTML(document.getElementById('ActivityeReport'), {
                    onrendered(canvas2) {
                        var img = canvas.toDataURL("image/png");
                        doc.addImage(img, 'JPEG', 0, 0, 210, 230);
                        var img2 = canvas2.toDataURL("image/png");
                        doc.addPage();
                        doc.addImage(img2, 'JPEG', 0, 0, 210, 230);
                        doc.save('test.pdf');
                    }
                });
            }
        });
    }
    handleChange = async (event, value) => {
        this.setState({
            index: value,
        });
    };


    handleChangeIndex = index => {
        this.setState({
            index,
        });
    };
    handleClose = () => {
        this.onShowReportPreview()
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunctionForm, false);
    }
    componentDidMount = async () => {
        document.addEventListener("keydown", this.escFunctionForm, false);
        if (this.context.user.usertype > 1)
            await this.context.sendrequest('getcustomers', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                .then(async response => {
                    if (response)
                        if (response.data.success) {
                            this.setState({
                                customers: response.data.data,
                            })
                            await this.context.sendrequest('getservices', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                                .then(async response => {
                                    if (response)
                                        if (response.data.success) {
                                            this.setState({
                                                services: response.data.data,
                                                activities: [],
                                            })
                                            await this.context.sendrequest('getusers', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                                                .then(async response => {
                                                    if (response)
                                                        if (response.data.success) {
                                                            this.setState({
                                                                users: response.data.data,
                                                                allusers: response.data.data,
                                                            })
                                                            await this.context.sendrequest('getactivities', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                                                                .then(response => {
                                                                    var LS = localStorage.getItem("report");
                                                                    if (response)
                                                                        if (response.data.success)
                                                                            this.setState({
                                                                                activities: response.data.data,
                                                                                showChooseLoading: false,
                                                                                Loading: false,
                                                                                showprompt: LS ? LS === "[object Object]" ? false : true : false,
                                                                            })
                                                                    window.scrollTo(0, 0);

                                                                })
                                                        }
                                                })
                                        }
                                })

                        }
                })
        else {
            let data = {
                resourceId: this.context.user._id
            }
            await this.context.sendrequest("getcustomer/resourcecustomers", data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                .then(async response => {
                    if (response)
                        if (response.data.success) {
                            this.setState({
                                customers: response.data.data,
                            })
                            if (response.data.data.length === 1)
                                this.selectedCustomers = this.arrayforMulti(response.data.data)

                            await this.context.sendrequest('getservices', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                                .then(async response => {
                                    if (response)
                                        if (response.data.success) {
                                            this.setState({
                                                services: response.data.data,
                                                activities: [],
                                            })
                                            await this.context.sendrequest('getuser', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                                                .then(async response => {
                                                    if (response)
                                                        if (response.data.success) {
                                                            this.setState({
                                                                users: [response.data.user],
                                                                allusers: [response.data.user],
                                                            })
                                                            this.selectedUsers = this.arrayforMulti([response.data.user])
                                                            await this.context.sendrequest('getactivities', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                                                                .then(response => {
                                                                    var LS = localStorage.getItem("report");
                                                                    if (response)
                                                                        if (response.data.success)
                                                                            this.setState({
                                                                                activities: response.data.data,
                                                                                showChooseLoading: false,
                                                                                Loading: false,
                                                                                showprompt: LS ? LS === "[object Object]" ? false : true : false,
                                                                            })
                                                                    window.scrollTo(0, 0);

                                                                })
                                                        }
                                                })
                                        }
                                })

                        }
                })
        }
    }
    arrayforMulti(array) {
        let newArray = []
        array = array ? array : [];
        if (array[0]) {
            if (array[0].customername) {
                for (let a = 0; a < array.length; a++) {
                    newArray.push({
                        id: array[a]._id,
                        label: array[a].customername,
                        object: array[a]
                    })
                }
            }
            else if (array[0].firstname) {
                for (let a = 0; a < array.length; a++) {
                    newArray.push({
                        id: array[a]._id,
                        label: array[a].firstname + " " + array[a].lastname,
                        object: array[a]
                    })
                }
            }
            else {
                for (let a = 0; a < array.length; a++) {
                    newArray.push({
                        id: array[a]._id,
                        label: array[a].name,
                        object: array[a]
                    })
                }
            }
            return newArray
        }
    }
    onSelectInterval = (event) => {
        this.setState({
            selectedperiod: event
        })
        let formattedDate = new Date();
        let newdate;
        if (event === "Today") {
            this.setState({
                startdate: moment().format("YYYY-MM-DD"),
                enddate: moment().format("YYYY-MM-DD"),
            })
        }
        else if (event === "Yesterday") {
            newdate = formattedDate.getDate();
            newdate = newdate - 1;
            this.setState({
                startdate: moment().subtract(1, "days").format("YYYY-MM-DD"),
                enddate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            })
        }
        else if (event === "This Week") {
            this.setState({
                startdate: moment().startOf('week').add(1, "days").format("YYYY-MM-DD"),
                enddate: moment().endOf('week').add(1, "days").format("YYYY-MM-DD"),
            })
        }
        else if (event === "Last Week") {
            this.setState({
                startdate: moment().startOf('week').subtract(6, "days").format("YYYY-MM-DD"),
                enddate: moment().endOf('week').subtract(6, "days").format("YYYY-MM-DD"),
            })
        }
        else if (event === "This Month") {
            this.setState({
                startdate: moment().startOf('month').format("YYYY-MM-DD"),
                enddate: moment().endOf('month').format("YYYY-MM-DD"),
            })
        }
        else if (event === "Last Month") {
            this.setState({
                startdate: moment().subtract(1, "month").startOf('month').format("YYYY-MM-DD"),
                enddate: moment().subtract(1, "month").endOf('month').format("YYYY-MM-DD"),
            })
        }

    }
    onChangeCustomers = async (selectedCustomers) => {
        this.setState({
            showChooseLoading: true
        })
        if (selectedCustomers.length > 0) {
            this.selectedCustomers = selectedCustomers;
            var ids = []
            for (let a = 0; a < selectedCustomers.length; a++)
                for (let b = 0; b < selectedCustomers[a].object.resources.length; b++)
                    ids.push(selectedCustomers[a].object.resources[b])
            var data = {
                resources: ids
            }
            await this.context.sendrequest('getcustomer/resources', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                .then(response => {
                    if (response.data.success)
                        this.setState({
                            users: response.data.data,
                            showChooseLoading: false,
                        })
                })
        }
        else {
            this.setState({
                users: this.state.allusers,
                showChooseLoading: false,
            })
            this.selectedUsers = [];
        }

    }
    onChnageServices = async (selectedServices) => {
        if (selectedServices.length > 0) {
            this.setState({
                showChooseLoading: true
            })
            this.selectedServices = selectedServices
            let sa = [];
            for (let a = 0; a < selectedServices.length; a++) {
                if (selectedServices[a].object.activities)
                    for (let b = 0; b < selectedServices[a].object.activities.length; b++) {
                        sa.push(selectedServices[a].object.activities[b]);
                    }
            }
            if (sa.length > 0) {
                var data = {
                    serviceActivities: sa
                }
                await this.context.sendrequest('getserviceactivities', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                    .then(async response => {
                        if (response.data.success) {
                            this.setState({
                                activities: response.data.data,
                            })
                            let activities = [];
                            var LS = localStorage.getItem("report");
                            LS = JSON.parse(LS)

                            if (this.state.usePreviousReport) {
                                LS.ActivityReport.forEach(activity => {
                                    activities.push({
                                        id: activity.activityId,
                                        label: activity.activityName,
                                        object: {
                                            _id: activity.activityId,
                                            name: activity.activityName,
                                        }
                                    })
                                })
                                this.onChangeActivites(activities)
                            }
                            else {
                                this.setState({
                                    showChooseLoading: false
                                })
                            }
                        }
                    })
            }
            else {
                this.selectedActivities = [];
                this.setState({
                    showChooseLoading: false,
                    activities: []
                })
            }
        }
        else {
            this.selectedActivities = [];
            this.setState({
                showChooseLoading: false,
                activities: []
            })
        }
    }
    onChangeActivites = (selectedActivities) => {
        this.setState({
            showChooseLoading: true
        })
        this.selectedActivities = selectedActivities;
        this.setState({
            showChooseLoading: false
        })
    }
    onChangeUsers = (selectedUsers) => this.selectedUsers = selectedUsers;

    onLeftArrowCalendar = (olddate, whichdate) => {
        let changeddate = moment(olddate, "YYYY/MM/DD").subtract(1, "days").format("YYYY-MM-DD")

        if (whichdate === "start") {
            this.setState({
                startdate: changeddate,
            })
        }
        else {
            this.setState({
                enddate: changeddate,
            })
        }
    }
    onRightArrowCalendar = (olddate, whichdate) => {
        let changeddate = moment(olddate, "YYYY/MM/DD").add(1, "days").format("YYYY-MM-DD")

        if (whichdate === "start") {
            this.setState({
                startdate: changeddate,
            })
        }
        else {
            this.setState({
                enddate: changeddate,
            })
        }
    }
    onRightDoubleArrow = (olddate, whichdate) => {
        let changeddate = moment(olddate, "YYYY/MM/DD").add(1, "month").format("YYYY-MM-DD")

if (whichdate === "start") {
            this.setState({
                startdate: changeddate,
            })
        }
        else {
            this.setState({
                enddate: changeddate,
            })
        }
    }
    onLeftDoubleArrow = (olddate, whichdate) => {
        let changeddate = moment(olddate, "YYYY/MM/DD").subtract(1, "month").format("YYYY-MM-DD")
        if (whichdate === "start") {
            this.setState({
                startdate: changeddate,
            })
        }
        else {
            this.setState({
                enddate: changeddate,
            })
        }
    }
    onGenerateReport = async () => {
        this.setState({
            showChooseLoading: true
        })
        let customerId = [];
        let userId = [];
        let serviceId = [];
        let activityId = [];
        if (this.selectedCustomers.length === 0) {
            const alert = this.props.alert;
            alert.error("Customer required");
            this.setState({
                showChooseLoading: false
            })
        }
        else if (this.selectedUsers.length === 0) {
            const alert = this.props.alert;
            alert.error("Resource required");
            this.setState({
                showChooseLoading: false
            })
        }
        else if (this.selectedServices.length === 0) {
            const alert = this.props.alert;
            alert.error("Service required");
            this.setState({
                showChooseLoading: false
            })
        }
        else if (this.selectedActivities.length === 0) {
            const alert = this.props.alert;
            alert.error("Activity required");
            this.setState({
                showChooseLoading: false
            })
        }
        else {
            for (let a = 0; a < this.selectedCustomers.length; a++) {
                customerId.push(this.selectedCustomers[a].object._id);
            }
            for (let a = 0; a < this.selectedUsers.length; a++) {
                userId.push(this.selectedUsers[a].object._id);
            }
            for (let a = 0; a < this.selectedServices.length; a++) {
                serviceId.push(this.selectedServices[a].object._id);
            }
            for (let a = 0; a < this.selectedActivities.length; a++) {
                activityId.push(this.selectedActivities[a].object._id);
            }
        }
        var data = {
            dates: JSON.stringify({
                startdate: this.state.startdate,
                enddate: this.state.enddate,
            }),
            customerIds: customerId,
            serviceIds: serviceId,
            activityIds: activityId,
            userIds: userId,
        }

        await this.context.sendrequest('generatereport', data, (boolean) => this.setState({ showChooseLoading: false }), this.props.alert.error)
            .then(async response => {
                if (response)
                    if (response.data.success && response.data.data.customerReport.length > 0) {
                        var data = [];
                        await response.data.data.customerReport.map(async customer => {
                            data.push(await this.getCustomer(customer.customerId))
                        })

                        this.setState({
                            reportCustomerObject: response.data.data.customerReport,
                            reportUserObject: response.data.data.userReport,
                            reportServiceObject: response.data.data.serviceEntries,
                            reportActivityObject: response.data.data.activityEntries,
                            reportEntriesObject: response.data.data.entries,
                            reportCustomerFullObject: data
                        })
                        // this.context.setCustomerReport(this.state.reportCustomerObject);
                        // this.context.setServiceReport(this.state.reportServiceObject);
                        // this.context.setActivityReport(this.state.reportActivityObject);
                        // this.context.setUserReport(this.state.reportUserObject);
                        // this.context.setCustomerObject(this.state.reportCustomerFullObject);
                        this.context.setDates(this.state.startdate, this.state.enddate)

                        this.successfulnotification(response.data.message);
                        this.onShowReportPreview();
                    }
                    else if (!response.data.success) {
                        this.setState({
                            showChooseLoading: false,
                        })
                        this.warningnotification("No data was returned from your query")
                    }
            })
        this.setState({
            showChooseLoading: false,
        })
    }
    getCustomer = (id) => {
        let customer = {};
        for (let a = 0; a < this.state.customers.length; a++) {
            if (this.state.customers[a]._id === id) {
                customer = this.state.customers[a];
            }
        }
        return customer;
    }
    setMonthToNum = (month) => {
        var monthNames = [
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct",
            "Nov", "Dec"
        ];

        for (let a = 0; a < monthNames.length; a++) {
            if (monthNames[a] === month) {
                var monthIndex = monthNames.indexOf(month) + 1
                monthIndex = monthIndex < 10 ? '0' + monthIndex : monthIndex
            }
        }

        return monthIndex
    }
    onClickStartDay = (date) => {
        this.setState({
            startdate: date.toString().split(' ')[3] + '-' + this.setMonthToNum(date.toString().split(' ')[1]) + '-' + date.toString().split(' ')[2],
        })
    }
    onClickEndDay = (date) => {
        this.setState({
            enddate: date.toString().split(' ')[3] + '-' + this.setMonthToNum(date.toString().split(' ')[1]) + '-' + date.toString().split(' ')[2],
        })
    }
    setDate(date) {
        let d = new Date();
        let newDate;
        if (date === d)
            return date
        else {
            newDate = date.split('-');
            newDate = new Date(newDate[0], newDate[1] - 1, newDate[2])
            return newDate
        }
    }
    showStartDateCal = () => this.setState({ showCalendarStartDate: !this.state.showCalendarStartDate })
    showEndDateCal = () => this.setState({ showCalendarEndDate: !this.state.showCalendarEndDate })
    onShowReportPreview = () => {
        this.setState({ showReportPreview: !this.state.showReportPreview })
    }
    successfulnotification = (message) => {
        const alert = this.props.alert;
        alert.success(message);
    }
    errornotification = (message) => {
        const alert = this.props.alert;
        alert.error(message);
    }
    warningnotification = (message) => {
        const alert = this.props.alert;
        alert.show(message);
    }
    onChangeBar = () => {
        let tab = this.state.index;
        if (tab === 0)
            this.setState({
                serviceview: "column",
            })
        else if (tab === 1)
            this.setState({
                activityview: "column",
            })
        else
            this.setState({
                resourceview: "column",
            })
    }

    onChangeTable = () => {
        let tab = this.state.index;
        if (tab === 0)
            this.setState({
                serviceview: "table",
            })
        else if (tab === 1)
            this.setState({
                activityview: "table",
            })
        else
            this.setState({
                resourceview: "table",
            })
    }

    onChangePieChart = () => {
        let tab = this.state.index;
        if (tab === 0)
            this.setState({
                serviceview: "pie",
            })
        else if (tab === 1)
            this.setState({
                activityview: "pie",
            })
        else
            this.setState({
                resourceview: "pie",
            })
    }
    escFunctionForm = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                showReportPreview: false,
                showCalendarStartDate: false,
                showCalendarEndDate: false
            })
        }
    }

    usePreviousReport = async () => {
        if (localStorage.getItem("report")) {
            this.setState({
                usePreviousReport: true
            })
            var LS = localStorage.getItem("report")
            LS = JSON.parse(LS)

            var customers = []
            LS.CustomerObject.forEach(customer => {
                customers.push({
                    id: customer._id,
                    label: customer.customername,
                    object: customer
                })
            })
            this.selectedCustomers = customers;

            var users = [];
            LS.UserReport.forEach(user => {
                users.push({
                    id: user.userId,
                    label: user.userName,
                    object: {
                        _id: user.userId,
                        name: user.userName
                    }
                })
            })
            this.selectedUsers = users;
            var oldservices = []
            for (let a = 0; a < this.state.services.length; a++)
                for (let b = 0; b < LS.ServiceReport.length; b++)
                    if (LS.ServiceReport[b].serviceId === this.state.services[a]._id)
                        oldservices.push({
                            id: this.state.services[a]._id,
                            label: this.state.services[a].name,
                            object: this.state.services[a]
                        })
            let createdDate = new Date();
            let startdate = createdDate.getFullYear() + "-" + (createdDate.getMonth() + 1 < 10 ? "0" + (createdDate.getMonth() + 1) : createdDate.getMonth() + 1) + "-" + createdDate.getDate();
            await this.onChnageServices(oldservices)
                .then(
                    this.setState({
                        startdate: LS.startdate > "" ? LS.startdate : startdate,
                        enddate: LS.enddate > "" ? LS.enddate : startdate,
                        showChooseLoading: false,
                        Loading: false,
                        showprompt: false,
                    })
                )

        }
        else {
            this.error("Something went wrong with the request")
            this.setState({
                showprompt: false,
            })
            this.selectedUsers = [];
        }
    }
    render() {
        document.body.style.overflow = this.state.Loading ? "hidden" : "visible"
        if (this.state.showReportPreview) {
            return <Redirect to={{
                pathname: `/${this.context.user.firstname}/reports/preview`,
                reportCustomerFullObject: this.state.reportCustomerFullObject,
                reportCustomerObject: this.state.reportCustomerObject,
                reportUserObject: this.state.reportUserObject,
                reportServiceObject: this.state.reportServiceObject,
                reportActivityObject: this.state.reportActivityObject,
                reportEntriesObject: this.state.reportEntriesObject,
            }} />
        }
        else
            return (
                <div className="pageContainer">
                    <h1 id="heading">Reports</h1>
                    <hr id="line" />
                    {/* <CSSTransition
 in={localStorage.getItem("report") && this.state.showprompt ? true : false}
 // in={true}
 appear={true}
 timeout={300}
 classNames="fade"
 unmountOnExit
 >
 <Loading />
 </CSSTransition> */}
                    <CSSTransition
                        // in={this.state.showprompt}
                        in={false}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div className="mother">
                            <div className="comfirmDialog">
                                <h4>Would you like to use the previous report ?</h4>
                                <div className="comfirmDialogButtons">
                                    <button className="comfirmDialogCancel" type="button" onClick={this.usePreviousReport}>Yes</button>
                                    <button className="comfirmDialogCancel" type="button" onClick={() => this.setState({ showprompt: false })}>No</button>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        in={this.state.showCalendarStartDate}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div className="StartCalendar" onClick={(e) => {
                            if (e.target.className === "StartCalendar fade-enter-done")
                                this.setState({ showCalendarStartDate: false })
                            else if (e.target.className === "react-calendar__tile react-calendar__month-view__days__day" ||
                                e.target.className === "react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" ||
                                e.target.className === "react-calendar__tile react-calendar__tile--active react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day" ||
                                e.target.className === "react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth" ||
                                e.target.className === "react-calendar__tile react-calendar__tile--active react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" ||
                                e.target.className === "react-calendar__tile react-calendar__tile--active react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day" ||
                                e.target.className === "react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend react-calendar__month-view__days__day--neighboringMonth" ||
                                e.target.className === "react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth"
                            )
                                this.setState({ showCalendarStartDate: false })
                        }}>
                            <Calendar
                                className="calendar"
                                value={this.setDate(this.state.startdate)}
                                onClickDay={this.onClickStartDay}
                            />
                        </div>
                    </CSSTransition>
                    <CSSTransition
                        in={this.state.showCalendarEndDate}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div className="StartCalendar" onClick={(e) => {
                            if (e.target.className === "StartCalendar fade-enter-done")
                                this.setState({ showCalendarEndDate: false })
                            else if (e.target.className === "react-calendar__tile react-calendar__month-view__days__day" ||
                                e.target.className === "react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" ||
                                e.target.className === "react-calendar__tile react-calendar__tile--active react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day" ||
                                e.target.className === "react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth" ||
                                e.target.className === "react-calendar__tile react-calendar__tile--active react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend" ||
                                e.target.className === "react-calendar__tile react-calendar__tile--active react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day" ||
                                e.target.className === "react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend react-calendar__month-view__days__day--neighboringMonth" ||
                                e.target.className === "react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--neighboringMonth"
                            )
                                this.setState({ showCalendarEndDate: false })
                        }}>
                            <Calendar
                                className="calendarEnd"
                                value={this.setDate(this.state.enddate)}
                                onClickDay={this.onClickEndDay}
                            />
                        </div>

                    </CSSTransition>

                    {
                        this.state.Loading ? <Loading /> :
                            <div className="ReportsContainer">
                                <div className="DateOptions">
                                    <div className="StartDate">
                                        <div className="startDateOptions">
                                            <div className="DateLabels">From:</div>
                                            <button type="button" onClick={() => this.onLeftDoubleArrow(this.state.startdate, "start")}><FiChevronsLeft /></button>
                                            <button type="button" onClick={() => this.onLeftArrowCalendar(this.state.startdate, "start")}><FiChevronLeft /></button>
                                            <h4 onClick={() => this.showStartDateCal()}> {this.state.startdate} </h4>
                                            <button type="button" onClick={() => this.onRightArrowCalendar(this.state.startdate, "start")}><FiChevronRight /></button>
                                            <button type="button" onClick={() => this.onRightDoubleArrow(this.state.startdate, "start")}><FiChevronsRight /></button>
                                        </div>
                                    </div>
                                    <div className="StartDate">
                                        <div className="startDateOptions">
                                            <div className="DateLabels">To:</div>
                                            <button type="button" onClick={() => this.onLeftDoubleArrow(this.state.enddate, "end")}><FiChevronsLeft /></button>
                                            <button type="button" onClick={() => this.onLeftArrowCalendar(this.state.enddate, "end")}><FiChevronLeft /></button>
                                            <h4 onClick={() => this.showEndDateCal()}> {this.state.enddate} </h4>
                                            <button type="button" onClick={() => this.onRightArrowCalendar(this.state.enddate, "end")}><FiChevronRight /></button>
                                            <button type="button" onClick={() => this.onRightDoubleArrow(this.state.enddate, "end")}><FiChevronsRight /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="IntervalOptions">
                                    <div className="userTypeOptions">
                                        <RadioGroup value={this.state.selectedperiod} onChange={this.onSelectInterval} horizontal>

                                            {
                                                this.state.periodintervals.map(option => {
                                                    return (
                                                        <RadioButton value={option} pointColor="#00dd21">
                                                            {option}
                                                        </RadioButton>
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="ReportOptions">
                                    <div className="ReportMultiSelect">
                                        <CSSTransition
                                            in={this.state.showChooseLoading}
                                            appear={true}
                                            timeout={300}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <Loading />
                                        </CSSTransition>
                                        <div className="reportHeading">
                                            <h3>
                                                Customers
 </h3>
                                        </div>
                                        <MultiSelect
                                            height={300}
                                            searchPlaceholder={"Search Customers"}
                                            items={this.arrayforMulti(this.state.customers)}
                                            // maxSelectedItems={1}
                                            showSelectedItems={true}
                                            onChange={this.onChangeCustomers}
                                            selectedItems={this.selectedCustomers}
                                        />
                                    </div>
                                    <div className="ReportMultiSelect">
                                        <div className="reportHeading">
                                            <h3>
                                                Resources
 </h3>
                                        </div>
                                        <MultiSelect
                                            items={this.arrayforMulti(this.state.users)}
                                            showSelectedItems={true}
                                            onChange={this.onChangeUsers}
                                            showSelectAll={true}
                                            selectedItems={this.selectedUsers}
                                        />
                                    </div>
                                </div>
                                <div className="ReportOptions">
                                    <div className="ReportMultiSelect">
                                        <div className="reportHeading">
                                            <h3>
                                                Services
 </h3>
                                        </div>
                                        <MultiSelect
                                            items={this.arrayforMulti(this.state.services)}
                                            showSelectedItems={true}
                                            onChange={this.onChnageServices}
                                            showSelectAll={true}
                                            selectedItems={this.selectedServices}
                                        />
                                    </div>
                                    <div className="ReportMultiSelect">
                                        <div className="reportHeading">
                                            <h3>
                                                Activities
 </h3>
                                        </div>
                                        <MultiSelect
                                            items={this.arrayforMulti(this.state.activities)}
                                            showSelectedItems={true}
                                            onChange={this.onChangeActivites}
                                            showSelectAll={true}
                                            selectedItems={this.selectedActivities}
                                        />
                                    </div>
                                </div>
                                <div className="ReportSubmitOptions">
                                    <button className="Submit" type="button" onClick={() => this.onGenerateReport()}>Generate Report</button>
                                </div>
                            </div>
                    }
                </div >
            )
    }
}
export default withAlert()(Reports)