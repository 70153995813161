import React from 'react';
import '../style/newstyle.css';

function Timesheet() {
    return (
        <div class="wrapper">
            <header class="site-header">
                <div class="container">
                    <div class="row">
                        <div class="header-right">
                            <nav class="menu-bar">
                                <ul>
                                    {/* <li><a href="#">Case Studies</a></li> */}
                                    <li><a href="#">Register/Demo</a></li>
                                    <li><a href="mailto:sales@condor360.online">Contact Sales</a></li>
                                    <li><a href="mailto:support@condor360.online">Support</a></li>
                                    <li><a href="#">Sign In <img src={require('../style/img/login-icon.png')} alt="login" /></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <section class="inner-section">
                <div class="container">
                    <h2 class="section-heading">Time <strong>Sheets</strong></h2>
                    <div class="timesheet-images">
                        <div class="img-wrap">
                            <img src={require("../style/img/timesheet_img1.jpg")} alt="" />
                        </div>
                        <div class="img-wrap">
                            <img src={require("../style/img/timesheet_img2.jpg")} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact-sale">
                <div class="container">
                    <h2 class="section-heading">Contact <strong>Us</strong></h2>
                    <div class="row d-flex">
                        <div class="col-5">
                            <div class="contact-form">
                                <form method="post" action="">
                                    <div class="form-group">
                                        <input type="email" name="" placeholder="Email Address" class="forn-control" />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="" placeholder="Subject" class="forn-control" />
                                    </div>
                                    <div class="form-group">
                                        <textarea placeholder="Your Message" class="forn-control"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="default-btn dark-btn">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="contact-content">
                                <p>Don’t Hesitate to contact with us for any Kind of information</p>
                                <p>(+27) 21 555 2013</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="sub-footer">
                <div class="row d-flex">
                    <div class="col-6">
                        <h3><span>Want</span> To Learn More ?</h3>
                        <p>The First Draft Is Written and Reviewed to Create</p>
                    </div>
                    <div class="col-4">
                        <div class="text-right">
                            <a href="#" class="default-btn prime-btn">Contact Us Today</a>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="main-footer">
                <div class="container">
                    <div class="row d-flex">
                        <div class="col-3">
                            <div class="foot-widget f-widget-1">
                                <div class="let-talk-btn"><a href="#">LET’S TALK! <img src="images/foot-arrow.jpg" alt="" /></a></div>
                                <p>There is no better time than now</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="foot-widget  f-widget-2">
                                <h4 class="title">Address</h4>
                                <p>4th Floor, West Tower, Canal Walk, Century City, Cape Town, 7435<br></br>South Africa</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="foot-widget f-widget-3">
                                <h4 class="title">Contact Detail</h4>
                                <p>Phone: (+27) 21 555 2013</p>
                                <p>Email: sales@condor360.online </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright">
                    <div class="container">
                        <p>&copy; Copyright 2020 Condogreen Infotech | All Rghts Reserved</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Timesheet