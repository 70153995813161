import React, { useState, useEffect } from 'react'
import '../styles/entry.scss'
import { AiOutlineClose } from 'react-icons/ai'
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
import Switch from 'react-switch';
import moment from 'moment'
import { withAlert } from 'react-alert';
import { parse } from 'query-string';
function Entry(props) {
    var {
        toggleEntry,
        resourcecustomers,
        services,
        activities,
        timestarted,
        user,
        date,
        sendrequest,
        selectedCustomer,
        getEntries,
        timefinished,
        hour,
        changeHour,
        entrydata,
        changeLoading,
        specificdate,
        allentriesforcustomer,
        escEntry,
        onsuccentry,
        onChangeSelectedCustomer
    } = props
    const [selectedService, changeService] = useState([])
    const [selectedActivities, changeActivity] = useState([])
    const [billable, changeBillable] = useState(true)
    const [overtime, changeOvertime] = useState(false)
    const [description, changeDescription] = useState("")
    const [selcustomer, changecustomer] = useState(selectedCustomer)
        return (
        <div className="timesheetEntry">
            <div className="entrywindowcontainer">
                <div className="top">
                    <div className="customerh">

                        <div class="dropdown">
                            <button className="dropbtn">
                                <h1>
                                    {selcustomer.customername}
                                </h1>
                            </button>
                            {
                                resourcecustomers.length > 0 ?
                                    <div className="dropdown-content">
                                        {resourcecustomers.map((customer) => {
                                            return (
                                                <div id='times' style={{ background: customer.customername === selcustomer.customername ? "#aaa" : "#f1f1f1" }}
                                                    onClick={() => changecustomer(customer)}
                                                >
                                                    {customer.customername}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    <button type="button" className="closebutton" title='close' onClick={() => toggleEntry()}>
                        <AiOutlineClose />
                    </button>
                </div>
                <div className="middle">
                    <Condor360
                        timestarted={timestarted}
                        timefinished={timefinished}

                        resourcecustomers={resourcecustomers}
                        services={services}
                        activities={activities}

                        selectedCustomer={selcustomer}
                        selectedService={selectedService}
                        selectedActivities={selectedActivities}

                        changeService={changeService}
                        changeActivity={changeActivity}

                        billable={billable}
                        overtime={overtime}

                        changeBillable={changeBillable}
                        changeOvertime={changeOvertime}

                        description={description}
                        changeDescription={changeDescription}

                        user={user}
                        date={date}

                        toggleEntry={toggleEntry}

                        sendrequest={sendrequest}

                        alert={props.alert}

                        getEntries={getEntries}

                        hour={hour}
                        changeHour={changeHour}

                        entrydata={entrydata}

                        changeLoading={changeLoading}
                        specificdate={specificdate}

                        allentriesforcustomer={allentriesforcustomer}
                        escEntry={escEntry}

                        onsuccentry={onsuccentry}
                        onChangeSelectedCustomer={onChangeSelectedCustomer}
                    />
                </div>

            </div>
        </div>
    )
}

function arrayforMulti(array) {
    let newArray = []
    array = array ? array : [];
    if (array[0]) {
        if (array[0].customername) {
            for (let a = 0; a < array.length; a++) {
                newArray.push({
                    id: array[a]._id,
                    label: array[a].customername,
                    object: array[a]
                })
            }
        }
        else if (array[0].firstname) {
            for (let a = 0; a < array.length; a++) {
                newArray.push({
                    id: array[a]._id,
                    label: array[a].firstname + " " + array[a].lastname,
                    object: array[a]
                })
            }
        }
        else {
            for (let a = 0; a < array.length; a++) {
                newArray.push({
                    id: array[a]._id,
                    label: array[a].name,
                    object: array[a]
                })
            }
        }
        return newArray
    }
}

function createTimeOptions(startedtime, originalEndTime) {
    const start = startedtime.split(":")
    const starthour = parseInt(start[0])
    let array = [...Array(5)]
    let timeblocks = []
    timeblocks.push(["0", originalEndTime])
    array.forEach((hour, i) => {
        const endtimeblock = starthour + (i + 1) < 10 ? "0" + (starthour + (i + 1)) : starthour + i + 1
        const block = [i + 1, endtimeblock + ":" + start[1]]
        timeblocks.push(block)
    })
    return timeblocks
}

function Condor360(data) {
    var {
        timestarted,
        timefinished,

        selectedCustomer,
        services,

        selectedService,
        selectedActivities,

        changeService,
        changeActivity,

        billable,
        overtime,

        changeBillable,
        changeOvertime,

        description,
        changeDescription,

        user,
        date,

        toggleEntry,

        sendrequest,
        alert,

        getEntries,

        hour,
        changeHour,

        entrydata,

        changeLoading,
        specificdate,
        allentriesforcustomer,
        onsuccentry,

        resourcecustomers,
        onChangeSelectedCustomer

    } = data

    //Activities for entry window
    const [activities, changeActivities] = useState([])

    // Time started Value and change function
    const [timestartedValue, changetimestarted] = useState(timestarted)

    // Time finished Value and change function
    const timeparts = timestarted.split(":")
    let timetemp = ""
    if (timeparts[1] === "45") {
        timetemp = ((parseInt(timeparts[0]) + 1) < 10 ? "0" + (parseInt(timeparts[0]) + 1) : (parseInt(timeparts[0]) + 1)) + ":00"
    }
    else {
        if (timeparts[1] === "00")
            timetemp = timeparts[0] + ":15"
        else if (timeparts[1] === "15")
            timetemp = timeparts[0] + ":30"
        else
            timetemp = timeparts[0] + ":45"
    }
    const [timefinishedValue, changetimefinished] = useState(timetemp)
    //Declare time block variables
    const timeoptions = createTimeOptions(timestartedValue, timefinished)

    //Declare dropdown array
    const [timeDropDowns, changetimeDropDowns] = useState([":00", ":15", ":30", ":45"])
    const [timeDropDownsfinish, changetimeDropDownsfinish] = useState([":15", ":30", ":45", ":00"])

    //Change time started
    const changetimestartedfrom = value => {
        changetimestarted(value)
        if (timefinishedValue <= value) {
            let timedrop = 0;
            const timestartminutes = value.split(":")
            while (timeDropDownsfinish[timedrop] <= (":" + timestartminutes[1]) && timedrop < timeDropDownsfinish.length) {
                if (timeDropDownsfinish[timedrop] <= ":" + timestartminutes[1]) {
                    timedrop++
                }
            }
            if (timeDropDownsfinish[timedrop]) {
                if (timeDropDownsfinish[timedrop] > (":" + timestartminutes[1]))
                    changetimefinsihedfrom((hour < 10 ? "0" + (hour) : hour) + timeDropDownsfinish[timedrop])
            }
            else {
                changetimefinsihedfrom((hour + 1 < 10 ? "0" + (hour + 1) : hour + 1) + ":00")
            }
        }
    }

    //change time finished
    const changetimefinsihedfrom = value => {
        if (value > timestartedValue)
            changetimefinished(value)
    }

    //change billable
    const ChangeBillable = value => {
        changeBillable(value)
    }

    const ChangeOvertime = value => {
        changeOvertime(value)
    }

    const onChangeDescription = e => {
        changeDescription(e.target.value)
    }

    const [disablebutton, changedisablebutton] = useState(false)

    const entrysubmit = async () => {
        if (selectedService.length === 0) {
            alert.error("Please select a service")
        }
        else if (selectedActivities.length === 0) {
            alert.error("Please select an activity")
        }
        else if (description.trim() === "") {
            alert.error("Please select add a description")
        }
        else {
            changedisablebutton(true)
            
            var currententry = {
                description: description,
                time: JSON.stringify({
                    timestarted: timestartedValue,
                    timefinished: timefinishedValue
                }),
                billable: billable,
                overtime: overtime,

                user: JSON.stringify({
                    userId: user._id,
                    name: user.firstname + " " + user.lastname,
                }),
                date: specificdate > "" ? specificdate : date,
                customer: JSON.stringify({
                    customerId: selectedCustomer._id,
                    customerName: selectedCustomer.customername
                }),
                service: JSON.stringify({
                    serviceName: selectedService[0].object.name,
                    serviceId: selectedService[0].object._id
                }),
                activity: JSON.stringify({
                    activityName: selectedActivities[0].label,
                    activityId: selectedActivities[0].id
                }),
                entryId: entrydata.entryId ? entrydata.entryId : "",
                edit: entrydata.edit ? entrydata.edit : false,
                jira: JSON.stringify({
                    isJira: false,
                    status: 0,
                }),
                completed: false,
                lastSeen: JSON.stringify({
                    date: moment().format("YYYY-MM-DD"),
                    hour: moment().format("HH:mm")
                })
            }

            await sendrequest('addjournalentry', currententry, null, alert.error)
                .then(async res => {
                    if (res)
                        if (res.data.success) {
                            var data = {
                                bio: user.bio,
                                fax: user.fax,
                                email: user.email,
                                mobile: user.mobile,
                                skills: user.skills[0] ? JSON.stringify(user.skills) : user.skills.length === 0 ? JSON.stringify([]) : JSON.stringify([user.skills]),
                                landline: user.landline,
                                position: user.position,
                                firstname: user.firstname,
                                lastname: user.lastname,
                                homeaddress: user.homeaddress,
                                editemail: user.editemail,
                                oldemail: user.email,
                                oldpassword: "",
                                confirmpassword: "",
                                password:"",
                                image: user.image,
                                isadmin: user.isadmin,
                                admin: user.isadmin ? this.context.user.email : "",
                                active: user.active,
                                _id: user._id,
                                fromTimeSheet:true
                            }
                            await sendrequest("updateuser", data, null, alert.error)

                            await getEntries(date)
                            onChangeSelectedCustomer(selectedCustomer)
                            alert.success(res.data.message)
                            toggleEntry()
                            const time = moment().format("HH:mm");
                            const changeddate = specificdate > "" ? specificdate : date;
                            // onsuccentry(changeddate, time)
                        }
                        else {
                            alert.error(res.data.message)
                        }

                })
        }
    }

    const deletesubmit = async (_id) => {
        var data = {
            entryId: _id
        }
        await sendrequest('deleteentry', data, null, alert.error)
            .then(async res => {
                if (res)
                    if (res.data.success) {
                        var data = {
                            bio: user.bio,
                            fax: user.fax,
                            email: user.email,
                            mobile: user.mobile,
                            skills: user.skills[0] ? JSON.stringify(user.skills) : user.skills.length === 0 ? JSON.stringify([]) : JSON.stringify([user.skills]),
                            landline: user.landline,
                            position: user.position,
                            firstname: user.firstname,
                            lastname: user.lastname,
                            homeaddress: user.homeaddress,
                            editemail: user.editemail,
                            oldemail: user.email,
                            oldpassword: "",
                            confirmpassword:"",
                            password: "",
                            image: user.image,
                            isadmin: user.isadmin,
                            admin: user.isadmin ? this.context.user.email : "",
                            active: user.active,
                            _id: user._id,
                            fromTimeSheet:true
                        }
                        await sendrequest("updateuser", data, null, alert.error)
                        toggleEntry()
                        await getEntries(date)
                    }
            })
    }


    const getserviceactivities = async (selectedServ, selact) => {
        changeService(selectedServ)
        if (selectedServ[0]) {
            let serviceActivities = {
                serviceActivities: selectedServ[0].object.activities
            }
            await sendrequest("getserviceactivities", serviceActivities, null, null)
                .then(res => {
                    if (res)
                        if (res.data.success) {
                            if (selact) {
                                if (selact[0]) {
                                    var acts = res.data.data.sort((x, y) => { return x.name === selact[0].label ? -1 : y.name === selact[0].label ? 1 : 0; });
                                    changeActivities(acts)
                                    changeActivity(selact)
                                }
                            }
                            else {
                                changeActivities(res.data.data.sort((a, b) => (a.name > b.name) ? 1 : -1))
                            }
                        }
                })
        }
        else {
            changeActivities([])
            changeActivity([])
        }
    }

    const quickTimeChange = (timeoption, changedHour) => {
        changetimefinished(timeoption)
        changeHour(parseInt(timeoption.split(":")[0]))
    }

    const [finishdropdownend, changefinishdropdown] = useState("24:00")
    useEffect(() => {
        async function onmount() {
            const {
                description,
                billable,
                overtime,
                service,
                activity,
                entryId,
                jira,
                completed,
                edit,
                time
            } = entrydata
            if (edit) {
                changetimestarted(time.timestarted)
                changetimefinished(time.timefinished)
                changeDescription(description)
                changeBillable(billable)
                changeOvertime(overtime)
                let index = 0;
                let found = false
                var object = {}
                while (index < services.length && !found) {
                    if (services[index].name === service.serviceName) {
                        object = {
                            id: service.serviceId,
                            label: service.serviceName,
                            object: services[index]
                        }
                        found = true
                    }
                    else {
                        index++
                    }
                }

                await getserviceactivities([object], [{
                    label: activity.activityName,
                    id: activity.activityId,
                    object: activity
                }])
                if (found) {
                    services.splice(index, 1)
                    services.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    services.unshift(object.object)
                }
            }
            else
                services.sort((a, b) => (a.name > b.name) ? 1 : -1)

            const temparray = [":00", ":15", ":30", ":45"]
            let fulltemparray = timeDropDowns
            allentriesforcustomer.forEach(entrytemp => {
                for (let a = 0; a < 4; a++) {
                    let temptime = (hour < 10 ? "0" + hour : hour) + temparray[a]

                    const startparts = entrytemp.time.timestarted.split(":")
                    const finishparts = entrytemp.time.timefinished.split(":")
                    const temptimestart = temptime.split(":")

                    const startsum = (parseInt(startparts[0]) * 60) + parseInt(startparts[1])
                    const finishsum = (parseInt(finishparts[0]) * 60) + parseInt(finishparts[1])
                    const tempsum = (parseInt(temptimestart[0]) * 60) + parseInt(temptimestart[1])

                    if (parseInt(temptimestart[0]) >= parseInt(startparts[0]) && parseInt(temptimestart[0]) <= parseInt(finishparts[0])) {
                        if (parseInt(temptimestart[1]) < parseInt(finishparts[1]) && parseInt(temptimestart[0]) > parseInt(startparts[0])) {
                            fulltemparray.splice(a, 1, "")
                        }
                        else if (parseInt(temptimestart[0]) === parseInt(startparts[0]) && parseInt(temptimestart[1]) >= parseInt(startparts[1])) {
                            fulltemparray.splice(a, 1, "")
                        }
                    }
                }
            })
            allentriesforcustomer.sort((a, b) => (a.time.timestarted > b.time.timestarted) ? 1 : -1)
            var az = 0
            let found = false
            while (!found && az < allentriesforcustomer.length) {
                if (allentriesforcustomer[az].time.timestarted.replace(/\s+/g, '') > timestartedValue) {
                    found = true
                }
                else
                    az++
            }
            if (found)
                changefinishdropdown(allentriesforcustomer[az].time.timestarted.replace(/\s+/g, ''))
        }
        onmount();
    }, [entrydata])

    return (
        <div className="Condor360Container">
            {/* <div className="timeoptions">
                {
                    timeoptions.map(block => {
                        return (
                            <div id={"timeblock" + block[1]} style={{ background: timefinishedValue === block[1] ? "#aaa" : "#ddd" }} className="timeblock" onClick={() => quickTimeChange(block[1], block[0])} >
                                <label>
                                    {block[0] === 1 ? block[0] + "hr" : block[0] + "hrs"}
                                </label>
                                <label className="timetimeblock">
                                    {block[1]}
                                </label>
                            </div>
                        )
                    })
                }
            </div> */}
            <div className="timeDropDowns">
                <div className="timestarted">
                    <label>
                        Started at
                    </label>
                    <div class="dropdown">
                        <button className="dropbtn">
                            <h1>
                                {timestartedValue}
                            </h1>
                        </button>
                        <div className="dropdown-content">
                            {timeDropDowns.map((a, i) => {
                                if (a !== "") {
                                    const timestamp = (hour < 10 ? "0" + hour : hour) + a
                                    return (
                                        <div id='times' style={{ background: timestartedValue === timestamp ? "#aaa" : "#f1f1f1" }} onClick={() => changetimestartedfrom(timestamp)}>{timestamp}</div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className="timefinished">
                    <label>
                        Finished at
                    </label>
                    <div class="dropdown">
                        <button className="dropbtn">
                            <h1>
                                {timefinishedValue}
                            </h1>
                        </button>
                        <div className="dropdown-content">
                            {
                                [...Array(24)].map((nothing, hourindex) => {
                                    if (hourindex >= hour) {
                                        return (
                                            timeDropDownsfinish.map((a, i) => {
                                                let finishedtime
                                                if (a === ":00")
                                                    finishedtime = (hourindex + 1 < 10 ? "0" + (hourindex + 1) : hourindex + 1) + a
                                                else
                                                    finishedtime = (hourindex < 10 ? "0" + hourindex : hourindex) + a


                                                if (finishedtime <= finishdropdownend) {
                                                    if (finishedtime === timefinishedValue)
                                                        return (
                                                            <div id='times' style={{ background: timefinishedValue === finishedtime ? "#aaa" : "#f1f1f1" }}>
                                                                {finishedtime}
                                                            </div>
                                                        )
                                                    else {
                                                        return (
                                                            <div id='times'
                                                                style={{ background: timefinishedValue === finishedtime ? "#aaa" : "#f1f1f1" }}
                                                                onClick={() => changetimefinsihedfrom(finishedtime)}>
                                                                {finishedtime}
                                                            </div>
                                                        )
                                                    }
                                                }
                                            })
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="CustomersServicesActivities">
                <div className="serviceChecklist">
                    <div className="checklisheader">
                        Services
                    </div>
                    <div className="checklist">
                        <MultiSelect
                            searchPlaceholder={"Search Services"}
                            items={arrayforMulti(services)}
                            maxSelectedItems={1}
                            showSelectedItems={false}
                            onChange={service => getserviceactivities(service)}
                            selectedItems={selectedService}
                            responsiveHeight="30vh"
                        />
                    </div>
                </div>
                <div className="activitiesChecklist">
                    <div className="checklisheader">
                        Activities
                    </div>
                    <div className="checklist">
                        <MultiSelect
                            searchPlaceholder={"Search Activities"}
                            items={arrayforMulti(activities)}
                            maxSelectedItems={1}
                            showSelectedItems={false}
                            onChange={activity => {
                                changeActivity(activity)
                            }}
                            selectedItems={selectedActivities}
                            responsiveHeight="30vh"

                        />
                    </div>
                </div>
            </div>
            <div className="billingtype">
                <div className="billable">
                    Billable
                    <Switch
                        checked={billable}
                        onChange={ChangeBillable}
                    />
                </div>
                <div className="overtime">
                    Overtime
                    <Switch
                        checked={overtime}
                        onChange={ChangeOvertime}
                    />
                </div>
            </div>
            <div className="description" id="description">
                <textarea
                    value={description}
                    onChange={onChangeDescription}
                    placeholder={"So what have you done at " + timestartedValue + "?"}
                >
                </textarea>
            </div>
            <div className="bottom">
                {
                    disablebutton ?
                        <button type="button" disabled>
                            Done
                </button>
                        : <button type="button" onClick={entrysubmit}>
                            Done
            </button>
                }
                {
                    entrydata.entryId ?
                        <button style={{ marginLeft: "1%" }} type="button" id="del" onClick={() => deletesubmit(entrydata.entryId)}>
                            Delete
                        </button>
                        : null
                }
            </div>
        </div>
    )
}

function JiraEntries() {
    return (
        <div className="JiraContainer">

        </div>
    )
}

export default withAlert()(Entry)