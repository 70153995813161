import React, { useState } from "react";
import { RadioGroup, RadioButton } from 'react-radio-buttons';

function AddUser(props) {
  const [newuser, changeUser] = useState(props.user)


  const lastItemActive = () => {
    if (props.lastItemActive)
      props.lastItemActive()
  }
  const changefirstname = (e) => {
    // props.deleteEmpty();
    changeUser({
      firstname: e.target.value,
      email: newuser.email,
      usertype: newuser.usertype,
      position: newuser.position,
      valid: true
    })
    lastItemActive()
  }

  const changeemail = (e) => {
    changeUser({
      firstname: newuser.firstname,
      email: e.target.value,
      usertype: newuser.usertype,
      position: newuser.position,
      valid: true
    })
    lastItemActive()
    // props.deleteEmpty();
  }

  const changeposition = (e) => {
    changeUser({
      firstname: newuser.firstname,
      email: newuser.email,
      usertype: newuser.usertype,
      position: e.target.value,
      valid: true
    })
    // props.deleteEmpty();
    lastItemActive()
  }

  return (
    <div
      className="userCard"
      id={props.id}
      style={{
        background: props.lastItemActive && props.id > 0 ? "rgba(240,240,240, .8)" : "rgba(249,249,249, .95)"
      }}
    >
      <div className="cancelCardContainer">
        {
          props.showdelete ?
            <button className="cancelButton" type="button" onClick={() => props.deleteNewUser(props.id)}>x</button>
            : null
        }
      </div>
      <form className="userCardForm">
        <input type="text" placeholder="First name" value={newuser.firstname} onChange={changefirstname} />
        <input type="text" placeholder="Email" value={newuser.email} onChange={changeemail} />
        <input type="text" placeholder="Position" value={newuser.position} onChange={changeposition} />
        <div className="userTypeOptions">
          <RadioGroup horizontal>
            <RadioButton value="1" pointColor="#00dd21">
              User
            </RadioButton>
            <RadioButton value="2" pointColor="#00dd21">
              Manager
            </RadioButton>
            {
              props.context.user.usertype > 2 ?
                <RadioButton value="3" pointColor="#00dd21">Admin</RadioButton>
                : null
            }
          </RadioGroup>
        </div>
      </form>
    </div>
  )
}

export default AddUser