import React from 'react';
import logo from '../style/img/logo.png'
import logo1 from '../style/img/logo1.png'
export default class Email extends React.Component {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto', width: '100%', alignItems: 'center' }}>
                <div style={{ display: 'flex', height: '100px', }}>
                    <img src={logo} alt='logo' style={{ margin: 'auto', height: '50px' }} />
                    <img src={logo1} alt='logo' style={{ margin: 'auto', marginLeft: '10px', height: '50px' }} />
                </div>
                <h1 style={{ fontWeight: '100' }}>Sign up to Condor360</h1>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto', width: '100%', alignItems: 'center' }}>
                    <h3 style={{ fontWeight: '100' }}>Welcome to Condor360</h3>
                    <p>Please continue the signup process by clicking the link below</p>
                    <a href='http://www.google.com' style={{ background: '#999', height: '50px', width: '10%', borderRadius: '10px', display: 'flex', color: 'white', textDecoration: 'none' }}>
                        <p style={{ margin: 'auto' }}> Verify Account</p>
                    </a>
                </div>
            </div>
        )
    }
}