import React from 'react';
import '../style/newstyle.css';

function ProjectIntegration() {
    return (
        <div class="wrapper">
            <header class="site-header">
                <div class="container">
                    <div class="row">
                        <div class="logo">
                            <a href="/"><img src={require('../style/img/header-logo.png')} alt="Condor Green Logo" /></a>
                            <button class="mobile-toggle" onClick={() => this.onSetSidebarOpen(true)}>Menu</button>
                        </div>
                        <div class="header-right">
                            <nav class="menu-bar">
                                <ul>
                                    {/* <li><a href="#">Case Studies</a></li> */}
                                    <li><a href="#">Register/Demo</a></li>
                                    <li><a href="mailto:sales@condor360.online">Contact Sales</a></li>
                                    <li><a href="mailto:support@condor360.online">Support</a></li>
                                    <li><a href="#">Sign In <img src={require('../style/img/login-icon.png')} alt="login" /></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <section class="inner-section">
                <div class="container">
                    <h2 class="section-heading">Project <strong>Integration</strong></h2>
                    <div class="watermark-box">
                        <div class="content-wrap">
                            <p>Condor360, Integrates with Jira and will be expanded to integrate with other Collaborations tools like Trello and others.</p>
                            <p>Clients approach Service Providers for a Service that could be made up of daily maintenance or "Business as Usual" support and projects. Managing a project and managing a Service are different things that overlap considerably.</p>
                            <p>Condor360 caters for this Overlap by importing User project information. The Users therfore has a view of the project tasks/deliverable in their Journals.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div class="sub-footer">
                <div class="row d-flex">
                    <div class="col-6">
                        <h3><span>Want</span> To Learn More ?</h3>
                        <p>The First Draft Is Written and Reviewed to Create</p>
                    </div>
                    <div class="col-4">
                        <div class="text-right">
                            <a href="#" class="default-btn prime-btn">Contact Us Today >></a>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="main-footer">
                <div class="container">
                    <div class="row d-flex">
                        <div class="col-3">
                            <div class="foot-widget f-widget-1">
                                <div class="let-talk-btn"><a href="#">LET’S TALK! <img src="images/foot-arrow.jpg" alt="" /></a></div>
                                <p>There is no better time than now</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="foot-widget  f-widget-2">
                                <h4 class="title">Address</h4>
                                <p>4th Floor, West Tower, Canal Walk, Century City, Cape Town, 7435<br></br>South Africa</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="foot-widget f-widget-3">
                                <h4 class="title">Contact Detail</h4>
                                <p>Phone: (+27) 21 555 2013</p>
                                <p>Email: sales@condor360.online </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright">
                    <div class="container">
                        <p>&copy; Copyright 2020 Condogreen Infotech | All Rghts Reserved</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default ProjectIntegration