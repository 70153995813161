import React, { Component } from 'react';
import { AppContext } from '../appcontext';
import Calendar from '../components/Home/layouts/Calendar';
import TimeSheet from '../components/Home/layouts/TimeSheet';
import { withAlert } from 'react-alert';
import '../components/Home/styles/home.scss';
import Loading from '../components/loading';
import SmallCalendar from '../components/Home/components/smallCalendar';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment'
import * as  Cookies from 'js-cookie';

class Home extends Component {
     static contextType = AppContext;
     constructor() {
          super();
          this.state = {
               timesheetView: true,
               userView: true,
               loading: false,
               resourcecustomers: [],
               services: [],
               activities: [],
               countnotification: 0,
               users: [],
               selectedCustomer: {},



          }
     }

     componentDidMount() {
          this.onMount()
     }

     onMount = async () => {
          this.setState({
               loading: true
          })
          await this.getResourceCustomers()
          this.onChangeSelectedCustomer(this.state.resourcecustomers[0])
          await this.getServices();
          // await this.getActivities();


          this.setState({
               loading: false
          })
     }

     //get user report data


     //Get the customers assigned to a resource
     getResourceCustomers = async (id) => {
          let data = {
               resourceId: id ? id : this.context.user._id,
          }
          await this.context.sendrequest("getcustomer/resourcecustomers", data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
               .then(res => {
                    if (res)
                         if (res.data.success)
                              this.setState({
                                   resourcecustomers: res.data.data,
                                   selectedCustomer: res.data.data[0]
                              })
               }).catch((e) => {
                    if (this.state.countnotification === 0 && e.request) {
                         const alert = this.props.alert
                         this.context.responseError(e, () => console.log("error at get resource customer"), alert.error)
                         this.setState({
                              countnotification: 1
                         })
                    }
               })
     }

     //Get services
     getServices = async () => {
          await this.context.sendrequest("getservices", null, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
               .then(res => {
                    if (res)
                         if (res.data.success)
                              this.setState({
                                   services: res.data.data
                              })
               }).catch((e) => {
                    if (this.state.countnotification === 0 && e.request) {
                         this.context.responseError(e, () => console.log("error at get service"), this.props.alert.error)
                         this.setState({
                              countnotification: 1
                         })
                    }
               })
     }

     //Get activities
     getActivities = async () => {
          await this.context.sendrequest("getactivities", null, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
               .then(res => {
                    if (res)
                         if (res.data.success)
                              this.setState({
                                   activities: res.data.data
                              })
               })
     }

     showMenu = () => {
          this.setState({
               timesheetView: !this.state.timesheetView
          })
     }
     onChangeSelectedCustomer = (customer) => {
          if (customer)
               if (customer.customername)
                    this.setState({
                         selectedCustomer: customer
                    })
     }


     render() {
          if (this.state.resourcecustomers.length <= 0) {
               document.body.style.overflow = "hidden"
          }
          else {
               document.body.style.overflow = "visible"
          }

          if (this.state.loading)
               return <Loading />
          else
               return (
                    <div className="homeContainer">
                         <div className="homeView">

                              <div className="timesheet">
                                   {
                                        this.state.resourcecustomers.length <= 0 ?
                                             <div className="noresourcecustomer">
                                                  <div className="noresourcecustomercont">
                                                       <h1>
                                                            You are not assigned to a customer.
                                                            Please request your manager to assign you to
                                                            a customer so that you are able to complete your daily timesheet.
                                                            {
                                                                 this.state.services <= 0 ?
                                                                      "You may want to bring to their attention that there are no services and activites(or tasks)."
                                                                      : null
                                                            }
                                                       </h1>
                                                  </div>
                                             </div>
                                             : null
                                   }
                                   <TimeSheet
                                        onChangeSelectedCustomer={this.onChangeSelectedCustomer}
                                        selectedCustomer={this.state.selectedCustomer}
                                        resourcecustomers={this.state.resourcecustomers}
                                        services={this.state.services}
                                        activities={this.state.activities}
                                        getResourceCustomers={this.getResourceCustomers}
                                   />
                              </div>


                         </div>
                    </div>
               )
     }
}

export default withAlert()(Home);
