import React,{Component} from 'react';
import Chart from "react-apexcharts";
import defaulticon from '../style/img/male.jpg';
import Loading from './loading';
import "../style/myassistant.scss";
class MyAssistantUsers extends Component{
    constructor(props){
        super(props);
        var today = new Date();
        this.state = {
            date : today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
            options: {
                fill:{colors:['#00ff55',"rgba(0,0,0,0.7)"]},
                labels:["Complete", "Incomplete"],
                legend:{
                    labels:{colors:["black"],position:"right" },
                    fontSize:'25px',
                    markers:{
                        radius:0,
                        width:60,
                        height:30,
                        fillColors:["#00ff33", "rgba(0,0,0,0.7)"]
                    }
                },

                tooltip:{
                    enabled:false
                },

                dataLabels:{
                    enabled: true,
                    style: {
                        fontSize: '20px',
                        colors: ["rgba(0,0,0,0.7)",'#fff']
                    },
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        opacity: 0.45
                    }
                }  
            },
            isLoading : false,
            user: this.props.user
        }
    }

    render(){
        return(
                <div className="pageContainer">
                <h1 id="heading">360Assistant | Users | {this.state.user.firstname} <div className="date"><div className="today">{this.state.date}</div></div></h1>                   
                <hr id="line"/>
                {
                this.state.isLoading?<Loading/>:
                <div className="report">
                    <div className="userDets">
                        <div className="imagery">
                            <img src={defaulticon} alt="default profile"/>
                        </div>
                        
                        <div className="lists">
                           
                            <div className="Incomplete">
                                <div className="IncompleteHeader">
                                    <h3>Incompleted Tasks</h3>
                                </div>
                                <div className="IncompleteList">
                                    <ul>
                                        <li>Task5</li>
                                        <li>Task5</li>
                                        <li>Task5</li>
                                        <li>Task5</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="Complete">
                                <div className="CompleteHeader">
                                    <h3>Completed Tasks</h3>
                                </div>
                                <div className="CompleteList">
                                    <ul>
                                        <li>Task5</li>
                                        <li>Task5</li>
                                        <li>Task5</li>
                                        <li>Task5</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rhs">
                    
                    <div className="dataCharts">
                        <div className="donutChart">
                          <div className="topLabel">
                              
                          </div>
                            <Chart
                                options={this.state.options}
                                series={[80,20]}    
                                type="donut"
                                width="100%" height="500px" 
                            />
                        </div>
                       
                    </div>
                    <hr id="line"/>
                    <div className="reportCard">
                            <h1>Personal report</h1>
                           
                            <div className="timeSummary">

                                <div className="gridhead">
                                                    <div className="date">
                                                        <h3>Date</h3>
                                                    </div>
                                                    <div className="time">
                                                        {/* <h3>Time</h3> */}
                                                            <div className="times">
                                                            <div className="startTime">
                                                                <h3>start-time</h3>
                                                            </div>
                                                            <div className="endTime">
                                                                <h3>end-time</h3>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <div className="customer">
                                                        <h3>Customer</h3>
                                                    </div>
                                                    <div className="service">
                                                        <h3>Service</h3>
                                                    </div>
                                                    <div className="service">
                                                        <h3>Activity</h3>
                                                    </div>
                                                    <div className="description">
                                                        <h3>Description</h3>
                                                    </div>
                                </div>
                                <div className="datafill">
                                    <div className="data">
                                        
                                    </div>
                                </div>

                            </div>
                    </div>
                </div>
                </div>
                }
                </div>

        )
    }
}

export default MyAssistantUsers;