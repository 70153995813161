import React, { Component } from 'react';

class JournalLeft extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leftHours: []
        }
    }

    render() {
        return <div>{[...Array(10)].map((e, i) => {
            return <p>{"00:"+i}</p>
        })}</div>
    }
}
export default JournalLeft