import React, { useState } from 'react';
import moment, { weekdays } from 'moment'
import '../styles/calendarTimesheet.scss'
function CalendarComponent(props) {
    //setting date
    const [dateObject, setdateObject] = useState(moment());
    if (props.month)
        setdateObject(moment().set("month", props.month))

    //setting the arrows for the weeks displayed on calendar
    var totalSlots = [...createBlankBlocks(dateObject), ...createDaysInMonths(props.data)];
    let rows = [];
    let cells = [];
    totalSlots.forEach((row, i) => {
        if (i % 7 !== 0) {
            cells.push(row); // if index not equal 7 that means not go to next week
        } else {
            rows.push(cells); // when reach next week we contain all td in last week to rows 
            cells = []; // empty container 
            cells.push(row); // in current loop we still push current row to new container
        }
        if (i === totalSlots.length - 1) { // when end loop we add remain date
            rows.push(cells);
        }
    });

    var {
        services,
        resourcecustomers,
        activities,
        user,
        sendrequest,
        entries
    } = props


    let firstentrydateString = []
    let firstdate = []
    let firstmonth = 0
    let firstyear = 0
    
    if (entries.length) {
        if (entries.length > 0) {
            firstentrydateString = props.entries[0].date.split("T")
            firstdate = firstentrydateString[0].split("-")
            firstmonth = parseInt(firstdate[1])
            firstyear = firstdate[0]
        }
    }
    else {
        firstmonth = moment().month() + 1
        firstyear = moment().year()
    }
    return (
        <div className="TimeSheetCalendarContainer" id={"PreviewCalendarContainer" + props.indexOfContainer}>
            <div className="PreviewCalendar">
                <label className="MonthYear">
                    <GetMonth index={firstmonth} year={firstyear} />
                </label>
                <div className="calenderHeaders">
                    <Weekdays />
                </div>
                <div className="calenderDays">
                    <Days rows={rows} />
                </div>
            </div>
        </div>
    )
}


function GetMonth(index, year) {
    var months = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ]
    return months[index.index - 1] + " " + index.year
}

function Weekdays() {
    let weekdayshort = moment.weekdaysShort();
    let weekdayshortname = weekdayshort.map(day => {
        return (
            <th key={day} >
                {day}
            </th>
        );
    });
    return weekdayshortname
}

function Days(rows) {
    let daysinmonth = rows.rows.map((d, i) => {
        if (d.length < 7) {
            for (let a = d.length; a < 7; a++)
                d[a] = ""
        }
        if (i > 0 && i < rows.rows.length) {
            return (
                <tr>
                    {
                        d.map((object, index) => {
                            //Blank days
                            if (object === "")
                                return (
                                    <td key={object} id={"calendar-day-empty"}>
                                        {object}
                                    </td>
                                )
                            //Last row on the calendar
                            if (i === rows.rows.length - 1 && index === d.length - 1) {
                                var previouselement = document.getElementById("calendar-day5")
                                return (
                                    <td key={object.date} id={"calendar-day" + index} style={{ maxWidth: previouselement ? previouselement.offsetWidth : "" }}>
                                        {/* <div className="calendarNumber" style={{ background: show ? "#aaffaa" : "#efefef" }}> */}
                                        <div className="calendarNumber">
                                            {object.date}
                                        </div>
                                        <div className="HoursList" id="hourslistcalendar">
                                        </div>
                                    </td>
                                )
                            }
                            //days with dates
                            else
                                return (
                                    <td key={object.date} id={"calendar-day" + index}>
                                        <div className="calendarNumber">
                                            {object.date}
                                        </div>
                                        <div className="HoursList" id="hourslistcalendar">
                                        </div>
                                    </td>
                                )
                        })
                    }
                </tr >
            )
        }
    });
    return daysinmonth
}

function createDaysInMonths(data) {
    let daysInMonth = [];
    for (let d = 1; d <= getDaysOfMonth(); d++) {
        daysInMonth.push({
            date: d,
        });
    }
    return daysInMonth
}


function createBlankBlocks(dateObject) {
    let blanks = [];
    for (let i = 0; i < getFirstDayOfMonth(dateObject); i++) {
        blanks.push("");
    }
    return blanks
}

function getFirstDayOfMonth(dateObject) {
    let firstDay = moment(dateObject)
        .startOf("month")
        .format("d");
    return firstDay;
}

function getDaysOfMonth(dateObject) {
    let daysInMonth = moment(dateObject).daysInMonth()
    return daysInMonth;
}

export default CalendarComponent;