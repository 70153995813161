import React, { useState } from 'react'
import '../styles/monthentries.scss'
import Loading from '../../loading'
import moment from 'moment'
import { CSSTransition } from 'react-transition-group';
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
function MonthEntries(props) {
    var {
        data,
        close,
        toggleEntry,
    } = props
    data.sort((a, b) => (a.date > b.date) ? -1 : 1)
    let newdata = []
    data.map((entry, index) => {
        var {
            date,
            _id,
        } = entry
        let entriesdate = moment(date).format("DD-MMM-YYYY-ddd")
        if (data[index - 1]) {
            if (data[index - 1].date === date) {
                newdata[newdata.length - 1].entries.push(entry)
            }
            else {
                newdata.push({
                    dateheader: entriesdate,
                    entries: [entry]
                })
            }
        }
        else {
            newdata.push({
                dateheader: entriesdate,
                entries: [entry]
            })
        }
    })
    if (data) {
        return (
            <div className="MonthEntriesContainer">
                <div className="innercontainer">
                    <div className="closethis">
                        <button type="button" onClick={() => close(false)}><AiOutlineClose /></button>
                    </div>
                    {
                        data.length > 0 ?
                        <div className="innerinner">
                        <h1 style={{textAlign:'center'}}> {moment().format("MMM YYYY")} entries</h1>
                                {
                                    newdata.map(dataobjects => {
                                        return <Entrycomponent data={dataobjects} toggleEntry={toggleEntry} />
                                    })
                                }
                            </div>
                            : <h1 style={{ margin: "auto" }}>You have not completed any entries for {moment().format("MMM YYYY")}</h1>
                    }
                </div>
            </div>
        )
    }
    else return <Loading />
}

function Entrycomponent(props) {
    var {
        dateheader,
        entries,
    } = props.data

    const header = dateheader.split("-")
    const [toggleEntries, ontoggleEntries] = useState(dateheader === moment(new Date()).format("DD-MMM-YYYY-ddd") ? true : false)
    return (
        <div className="entriescontainer">
            <div className="datediv" onClick={() => ontoggleEntries(!toggleEntries)}>
                {header[0]} {header[1]} {header[2]} , {header[3]}
                {
                    toggleEntries ?
                        <MdKeyboardArrowUp /> :
                        <MdKeyboardArrowDown />
                }
            </div>
            <CSSTransition
                in={toggleEntries}
                appear={true}
                timeout={300}
                classNames="fade"
                unmountOnExit
            >
                <>
                    {
                        entries.map(entry => {
                            var {
                                activity,
                                billable,
                                customer,
                                date,
                                description,
                                hours,
                                lastSeen,
                                overtime,
                                service,
                                time,
                                user,
                                _id,
                            } = entry
                            const timesparts = time.timestarted.split(":")
                            let newdate = moment(date).format("YYYY-MM-DD")
                            return (
                                <div className="entrydiv" onClick={() => {
                                    props.toggleEntry(parseInt(timesparts[0]), null, null, entry, newdate)
                                }}>
                                    <div className="custandtime">
                                        <div className="customerdiv">{customer.customerName}</div>
                                        <div className="timediv">{billable ? "Billable" : overtime ? "Overtime" : "Non billable"} between {time.timestarted} - {time.timefinished}</div>
                                    </div>
                                    <div className="servicediv">{service.serviceName}</div>
                                    <div className="activitydiv">{activity.activityName}</div>
                                    <div className="descriptiondiv">{description}</div>
                                </div>
                            )
                        })
                    }
                </>
            </CSSTransition>
        </div>
    )
}

export default MonthEntries