import React from 'react';

export default function Loading() {
    return (
        // <div
        //     style={{ background: 'rgba(255,255,255, .7)' }}
        //     className="lds-ripple">
        //     <div>
        //     </div>
        //     <div>
        //     </div>
        // </div>
        <div className="LoadingContainer">
            <div className="lds-ellipsis">
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}