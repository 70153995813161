import React, { Component } from 'react';

class reportCalendarView extends Component {
    renderTableData() {
        if (this.props.data[0].customer)
            return this.customerData();
        else
            return this.serviceData();

    }
    customerData = () => {
        return this.props.data.map((entry, index) => {
            const {
                _id,
                date,
                overtime,
                billable,
                user,
            } = entry //destructuring
            let newdate = date.split('T')
            let billing;
            if (billable)
                billing = "Billable"
            else if (billable && overtime)
                billing = "Billable & Overtime"
            else {
                if (overtime)
                    billing = "Overtime"
                else
                    billing = "Non billable"
            }
            return (
                <tr key={_id} className="calenderDates">
                    <td className="datesview">
                        Billable
                        Non-buillable
                        Overtime
                    </td>
                    <td className="">{billing}</td>
                    <td>{user.name}</td>
                    <td>{user.name}</td>
                    <td>{user.name}</td>
                    <td>{user.name}</td>
                    <td>{user.name}</td>
                    <td>{user.name}</td>
                    <td>{user.name}</td>
                </tr>
            )
        })
    }
    serviceData = () => {
        return this.props.data.map((entry) => {
            const {
                overtimeHours,
                billableHours,
                nonBillableHours,
            } = entry //destructuring)
            let name = "";
            let id = ""
            if (entry.serviceId) {
                name = entry.serviceName;
                id = entry.serviceId;
            }
            else if (entry.userId) {
                name = entry.userName;
                id = entry.userId;
            }
            else {
                name = entry.activityName;
                id = entry.activityId
            }
            return (
                <tr className="tablerow" key={id} id="two" >
                    <td>{name}</td>
                    <td>{billableHours}</td>
                    <td>{nonBillableHours}</td>
                    <td>{overtimeHours}</td>
                </tr>
            )
        })
    }
    calcTotal = () => {
        var data = this.props.data;
        var totalBillable = 0;
        var totalNonBillable = 0;
        var totalOvertime = 0;
        for (let a = 0; a < data.length; a++) {
            totalBillable += data[a].billableHours;
            totalNonBillable += data[a].nonBillableHours;
            totalOvertime += data[a].overtimeHours;
        }
        return (
            <tr key={1} className="tablerow" id="two" style={{ borderTop: "3px solid #111" }} >
                <td>Total</td>
                <td>{totalBillable}</td>
                <td>{totalNonBillable}</td>
                <td>{totalOvertime}</td>
            </tr>
        )
    }
    render() { //Whenever our class runs, render method will be called automatically, it may have already defined in the constructor behind the scene.
        // var testdate = this.props.entry.newdate
        return (
            <div id='student'>
                <table id='students'>
                    <thead>
                        <div className="ColumnHeadings">
                            <tr>
                                {/* <label>{testdate}</label> */}
                                {/* <label>Name</label>
                                <label>Name</label>
                                <label>Name</label>
                                <label>Name</label> */}
                            </tr>
                        </div>
                    </thead>
                    <tbody>
                        {this.renderTableData()}
                        {
                            this.props.data[0].billableHours ?
                                this.calcTotal()
                                : null
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
export default reportCalendarView