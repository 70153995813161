import React from 'react';
import Loading from './loading';
import "../style/userstats.scss"
import ReactApexChart from 'react-apexcharts';
import { AiOutlineClose } from 'react-icons/ai'
import moment from "moment"
function Stats(props) {
    if (props.data.userReport) {
        var user = props.data.userReport[0]
        var {
            totalHours, escUserStats
        } = user
        for (let a = 0; a < props.data.customerReport.length; a++) {
            for (let b = 0; b < props.customers.length; b++) {
                if (props.data.customerReport[a].customerId === props.customers[b]._id) {
                    props.data.customerReport[a].logo = props.customers[b].logo
                }
            }
        }
        return (
            <div className="StatsContainer">
                <div className="StatsProfile">
        <h2 style={{textAlign: "center"}}>{props.userObject.firstname}'s statistics for {moment().format("MMMM YYYY")}</h2>
                    <button type="button" className="closeStats" onClick={() => props.toggleStats(false)}>
                        <AiOutlineClose />
                    </button>
                    <section className="top">
                        <div className="left">
                            <div className="customersList">
                                <div className="customerHeaders">
                                    <div className="customerInfo">
                                        Customer
                                    </div>
                                    <div className="Billable">
                                        Billable
                                    </div>
                                    <div className="nonBillable">
                                        Non billable
                                    </div>
                                    <div className="Overtime">
                                        Overtime
                                    </div>
                                    <div className="Total">
                                        Total
                                    </div>
                                </div>
                                <div className="customersListContainer">
                                    <CustomersList escUserStats={escUserStats} customers={props.data.customerReport} />
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="statsprofile">
                                <div className="left">
                                    <section className="hours">
                                        <label className="totalHourshours">
                                            <label>Your hours for this month</label>
                                            <label>
                                                Total : {totalHours === 1 ? totalHours + " hour" : totalHours + " hours"}
                                            </label>
                                        </label>
                                        <section className="HoursSection">
                                            <HoursChart data={user} />
                                        </section>

                                        {/* Simple View */}
                                        {/* <section className="HoursSection">
                                            <div className="left">
                                                <label>Billable</label>
                                                <label>Non billable</label>
                                                <label>Overtime</label>
                                                <label className="total">Total</label>
                                            </div>
                                            <div className="right">
                                                <label>: {billableHours < 2 && billableHours !== 0 ? billableHours + " hour" : billableHours + " hours"}</label>
                                                <label>: {nonBillableHours < 2 && nonBillableHours !== 0 ? nonBillableHours + " hour" : nonBillableHours + " hours"}</label>
                                                <label>: {overtimeHours < 2 && overtimeHours !== 0 ? overtimeHours + " hour" : overtimeHours + " hours"}</label>
                                                <label className="total">: {totalHours < 2 && totalHours !== 0 ? totalHours + " hour" : totalHours + " hours"}</label>
                                            </div>
                                        </section> */}
                                    </section>
                                </div>
                                <div className="right">
                                    <div className="statsuserprofile">
                                        <div className="top">
                                            <div className="imgContainer">
                                                <img src={props.userObject.image} alt="User Profile" />
                                            </div>
                                            <div className="userDetailsstats">
                                                <label> {props.userObject.firstname + " " + props.userObject.lastname}</label>
                                                <label> {props.userObject.email}</label>
                                            </div>
                                        </div>
                                        {
                                            props.userObject.lastSeen.date > "" ?
                                                <div className="bottom">
                                                    <p>
                                                        Your last activity :
                                            </p>
                                                    <p>
                                                        {props.userObject.lastSeen.date + " at " + props.userObject.lastSeen.hour}
                                                    </p>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="bottom">
                        <EntriesList escUserStats={escUserStats} data={props.data.entries} />
                    </section>
                </div>
            </div>
        )
    }
    else
        return (
            <Loading />
        )
}

function HoursChart(user) {
    var {
        billableHours,
        nonBillableHours,
        overtimeHours
    } = user.data
    var state = {

        series: [{
            data: [billableHours, nonBillableHours, overtimeHours]
        }],
        options: {
            chart: {
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                    }
                }
            },
            // colors: ["blue", "red"],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true
                }
            },
            dataLabels: {
                enabled: true,
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: ["Billable", "Non Billable", "Overtime"],
                labels: {
                    style: {
                        // colors: ["black", "blue", "red"],
                        fontSize: '12px'
                    }
                }
            }
        },


    };
    return (
        <div id="chart" >
            <ReactApexChart options={state.options} series={state.series} type="bar" height="100%" />
        </div>
    )
}

function CustomersList(customers) {
    return (
        customers.customers.map(customer => {
            var {
                customerName,
                billableHours,
                nonBillableHours,
                overtimeHours,
                totalHours,
                logo
            } = customer
            return (
                <div className="customerItem">
                    <div className="customerInfo">
                        <div className="customerLogo">
                            <img src={logo} alt="customer lgoo" />
                        </div>
                        <div className="customerName">
                            {customerName}
                        </div>
                    </div>
                    <div className="Billable">
                        {billableHours}
                    </div>
                    <div className="nonBillable">
                        {nonBillableHours}
                    </div>
                    <div className="Overtime">
                        {overtimeHours}
                    </div>
                    <div className="Total">
                        {totalHours}
                    </div>
                </div>
            )
        })
    )
}

function EntriesList(data) {
    data.data.sort((a, b) => a.date > b.data ? -1 : 1)
    var newdata = [];
    // let arrayLength = data.data.length > 10 ? 10 : data.data.length

    for (let a = 0; a < data.data.length; a++)
        newdata.push(data.data[a])

    newdata.sort((a, b) => a.date > b.date ? -1 : 1)
    return (
        <div className="StatsEntryContainer">
            <div className="statentry" id="HeaderstatEntry">
                <div className="date">
                    Date
                </div>
                <div className="time">
                    Time
                </div>
                <div className="customer">
                    Customers
                </div>
                <div className="service">
                    Service - Activity
                </div>
                <div className="hourtype">
                    Billing Type
                </div>
                <div className="description">
                    Description
                </div>
            </div>
            {
                newdata.map(entry => {
                    var {
                        activity,
                        billable,
                        customer,
                        date,
                        description,
                        lastSeen,
                        overtime,
                        service,
                        time,
                        user,
                    } = entry
                    date = date.split("T");
                    date = date[0]
                    let hourtype = "Billable"
                    if (!billable) {
                        if (overtime)
                            hourtype = "Overtime"
                        else
                            hourtype = "Non billable"
                    }
                    return (
                        <div className="statentry">
                            <div className="date">
                                {date}
                            </div>
                            <div className="time">
                                {time.timestarted} - {time.timefinished}
                            </div>
                            <div className="customer">
                                {customer.customerName}
                            </div>
                            <div className="service">
                                <label className="dataHead">
                                    {service.serviceName}
                                </label>
                                <label>
                                    {activity.activityName}
                                </label>
                            </div>
                            <div className="hourtype">
                                {hourtype}
                            </div>
                            <div className="description">
                                {description}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Stats