import React, { Component } from 'react';
import { AppContext } from '../../../appcontext'
// import '../style/loading.scss'
// import '../components/Home/styles/home.scss'
import Loading from '../../loading';
import CalendarComponent from '../components/CalendarComponent'
import * as  Cookies from 'js-cookie';
import moment, { weekdays } from 'moment'

class Calendar extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            timesheetView: true,
            user: {},
            resourcecustomers: [],
            services: [],
            activities: [],
            entries: [],
            loading: true,
            date: moment().year() + '-' + (moment().month() < 10 ? '0' + moment().month() : moment().month()) + '-' + (moment().date() < 10 ? '0' + moment().date() : moment().date()),
        }
    }

    componentDidMount = () => {
        this.onMount()
    }

    onMount = async () => {
        this.setState({
            resourcecustomers: this.props.resourcecustomers,
            services: this.props.services,
            activities: this.props.activities
        })
        if (this.state.resourcecustomers)
            if (this.state.resourcecustomers.length)
                await this.getEntries(
                    this.state.services,
                    this.state.resourcecustomers,
                    this.state.activities,
                    this.context.user
                );
        this.setState({
            loading: false
        })

    }

    getEntries = async (allservices, allresourcecustomers, allactivities, user) => {
        const startdate = moment().year() + "-" +
            // (moment().month() + 1 < 10 ? "0" + (moment().month() + 1) : (moment().month()) + 1) + "-" +
            (moment().month() < 10 ? "0" + (moment().month()) : (moment().month())) + "-" +
            "01"
        const enddate = moment().year() + "-" +
            // (moment().month() + 1 <10 ? "0" + (moment().month() + 1) : (moment().month()) + 1) + "-" +
            (moment().month() < 10 ? "0" + (moment().month()) : (moment().month())) + "-" +
            (moment().daysInMonth() < 10 ? "0" + moment().daysInMonth() : moment().daysInMonth())
        let services = [];
        allservices.forEach(service => {
            services.push(service._id)
        })
        let activities = [];
        allactivities.forEach(activity => {
            activities.push(activity._id)
        })
        let customers = [];
        allresourcecustomers.forEach(customer => {
            customers.push(customer._id)
        })

        let data = {
            dates: JSON.stringify({
                startdate: startdate,
                enddate: enddate
            }),
            userIds: user._id,
            serviceIds: services,
            activityIds: activities,
            customerIds: customers
        }
        await this.context.sendrequest('getentries', data)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        entries: res.data.data,
                    })
                }
            })
    }
    render() {
        if (this.state.loading)
            return <Loading />
        else
        return (
            <div className="calendarContainer">
                <CalendarComponent
                    resourcecustomers={this.state.resourcecustomers}
                    services={this.state.services}
                    activities={this.state.activities}
                    user={this.context.user}
                    sendrequest={this.context.sendrequest}
                    entries={this.state.entries}
                />
            </div>

        )
    }
}

export default Calendar;
