import React, { useState } from 'react';
import '../style/skillscards.scss'
import { withAlert } from 'react-alert';
import StarRatings from 'react-star-ratings';
import { MdDone } from "react-icons/md";
import { GrAddCircle } from "react-icons/gr";

function Cards({ skill, index, removeSkill, editSkill, readonly}) {
    return (
        <div className="skilllist" key={index}>
            <div className="skillsName" onClick={() => editSkill(skill, index)}>
                <p>{skill.name.charAt(0).toUpperCase() + skill.name.slice(1)}</p>
            </div>
            <div className="skillYears" onClick={() => editSkill(skill, index)}>
                {skill.years} {parseInt(skill.years) > 1 ? "years" : "year"}
            </div>
            <div className="skillRating" onClick={() => editSkill(skill, index)}>
                {skill.rating}/5
            </div>
            {
                readonly ? 
            <div className="removeskill" onClick={() => removeSkill(index)} >
                x
            </div>
            :null
}
        </div>
    )
}


function SkillCards(props) {
    console.log(props)
    const alert = props.alert;
    //Skills of Selected user
    var userskills = [];
    if (typeof props.skills === "string") {
        userskills.push({
            name: props.skills.name ? props.skills.name : props.skills,
            years: props.skills.years ? props.skills.years : "0",
            rating: props.skills.years ? props.skills.years : 0
        })
    }
    else {
        props.skills.forEach(skill => {
            userskills.push({
                name: skill.name ? skill.name : skill,
                years: skill.years ? skill.years : "0",
                rating: skill.years ? skill.years : 0
            })
        })
    }


    const [Skills, setSkills] = useState(userskills);

    //function that adds a skill on enter key
    const addSkill = text => {
        const newskills = [...Skills, text];
        props.onChangeSkills(newskills)
        setSkills(newskills);
    };

    //function that removes a skill on click
    const removeSkill = index => {
        const newskills = [...Skills];
        newskills.splice(index, 1);
        props.onChangeSkills(newskills)
        setSkills(newskills);
    };

    //changes the value in the "add skill" textfiled
    const [value, setValue] = useState("");
    const [rating, setRating] = useState(0);
    const [years, setYears] = useState("");
    const [indextoremove, setIndex] = useState(0)


    const handleSubmit = e => {
        e.preventDefault();
        if (!value) return;
        let validskill = true
        Skills.forEach(skill => {
            if (skill.name === value)
                validskill = false
        });
        if (validskill) {
            addSkill({
                name: value,
                years: years === "" ? "0" : years,
                rating: rating
            });
            setValue("");
            setYears("");
            setRating(0)
        }
        else {
            for (let a = 0; a < Skills.length; a++)
                if (a === indextoremove)
                    Skills[a] = {
                        name: value,
                        years: years,
                        rating: rating
                    }
            setIndex(0);
            setValue("");
            setYears("");
            setRating(0)
        }
        onChangeShowForm(false)
    }

    //Edit skill 
    const editSkill = (skill, index) => {
        setIndex(index)
        setValue(skill.name)
        setYears(skill.years)
        setRating(skill.rating)
        // removeSkill(index)
    }

    const changeRating = (newRating) => {
        setRating(newRating)
    }

    const [showAddForm, onChangeShowForm] = useState(false)
    console.log(props)
    return (
        < div className="skillcardContainer"  >
            <div className="skillarray">
                {
                    Skills.map((s, index) => (
                        <Cards skill={s} index={index} key={index} removeSkill={removeSkill} editSkill={editSkill} />
                    ))
                }
            </div>
            {
                props.readonly === false ?
                <button className="btnAddSkill" onClick={() => onChangeShowForm(true)}><GrAddCircle/></button>
                :null
            }
            {
                showAddForm?
                    <div className="showaddform">
                        <form className="addskill" onSubmit={handleSubmit}>
                            <button className="closeSkills" onClick={() => onChangeShowForm(false)}>X</button>
                            <input
                                type="text"
                                value={value}
                                className="skillname"
                                placeholder="Skill"
                                onChange={e => setValue(e.target.value)}
                                autoComplete="false" />
                            <input
                                className="skillyears"
                                type="text"
                                placeholder="Years"
                                autoComplete="false"
                                value={years}
                                onChange={e => setYears(e.target.value)}
                            />
                            <div className="skillrating">
                                <label>Rating</label>
                                <StarRatings
                                    rating={rating}
                                    starRatedColor="lime"
                                    changeRating={changeRating}
                                    numberOfStars={5}
                                    name='rating'
                                    starDimension="15px"
                                />
                            </div>
                            <button className="skillbutton" type="submit">
                               Done
                            </button>
                        </form>
                    </div>
                    : null
            }

        </div >
    )
}
// export default SkillCards;
export default withAlert()(SkillCards)

