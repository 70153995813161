import React, { Component } from 'react';
import Trash from '../style/img/trash.png';
import ImageUploader from "react-images-upload";
import "../style/showprofilecustomers.scss";
import "../style/users.scss";
import Cards from "./cards";
import defaultIcon from "../style/img/defaultUser.png";
import Loading from './loading';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../appcontext';
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import defaultLogo from '../style/img/default-company-logo.png';
import { FiSettings } from "react-icons/fi";
import { FiMinimize2 } from "react-icons/fi";
import { FiMapPin } from "react-icons/fi";
import { FiPhone } from "react-icons/fi";
import { FiEdit3 } from "react-icons/fi";
import { GoPlusSmall } from "react-icons/go";
import { FiUserPlus } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { withAlert } from 'react-alert';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { FiChevronDown } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import { CSSTransition } from 'react-transition-group';
import { TiContacts } from "react-icons/ti";
import OrgChart from './orgChart';

class showProfileUser extends Component {
     static contextType = AppContext;

     constructor(props) {
          super(props);
          this.state = {
               isLoading: true
          }
          var LS = localStorage.getItem("previous-selected-customer")

          if (this.props.company) {
               this.newprops = {
                    company : this.props.company

               }
          }
          else {
               this.newprops = LS
               this.newprops = JSON.parse(this.newprops)
          }

          this.company = this.newprops.company
          this.contactImage = [];
     }

     componentDidMount() {
          document.addEventListener("keydown", this.escFunctionForm, false);
          document.addEventListener("keydown", this.escFunctionFormContact, false);
          this.company = this.newprops.company

          this.setState({
               isLoading: true,
               showMore: true
          })
          // this.toggleShowMore()
          let contacts = [];
          let resources = [];
          this.setState({
               customername: this.company.customername,
               description: this.company.description,
               building: this.company.building,
               area: this.company.area,
               street: this.company.street,
               region: this.company.region,
               code: this.company.code,
               country: this.company.country,
               industry: this.company.industry,
               active: this.company.active,
               logo: this.company.logo,
               picturesChosen: this.company.logo > "" ? 1 : 0,
               _id: this.company._id,
               contacts: this.company.contacts ? this.company.contacts : contacts,
               resources: this.company.resources ? this.company.resources : resources,
               selectedItems: [],
               contactImage: 0,
               contactFirstName: "",
               contactLastName: "",
               contactPosition: "",
               contactEmail: "",
               contactLandline: "",
               contactMobile: "",
               contactFax: "",
               contactKeyperson: false,
               contactBio: "",
               contactComments: "",
               showAddContact: false,
               whichmanage: "Resource",
               showContactsManage: false,
               showContacts: false,
               showResources: false,
               contactsObjects: [],
               resourcesObjects: [],
               loggedInUser: this.context.user
          })
          this.customerImage = []
          this.customerImage[0] = this.company.logo
          this.context.sendrequest('getusers', null, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
               .then(async response => {
                    if (response.data.success) {
                         this.setState({
                              companyPeople: response.data.data,
                         })
                         await this.toggleShowResources()
                         await this.toggleShowContacts()
                         localStorage.setItem("previous-selected-customer", JSON.stringify(this.newprops));
                         this.setState({
                              isLoading: false
                         })
                    }
               })

     }
     onActiveChange = (value) => this.setState({ contactKeyperson: value === "true" ? true : false })

     onDrop = (pictureFiles, pictureDataURLs) => {
          this.customerImage = pictureDataURLs
          if (this.customerImage.length > 1) {
               this.customerImage.shift();
               this.company.logo = this.customerImage[0];
          }
          this.setState({
               picturesChosen: 1
          })

     }
     onDropContactImage = (pictureFiles, pictureDataURLs) => {
          this.contactImage = pictureDataURLs
          if (this.contactImage.length > 1) {
               this.contactImage.shift();
               this.contactImageFinal = this.contactImage[0];
          }
          this.setState({
               contactImage: 1
          })

     }
     escFunctionForm = (event) => {
          if (event.keyCode === 27) {
               this.setState({
                    showForm: false
               })
          }
     }
     notification(message) {
          const alert = this.props.alert
          alert.success(message)
     }
     error(message) {
          const alert = this.props.alert
          alert.error(message)
     }
     escFunctionFormContact = (event) => {
          if (event.keyCode === 27) {
               this.setState({ showAddContact: false })
          }
     }


     componentWillUnmount() {
          document.removeEventListener("keydown", this.escFunctionForm, false);
          document.removeEventListener("keydown", this.escFunctionFormContact, false);

     }

     getInitialState = () => {
          return JSON.parse(localStorage.getItem('ticker') || '{}');
     }
     toggleShowMore = () => {
          this.setState({
               showMore: !this.state.showMore
          })
     }

     onChangeContactFirstName = (event) => this.setState({ contactFirstName: event.target.value })
     onChangeContactLastName = (event) => this.setState({ contactLastName: event.target.value })
     onChangeContactPosition = (event) => this.setState({ contactPosition: event.target.value })
     onChangeContactEmail = (event) => this.setState({ contactEmail: event.target.value })
     onChangeContactLandline = (event) => this.setState({ contactLandline: event.target.value })
     onChangeContactMobile = (event) => this.setState({ contactMobile: event.target.value })
     onChangeContactFax = (event) => this.setState({ contactFax: event.target.value })
     onChangeContactBio = (event) => this.setState({ contactBio: event.target.value })
     onChangeContactComments = (event) => this.setState({ contactComments: event.target.value })
     onChangeContactKeyperson = (event) => this.setState({ contactKeyperson: event.target.value })


     validateEmail(email) {
          const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(email)
     }

     addNewContact = async () => {
          if (this.state.contactFirstName.trim() === "")
               this.error("First name required")
          else if (this.state.contactEmail.trim() === "")
               this.error("Email required")
          else if(!this.validateEmail(this.state.contactEmail))
               this.error("Email format incorrect")
          else {
               // this.setState({
               //      isLoading: true
               // })
               var data = {
                    firstname: this.state.contactFirstName,
                    lastname: this.state.contactLastName,
                    position: this.state.contactPosition,
                    email: this.state.contactEmail,
                    image: this.contactImage[0],
                    landLine: this.state.contactLandline,
                    mobile: this.state.contactMobile,
                    fax: this.state.contactFax,
                    comments: this.state.contactComments,
                    keyperson: this.state.contactKeyperson,
                    bio: this.state.contactBio
               }
               await this.context.sendrequest('createcontact', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                    .then(async response => {
                         if (response.data.success) {
                              this.setState({
                                   contactFirstName: "",
                                   contactLastName: "",
                                   contactPosition: "",
                                   contactEmail: "",
                                   contactLandline: "",
                                   contactMobile: "",
                                   contactFax: "",
                                   contactKeyperson: ""
                              })
                              let contacts = this.state.contacts
                              contacts.push(response.data.data._id)
                              var data = {
                                   customername: this.state.customername,
                                   description: this.state.description,
                                   building: this.state.building,
                                   area: this.state.area,
                                   street: this.state.street,
                                   region: this.state.region,
                                   code: this.state.code,
                                   country: this.state.country,
                                   industry: this.state.industry,
                                   active: this.state.active,
                                   logo: this.customerImage[0],
                                   _id: this.state._id,
                                   contacts: contacts.length > 0 ? contacts : [],
                                   resources: this.state.resources
                              }
                              await this.context.sendrequest('updatecustomer', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                                   .then(async response => {
                                        if (response.data.success === true) {
                                             var data = {
                                                  _id: this.state._id
                                             }
                                             await this.context.sendrequest('getcustomer', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                                                  .then(async response => {
                                                       if (response.data.success) {
                                                            this.setState({
                                                                 customername: response.data.data.customername,
                                                                 description: response.data.data.description,
                                                                 building: response.data.data.building,
                                                                 area: response.data.data.area,
                                                                 street: response.data.data.street,
                                                                 region: response.data.data.region,
                                                                 code: response.data.data.code,
                                                                 country: response.data.data.country,
                                                                 industry: response.data.data.industry,
                                                                 active: response.data.data.active,
                                                                 logo: response.data.data.logo,
                                                                 _id: response.data.data._id,
                                                                 contacts: response.data.data.contacts,
                                                                 resources: response.data.data.resources,
                                                                 showAddContact: false,
                                                            })
                                                            this.newprops = {
                                                                 state: this.state,
                                                                 company: {
                                                                      customername: this.state.customername,
                                                                      description: this.state.description,
                                                                      building: this.state.building,
                                                                      area: this.state.area,
                                                                      street: this.state.street,
                                                                      region: this.state.region,
                                                                      code: this.state.code,
                                                                      country: this.state.country,
                                                                      industry: this.state.industry,
                                                                      active: this.state.active,
                                                                      logo: this.state.logo,
                                                                      _id: this.state._id,
                                                                      contacts: this.state.contacts,
                                                                      resources: this.state.resources
                                                                 }
                                                            }
                                                            this.toggleShowContacts();
                                                            localStorage.removeItem("previous-selected-customer")
                                                            try{
                                                                 localStorage.setItem("previous-selected-customer", JSON.stringify(this.newprops));
                                                            }catch(err){
                                                                 // Do nothing 
                                                            }
                                                            // if (this.state.Heading === "Contacts") {
                                                            //      this.toggleShowContacts()
                                                            // }
                                                            // else {
                                                            //      this.toggleShowResources()
                                                            // }
                                                            this.notification("Added new contact");
                                                            this.setState({ isLoading: false })
                                                       }

                                                  })

                                        }
                                        else {
                                             this.setState({
                                                  newUserSuccessful: false,
                                                  isLoading: false,
                                                  showForm: false,
                                             })
                                        }
                                   })
                         }
                    })
          }
     }

     toggleShowContacts = async () => {
          this.setState({
               // isLoading: true,
               showMore: false
          })

          let contacts = [];
          var data = {
               contacts: this.state.contacts && this.state.contacts.length > 0 ? this.state.contacts : contacts
          }
          await this.context.sendrequest('getcustomer/contacts', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
               .then(response => {
                    if (response.data.success) {
                         let users = [];
                         users = response.data.data;
                         let Users = []
                         for (let a = 0; a < users.length; a++) {
                              Users[a] = {
                                   id: users[a]._id,
                                   label: users[a].firstname + " " + users[a].lastname,
                                   checked: true
                              }
                         }
                         this.setState({
                              contactsObjects: response.data.data,
                              contactsName: Users,
                              showContacts: response.data.data.length > 0 ? true : false,
                         })
                    }
                    // else {
                    //      this.setState({
                    //           contactsObjects: [],
                    //           contactsName: [],
                    //           showContacts : response.data.data ? true : false
                    //           isLoading: false,
                    //      })
                    // }
               }).catch(
                    this.setState({
                         // isLoading: false
                    })
               )

     }

     toggleShowResources = async () => {
          this.setState({
               // isLoading: true,
               showMore: false
          })
          var data = {
               resources: this.state.resources
          }
          await this.context.sendrequest('getcustomer/resources', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
               .then(response => {
                    if (response) {
                         if (response.data.success) {

                              this.setState({
                                   resourcesObjects: response.data.data ? response.data.data : []
                              })
                              let users = [];
                              users = response.data.data ? response.data.data : [];
                              let Users = []
                              for (let a = 0; a < users.length; a++) {
                                   Users[a] = {
                                        id: users[a]._id,
                                        label: users[a].firstname + " " + users[a].lastname,
                                        checked: true
                                   }
                              }
                              let resourcesNamesfromCompany = Users;
                              this.setState({
                                   resourcesNamesfromCompany: resourcesNamesfromCompany,
                                   resourcesObjects: response.data.data
                              })
                              this.context.sendrequest('getusers')
                                   .then(response => {
                                        if (response.data.success) {
                                             let usersA = [];
                                             usersA = response.data.data;
                                             let UsersA = []
                                             for (let a = 0; a < usersA.length; a++) {
                                                  UsersA[a] = {
                                                       id: usersA[a]._id,
                                                       label: usersA[a].firstname + " " + usersA[a].lastname,
                                                       checked: true
                                                  }
                                             }
                                             this.setState({
                                                  resourceNames: UsersA,
                                                  showResources: this.state.resourcesObjects ? true : false
                                             })
                                        }
                                   })

                              this.setState({
                                   // Heading: "Resources",
                              })
                         }
                    }
                    else {
                         this.setState({
                              // isLoading: false
                         })
                    }
               })

     }

     toggleShowDetails = () => {
          this.setState({
               Heading: "Details",
               showDetails: true,
               showResources: false,
               showContacts: true,
               showMore: false
          });
     }
     setRedirect = () => {
          if (this.state.Heading !== "Details") {
               this.toggleShowDetails()
          }
          else {
               this.setState({
                    redirect: true
               })
          }
     }
     renderRedirect = () => {
          if (this.state.redirect) {
               return <Redirect to={`/${this.context.user.firstname}/customers`} />
          }
     }

     onRegName = (event) => this.setState({ customername: event.target.value });
     onRegDescription = (event) => this.setState({ description: event.target.value });
     onRegStreet = (event) => this.setState({ street: event.target.value });
     onRegBuilding = (event) => this.setState({ building: event.target.value });
     onRegArea = (event) => this.setState({ area: event.target.value });
     onRegRegion = (event) => this.setState({ region: event.target.value });
     onRegIndustry = (event) => this.setState({ industry: event.target.value });
     onRegCode = (event) => this.setState({ code: event.target.value });
     onRegCountry = (event) => this.setState({ country: event.target.value });
     onRegStatusActive = (value) => this.setState({ active: value === "true" ? "true" : "false" });
     onRegStatusInactive = () => this.setState({ active: false });

     onChangeContactsOrResources = async (selectedItems) => {
          // this.setState({
          //      isLoading: true,
          // })
          this.users = []
          for (let a = 0; a < selectedItems.length; a++) {
               this.users.push(selectedItems[a].id)
          }
          let ids = this.users.length === 0 ? [] : this.users;
          var data = {
               customername: this.state.customername,
               description: this.state.description,
               building: this.state.building,
               area: this.state.area,
               street: this.state.street,
               region: this.state.region,
               code: this.state.code,
               country: this.state.country,
               industry: this.state.industry,
               active: this.state.active,
               logo: this.customerImage[0],
               _id: this.state._id,
               contacts: this.state.whichmanage === "Contacts" ? (ids.length === 0 ? [] : ids) : (this.state.contacts.length === 0 ? [] : this.state.contacts),
               resources: this.state.whichmanage === "Resources" ? (ids.length === 0 ? [] : ids) : (this.state.resources.length === 0 ? [] : this.state.resources)
          }
          await this.context.sendrequest('updatecustomer', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
               .then(async response => {
                    if (response.data.success === true) {
                         var data = {
                              _id: this.state._id
                         }
                         this.notification(response.data.message)
                         await this.context.sendrequest('getcustomer', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                              .then(response => {
                                   if (response.data.success) {
                                        this.setState({
                                             customername: response.data.data.customername,
                                             description: response.data.data.description,
                                             building: response.data.data.building,
                                             area: response.data.data.area,
                                             street: response.data.data.street,
                                             region: response.data.data.region,
                                             code: response.data.data.code,
                                             country: response.data.data.country,
                                             industry: response.data.data.industry,
                                             active: response.data.data.active,
                                             logo: response.data.data.logo,
                                             _id: response.data.data._id,
                                             contacts: response.data.data.contacts ? response.data.data.contacts : [],
                                             resources: response.data.data.resources,

                                        })
                                        this.newprops = {
                                             company: {
                                                  customername: this.state.customername,
                                                  description: this.state.description,
                                                  building: this.state.building,
                                                  area: this.state.area,
                                                  street: this.state.street,
                                                  region: this.state.region,
                                                  code: this.state.code,
                                                  country: this.state.country,
                                                  industry: this.state.industry,
                                                  active: this.state.active,
                                                  logo: this.state.logo,
                                                  _id: this.state._id,
                                                  contacts: this.state.contacts.length > 0 ? this.state.contacts : [],
                                                  resources: this.state.resources
                                             }
                                        }
                                        this.toggleShowContacts()

                                        if (this.state.Heading === "Contacts") {
                                             this.toggleShowContacts()
                                        }
                                        else {
                                             this.toggleShowResources()
                                        }
                                        try {
                                             localStorage.removeItem("previous-selected-customer")
                                             localStorage.setItem("previous-selected-customer", JSON.stringify(this.newprops));
                                        }
                                        catch (e) {
                                             this.props.alert.error("Something went wrong. Please contact support and explain your issue.")
                                        }
                                        // this.setState({ isLoading: false })
                                   }

                              })

                    }
                    else {
                         this.setState({
                              newUserSuccessful: false,
                              isLoading: false,
                              showForm: false,
                         })
                         this.error(response.data.message)
                    }
               })
     }

     onSubmitNewCompany = async () => {
          this.setState({
               isLoading: true,
          })
          if (this.state.customername.trim() === "") {
               this.error("Company name required")
          }
          else if (this.state.description.trim() === "") {
               this.error("Company description required")
          }
          else if (this.state.street.trim() === "") {
               this.error("Company street required")
          }
          else if (this.state.building.trim() === "") {
               this.error("Company building required")
          }
          else if (this.state.area.trim() === "") {
               this.error("Company area required")
          }
          else if (this.state.code.trim() === "") {
               this.error("Company address code required")
          }
          else if (this.state.country.trim() === "") {
               this.error("Company country required")
          }
          else {
               let contacts = [];
               var data = {
                    customername: this.state.customername,
                    description: this.state.description,
                    building: this.state.building,
                    area: this.state.area,
                    street: this.state.street,
                    region: this.state.region,
                    code: this.state.code,
                    country: this.state.country,
                    industry: this.state.industry,
                    active: this.state.active,
                    logo: this.customerImage[0],
                    _id: this.state._id,
                    contacts: this.state.contacts || this.state.contacts.length === 0 ? contacts : this.state.contacts,
                    resources: this.state.resources
               }
               await this.context.sendrequest('updatecustomer', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                    .then(async response => {
                         var data = {
                              _id: this.state._id
                         }

                         if (response.data.success === true) {
                              this.notification(response.data.message);
                              this.setState({
                                   showForm: false
                              })
                              await this.context.sendrequest('getcustomer', data, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                                   .then(response => {
                                        if (response.data.success) {
                                             this.setState({
                                                  customername: response.data.data.customername,
                                                  description: response.data.data.description,
                                                  building: response.data.data.building,
                                                  area: response.data.data.area,
                                                  street: response.data.data.street,
                                                  region: response.data.data.region,
                                                  code: response.data.data.code,
                                                  country: response.data.data.country,
                                                  industry: response.data.data.industry,
                                                  active: response.data.data.active,
                                                  logo: response.data.data.logo,
                                                  _id: response.data.data._id,
                                                  contacts: response.data.data.contacts,
                                                  resources: response.data.data.resources,

                                             })
                                             this.newprops = {
                                                  //state: this.state,
                                                  company: {
                                                       customername: this.state.customername,
                                                       description: this.state.description,
                                                       building: this.state.building,
                                                       area: this.state.area,
                                                       street: this.state.street,
                                                       region: this.state.region,
                                                       code: this.state.code,
                                                       country: this.state.country,
                                                       industry: this.state.industry,
                                                       active: this.state.active,
                                                       logo: this.state.logo,
                                                       _id: this.state._id,
                                                       contacts: this.state.contacts,
                                                       resources: this.state.resources
                                                  }
                                             }
                                             localStorage.removeItem("previous-selected-customer")
                                             localStorage.setItem("previous-selected-customer", JSON.stringify(this.newprops));
                                             this.company = this.newprops.company
                                             this.setState({ isLoading: false })
                                        }

                                   })

                         }
                         else {
                              this.setState({
                                   newUserSuccessful: false,
                                   isLoading: false,
                                   showForm: true,
                              })
                              this.error(response.data.message)
                         }
                    })
                    .catch(
                         this.setState({
                              isLoading: false,
                         })
                    )
          }
          this.setState({
               isLoading: false
          })
     }

     render() {
          if (this.state.isLoading) {
               return <Loading />
          }
          else {
               return (
                    <div className="">
                                        <div className="moreButtons">
                                             {/* <button className="addButton" onClick={() => this.setState({ showDetails: false, showMore: false })} title="Organizational Chart">
                                                  O
                                             </button> */}
                                             <button className="addButton" onClick={() => this.setState({ showForm: true, showMore: false })} title="Edit this customer">
                                                  <FiEdit3 />
                                             </button>

                                             <button className="addButton" title="Add Resource" onClick={() => this.setState({ showContactsManage: !this.state.showContactsManage, whichmanage: "Resources", showMore: false })}>
                                                  <FiUserPlus />
                                             </button>

                                             <button className="addButton" id="manualChange" title="Assign Contacts" onClick={() => this.setState({ showContactsManage: !this.state.showContactsManage, whichmanage: "Contacts", showMore: false })}>
                                                  <TiContacts />
                                             </button>
                                             <button className="addButton" title="Add Contacts" onClick={() => this.setState({ showAddContact: true, showMore: false })}>
                                                  <label>
                                                       <FiPhone />
                                                  </label>
                                                  <GoPlusSmall />
                                             </button>
                                        </div>
                         {
                              this.state.showForm ?
                                   <div className="AddCustomerForm">
                                        <section>
                                             <div className="FormHeader">
                                                  <p>Update Customer <button className="CloseCus"
                                                       onClick={
                                                            () => this.setState({
                                                                 showForm: false,
                                                                 customername: this.company.customername,
                                                                 description: this.company.description,
                                                                 building: this.company.building,
                                                                 area: this.company.area,
                                                                 street: this.company.street,
                                                                 region: this.company.region,
                                                                 code: this.company.code,
                                                                 country: this.company.country,
                                                                 industry: this.company.industry,
                                                                 active: this.company.active,
                                                                 logo: this.company.logo,
                                                                 picturesChosen: this.company.logo > "" ? 1 : 0,
                                                                 _id: this.company._id,
                                                                 contacts: this.company.contacts,
                                                                 resources: this.company.resources
                                                            })
                                                       }>
                                                       X</button></p>
                                             </div>
                                             <div className="FormContent">
                                                  <form>

                                                       <input type="text" placeholder="Street" value={this.state.street} onChange={this.onRegStreet} />
                                                       <input type="text" placeholder="Building" value={this.state.building} onChange={this.onRegBuilding} />
                                                       <input type="text" placeholder="Area" value={this.state.area} onChange={this.onRegArea} />
                                                       <input type="text" placeholder="Region" value={this.state.region} onChange={this.onRegRegion} />
                                                       <input type="text" placeholder="Code" value={this.state.code} onChange={this.onRegCode} />
                                                       <input type="text" placeholder="Country" value={this.state.country} onChange={this.onRegCountry} />
                                                       <input type="text" checked={this.state.checked} placeholder="Industry" value={this.state.industry} onChange={this.onRegIndustry} />
                                                       <div className="userTypeOptions">
                                                            <RadioGroup onChange={this.onRegStatusActive} value={this.state.active ? "true" : "false"} horizontal>
                                                                 <RadioButton value="true" pointColor="#00dd21">
                                                                      Active
                                                            </RadioButton>
                                                                 <RadioButton value="false" pointColor="red">
                                                                      Inactive
                                                            </RadioButton>
                                                            </RadioGroup>
                                                       </div>
                                                  </form>
                                                  <div className="AddFormProfile">
                                                       <section>
                                                            <input type="text" placeholder="Name" value={this.state.customername} onChange={this.onRegName} />
                                                            {/* <input type="text" placeholder="Description" value={this.state.description} onChange={this.onRegDescription} /> */}
                                                            <textarea placeholder="Description" value={this.state.description} onChange={this.onRegDescription}></textarea>
                                                       </section>
                                                       <div className="UploadImage">
                                                            {
                                                                 this.state.picturesChosen === 0 ?
                                                                      <ImageUploader
                                                                           withIcon={false}
                                                                           withPreview={false}
                                                                           label=""
                                                                           buttonText="Add Logo"
                                                                           onChange={this.onDrop}
                                                                           imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                                                           maxFileSize={1048576}
                                                                           fileSizeError=" file size is too big"
                                                                           className="ImageUploader"
                                                                           singleImage={true}
                                                                           defaultImage={this.company.logo}
                                                                      />
                                                                      :
                                                                      <div className="logo" >
                                                                           <div className="remove">
                                                                                <img src={Trash} alt="trash" onClick={() => { this.setState({ picturesChosen: 0 }); this.customerImage[0] = [] }} />
                                                                           </div>
                                                                           <img src={this.customerImage} alt="cutsomer" />
                                                                      </div>
                                                            }
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="SubmitContainer">
                                                  {
                                                       this.state.customername === "" ?
                                                            <button className="CustomerSubmit" type="button" disabled>Update</button>
                                                            :
                                                            <button className="CustomerSubmit" type="button" onClick={this.onSubmitNewCompany}>Update</button>
                                                  }

                                             </div>
                                        </section>
                                   </div> : null
                         }
                         {
                              this.state.showAddContact ?
                                   <div className="ContactFormContainer">
                                        <form>
                                             <div className="Heading">
                                                  <h3>
                                                       Add a contact
                                                  </h3>
                                                  <button className="CloseCus" type="button" onClick={() => this.setState({ showAddContact: false })}>
                                                       X
                                                  </button>
                                             </div>
                                             <section>
                                                  <div className="left">
                                                       <input type="text" placeholder="First Name" value={this.state.contactFirstName} onChange={this.onChangeContactFirstName} />
                                                       <input type="text" placeholder="Last Name" value={this.state.contactLastName} onChange={this.onChangeContactLastName} />
                                                       <input type="text" placeholder="Position" value={this.state.contactPosition} onChange={this.onChangeContactPosition} />
                                                       {/* <input type="text" placeholder="Key person" value={this.state.contactKeyperson} onChange={this.onChangeContactKeyperson} /> */}
                                                       <input type="text" placeholder="E-mail" value={this.state.contactEmail} onChange={this.onChangeContactEmail} />
                                                       <input type="text" placeholder="Landline" value={this.state.contactLandline} onChange={this.onChangeContactLandline} />
                                                       <input type="text" placeholder="Mobile" value={this.state.contactMobile} onChange={this.onChangeContactMobile} />
                                                       <input type="text" placeholder="Fax" value={this.state.contactFax} onChange={this.onChangeContactFax} />
                                                       <div className="userTypeOptions">
                                                            <RadioGroup onChange={this.onActiveChange} value={this.state.contactKeyperson + ""} horizontal>
                                                                 <RadioButton value={"true"} pointColor="#00dd21">
                                                                      Key person
                                                       </RadioButton>
                                                                 <RadioButton value={"false"} pointColor="red">
                                                                      Non key person
                                                       </RadioButton>
                                                            </RadioGroup>
                                                       </div>
                                                  </div>
                                                  <div className="right">
                                                       <div className="UploadImage">
                                                            {
                                                                 this.state.contactImage === 0 ?
                                                                      <ImageUploader
                                                                           withIcon={false}
                                                                           withPreview={false}
                                                                           label=""
                                                                           buttonText="Add Image"
                                                                           onChange={this.onDropContactImage}
                                                                           imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                                                           maxFileSize={1048576}
                                                                           fileSizeError=" file size is too big"
                                                                           className="ImageUploader"
                                                                           singleImage={true}
                                                                      />
                                                                      :
                                                                      <div className="logo" >
                                                                           <div className="remove">
                                                                                <img src={Trash} alt="trash" onClick={() => this.setState({ contactImage: 0 })} />
                                                                           </div>
                                                                           <img src={this.contactImage[0]} alt="cutsomer" />
                                                                      </div>
                                                            }
                                                       </div>
                                                       <section className="contactSectionTextArea">
                                                            <textarea placeholder="Bio" value={this.state.contactBio} onChange={this.onChangeContactBio}>

                                                            </textarea>
                                                            <textarea placeholder="Comments" value={this.state.contactComments} onChange={this.onChangeContactComments}>

                                                            </textarea>
                                                       </section>
                                                  </div>
                                             </section>
                                             <div className="bottom">
                                                  <button className="AddContactSubmit" type="button" onClick={this.addNewContact}>
                                                       Submit
                                                  </button>
                                             </div>
                                        </form>
                                   </div>
                                   :
                                   null
                         }
                         {
                              this.state.showContactsManage ?
                                   <div className="MultiSelect" id="close" onClick={(e) => {
                                        if (e.target.className === "MultiSelect" || e.target.class === "kn-multi_select__wrapper___30BEc")
                                             this.setState({ showContactsManage: false })
                                   }}
                                   >
                                        <section>

                                             <MultiSelect
                                                  wrapperClassName="multisel"
                                                  className="Multi"
                                                  items={this.state.whichmanage === "Contacts" ? this.state.contactsName : this.state.resourceNames}
                                                  showSelectedItems={false}
                                                  onChange={this.onChangeContactsOrResources}
                                                  showSelectAll={this.state.whichmanage === "Contacts" ? false : true}
                                                  selectedItems={this.state.whichmanage === "Contacts" ? this.state.contactsName : this.state.resourcesNamesfromCompany}
                                             />
                                        </section>
                                   </div>
                                   : null
                         }

                         <div className="pageContainer">
                              {/* {
                                   this.state.showMore ?
                                        <button className="addButton" style={{ background: "", cursor: "pointer" }} onClick={this.toggleShowMore}>
                                             <FiMinimize2 />
                                        </button>
                                        :
                                        <button className="addButton" style={{ cursor: 'pointer' }} onClick={this.toggleShowMore}>
                                             <FiSettings />
                                        </button>
                              } */}
                              {this.renderRedirect()}
                              <h1 id="heading">
                                   {/* <div className="backButton" onClick={this.setRedirect}>
                                        <IoIosArrowBack title="Back" />
                                   </div> */}
                                   Customers | {this.state.customername}
                              </h1>
                              <hr id="line" />

                              {/* for the details */}
                              {/* {this.state.showDetails ?

                                   : <OrgChart />
                                   } */}

                                   <div className="customerprofile">
                                        <div className="cardCus">
                                             <div className="cardtop">
                                                  <img src={this.company.logo ? this.company.logo : defaultLogo} alt='Profile' />
                                             </div>
                                        </div>
                                        <div className="forDesc" >
                                             <div className="CompanyName">
                                                  <h1>{this.company.customername}</h1>
                                                  <hr id="line" />
                                             </div>
                                             <div className="status">
                                                  <h2 style={{
                                                       color: this.company.active ? "lime" : "red"
                                                  }}>
                                                       {
                                                            this.company.active ?
                                                                 "Active" : "Inactive"
                                                       }
                                                  </h2>
                                             </div>
                                             <div className="description">
                                                  <h3>{this.company.description}</h3>
                                             </div>
                                             <div className="Address">
                                                  <h3>
                                                       {/* <img src={mapIcon} alt="mapIcon" /> */}
                                                       <FiMapPin />
                                                       {this.company.street + " " + this.company.building + " " + this.company.area + " " + this.company.code + " " + this.company.country}
                                                  </h3>
                                             </div>
                                        </div>
                                        <div className="DetailsCards">
                                             <h1 id="headingforsub" onClick={() => this.setState({ showResources: !this.state.showResources })}>
                                                  Resources ({this.state.resourcesObjects ? this.state.resourcesObjects.length : 0})
                                             <div className="headingButton">

                                                       {
                                                            this.state.showResources ?
                                                                 <FiChevronDown /> : <FiChevronLeft />
                                                       }
                                                  </div>
                                             </h1>

                                             <CSSTransition
                                                  in={this.state.showResources}
                                                  appear={true}
                                                  timeout={300}
                                                  classNames="fade"
                                                  unmountOnExit
                                             >
                                                  <div className="structureContainer">
                                                       {
                                                            this.state.resourcesObjects ?
                                                                 <Cards
                                                                      whichUsers="currentUsers"
                                                                      toggleCurrentUserDetails={this.currentUser}
                                                                      toggleShowProfile={this.viewProfile}
                                                                      users={this.state.resourcesObjects}
                                                                      defaultIcon={defaultIcon}
                                                                      state={this.state}
                                                                      from={false}
                                                                 />
                                                                 :
                                                                 <h1 style={{ fontWeight: '100' }}>
                                                                      No Resources to display
                                                                 </h1>
                                                       }
                                                  </div>
                                             </CSSTransition>
                                             <h1 id="headingforsub" onClick={() => this.setState({ showContacts: !this.state.showContacts })}>
                                                  Contacts ({this.state.contactsObjects ? this.state.contactsObjects.length : 0})
                                             <div className="headingButton">

                                                       {
                                                            this.state.showContacts ?
                                                                 <FiChevronDown /> : <FiChevronLeft />
                                                       }
                                                  </div>
                                             </h1>
                                             <CSSTransition
                                                  in={this.state.showContacts}
                                                  appear={true}
                                                  timeout={300}
                                                  classNames="fade"
                                                  unmountOnExit
                                             >
                                                  <div className="structureContainer">
                                                       {
                                                            this.state.contactsObjects > [] ?
                                                                 <Cards
                                                                      whichUsers="contacts"
                                                                      toggleCurrentUserDetails={this.currentUser}
                                                                      toggleShowProfile={this.viewProfile}
                                                                      users={this.state.contactsObjects}
                                                                      defaultIcon={defaultIcon}
                                                                      state={this.state}
                                                                 />
                                                                 :
                                                                 <h1 style={{ fontWeight: '100' }}>
                                                                      No Contacts to display
                                                                  </h1>
                                                       }
                                                  </div>
                                             </CSSTransition>
                                        </div>
                                   </div>
                                  
                         </div>


                    </div>
               );
          }
     }
}
export default withAlert()(showProfileUser);