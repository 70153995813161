import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import * as  Cookies from 'js-cookie';
import Loading from '../components/loading';
import '../style/loading.scss'
import { AppContext } from '../appcontext';
import { withAlert } from 'react-alert';
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { AiOutlineArrowLeft } from "react-icons/ai";

const qs = require('query-string');

class Login extends Component {
     static contextType = AppContext;
     constructor(props) {
          super(props);

          this.state = {
               username: '',
               email: '',
               password: '',
               isLoading: false,
               showPassword: false,
               showLogin: true,
               user: "",
               forgotpasswordShow: false,
               emailSentForm: false,
               valid: true
          };
     }
     onEmailSent = () => { this.setState({ emailSentForm: !this.state.emailSentForm }) }

     onShowForgotPassword = () => { this.setState({ forgotpasswordShow: !this.state.forgotpasswordShow }) }
     back = () => { this.setState({ forgotpasswordShow: false }) }

     onEnter = (event) => {
          const alert = this.props.alert
          if (event.keyCode === 13) {
               if (this.state.username.trim() === "") {
                    alert.error("User name required")
                    this.setState({ showLogin: true })
               }
               else if (this.state.password.trim() === "") {
                    alert.error("Password required")
                    this.setState({ showLogin: true })
               }
               else
                    this.handleSubmit()
          }
     }

     sendrequest = () => this.context.sendrequest

     componentWillUnmount() {
          document.removeEventListener("keydown", this.onEnter);
     }

     componentDidMount() {
          document.addEventListener("keydown", this.onEnter);
          if (Cookies.get('token')) {
               this.setState({
                    isLoading: true
               })
               this.context.sendrequest("verifytoken", null, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                    .then(response => {
                         if (response)
                              if (response.data.success === true) {
                                   this.setState({ isLoading: false })
                              } else {
                                   Cookies.remove('token');
                                   this.setState({ isLoading: false })
                              }
                         else {
                              Cookies.remove('token');
                              this.setState({ isLoading: false })
                         }
                    })
          } else {
               this.setState({ isLoading: false });
          }
     }

     handleSubmit = async () => {
          this.setState({ isLoading: true });
          var data = qs.stringify({
               username: this.state.username,
               password: this.state.password
          })
          await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, data)
               .then(async response => {
                    if (response.data.success === true) {
                         Cookies.set('token', response.data.token, { expires: 7 });
                         var userdata = qs.stringify({
                              token: Cookies.get('token')
                         })
                         this.notification(response.data.message)
                         
                         await this.context.sendrequest("getuser", null, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
                              .then(async res => {
                                   if (res) {
                                        if (res.data.success === true) {
                                             this.context.authenticate(res.data.user)
                                             this.setState({
                                                  isLoading: false
                                             })
                                        }
                                   }
                              })
                    }
                    else {
                         this.setState({ isLoading: false })
                         this.handleError(response.data.message)
                         localStorage.clear();
                    }
               }).catch(e => {
                    localStorage.clear();
                    if (e.response) {
                         this.props.alert.error("Something went wrong. Please try again")
                    }
                    else if (e.request) {
                         this.props.alert.error("The server is down. Please try again shortly")
                    }
                    else {
                         this.props.alert.error("Something went wrong. Please try again or contact support")
                    }
                    this.setState({
                         isLoading: false
                    })
               })
     }
     handleForgotPassword = async () => {
          this.setState({ isLoading: true });
          var data = {
               email: this.state.email
          }
          if (this.state.email > "")
               await this.context.sendrequest("forgotpassword", data)
                    .then(response => {
                         if (response.data.success === true) {
                              this.setState({ isLoading: false, emailSentForm: true, forgotpasswordShow: false })
                              this.notification(response.data.message)
                         }
                         else {
                              this.setState({ isLoading: false })
                              this.handleError(response.data.message)
                         }
                    }).catch(error => { 
                         this.setState({ isLoading: false })
                         this.handleError("Something went wrong please try again later")
                     })
          else {
               this.setState({ isLoading: false })
               this.handleError("Email required")
          }
     }
     onTextboxChangeEmail = (event) => {
          this.setState({
               email: event.target.value,
          })
     }


     onTextboxChangeUsername = (event) => {
          this.setState({
               username: event.target.value,
          })
     }

     onTextboxChangePassword = (event) => {
          this.setState({
               password: event.target.value,
          })
     }

     onSuccessfulLogin() {
          this.setState({
               isLoading: false
          })
     }

     onShowPassword = () => {
          this.setState({
               showPassword: !this.state.showPassword
          })
     }
     notification(message) {
          const alert = this.props.alert
          alert.success(message)
     }
     handleError(message) {
          const alert = this.props.alert
          alert.error(message)
     }
     handleEmptyUserName = () => {
          const alert = this.props.alert
          alert.error("User name required")
     }
     handleEmptyUserPassword = () => {
          const alert = this.props.alert
          alert.error("Password required")
     }

     render() {

          var {
               username,
               password,
               email,
          } = this.state;
          // var email = this.state.email

          if (this.state.isLoading) {
               return (
                    <div className="loginContainer">
                         <Loading />
                    </div>
               );
          }
          else if (this.context.isAuthenticated && !this.state.isLoading) {
               return (
                    <Redirect to={{
                         pathname: `/${this.context.user.firstname}/home`,
                         state: this.state
                    }}
                    />
               )
          }
          else if (!this.context.isAuthenticated && !this.state.isLoading) {
               return (
                    <div className="loginContainer" onClick={(e) => {
                         if (e.target.className === "loginContainer fade-enter-done")
                              this.props.onShowLogin();
                    }}>
                         {
                              this.state.forgotpasswordShow ?
                                   <form id="loginForm" style={{ height: "210px" }}>
                                        <button className="backToLogin" title="Back" onClick={this.back}><AiOutlineArrowLeft /></button>
                                        <h4>Forgot password</h4>
                                        <div className="passwordLogin">
                                             <input type="text" className="textbox" placeholder="email" value={email} onChange={this.onTextboxChangeEmail} required />
                                             <button
                                                  type="button"
                                             >
                                             </button>
                                        </div>
                                        {
                                             <button id="onSubmitButton" onClick={this.handleForgotPassword} type="button">Reset</button>
                                        }
                                   </form> :
                                   <form id="loginForm">
                                        <h4>Sign In</h4>
                                        <div className="passwordLogin">
                                             <input type="text" className="textbox" placeholder="Username" value={username} onChange={this.onTextboxChangeUsername} required />
                                             <button
                                                  type="button"
                                             >

                                             </button>
                                        </div>
                                        <div className="passwordLogin">
                                             {
                                                  this.state.showPassword ? <input type="text" placeholder="Password" value={password} onChange={this.onTextboxChangePassword} id="passwordField" required /> :
                                                       <input type="password" placeholder="Password" value={password} onChange={this.onTextboxChangePassword} id="passwordField" required />
                                             }
                                             <button
                                                  type="button"
                                                  onClick={this.onShowPassword}
                                             >
                                                  {
                                                       !this.state.showPassword ?
                                                            <FiEye className="eye" onClick={this.onShowPassword} /> :
                                                            <FiEyeOff className="eye" onClick={this.onShowPassword} />
                                                  }
                                             </button>
                                        </div>

                                        <button className="ref" onClick={this.onShowForgotPassword}>Forgot password</button>
                                        {
                                             this.state.username.trim() === "" ?
                                                  <button id="onSubmitButton" type="button" onClick={this.handleEmptyUserName}>Login</button> :
                                                  this.state.password.trim() === "" ?
                                                       <button id="onSubmitButton" type="button" onClick={this.handleEmptyUserPassword}>Login</button> :
                                                       <button id="onSubmitButton" type="button" onClick={this.handleSubmit}>Login</button>
                                        }
                                   </form>
                         }
                    </div>
               );
          }
          else {
               return <h1 style={{ display: "none" }}>hello</h1>
          }
     }
}


export default withAlert()(Login)
