import React from 'react';
import { Link } from 'react-router-dom';
import '../style/card.scss';
import defaultIcon from '../style/img/defaultUser.png';
import Avatar from '@material-ui/core/Avatar';
import Trash from '../style/img/trash.png';

function Card(props) {
    const style = {
        cardContainer: {
            width: '180px',
            height: '300px',
            margin: '5px',
            marginBottom: '50px',
        },

        card: {
            width: '100%',
            height: '100%',
            transition: '.3s',
            display: 'flex',
            flexDirection: 'column'
        },

        cardImage: {
            margin: 'auto',
            width: '90%',
            display: 'flex',
            justifyContent: 'center',
            borderBottomStyle: 'solid',
            borderBottomColor: '#ababab',
            borderBottomWidth: 'thin',
            transition: '.3s',
            flex: '3',
            order: '1'
        },

        img: {
            height: '60%',
            width: '60%',
            alignSelf: 'center',
            transition: '.3s',
            objectFit: 'contain',
        },

        details: {
            width: '100%',
            textAlign: 'center',
            flex: '1',
            order: '2',
            paddingTop: '10px'
        },

        h1: {
            padding: '0',
            margin: '0',
            fontSize: '20px',
        },

        h3: {
            margin: 'auto',
            fontWeight: '590',
            // background: '#555',
            color: '#555',
            width: '99%',
            flex: '1',
            order: '3',
            textAlign: 'center',
            minHeight: '50px',
        },

        link: {
            color: 'inherit',
            textDecoration: 'inherit'
        }
    }

    if (props.whichUsers === "currentUsers") {
        if (props.view === "grid") {
            return (
                props.users.map(function (employee, key) {
                    return (
                        <div style={style.cardContainer} onClick={props.toggleShowProfile}>
                            <Link key={key} style={style.link} to={{ pathname: `/${props.state.loggedInUser.firstname}/users/${employee.firstname}`, employee: employee, fromUsers: props.from }}>
                                <div style={style.card} className="card" onClick={() => props.toggleCurrentUserDetails(employee)}>
                                    <div style={style.cardImage} className="cardImage">
                                        {
                                            employee.image === "" || employee.image === null ?
                                                <img style={style.img} src={props.defaultIcon} alt="another" /> :
                                                <img style={style.img} src={employee.image} alt="asd" />
                                        }
                                    </div>
                                    <div style={style.details}>
                                        <h1 style={style.h1}>{employee.firstname}</h1>
                                        <h1 style={style.h1}>{employee.lastname}</h1>
                                    </div>
                                    <h3 style={style.h3}>{employee.position}</h3>
                                </div>
                            </Link>
                        </div>
                    )
                })
            )
        }
        else {
            return (
                props.users.map(function (employee, key) {
                    return (
                        <Link key={key} style={style.link} to={{ pathname: `/${props.state.loggedInUser.firstname}/users/${employee.firstname}`, employee: employee, fromUsers: props.from }}>
                            <div className="listcard" onClick={props.toggleShowProfile}>
                                <div className="indicator" style={{ background: employee.active ===1? "lime" : "red" }}>

                                </div>
                                <div className="userImage">
                                    {
                                        employee.image === "" || employee.image === null ?
                                            <Avatar title={employee.firstname}>
                                                <p>
                                                    {employee.firstname.charAt(0).toUpperCase()}
                                                </p>
                                            </Avatar> :
                                            <img style={style.img} src={employee.image} alt="asd" />
                                    }
                                </div>
                                <div className="userName">
                                    <p className="userName_names">
                                        {employee.firstname} {employee.lastname}
                                        </p>
                                    <p className="skills_listcard">
                                        {
                                            employee.skills ? employee.skills.length > 0 ? 
                                            employee.skills.map(skill => {
                                                return skill.name ? skill.name.charAt(0).toUpperCase() + skill.name.slice(1) + ", ":null
                                            })
                                            : null : null
                                        }
                                    </p>
                                </div>
                                <div className="email">
                                    {employee.email}
                                </div>
                                <div className="mobile">
                                    {Array.isArray(employee.mobile) ?
                                        employee.mobile[0] > "" ? employee.mobile[0] : "N/A"
                                        : employee.mobile > "" ? employee.mobile : "N/A"}
                                </div>
                                <div className="userPosition">
                                    {employee.position}
                                </div>
                            </div>
                        </Link>
                    )
                })
            )
        }
    }

    else if (props.whichUsers === "contacts") {
        if (props.view === "grid") {
            return (
                props.users.map(function (employee, key) {
                    return (
                        <div style={style.cardContainer} onClick={props.toggleShowProfile}>
                            <Link key={key} style={style.link} to={{ pathname: `/${props.state.loggedInUser.firstname}/customers/${props.state.customername}/contact/${employee.firstname}`, state: props.state, employee: employee, fromUsers: props.from }}>
                                <div style={style.card} className="card" onClick={() => props.toggleCurrentUserDetails(employee)}>
                                    <div style={style.cardImage} className="cardImage">
                                        {
                                            employee.image === "" || employee.image === null ?
                                                <img style={style.img} src={props.defaultIcon} alt="another" /> :
                                                <img style={style.img} src={employee.image} alt="asd" />
                                        }
                                    </div>
                                    <div style={style.details}>
                                        <h1 style={style.h1}>{employee.firstname}</h1>
                                        <h1 style={style.h1}>{employee.lastname}</h1>
                                    </div>
                                    <h3 style={style.h3}>{employee.position}</h3>
                                </div>
                            </Link>
                        </div>
                    )
                })
            )
        }
        else {
            return (
                props.users.map(function (employee, key) {
                    return (
                        <Link key={key} style={style.link} to={{ pathname: `/${props.state.loggedInUser.firstname}/customers/${props.state.customername}/contact/${employee.firstname}`, employee: employee, fromUsers: props.from }}>
                            <div className="listcard" onClick={props.toggleShowProfile}>
                                <div className="indicator" style={{ background: employee.active ===1 ? "lime" : "red" }}>

                                </div>
                                <div className="userImage">
                                    {
                                        employee.image === "" || employee.image === null ?
                                            <Avatar title={employee.firstname}>
                                                <p>
                                                    {employee.firstname.charAt(0).toUpperCase()}
                                                </p>
                                            </Avatar> :
                                            <img style={style.img} src={employee.image} alt="asd" />
                                    }
                                </div>
                                <div className="userName">
                                    {employee.firstname} {employee.lastname}
                                </div>
                                <div className="email">
                                    {employee.email}
                                </div>
                                <div className="mobile">
                                    {Array.isArray(employee.mobile) ?
                                        employee.mobile[0] > "" ? employee.mobile[0] : "N/A"
                                        : employee.mobile > "" ? employee.mobile : "N/A"}

                                </div>
                                <div className="userPosition">
                                    <label>
                                        {employee.position}
                                    </label>
                                    {/* <div className="deleteContact">
                                        <img src={Trash} alt="trash" />
                                    </div> */}
                                </div>
                            </div>
                        </Link>
                    )
                })
            )
        }
    }
    if (props.whichUsers === "360Users") {
        return (
            props.users.map(employee => (
                <Link style={style.link}
                    to={{
                        pathname: `/${props.state.user.username}/360assistant/myusers/${employee.firstname}`,
                        employee: employee
                    }}>
                    <div style={style.cardContainer} onClick={props.toggleShowProfile}>
                        <div style={style.card} className="card" >
                            <div style={style.cardImage} className="cardImage">
                                {
                                    employee.image === "" ? <img style={style.img} src={props.defaultIcon} alt="another" /> : <img style={style.img} src={employee.image} alt="asd" />
                                }
                            </div>
                            <div style={style.details}>
                                <h1 style={style.h1}>{employee.firstname}</h1>
                                <h1 style={style.h1}>{employee.lastname}</h1>
                            </div>
                            <h3 style={style.h3}>{employee.position}</h3>
                        </div>
                    </div>
                </Link>
            ))
        )
    }
    else if (props.whichUsers === "dud") {
        return (
            <div style={style.cardContainer}>
                <div style={style.card} className="card">
                    <div style={style.cardImage} className="cardImage">
                        <img style={style.img} src={defaultIcon} alt="a" />
                    </div>
                    <div style={style.details}>
                        <h1 style={style.h1}>{ }</h1>
                        <h1 style={style.h1}>{ }</h1>
                    </div>
                    <h3 style={style.h3}>{ }</h3>
                </div>
            </div>
        )
    }
    else if (props.whichUsers === "penging") {
        if (props.view === "grid") {
            return (
                props.users.map(function (employee, key) {
                    return (
                        <div key={key} style={style.cardContainer}>
                            <div style={style.card} className="card">
                                <div style={style.cardImage}>
                                    {
                                        employee.image ? <img style={style.img} src={employee.image} alt="asd" /> : <img style={style.img} src={props.defaultIcon} alt="another" />
                                    }
                                </div>
                                <div style={style.details}>
                                    <h1 style={style.h1}>{employee.firstname}</h1>
                                    <br />
                                    <h3 id="detailsCard" style={style.h3}>{employee.position}</h3>
                                </div>
                            </div>
                        </div>
                    )
                })
            )
        }
        else {
            return (
                props.users.map(function (employee, key) {
                    return (
                        <div className="listcard">
                            <div className="indicator" style={{ background: employee.active ===1 ? "lime" : "red" }}>
                            </div>
                            <div className="userImage">
                                <Avatar title={employee.firstname}>
                                    <p>
                                        {employee.firstname.charAt(0).toUpperCase()}
                                    </p>
                                </Avatar>
                            </div>
                            <div className="userName">
                                {employee.firstname ? employee.firstname : " "} {employee.lastname ? employee.lastname : " "}
                            </div>
                            <div className="email">
                                {employee.email}
                            </div>
                            <div className="userPosition">
                                {employee.position ? employee.position : " "}
                            </div>
                        </div>
                    )
                })
            )
        }
    }
}

export default Card;