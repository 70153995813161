import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import "../style/assistant.scss";
import defaulticon from "../style/img/male.jpg";
import Card from '../components/cards';
import logo1 from '../style/img/customerImg/logo1.png';
import logo2 from '../style/img/customerImg/logo2.png';
import logo3 from '../style/img/customerImg/logo3.png';
import logo4 from '../style/img/customerImg/logo4.png';
import logo5 from '../style/img/customerImg/logo5.png';
import logo6 from '../style/img/customerImg/logo6.png';
export default class Assistant extends Component{
    constructor(props){
      super(props);
      this.state = {
          user : {
              usertype : 1
          },
          logos: [logo1,logo2,logo3,logo4,logo5,logo6],
      }
    }
    render(){
        return(
            <div className="pageContainer">
                <h1 id="heading">360Assistant</h1>
                <hr id="line"/>
                <div className="assistantContainer">
                   
                   
                    <Link style={{display:'flex', flex:'1', width:'100%', textDecoration:'none', color:'white'}} to={`/${this.props.user.firstname}/360assistant/myassistant`}>
                        <div className="topper">
                            <h1 id="header">360MyProfile</h1>
                            <hr id="line"/>
                        <div className="top">
                            <div className="curruser">
                                <img src={defaulticon} alt="currentuser"/>
                            </div>
                            <div className="details">
                                <div className="divDetails">
                                    <h1>{this.props.user.firstname} {this.props.user.lastname}</h1>
                                    <h2>{this.props.user.position}</h2>
                                    <h2>{this.props.user.email}</h2>
                                </div>
                            </div>
                        </div>
                        </div>
                    </Link>
                    
                    <div className="middleA">
                            <Link style={{color:'white', textDecoration:'none'}}to={`/${this.props.user.firstname}/360assistant/customers`}>
                        <div className="midhead"><h1 id="header">360Customers</h1>
                            <hr id="line"/></div>
                        <div className="customerdud">
                                {
                                    this.state.logos.map(logo => (
                                        <img src={logo} alt=""/>
                                    ))
                                }
                        </div>
                            </Link>
                     </div>
                    <div className="bottom">
                        <Link style={{color:'white', textDecoration:'none'}} to={`/${this.props.user.firstname}/360assistant/myusers`}>
                        <div className="headuser"><h1 id="header">360MyUsers</h1>
                            <hr id="line"/></div>
                            <div className="userdud">
                                <Card whichUsers="dud"/>
                                <Card whichUsers="dud"/>
                                <Card whichUsers="dud"/>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}