import React, { useState } from 'react';
import moment from 'moment'

import '../style/previewcalendar.scss'

function PreviewCalendar(props) {
    const dateObject = moment().weekday('Monday').set("month", props.month)
    var totalSlots = [...createBlankBlocks(dateObject), ...createDaysInMonths(props.data, dateObject)];
    let rows = [];
    let cells = [];
    totalSlots.forEach((row, i) => {
        if (i % 7 !== 0) {
            cells.push(row); // if index not equal 7 that means not go to next week
        } else {
            rows.push(cells); // when reach next week we contain all td in last week to rows 
            cells = []; // empty container 
            cells.push(row); // in current loop we still push current row to new container
        }
        if (i === totalSlots.length - 1) { // when end loop we add remain date
            rows.push(cells);
        }
    });
    const firstentrydateString = props.data[0].date.split("T")
    const firstdate = firstentrydateString[0].split("-")
    const firstmonth = parseInt(firstdate[1])
    const firstyear = firstdate[0]
    return (
        <div className="PreviewCalendarContainer" id={"PreviewCalendarContainer" + props.indexOfContainer}>
            <div className="PreviewCalendar">
                <label className="MonthYear">
                    <GetMonth index={firstmonth} year={firstyear} />
                </label>
                <div className="calenderHeaders">
                    <th className="week-day">
                        Hours
                    </th>
                    <Weekdays />
                    <th className="week-day">
                        Total(hours)
                    </th>
                </div>
                <div className="calenderDays">
                    <Days rows={rows} />
                </div>
            </div>
        </div>
    )
}

function GetMonth(index, year) {
    var months = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ]
    return months[index.index - 1] + " " + index.year
}

function Weekdays() {
    let weekdayshort = moment.weekdaysShort();
    weekdayshort.push(weekdayshort.shift())
    let weekdayshortname = weekdayshort.map(day => {
        return (
            <th key={day} >
                {day}
            </th>
        );
    });
    return weekdayshortname
}

function Days(rows) {
    let daysinmonth = rows.rows.map((d, i) => {
        if (d.length < 7) {
            for (let a = d.length; a < 7; a++)
                d[a] = ""
        }
        if (i > 0 && i < rows.rows.length) {
            var totalBillable = 0;
            var totalNonBillable = 0;
            var totalOvertime = 0;
            for (let a = 0; a < d.length; a++) {
                if (typeof d[a] === "object") {
                    totalBillable += d[a].billableHours
                    totalNonBillable += d[a].nonbillableHours
                    totalOvertime += d[a].overtimeHours
                }
            }
            var showTotals = false;
            if (totalBillable > 0 || totalNonBillable > 0 || totalOvertime > 0)
                showTotals = true
            return (
                <tr>
                    <td>
                        <div className="HoursList" id="left">
                            <label style={{fontWeight : 600}}>Billable</label>
                            <label style={{fontWeight : 600}}>Non Billable</label>
                            <label style={{fontWeight : 600}}>Overtime</label>
                        </div>
                    </td>
                    {
                        d.map((object, index) => {
                            //Blank days
                            let show = true
                            if (object.billableHours === 0 && object.nonbillableHours === 0 && object.overtimeHours === 0)
                                show = false
                            if (object === "")
                                return (
                                    <td key={object} id={"calendar-day-empty"}>
                                        {object}
                                    </td>
                                )
                            //Last row on the calendar
                            if (i === rows.rows.length - 1 && index === d.length - 1) {
                                var previouselement = document.getElementById("calendar-day5")
                                return (
                                    <td key={object.date} id={"calendar-day" + index} style={{ maxWidth: previouselement ? previouselement.offsetWidth : "" }}>
                                        <div className="calendarNumber" style={{ background: show ? "#aaffaa" : "#efefef" }}>
                                            {object.date}
                                        </div>
                                        <div className="HoursList" id="hourslistcalendar">
                                            <label>{show ? object.billableHours : ""}</label>
                                            <label>{show ? object.nonbillableHours : ""}</label>
                                            <label>{show ? object.overtimeHours : ""}</label>
                                        </div>
                                    </td>
                                )
                            }
                            //days with dates
                            else
                                return (
                                    <td key={object.date} id={"calendar-day" + index}>
                                        <div className="calendarNumber" style={{ background: show ? "#aaffaa" : "#efefef" }}>
                                            {object.date}
                                        </div>
                                        <div className="HoursList" id="hourslistcalendar">
                                            <label >{show ? object.billableHours : ""}</label>
                                            <label >{show ? object.nonbillableHours : ""}</label>
                                            <label >{show ? object.overtimeHours : ""}</label>
                                        </div>
                                    </td>
                                )
                        })
                    }
                    <td>
                        <div className="HoursList">
                            <div className="HoursList" id="hourslistcalendar">
                                <label style={{fontWeight : showTotals ? 600 : 350}}>{totalBillable}</label>
                                <label style={{fontWeight : showTotals ? 600 : 350}}>{totalNonBillable}</label>
                                <label style={{fontWeight : showTotals ? 600 : 350}}>{totalOvertime}</label>
                            </div>
                        </div>
                    </td>
                </tr >
            )
        }
    });
    return daysinmonth
}

function createDaysInMonths(data, dateob) {
    let daysInMonth = [];
    for (let d = 1; d <= getDaysOfMonth(dateob); d++) {
        var billableHours = 0;
        var nonbillableHours = 0;
        var overtimeHours = 0;
        //vscode added this, don't know what it's for but it takes away the yellow underlines for this block of code
        // eslint-disable-next-line no-loop-func
        data.forEach(entry => {
            var {
                date,
                hours,
                billable,
                overtime,
            } = entry
            let fulldatearray = date.split("T")
            let dateString = fulldatearray[0]
            let day = dateString.split("-");
            if (parseInt(day[2]) === d) {
                if (billable) {
                    billableHours += hours;
                }
                else {
                    if (overtime) {
                        overtimeHours += hours
                    }
                    else {
                        nonbillableHours += hours
                    }
                }

            }
        })
        daysInMonth.push({
            date: d,
            billableHours: billableHours,
            nonbillableHours: nonbillableHours,
            overtimeHours: overtimeHours
        });
    }
    return daysInMonth
}


function createBlankBlocks(dateObject) {
    let blanks = [];
    for (let i = 1; i < getFirstDayOfMonth(dateObject); i++) {
        blanks.push("");
    }
    return blanks
}

function getFirstDayOfMonth(dateObject) {
    let firstDay = moment(dateObject)
        .startOf("month")
        .format("d");
    return firstDay;
}

function getDaysOfMonth(dateObject) {
    let daysInMonth = moment(dateObject).daysInMonth()
    return daysInMonth;
}

export default PreviewCalendar;