import React, {Component} from 'react';

import "../style/viewprofile.scss";

import Cards from "./cards";
import axios from 'axios';
import defaultIcon from '../style/img/male.jpg';

class ShowCompanyUser extends Component{
    constructor(){
        super();

        this.state={
            toggledUser:{
                username : "Man", 
                email: "aghhg@skvfgw",
                contact: "0248442464"
            },

            showProfile : false,

            usersList : []
        }

    }

    componentDidMount(){
        axios.get('http://192.168.1.109:3000/api/getusers').then(response => {
             this.setState({usersList : response.data.data,})
        })

}

    currentUserDetails = (currentUser) => {
            this.setState({
                toggledUser: currentUser 
            })
    }

    toggleShowProfile = () => {
        this.setState({
            showProfile : !this.state.showProfile
        })
    }


    render(){
        return(
            <div className="pageContainer">
            <h1 id="heading">Users</h1>
            <hr id="line"/>
            <div className="structureContainer">
                <Cards 
                    whichUsers="currentUsers"
                    state={this.state}
                    users={this.state.usersList}
                    toggleCurrentUserDetails={this.currentUserDetails}
                    toggleShowProfile={this.toggleShowProfile}
                    defaultIcon={defaultIcon}
                    pathname="company"
                />
            </div>
            </div>
        );
    }
}



export default ShowCompanyUser;