import React from 'react';
import '../styles/timesheetheader.scss';
import Clock from 'react-live-clock';
import { AiOutlineLeft } from 'react-icons/ai';
import { AiOutlineRight } from 'react-icons/ai';

function Timesheetheader(props) {
    var {
        resourcecustomers,
        onChangeSelectedCustomer,
        selectedCustomer,
        onShowCalendar,
        onCalendarArrow,
        date,
        user,
        loggeduser
    } = props

    return (
        <div className="timesheetheader">
            <div className="HeaderCustomer">
                <h1 className='heading'>
    {user._id === loggeduser._id ? "" : user.firstname + "'s "}Timesheet |
                </h1>
                <div className='timesheetheadercustomer'>
                    <div class="dropdown">
                        <button className="dropbtn">
                            <h1>
                                {selectedCustomer ? selectedCustomer.customername : ""}
                            </h1>
                        </button>
                        <div className="dropdown-content">
                            {resourcecustomers.map(customer => {
                                if (customer === selectedCustomer)
                                    return <div id='Customers' style={{ backgroundColor: "#aaa", color: "#fff" }}>{customer.customername}</div>
                                else
                                    return <div id='Customers' onClick={() => onChangeSelectedCustomer(customer)}>{customer.customername}</div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="TimeandDate">
                <div className='timesheetheaderTime'>
                    <h1 className='heading'>
                        <Clock format={'HH:mm'} ticking={true} />&nbsp;|
                    </h1>
                </div>
                <FormatDate onShowCalendar={onShowCalendar} date={date} onCalendarArrow={onCalendarArrow} />
            </div>


        </div>
    )
}

function FormatDate(props) {
    var {
        date, onShowCalendar, onCalendarArrow
    } = props

    return (
        <div className='timesheetheaderDate'>
            <div className="arrows">
                <div className="leftArrow" title="Previous" onClick={() => onCalendarArrow("left", date)}><AiOutlineLeft /></div>
                <h1 onClick={onShowCalendar}>
                    {date}
                </h1>
                <div className="rightArrow" title="Next" onClick={() => onCalendarArrow("right", date)}> <AiOutlineRight /></div>
            </div>
        </div>
    )
}
export default Timesheetheader