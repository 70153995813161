import React, { useState } from 'react';
import '../style/statscards.scss'
import moment from 'moment';
import { CSSTransition } from 'react-transition-group';
import Avatar from '@material-ui/core/Avatar';

function StatsCards(props) {
    var {
        users,
        filter
    } = props
    return (
        users.map(user => {
            return <Card user={user} filter={filter} />
        })
    )
}

function Card(props) {
    var {
        active,
        alternativecontact,
        bio,
        email,
        fax,
        firstname,
        homeaddress,
        image,
        landLine,
        lastSeen,
        lastname,
        mobile,
        position,
        skills,
        usertype,
        hoursWork,
        _id
    } = props.user

    let filter;
    let progress;
    if(!hoursWork){
        console.log(props.user)
    }
    if (props.filter === "Last Activity") {
        filter = hoursWork.today.totalHours
        if (filter <= 5)
            progress = "Poor"
        else if (filter > 5 && filter <= 7)
            progress = "Medium"
        else
            progress = "Good"

    }
    else if (props.filter === "This Week") {
        filter = hoursWork.thisWeek.totalHours
        if (filter <= 29)
            progress = "Poor"
        else if (filter >= 30 && filter <= 39)
            progress = "Medium"
        else
            progress = "Good"
    }
    else if (props.filter === "Last Week") {
        filter = hoursWork.lastWeek.totalHours
        if (filter <= 29)
            progress = "Poor"
        else if (filter >= 30 && filter <= 39)
            progress = "Medium"
        else
            progress = "Good"
    }
    else if (props.filter === "This Month") {
        filter = hoursWork.thisMonth.totalHours
        if (filter <= 127)
            progress = "Poor"
        else if (filter >= 128 && filter <= 132)
            progress = "Medium"
        else
            progress = "Good"

    }
    else if (props.filter === "Last Month") {
        filter = hoursWork.lastMonth.totalHours
        if (filter <= 127)
            progress = "Poor"
        else if (filter >= 128 && filter <= 132)
            progress = "Medium"
        else
            progress = "Good"

    }


    const thismonth = moment().format("MMM YYYY")
    const lastmonth = moment().subtract(1, 'months').format("MMM YYYY")

    const startthisweek = moment().startOf('week').format("DD MMM YYYY");
    const endthisweek = moment().endOf('week').format("DD MMM YYYY");

    const startlastweek = moment().subtract(1, 'weeks').startOf('week').format("DD MMM YYYY");
    const endlastweek = moment().subtract(1, 'weeks').endOf('week').format("DD MMM YYYY");
    const [toggle, changeToggle] = useState(false)
    return (
        <div className="statsContainer" onClick={() => changeToggle(!toggle)}>
            <div className="imagecomp">
                {
                    image > "" ?
                        <img src={image} alt="picuser" />
                        :
                        <Avatar title={firstname}><p>{firstname.charAt(0).toUpperCase()}</p></Avatar>
                }
            </div>
            <div className="statscomp">
                <div className="name">
                    <label >{firstname + " " + lastname}</label>
                    <label className="filterperiod">  {props.filter === "Last Activity" ?
                        moment(lastSeen.date).format("DD MMMM YYYY") === moment().format("DD MMMM YYYY") ? "Today" :
                            moment(lastSeen.date).format("DD MMMM YYYY") === moment().subtract(1, "days").format("DD MMMM YYYY") ? "Yesterday" :
                                "Last Activity " + moment(lastSeen.date).format("DD MMMM YYYY") :
                        props.filter}  : </label><p> {filter}hrs</p>
                    <label className="progress">
                        <label>
                            {progress}
                        </label>
                        <div className="dot"
                            style={{ background: progress === "Medium" ? "orange" : progress === "Good" ? "lime" : "red" }}>
                        </div>
                    </label>
                </div>
                <CSSTransition
                    in={toggle}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="statistics">

                        <div className="statContainer">
                            <div className="cont">
                                <label className="stathead">
                                    This week ({startthisweek} - {endthisweek})
                            </label>
                                <label>
                                    <p>Billable : {hoursWork ? hoursWork.thisWeek.billableHours : "Not available"}hrs</p>
                                    <p>Non Billable : {hoursWork ? hoursWork.thisWeek.nonbillableHours : "Not available"}hrs</p>
                                    <p>Overtime : {hoursWork ? hoursWork.thisWeek.overtimeHours : "Not available"}hrs</p>
                                    <p>Total : {hoursWork ? hoursWork.thisWeek.totalHours : "Not available"}hrs</p>
                                </label>
                            </div>
                            <div className="cont">
                                <label className="stathead">
                                    Last Week ({startlastweek} - {endlastweek})
                            </label>
                                <label>
                                    <p>Billable : {hoursWork ? hoursWork.lastWeek.billableHours : "Not available"}hrs</p>
                                    <p>Non Billable : {hoursWork ? hoursWork.lastWeek.nonbillableHours : "Not available"}hrs</p>
                                    <p>Overtime : {hoursWork ? hoursWork.lastWeek.overtimeHours : "Not available"}hrs</p>
                                    <p>Total : {hoursWork ? hoursWork.lastWeek.totalHours : "Not available"}hrs</p>
                                </label>
                            </div>
                        </div>
                        <div className="statContainer">
                            <div className="cont">
                                <label className="stathead">
                                    This Month ({thismonth})
                            </label>
                                <label>
                                    <p>Billable : {hoursWork ? hoursWork.thisMonth.billableHours : "Not available"}hrs</p>
                                    <p>Non Billable : {hoursWork ? hoursWork.thisMonth.nonbillableHours : "Not available"}hrs</p>
                                    <p>Overtime : {hoursWork ? hoursWork.thisMonth.overtimeHours : "Not available"}hrs</p>
                                    <p>Total : {hoursWork ? hoursWork.thisMonth.totalHours : "Not available"}hrs</p>
                                </label>
                            </div>
                            <div className="cont">
                                <label className="stathead">
                                    Last Month ({lastmonth})
                            </label>
                                <label>
                                    <p>Billable : {hoursWork ? hoursWork.lastMonth.billableHours : "Not available"}hrs</p>
                                    <p>Non Billable : {hoursWork ? hoursWork.lastMonth.nonbillableHours : "Not available"}hrs</p>
                                    <p>Overtime : {hoursWork ? hoursWork.lastMonth.overtimeHours : "Not available"}hrs</p>
                                    <p>Total : {hoursWork ? hoursWork.lastMonth.totalHours : "Not available"}hrs</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}
export default StatsCards;