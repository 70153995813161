import React, { useState } from 'react';
import '../styles/userList.scss';
import { FiSearch } from "react-icons/fi";
import { Avatar } from '@material-ui/core';

function ListUsers(props) {
    var {
        users, closeUserList, userCxt,
        date, getEntries, changeUser,
        escUserList,
        getResourceCustomers
    } = props

    const [searchedValue, onchnageSearchValue] = useState('')
    const [searchedUsers, onchnageSearchUsers] = useState(users)

    const getSearchedUsers = (e) => {
        e.target.value = e.target.value.toLowerCase()
        onchnageSearchValue(e.target.value)

        var user = [];
        var space = false;
        for (let a = 0; a < e.target.value.length; a++)
            if (e.target.value.charAt(a) === " ")
                space = true;

        if (space) {
            var stringSearch = e.target.value.split(' ');
            let firstname = stringSearch[0];
            let lastname = stringSearch[1];
            users.forEach(function (person) {
                if (person.firstname.toLowerCase().indexOf(firstname) !== -1 && person.lastname.toLowerCase().indexOf(lastname) !== -1) {
                    user.push(person)
                }
            })
        }
        else {
            if (e.target.value !== '') {
                users.forEach(function (person) {
                    if (person.firstname.toLowerCase().indexOf(e.target.value) !== -1 || person.lastname.toLowerCase().indexOf(e.target.value) !== -1) {
                        user.push(person)
                    }
                })
            }

            if (e.target.value === "") {
                user = []
                for (let z = 0; z < users.length; z++) {
                    user.push(users[z]);
                }
            }
        }
        user.sort((a, b) => (a.firstname > b.firstname) ? 1 : (a.firstname === b.firstname) ? ((a.lastname > b.lastname) ? 1 : -1) : -1)
        onchnageSearchUsers(user)

    }

    return (
        <div className='userListContainer' onClick={(e) => {
            if (e.target.className === "userListContainer")
                closeUserList()
        }}>
            <section>
                <SearchUser onchnageSearchValue={getSearchedUsers} searchedValue={searchedValue} />
                <div className="userList">
                    <UserCard changeUser={changeUser} user={userCxt} date={date} getEntries={getEntries} id={"logged"} getResourceCustomers={getResourceCustomers}/>

                    {searchedUsers.map(user => {
                        if (user._id !== userCxt._id)
                            return <UserCard escUserList={escUserList} getResourceCustomers={getResourceCustomers} changeUser={changeUser} user={user} date={date} getEntries={getEntries} id={"no"} />
                    })}
                </div>
            </section>
        </div>
    )
}
function UserCard(props) {
    var {
        active,
        alternativecontact,
        bio,
        email,
        fax,
        firstname,
        homeaddress,
        image,
        landLine,
        lastSeen,
        lastname,
        mobile,
        password,
        position,
        skills,
        usertype,
        _id,
    } = props.user

    return (
        <div className="usercardContainer" id={props.id} onClick={async () => {
            await props.getResourceCustomers(_id)
            await props.changeUser(props.user)
            await props.getEntries(props.date, props.user)
        }}>
            <div className="indicate" style={{ background: active ? "lime" : "red" }}></div>
            <div className='imageContainer'>
                {image ? <img src={image} alt="user image" /> : <Avatar><p>{firstname.charAt(0).toUpperCase()}</p></Avatar>}
            </div>
            <div className='nameContainer'>
                {firstname + " " + lastname}
            </div>
        </div>
    )
}

function SearchUser(props) {
    var {
        searchedValue, onchnageSearchValue
    } = props
    return (
        <div className="searchUserContainer">
            <input placeholder="Search..." id="searchbarInput" value={searchedValue} onChange={e => onchnageSearchValue(e)} /><FiSearch />
        </div>
    )


}
export default ListUsers