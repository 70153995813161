import React, { Component } from "react";
import jsPDF from "jspdf";
import "../style/preview.scss";
import "../style/calendarView.scss";
import ColumnChart from "./columnChart";
import ReportsTable from "./reportsTable";
import ReportCalendarView from "./reportCalendarView";
import PieChart, { achart } from "./pieChart";
import Loading from "./loading";
import { CSSTransition } from "react-transition-group";
import { AiOutlinePieChart, AiOutlineMenu } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineTable } from "react-icons/ai";
import { AiOutlineBarChart } from "react-icons/ai";
import { GrContract, GrDocumentConfig } from "react-icons/gr";
import { FaRegFilePdf } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import domtoimage from "dom-to-image";
import Switch from "react-switch";
import { withAlert } from "react-alert";
import NavPreview from "./navpreview";
import { AppContext } from "../appcontext";
import { Redirect } from "react-router-dom";
import "../style/navpreview.scss";
import PreviewCalendar from "./previewCal";
import { red } from "@material-ui/core/colors";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ApexCharts from "react-apexcharts";
import moment from "moment";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import { Slide } from "react-slideshow-image";
import ReportContent from "./Reports/components/reportContent";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
class Preview extends Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      CustomerReport: [],
      showResourceForm: false,
      ServiceReport: [],
      ActivityReport: [],
      UserReport: [],
      CustomerObject: [],
      EntriesReport: [],
      Loading: true,
      serviceview: "table",
      activityview: "table",
      resourceview: "table",
      showServiceMenu: false,
      showActivityMenu: false,
      showResourcesMenu: false,
      generateLoading: false,
      showEntries: true,
      isResoureReport: false,
      sortby: "service",
      showNav: true,
      showMaxScreen: true,
      showpreviewcalendar: false,
      amountofcalendars: 0,
      calmonthsEntries: [],
      redirect: false,
      showcustomers: true,
      showresources: true,
      showservices: true,
      showactivities: true,
      pdfResources: "table",
      pdfServices: "table",
      pdfActivity: "table",
    };
  }

  togglepdfResource = (str) => this.setState({ pdfResources: str });

  togglepdfService = (str) => this.setState({ pdfServices: str });

  togglepdfActivity = (str) => this.setState({ pdfActivity: str });

  onShowMaxScreen = () => {
    this.setState({ showMaxScreen: !this.state.showMaxScreen });
  };

  componentDidMount() {
    this.setState({
      Loading: true,
    });

    if (typeof this.props.reportActivityObject === "object") {
      this.setState({
        CustomerReport: this.props.reportCustomerObject,
        ServiceReport: this.props.reportServiceObject,
        ActivityReport: this.props.reportActivityObject,
        UserReport: this.props.reportUserObject,
        CustomerObject: this.props.reportCustomerFullObject,
        EntriesReport: this.props.reportEntriesObject,
        isResoureReport: this.props.showResourceReport,
      });
      var state = {
        CustomerReport: this.props.reportCustomerObject,
        ServiceReport: this.props.reportServiceObject,
        ActivityReport: this.props.reportActivityObject,
        UserReport: this.props.reportUserObject,
        CustomerObject: this.props.reportCustomerFullObject,
        EntriesReport: this.props.reportEntriesObject,
        isResoureReport: this.props.showResourceReport,
        startdate: this.context.dates.startdate,
        enddate: this.context.dates.enddate,
      };
      if (this.props.showResourceReport) {
        this.onViewChangeBar(1);
        this.onViewChangeBar(2);
      }

      let EntriesReport = state.EntriesReport;
      let calmonthsEntries = [];
      for (let a = 0; a < EntriesReport.length; a++) {
        let date = EntriesReport[a].date;
        const dateString = date.split("T");
        const actualdate = dateString[0].split("-");
        const currentMonth = actualdate[1];
        let b = 0;
        let monthFound = false;
        let quit = false;
        while (b < calmonthsEntries.length && !monthFound && !quit) {
          if (calmonthsEntries[b]) {
            if (calmonthsEntries[b].month === currentMonth) {
              monthFound = true;
            } else b++;
          } else {
            quit = true;
          }
        }
        if (monthFound) {
          calmonthsEntries[b].data.push(EntriesReport[a]);
        } else {
          calmonthsEntries.push({
            month: currentMonth,
            data: [EntriesReport[a]],
          });
        }
      }
      calmonthsEntries.sort((a, b) => (a.month < b.month ? -1 : 1));
      this.setState({
        calmonthsEntries: calmonthsEntries,
        amountofcalendars: calmonthsEntries.length,
      });
      let userreport = [];
      let servicereport = [];
      let activityreport = [];
      for (
        let reportl = 0;
        reportl < Math.ceil(this.props.reportUserObject.length / 10);
        reportl++
      ) {
        if (!userreport[reportl]) {
          if (
            this.props.reportUserObject.slice(reportl * 10, reportl * 10 + 10)
              .length > 0
          )
            userreport[reportl] = this.props.reportUserObject.slice(
              reportl * 10,
              reportl * 10 + 10
            );
        }
      }
      if (userreport[userreport.length - 1].length < 10) {
        let diffUsers = 10 - userreport[userreport.length - 1].length;
        for (let i = 0; i < diffUsers; i++)
          userreport[userreport.length - 1].push({
            billableHours: 0,
            nonBillableHours: 0,
            overtimeHours: 0,
            totalHours: 0,
            userId: "",
            userName: "",
          });
      }

      for (
        let reportl = 0;
        reportl < Math.ceil(this.props.reportServiceObject.length / 10);
        reportl++
      ) {
        if (!servicereport[reportl]) {
          if (
            this.props.reportServiceObject.slice(
              reportl * 10,
              reportl * 10 + 10
            ).length > 0
          )
            servicereport[reportl] = this.props.reportServiceObject.slice(
              reportl * 10,
              reportl * 10 + 10
            );
        }
      }

      if (servicereport[servicereport.length - 1].length < 10) {
        let diffUsers = 10 - servicereport[servicereport.length - 1].length;
        for (let i = 0; i < diffUsers; i++)
          servicereport[servicereport.length - 1].push({
            billableHours: 0,
            nonBillableHours: 0,
            overtimeHours: 0,
            serviceId: "",
            serviceName: "",
            totalHours: 0,
          });
      }
      for (
        let reportl = 0;
        reportl < Math.ceil(this.props.reportActivityObject.length / 10);
        reportl++
      ) {
        if (!activityreport[reportl]) {
          if (
            this.props.reportActivityObject.slice(
              reportl * 10,
              reportl * 10 + 10
            ).length > 0
          )
            activityreport[reportl] = this.props.reportActivityObject.slice(
              reportl * 10,
              reportl * 10 + 10
            );
        }
      }
      if (activityreport[activityreport.length - 1].length < 10) {
        let diffUsers = 10 - activityreport[activityreport.length - 1].length;
        for (let i = 0; i < diffUsers; i++)
          activityreport[activityreport.length - 1].push({
            activityId: "",
            activityName: "",
            billableHours: 0,
            nonBillableHours: 0,
            overtimeHours: 0,
            totalHours: 0,
          });
      }

      this.setState({
        UserReportColumn: userreport,
        ServiceReportColumn: servicereport,
        ActivityReportColumn: activityreport,
        Loading: false,
        showNav: false,
      });
      localStorage.setItem("report", JSON.stringify(state));
    }
    // else
    //   this.setState({
    //     Loading: false,
    //     redirect: true
    //   })
    else {
      var LS = localStorage.getItem("report");
      LS = JSON.parse(LS);
      this.setState({
        CustomerReport: LS.CustomerReport,
        ServiceReport: LS.ServiceReport,
        ActivityReport: LS.ActivityReport,
        UserReport: LS.UserReport,
        CustomerObject: LS.CustomerObject,
        EntriesReport: LS.EntriesReport,
        isResoureReport: LS.isResoureReport,
      });
      var state = {
        CustomerReport: LS.CustomerReport,
        ServiceReport: LS.ServiceReport,
        ActivityReport: LS.ActivityReport,
        UserReport: LS.UserReport,
        CustomerObject: LS.CustomerObject,
        EntriesReport: LS.EntriesReport,
        isResoureReport: LS.isResoureReport,
        startdate: this.context.dates.startdate,
        enddate: this.context.dates.enddate,
      };
      if (this.props.showResourceReport) {
        this.onViewChangeBar(1);
        this.onViewChangeBar(2);
      }

      let EntriesReport = state.EntriesReport;
      let calmonthsEntries = [];
      for (let a = 0; a < EntriesReport.length; a++) {
        let date = EntriesReport[a].date;
        const dateString = date.split("T");
        const actualdate = dateString[0].split("-");
        const currentMonth = actualdate[1];
        let b = 0;
        let monthFound = false;
        let quit = false;
        while (b < calmonthsEntries.length && !monthFound && !quit) {
          if (calmonthsEntries[b]) {
            if (calmonthsEntries[b].month === currentMonth) {
              monthFound = true;
            } else b++;
          } else {
            quit = true;
          }
        }
        if (monthFound) {
          calmonthsEntries[b].data.push(EntriesReport[a]);
        } else {
          calmonthsEntries.push({
            month: currentMonth,
            data: [EntriesReport[a]],
          });
        }
      }
      calmonthsEntries.sort((a, b) => (a.month < b.month ? -1 : 1));
      this.setState({
        calmonthsEntries: calmonthsEntries,
        amountofcalendars: calmonthsEntries.length,
      });
      let limit = 20;
      // let groupmultiplier = 0;
      // for (let a = 2; a <= limit; a++) {
      //   if (LS.UserReport.length % a === 0)
      //     groupmultiplier = a
      // }
      // if (groupmultiplier === 0)
      let groupmultiplier = 10;

      let userreport = [];
      let servicereport = [];
      let activityreport = [];
      for (
        let reportl = 0;
        reportl < Math.ceil(LS.UserReport.length / groupmultiplier);
        reportl++
      ) {
        if (!userreport[reportl]) {
          if (
            LS.UserReport.slice(
              reportl * groupmultiplier,
              reportl * groupmultiplier + groupmultiplier
            ).length > 0
          )
            userreport[reportl] = LS.UserReport.slice(
              reportl * groupmultiplier,
              reportl * groupmultiplier + groupmultiplier
            );
        }
      }
      if (userreport[userreport.length - 1].length < groupmultiplier) {
        let diffUsers =
          groupmultiplier - userreport[userreport.length - 1].length;
        for (let i = 0; i < diffUsers; i++)
          userreport[userreport.length - 1].push({
            billableHours: 0,
            nonBillableHours: 0,
            overtimeHours: 0,
            totalHours: 0,
            userId: "",
            userName: "",
          });
      }

      // groupmultiplier = 0;
      // for (let a = 2; a <= limit; a++) {
      //   if (LS.ServiceReport.length % a === 0)
      //     groupmultiplier = a
      // }
      // if (groupmultiplier === 0)
      groupmultiplier = 10;
      for (
        let reportl = 0;
        reportl < Math.ceil(LS.ServiceReport.length / groupmultiplier);
        reportl++
      ) {
        if (!servicereport[reportl]) {
          if (
            LS.ServiceReport.slice(
              reportl * groupmultiplier,
              reportl * groupmultiplier + groupmultiplier
            ).length > 0
          )
            servicereport[reportl] = LS.ServiceReport.slice(
              reportl * groupmultiplier,
              reportl * groupmultiplier + groupmultiplier
            );
        }
      }
      if (servicereport[servicereport.length - 1].length < groupmultiplier) {
        let diffUsers =
          groupmultiplier - servicereport[servicereport.length - 1].length;
        for (let i = 0; i < diffUsers; i++)
          servicereport[servicereport.length - 1].push({
            billableHours: 0,
            nonBillableHours: 0,
            overtimeHours: 0,
            serviceId: "",
            serviceName: "",
            totalHours: 0,
          });
      }
      // groupmultiplier = 0;
      // for (let a = 2; a <= limit; a++) {
      //   if (LS.ActivityReport.length % a === 0)
      //     groupmultiplier = a
      // }
      // if (groupmultiplier === 0)
      groupmultiplier = 10;
      for (
        let reportl = 0;
        reportl < Math.ceil(LS.ActivityReport.length / groupmultiplier);
        reportl++
      ) {
        if (!activityreport[reportl]) {
          if (
            LS.ActivityReport.slice(
              reportl * groupmultiplier,
              reportl * groupmultiplier + groupmultiplier
            ).length > 0
          )
            activityreport[reportl] = LS.ActivityReport.slice(
              reportl * groupmultiplier,
              reportl * groupmultiplier + groupmultiplier
            );
        }
      }
      if (activityreport[activityreport.length - 1].length < groupmultiplier) {
        let diffUsers =
          groupmultiplier - activityreport[activityreport.length - 1].length;
        for (let i = 0; i < diffUsers; i++)
          activityreport[activityreport.length - 1].push({
            activityId: "",
            activityName: "",
            billableHours: 0,
            nonBillableHours: 0,
            overtimeHours: 0,
            totalHours: 0,
          });
      }

      this.setState({
        UserReportColumn: userreport,
        ServiceReportColumn: servicereport,
        ActivityReportColumn: activityreport,
        Loading: false,
        showNav: false,
      });
      this.setState({
        Loading: false,
        // redirect: true
      });
    }
  }

  onShowEntriesClick = () =>
    this.setState({ showEntries: !this.state.showEntries });

  calcDivHeight = (divHeight) => {
    let x = divHeight * 0.35;
    return x;
  };

  onbeforeGenPdf = () => {
    window.scrollTo(0, 60);

    this.setState({
      generateLoading: true,
      showNav: true,
    });

    setTimeout(() => {
      if (this.state.showpreviewcalendar) this.generateCalPdf();
      else {
        this.onGeneratePdf();
      }
    }, 10000);
  };

  generateCalPdf = async () => {
    this.setState({
      generateLoading: true,
    });
    // this.state.showNav = true
    document.body.style.overflow = "hidden";
    var pdf = new jsPDF("p", "px", "a4");

    //Getting divs from the document
    const previewHeader = document.getElementById("PreviewHeader");
    const pdfNav = document.getElementById("navpreviewContainer");

    var calendardivs = [];
    for (let z = 0; z < this.state.amountofcalendars; z++) {
      calendardivs.push(
        document.getElementById("PreviewCalendarContainer" + z)
      );
    }

    //Creating Canvas for pdf
    var previewnav = await domtoimage.toPng(pdfNav).then(function (dataUrl) {
      var img = new Image();
      img.src = dataUrl;
      img.width = pdf.internal.pageSize.getWidth();
      img.height = 40;
      return img;
    });
    var header = await domtoimage.toPng(previewHeader).then(function (dataUrl) {
      var img = new Image();
      img.src = dataUrl;
      // img.height = dataUrl.height;
      // img.width = dataUrl.width;
      return img;
    });

    var calendardimgs = [];
    for (let k = 0; k < calendardivs.length; k++) {
      var newimage = await domtoimage
        .toPng(calendardivs[k])
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          return img;
        });
      calendardimgs.push(newimage);
    }

    //Adding canvas to pdf
    if (this.state.CustomerReport.length > 1) {
      var dataHeight = 0;
      pdf.addImage(
        previewnav,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        this.calcDivHeight(previewnav.height)
      );

      dataHeight += this.calcDivHeight(previewnav.height);

      pdf.addImage(
        header,
        "JPEG",
        10,
        dataHeight + 10,
        pdf.internal.pageSize.getWidth() - 20,
        this.calcDivHeight(header.height)
      );

      //Calendars
      calendardimgs.map((img, i) => {
        dataHeight = 0;
        pdf.addPage();
        pdf.addImage(
          previewnav,
          "JPEG",
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          this.calcDivHeight(previewnav.height)
        );

        dataHeight += this.calcDivHeight(previewnav.height);

        pdf.addImage(
          img,
          "JPEG",
          10,
          dataHeight + 10,
          pdf.internal.pageSize.getWidth() - 20,
          this.calcDivHeight(img.height)
        );
      });
    } else {
      var dataHeight = 0;
      pdf.addImage(
        previewnav,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        this.calcDivHeight(previewnav.height)
      );

      dataHeight += this.calcDivHeight(previewnav.height);

      pdf.addImage(
        header,
        "JPEG",
        10,
        dataHeight,
        pdf.internal.pageSize.getWidth() - 20,
        this.calcDivHeight(header.height)
      );

      dataHeight += this.calcDivHeight(header.height);

      pdf.addImage(
        calendardimgs[0],
        "JPEG",
        10,
        dataHeight + 10,
        pdf.internal.pageSize.getWidth() - 20,
        this.calcDivHeight(calendardimgs[0].height)
      );

      //Calendars
      calendardimgs.map((img, i) => {
        if (i > 0) {
          dataHeight = 0;
          pdf.addImage(
            previewnav,
            "JPEG",
            10,
            0,
            pdf.internal.pageSize.getWidth(),
            this.calcDivHeight(previewnav.height)
          );

          dataHeight += this.calcDivHeight(previewnav.height);

          pdf.addImage(
            img,
            "JPEG",
            10,
            dataHeight + 10,
            pdf.internal.pageSize.getWidth() - 20,
            this.calcDivHeight(img.height)
          );
          if (i < calendardimgs.length - 1) {
            pdf.addPage();
          }
        }
      });
    }

    // dataHeight += this.calcDivHeight(canvasArray[0].height)
    // followDataHeight = dataHeight + this.calcDivHeight(canvasArray[1] ? canvasArray[1].height : 0)

    //Downloading pdf
    pdf.save("Report.pdf");
    document.body.style.overflow = "visible";
    this.setState({
      showNav: false,
      generateLoading: false,
    });
    this.notification("Successfully exported pdf");
  };
  notification(message) {
    const alert = this.props.alert;
    alert.success(message);
  }

  onViewChangeTable = (dataNo) => {
    if (dataNo === 1)
      this.setState({
        serviceview: "table",
        showServiceMenu: false,
      });
    else if (dataNo === 2)
      this.setState({
        activityview: "table",
        showActivityMenu: false,
      });
    else if (dataNo === 3)
      this.setState({
        resourceview: "table",
        showResourcesMenu: false,
      });
  };

  onGeneratePdf = async () => {
    this.setState({
      showresources: true,
      showcustomers: true,
      showactivities: true,
      showservices: true,
    });
    this.setState({
      generateLoading: true,
    });
    var observer = new MutationObserver(function (mutations) {
      if (
        document.contains(
          document
            .getElementById("ResourcesChart0")
            .getElementsByTagName("div")[0]
        ).offsetHeight > 0
      ) {
        setTimeout(() => {
          observer.disconnect();
        }, 100);
      }
    });
    var customercontent;
    var customertableValues = [
      [
        { text: "", style: "tableHeader" },
        { text: "Customer", style: "tableHeader" },
        { text: "Total", style: "tableHeader" },
        { text: "Billable", style: "tableHeader" },
        { text: "Non Billable", style: "tableHeader" },
        { text: "Overtime", style: "tableHeader" },
      ],
    ];
    for (var i = 0; i < this.state.CustomerReport.length; i++) {
      var values = Object.values(this.state.CustomerReport[i]);
      values.shift();
      values.unshift({
        image: this.state.CustomerObject[i].logo,
        width: 30,
        height: 30,
        margin: [0, -10, 0, 10],
      });
      customertableValues.push(values);
    }
    customercontent = {
      style: "customers",
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*", "*", "*"],
        body: customertableValues,
      },
      layout: "noBorders",
    };

    if (this.state.pdfResources.length > 0) {
      this.setState({ showresources: true });
      var resourcecontentTable;
      var resourcecontentChart = [];
      var resourcecontentPie;
      var totals = ["Total", 0, 0, 0, 0];

      // RESOURCES REPORT TABLE

      if (this.state.pdfResources.includes("table")) {
        var resourceTableValues = [
          [
            { text: "Resource", style: "tableHeader" },
            { text: "Billable", style: "tableHeader" },
            { text: "Non-Billable", style: "tableHeader" },
            { text: "Overtime", style: "tableHeader" },
            { text: "Total", style: "tableHeader" },
          ],
        ];
        for (var i = 0; i < this.state.UserReport.length; i++) {
          var values = Object.values(this.state.UserReport[i]);
          values.shift();
          totals[1] += values[1];
          totals[2] += values[2];
          totals[3] += values[3];
          totals[4] += values[4];
          resourceTableValues.push(values);
        }
        resourceTableValues.push(totals);
        resourcecontentTable = {
          style: "reportTable",
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*"],
            body: resourceTableValues,
          },
        };
        totals = ["Total", 0, 0, 0, 0];
      }
      if (this.state.pdfResources.includes("column")) {
        // RESOURCES REPORT Chart
        for (
          let a = 0;
          a <
          Math.floor(this.state.UserReport.length / 10) +
            (this.state.UserReport.length % 10 > 0 ? 1 : 0);
          a++
        ) {
          let resourcediv = document
            .getElementById("ResourcesChart" + a)
            .getElementsByTagName("div")[0];
          var Resource = await domtoimage
            .toPng(resourcediv, {
              width: 2650,
              height: resourcediv.offsetHeight * 2,
              // height: 402.96,
              style: {
                transform: "scale(2)",
                // 'width': '10',
                // 'height': '10',
                "transform-origin": "top left",
              },
            })
            .then(function (dataUrl) {
              return dataUrl;
            })
            .catch(function (error) {});

          resourcecontentChart.push({
            image: Resource,
            width: 480,
            height: 280,
          });
        }
      }

      if (this.state.pdfResources.includes("pie")) {
        // RESOURCES REPORT Chart
        let resourcediv = document
          .getElementById("ResourcesPie")
          .getElementsByTagName("div")[0];
        var Resource = await domtoimage
          .toPng(resourcediv, {
            width: 700,
            height: 402.96,
            style: {
              // 'transform': 'scale(2)',
              // 'width': '10',
              // 'height': '10',
              "transform-origin": "top left",
            },
          })
          .then(function (dataUrl) {
            return dataUrl;
          })
          .catch(function (error) {});

        resourcecontentPie = {
          image: Resource,
          width: 480,
          height: 280,
        };
      }
    }

    // SERVICES REPORT CONTENT
    if (this.state.pdfServices.length > 0) {
      this.setState({ showservices: true });
      var servicecontentTable;
      var servicecontentChart = [];
      var servicecontentPie;
      // Services REPORT Table
      if (this.state.pdfServices.includes("table")) {
        var serviceTableValues = [
          [
            { text: "Service", style: "tableHeader" },
            { text: "Billable", style: "tableHeader" },
            { text: "Non-Billable", style: "tableHeader" },
            { text: "Overtime", style: "tableHeader" },
            { text: "Total", style: "tableHeader" },
          ],
        ];
        for (var j = 0; j < this.state.ServiceReport.length; j++) {
          var values = Object.values(this.state.ServiceReport[j]);
          values.shift();
          totals[1] += values[1];
          totals[2] += values[2];
          totals[3] += values[3];
          totals[4] += values[4];
          serviceTableValues.push(values);
        }
        serviceTableValues.push(totals);
        servicecontentTable = {
          style: "reportTable",
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*"],
            body: serviceTableValues,
          },
        };
        totals = ["Total", 0, 0, 0, 0];
      }
      // Services REPORT Column
      if (this.state.pdfServices.includes("column")) {
        for (
          let a = 0;
          a <
          Math.floor(this.state.ServiceReport.length / 10) +
            (this.state.ServiceReport.length % 10 > 0 ? 1 : 0);
          a++
        ) {
          let servicediv = document
            .getElementById("ServicesChart")
            .getElementsByTagName("div")[0];
          var Service = await domtoimage
            .toPng(servicediv, {
              width: 2650,
              height: servicediv.offsetHeight * 2,
              style: {
                transform: "scale(2)",
                // 'width': '10',
                // 'height': '10',
                "transform-origin": "top left",
              },
            })
            .then(function (dataUrl) {
              return dataUrl;
            })
            .catch(function (error) {});

          servicecontentChart = {
            image: Service,
            width: 480,
            height: 280,
          };
        }
      }
      // Services REPORT Pie
      if (this.state.pdfServices.includes("pie")) {
        let servicediv = document
          .getElementById("ServicesPie")
          .getElementsByTagName("div")[0];
        var Service = await domtoimage
          .toPng(servicediv, {
            width: 700,
            height: 402.96,
            style: {
              // 'transform': 'scale(2)',
              // 'width': '10',
              // 'height': '10',
              "transform-origin": "top left",
            },
          })
          .then(function (dataUrl) {
            return dataUrl;
          })
          .catch(function (error) {});

        servicecontentPie = {
          image: Service,
          width: 480,
          height: 280,
        };
      }
    }

    // ACTIVITY REPORT CONTENT
    if (this.state.pdfActivity.length > 0) {
      this.setState({ showactivities: true });
      var activitycontentTable;
      var activitycontentChart = [];
      var activitycontentPie;
      // ACTIVITY REPORT table
      if (this.state.pdfActivity.includes("table")) {
        var activityTableValues = [
          [
            { text: "Activity", style: "tableHeader" },
            { text: "Billable", style: "tableHeader" },
            { text: "Non-Billable", style: "tableHeader" },
            { text: "Overtime", style: "tableHeader" },
            { text: "Total", style: "tableHeader" },
          ],
        ];
        for (var k = 0; k < this.state.ActivityReport.length; k++) {
          var values = Object.values(this.state.ActivityReport[k]);
          values.shift();
          totals[1] += values[1];
          totals[2] += values[2];
          totals[3] += values[3];
          totals[4] += values[4];
          activityTableValues.push(values);
        }
        activityTableValues.push(totals);
        activitycontentTable = {
          style: "reportTable",
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*"],
            body: activityTableValues,
          },
        };
      }
      // Activity REPORT Chart
      if (this.state.pdfActivity.includes("column")) {
        for (
          let a = 0;
          a <
          Math.floor(this.state.ActivityReport.length / 10) +
            (this.state.ActivityReport.length % 10 > 0 ? 1 : 0);
          a++
        ) {
          let activitydiv = document
            .getElementById("ActivitiesChart")
            .getElementsByTagName("div")[0];
          var Activities = await domtoimage
            .toPng(activitydiv, {
              width: 2650,
              height: activitydiv.offsetHeight * 2,
              style: {
                transform: "scale(2)",
                // 'width': '10',
                // 'height': '10',
                "transform-origin": "top left",
              },
            })
            .then(function (dataUrl) {
              return dataUrl;
            })
            .catch(function (error) {});

          activitycontentChart = {
            image: Activities,
            width: 480,
            height: 280,
          };
        }
      }
      if (this.state.pdfActivity.includes("pie")) {
        let activitydiv = document
          .getElementById("ActivitiesPie")
          .getElementsByTagName("div")[0];
        var Activities = await domtoimage
          .toPng(activitydiv, {
            width: 700,
            height: 402.96,
            style: {
              // 'transform': 'scale(2)',
              // 'width': '10',
              // 'height': '10',
              "transform-origin": "top left",
            },
          })
          .then(function (dataUrl) {
            return dataUrl;
          })
          .catch(function (error) {});

        activitycontentPie = {
          image: Activities,
          width: 480,
          height: 280,
        };
      }
    }
    // ENTRIES REPORT CONTENT
    if (this.state.showEntries) {
      var entriescontent;
      var entryTableValues = [
        [
          { text: "Date", style: "tableHeader" },
          { text: "Time", style: "tableHeader" },
          { text: "Billing", style: "tableHeader" },
          { text: "Customer", style: "tableHeader" },
          { text: "Service", style: "tableHeader" },
          { text: "Activity", style: "tableHeader" },
          { text: "Resource", style: "tableHeader" },
          { text: "Description", style: "tableHeader" },
        ],
      ];
      for (var k = 0; k < this.state.EntriesReport.length; k++) {
        var valuesTemp = Object.values(this.state.EntriesReport[k]);
        var values = [];
        // values.shift();
        values[0] = moment(valuesTemp[11].split("T")[0], "YYYY-MM-DD").format(
          "DD MMM YYYY"
        );
        values[1] =
          valuesTemp[1].timestarted + "-" + valuesTemp[1].timefinished;
        values[2] =
          valuesTemp[7] === true
            ? "Billable"
            : valuesTemp[8] === true
            ? "Overtime"
            : "Non-Billable";
        values[3] = valuesTemp[2].customerName;
        values[4] = valuesTemp[3].serviceName;
        values[5] = valuesTemp[4].activityName;
        values[6] = valuesTemp[0].name;
        values[7] = valuesTemp[6].normalize();

        entryTableValues.push(values);
      }
      entryTableValues.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a[0]) - new Date(b[0]);
      });

      entriescontent = {
        style: "reportTable",
        table: {
          headerRows: 1,
          widths: [65, 65, 60, 60, 60, 60, 80, 240],
          body: entryTableValues,
        },
      };
    }

    var content = [
      {
        text:
          moment(this.context.dates.startdate, "YYYY-MM-DD").format(
            "DD MMMM YYYY"
          ) +
          " - " +
          moment(this.context.dates.enddate, "YYYY-MM-DD").format(
            "DD MMMM YYYY"
          ),
        style: "header",
      },
      customercontent,
    ];

    if (this.state.pdfResources.length > 0) {
      content.push({ text: "Resources", style: "header" });
      if (this.state.pdfResources.includes("table"))
        content.push(resourcecontentTable);
      if (this.state.pdfResources.includes("column"))
        content.push(resourcecontentChart);
      if (this.state.pdfResources.includes("pie"))
        content.push(resourcecontentPie);
    }
    if (this.state.pdfServices.length > 0) {
      content.push({ text: "Services", style: "header" });
      if (this.state.pdfServices.includes("table"))
        content.push(servicecontentTable);
      if (this.state.pdfServices.includes("column"))
        content.push(servicecontentChart);
      if (this.state.pdfServices.includes("pie"))
        content.push(servicecontentPie);
    }
    if (this.state.pdfActivity.length > 0) {
      content.push({ text: "Activities", style: "header" });
      if (this.state.pdfActivity.includes("table"))
        content.push(activitycontentTable);
      if (this.state.pdfActivity.includes("column"))
        content.push(activitycontentChart);
      if (this.state.pdfActivity.includes("pie"))
        content.push(activitycontentPie);
    }
    if (this.state.showEntries) {
      content.push({
        text: "Condor360 Entries",
        style: "header",
        pageOrientation: "landscape",
        pageBreak: "before",
      });
      content.push(entriescontent);
    }

    var docDefinition = {
      info: {
        title: "Condor360 Report",
        author: "Condor360",
        subject: "Condor360 Report",
        keywords: "",
      },
      content: content,

      styles: {
        header: {
          fontSize: 14,
          bold: true,
          alignment: "center",
          margin: [0, 20, 0, 0],
        },
        subheader: {
          fontSize: 15,
          bold: true,
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 8,
        },
        reportTable: {
          fontSize: 8,
          alignment: "center",
          margin: [0, 0, 0, 10],
          width: 800,
        },
        customers: {
          fontSize: 8,
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
          margin: [0, 0, 0, 10],
        },
      },
    };
    pdfMake.createPdf(docDefinition).open({}, window.frames["printpdf"]);
    // this.setState({
    //   showresources: false,
    //   showservices: false,
    //   showactivities: false,
    //   showentries: false,
    //   showcustomers: false
    // })
    document.body.style.overflow = "visible";
    this.setState({
      showNav: false,
      generateLoading: false,
    });
    // this.notification("Successfully exported pdf")
  };
  // notification(message) {
  //   const alert = this.props.alert
  //   alert.success(message)
  // }

  onViewChangeTable = (dataNo) => {
    if (dataNo === 1)
      this.setState({
        serviceview: "table",
        showServiceMenu: false,
      });
    else if (dataNo === 2)
      this.setState({
        activityview: "table",
        showActivityMenu: false,
      });
    else if (dataNo === 3)
      this.setState({
        resourceview: "table",
        showResourcesMenu: false,
      });
  };
  onViewChangePie = (dataNo) => {
    if (dataNo === 1) {
      if (this.state.isResoureReport) {
        if (this.state.activityview === "pie")
          this.setState({
            activityview: "column",
          });
      }
      this.setState({
        serviceview: "pie",
        showServiceMenu: false,
      });
    } else if (dataNo === 2) {
      if (this.state.isResoureReport) {
        if (this.state.serviceview === "pie")
          this.setState({
            serviceview: "column",
          });
      }
      this.setState({
        activityview: "pie",
        showActivityMenu: false,
      });
    } else if (dataNo === 3) {
      this.setState({
        resourceview: "pie",
        showResourcesMenu: false,
      });
    }
  };
  onViewChangeBar = (dataNo) => {
    if (dataNo === 1)
      this.setState({
        serviceview: "column",
        showServiceMenu: false,
      });
    else if (dataNo === 2)
      this.setState({
        activityview: "column",
        showActivityMenu: false,
      });
    else if (dataNo === 3)
      this.setState({
        resourceview: "column",
        showResourcesMenu: false,
      });
  };
  onShowResource = () => {
    this.setState({ isResoureReport: !this.state.isResoureReport });
  };

  ChooseSort = (val) => {
    this.setState({
      sortby: val,
    });
  };

  setRedirect = () => {
    if (this.state.Heading === "Report Preview") {
      this.toggleShowDetails();
    } else {
      this.setState({
        redirect: true,
      });
    }
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={`/${this.context.user.firstname}/reports`} />;
    }
  };

  arrayHeaderForCustomers = () => {
    let a = 0;
    return this.state.CustomerReport.map((customer) => {
      var { logo } = this.state.CustomerObject[a];
      a++;
      return (
        <div className="childrenHeaders">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="PreviewCustomer">
            <div className="PreviewCustomerName">{customer.customerName}</div>
            <div className="PreviewCustomerHours">
              <label>
                Total : {customer.totalHours}{" "}
                {customer.totalHours > 1 ? " hours" : " hour"}
              </label>
              <label>
                Billable : {customer.billableHours}{" "}
                {customer.billableHours > 1 ? " hours" : " hour"}
              </label>
              <label>
                Non billable : {customer.nonBillableHours}{" "}
                {customer.nonBillableHours > 1 ? " hours" : " hour"}
              </label>
              <label>
                Overtime : {customer.overtimeHours}{" "}
                {customer.overtimeHours > 1 ? " hours" : " hour"}
              </label>
            </div>
          </div>
        </div>
      );
    });
  };

  onShowPreviewCalendar = () => {
    this.setState({
      showpreviewcalendar: !this.state.showpreviewcalendar,
    });
  };

  ontoggleresmenu = () =>
    this.setState({ showresourcesmenu: !this.state.showresourcesmenu });
  ontogglesermenu = () =>
    this.setState({ showservicessmenu: !this.state.showservicessmenu });
  ontoggleactmenu = () =>
    this.setState({ showactivitiesmenu: !this.state.showactivitiesmenu });
  render() {
    var {
      CustomerReport,
      ServiceReport,
      ActivityReport,
      UserReport,
      CustomerObject,
      EntriesReport,
      UserReportColumn,
      ServiceReportColumn,
      ActivityReportColumn,
    } = this.state;
    var dates = this.context.dates;
    if (this.state.Loading) {
      return <Loading />;
    } else if (this.state.redirect)
      return (
        <Redirect
          to={{
            pathname: `/${this.context.user.firstname}/reports/`,
          }}
        />
      );
    else {
      return (
        <>
          <div id="PreviewContainer">
            <CSSTransition
              in={this.state.generateLoading}
              // in={true}
              appear={true}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <Loading />
            </CSSTransition>

            <div className="exportMenuButtons" data-html2canvas-ignore="true">
              <button
                className="addButton"
                title={
                  this.state.showpreviewcalendar
                    ? "Detailed Report"
                    : "Calendar Report"
                }
                type="button"
                onClick={this.onShowPreviewCalendar}
              >
                {this.state.showpreviewcalendar ? (
                  <GrDocumentConfig />
                ) : (
                  <FaRegCalendarAlt />
                )}
              </button>
              <button type="button" onClick={() => this.onbeforeGenPdf()}>
                <FaRegFilePdf title="Export to PDF" />
              </button>
            </div>
            {/* {this.renderRedirect()} */}
            <div id="PreviewHeading" data-html2canvas-ignore="true">
              <h1>
                <IoIosArrowBack
                  onClick={this.setRedirect}
                  style={{
                    height: "50",
                    marginBottom: "-15",
                    cursor: "pointer",
                  }}
                  title="Back"
                />
                Report Preview
              </h1>
              <hr />
            </div>
            <section>
              <div className="PreviewContent">
                <div className="PreviewDates">
                  {moment(this.context.dates.startdate, "YYYY-MM-DD").format(
                    "DD MMMM YYYY"
                  )}{" "}
                  -{" "}
                  {moment(this.context.dates.enddate, "YYYY-MM-DD").format(
                    "DD MMMM YYYY"
                  )}
                </div>
                <div className="PreviewContentHeading">
                  <div className="headname">
                    <h1 id="headingforsubreport">
                      <button>
                        <AiOutlineCheckCircle style={{ color: "lime" }} />
                      </button>
                      <p>Customers ({this.state.CustomerReport.length})</p>
                      <div
                        className="headingButton"
                        onClick={() =>
                          this.setState({
                            showcustomers: !this.state.showcustomers,
                          })
                        }
                      >
                        {this.state.showcustomers ? (
                          <FiChevronDown />
                        ) : (
                          <FiChevronLeft />
                        )}
                      </div>
                    </h1>
                  </div>
                  <div className="viewMenuContainer">
                    <div
                      className="Previewburgermenu"
                      data-html2canvas-ignore="true"
                    >
                      <div className="PreviewburgerIcon">
                        <button
                          style={{ backgroundColor: "transparent" }}
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <CSSTransition
                  in={this.state.showcustomers ? true : false}
                  // in={true}
                  appear={true}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <this.arrayHeaderForCustomers />
                </CSSTransition>

                {!this.state.showpreviewcalendar ? (
                  <>
                    {/* Resources */}
                    <div className="PreviewContentHeading">
                      <div className="headname">
                        <h1 id="headingforsubreport">
                          <button
                            onClick={() => {
                              if (this.state.pdfResources.length > 0) {
                                this.setState({ pdfResources: "" });
                                this.setState({ showresources: false });
                              } else {
                                this.setState({ showresources: true });
                                this.togglepdfResource("table");
                              }
                            }}
                          >
                            <AiOutlineCheckCircle
                              style={{
                                color:
                                  this.state.pdfResources.length > 0
                                    ? "lime"
                                    : "#aaa",
                              }}
                            />
                          </button>
                          <p>Resources ({this.state.UserReport.length})</p>
                          <div
                            className="headingButton"
                            onClick={() =>
                              this.setState({
                                showresources: !this.state.showresources,
                              })
                            }
                          >
                            {this.state.showresources ? (
                              <FiChevronDown />
                            ) : (
                              <FiChevronLeft />
                            )}
                          </div>
                        </h1>
                      </div>
                      <div className="viewMenuContainer">
                        <div
                          className="Previewburgermenu"
                          data-html2canvas-ignore="true"
                        >
                          <div className="PreviewburgerIcon">
                            {this.state.showresources ? (
                              <button onClick={() => this.ontoggleresmenu()}>
                                <AiOutlineMenu title="Change view" />
                              </button>
                            ) : null}
                          </div>
                        </div>
                        <CSSTransition
                          in={this.state.showresourcesmenu}
                          // in={true}
                          appear={true}
                          timeout={50}
                          classNames="fade"
                          unmountOnExit
                        >
                          <div className="menutoggles">
                            <div className="viewMenu">
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfResource("table")}
                              >
                                <AiOutlineTable title="Table" />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfResource("column")}
                              >
                                <AiOutlineBarChart title="Bar graph" />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfResource("pie")}
                              >
                                <AiOutlinePieChart title="Pie chart" />
                              </button>
                            </div>
                            <div className="enableMenu">
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (this.state.pdfResources.includes("table"))
                                    this.setState({
                                      pdfResources:
                                        this.state.pdfResources.replace(
                                          "table"
                                        ),
                                    });
                                  else
                                    this.setState({
                                      pdfResources:
                                        this.state.pdfResources + "table",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfResources.includes(
                                      "table"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (
                                    this.state.pdfResources.includes("column")
                                  )
                                    this.setState({
                                      pdfResources:
                                        this.state.pdfResources.replace(
                                          "column"
                                        ),
                                    });
                                  else
                                    this.setState({
                                      pdfResources:
                                        this.state.pdfResources + "column",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfResources.includes(
                                      "column"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (this.state.pdfResources.includes("pie"))
                                    this.setState({
                                      pdfResources:
                                        this.state.pdfResources.replace("pie"),
                                    });
                                  else
                                    this.setState({
                                      pdfResources:
                                        this.state.pdfResources + "pie",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfResources.includes(
                                      "pie"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                        </CSSTransition>
                      </div>
                    </div>
                    <CSSTransition
                      in={this.state.showresources ? true : false}
                      // in={true}
                      appear={true}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      <ReportContent
                        UserReportColumn={UserReportColumn}
                        data={UserReport}
                        showpreviewcalendar={this.state.showpreviewcalendar}
                        view={this.state.resourceview}
                        onViewChangeBar={() => this.onViewChangeBar(3)}
                        onViewChangeTable={() => this.onViewChangeTable(3)}
                        onViewChangePie={() => this.onViewChangePie(3)}
                        enabledViews={this.state.pdfResources}
                        togglepdf={this.togglepdfResource}
                        heading="Resources"
                        id2="UserReport"
                        whichdata="Resources"
                        piecharttype="PieChartUser"
                      />
                    </CSSTransition>

                    {/* Services */}
                    <div className="PreviewContentHeading">
                      <div className="headname">
                        <h1 id="headingforsubreport">
                          <button
                            onClick={() => {
                              if (this.state.pdfServices.length > 0) {
                                this.setState({ pdfServices: "" });
                                this.setState({ showservices: false });
                              } else {
                                this.setState({ showservices: true });
                                this.togglepdfService("table");
                              }
                            }}
                          >
                            <AiOutlineCheckCircle
                              style={{
                                color:
                                  this.state.pdfServices.length > 0
                                    ? "lime"
                                    : "#aaa",
                              }}
                            />
                          </button>
                          <p>Services ({this.state.ServiceReport.length})</p>
                          <div
                            className="headingButton"
                            onClick={() =>
                              this.setState({
                                showservices: !this.state.showservices,
                              })
                            }
                          >
                            {this.state.showservices ? (
                              <FiChevronDown />
                            ) : (
                              <FiChevronLeft />
                            )}
                          </div>
                        </h1>
                      </div>
                      <div className="viewMenuContainer">
                        <div
                          className="Previewburgermenu"
                          data-html2canvas-ignore="true"
                        >
                          <div className="PreviewburgerIcon">
                            {this.state.showservices ? (
                              <button onClick={() => this.ontogglesermenu()}>
                                <AiOutlineMenu title="Change view" />
                              </button>
                            ) : null}
                          </div>
                        </div>
                        <CSSTransition
                          in={this.state.showservicessmenu}
                          // in={true}
                          appear={true}
                          timeout={50}
                          classNames="fade"
                          unmountOnExit
                        >
                          <div className="menutoggles">
                            <div className="viewMenu">
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfService("table")}
                              >
                                <AiOutlineTable title="Table" />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfService("column")}
                              >
                                <AiOutlineBarChart title="Bar graph" />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfService("pie")}
                              >
                                <AiOutlinePieChart title="Pie chart" />
                              </button>
                            </div>
                            <div className="enableMenu">
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (this.state.pdfServices.includes("table"))
                                    this.setState({
                                      pdfServices:
                                        this.state.pdfServices.replace("table"),
                                    });
                                  else
                                    this.setState({
                                      pdfServices:
                                        this.state.pdfServices + "table",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfServices.includes(
                                      "table"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (this.state.pdfServices.includes("column"))
                                    this.setState({
                                      pdfServices:
                                        this.state.pdfServices.replace(
                                          "column"
                                        ),
                                    });
                                  else
                                    this.setState({
                                      pdfServices:
                                        this.state.pdfServices + "column",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfServices.includes(
                                      "column"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (this.state.pdfServices.includes("pie"))
                                    this.setState({
                                      pdfServices:
                                        this.state.pdfServices.replace("pie"),
                                    });
                                  else
                                    this.setState({
                                      pdfServices:
                                        this.state.pdfServices + "pie",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfServices.includes(
                                      "pie"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                        </CSSTransition>
                      </div>
                    </div>
                    <CSSTransition
                      in={this.state.showservices ? true : false}
                      // in={true}
                      appear={true}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      <ReportContent
                        UserReportColumn={ServiceReportColumn}
                        data={ServiceReport}
                        showpreviewcalendar={this.state.showpreviewcalendar}
                        view={this.state.serviceview}
                        onViewChangeBar={() => this.onViewChangeBar(1)}
                        onViewChangeTable={() => this.onViewChangeTable(1)}
                        onViewChangePie={() => this.onViewChangePie(1)}
                        heading="Services"
                        id2="UserReport"
                        enabledViews={this.state.pdfServices}
                        togglepdf={this.togglepdfService}
                        piecharttype="PieChartService"
                        whichdata="Services"
                      />
                    </CSSTransition>

                    {/* Activities */}
                    <div className="PreviewContentHeading">
                      <div className="headname">
                        <h1 id="headingforsubreport">
                          <button
                            onClick={() => {
                              if (this.state.pdfActivity.length > 0) {
                                this.setState({ pdfActivity: "" });
                                this.setState({ showactivities: false });
                              } else {
                                this.setState({ showactivities: true });
                                this.togglepdfActivity("table");
                              }
                            }}
                          >
                            <AiOutlineCheckCircle
                              style={{
                                color:
                                  this.state.pdfActivity.length > 0
                                    ? "lime"
                                    : "#aaa",
                              }}
                            />
                          </button>
                          <p>Activities({this.state.ActivityReport.length})</p>
                          <div
                            className="headingButton"
                            onClick={() =>
                              this.setState({
                                showactivities: !this.state.showactivities,
                              })
                            }
                          >
                            {this.state.showactivities ? (
                              <FiChevronDown />
                            ) : (
                              <FiChevronLeft />
                            )}
                          </div>
                        </h1>
                      </div>
                      <div className="viewMenuContainer">
                        <div
                          className="Previewburgermenu"
                          data-html2canvas-ignore="true"
                        >
                          <div className="PreviewburgerIcon">
                            {this.state.showactivities ? (
                              <button onClick={() => this.ontoggleactmenu()}>
                                <AiOutlineMenu title="Change view" />
                              </button>
                            ) : null}
                          </div>
                        </div>
                        <CSSTransition
                          in={this.state.showactivitiesmenu}
                          // in={true}
                          appear={true}
                          timeout={50}
                          classNames="fade"
                          unmountOnExit
                        >
                          <div className="menutoggles">
                            <div className="viewMenu">
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfActivity("table")}
                              >
                                <AiOutlineTable title="Table" />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfActivity("column")}
                              >
                                <AiOutlineBarChart title="Bar graph" />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => this.togglepdfActivity("pie")}
                              >
                                <AiOutlinePieChart title="Pie chart" />
                              </button>
                            </div>
                            <div className="enableMenu">
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (this.state.pdfActivity.includes("table"))
                                    this.setState({
                                      pdfActivity:
                                        this.state.pdfActivity.replace("table"),
                                    });
                                  else
                                    this.setState({
                                      pdfActivity:
                                        this.state.pdfActivity + "table",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfActivity.includes(
                                      "table"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (this.state.pdfActivity.includes("column"))
                                    this.setState({
                                      pdfActivity:
                                        this.state.pdfActivity.replace(
                                          "column"
                                        ),
                                    });
                                  else
                                    this.setState({
                                      pdfActivity:
                                        this.state.pdfActivity + "column",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfActivity.includes(
                                      "column"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                              <button
                                className="btnView"
                                onClick={() => {
                                  if (this.state.pdfActivity.includes("pie"))
                                    this.setState({
                                      pdfActivity:
                                        this.state.pdfActivity.replace("pie"),
                                    });
                                  else
                                    this.setState({
                                      pdfActivity:
                                        this.state.pdfActivity + "pie",
                                    });
                                }}
                              >
                                <AiOutlineCheckCircle
                                  style={{
                                    color: this.state.pdfActivity.includes(
                                      "pie"
                                    )
                                      ? "lime"
                                      : "#aaa",
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                        </CSSTransition>
                      </div>
                    </div>

                    <CSSTransition
                      in={this.state.showactivities ? true : false}
                      // in={true}
                      appear={true}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      <ReportContent
                        UserReportColumn={ActivityReportColumn}
                        data={ActivityReport}
                        showpreviewcalendar={this.state.showpreviewcalendar}
                        view={this.state.activityview}
                        onViewChangeBar={() => this.onViewChangeBar(2)}
                        onViewChangeTable={() => this.onViewChangeTable(2)}
                        onViewChangePie={() => this.onViewChangePie(2)}
                        heading="Activities"
                        enabledViews={this.state.pdfActivity}
                        togglepdf={this.togglepdfActivity}
                        whichdata="Activity"
                        id2="UserReport"
                        piecharttype="PieChartActivity"
                      />
                    </CSSTransition>

                    {/* Entries */}
                    <div className="PreviewContentHeading">
                      <div className="headname">
                        <h1 id="headingforsubreport">
                          <button
                            onClick={() =>
                              this.setState({
                                showEntries: !this.state.showEntries,
                              })
                            }
                          >
                            <AiOutlineCheckCircle
                              style={{
                                color: this.state.showEntries ? "lime" : "#aaa",
                              }}
                            />
                          </button>
                          <p>Entries ({this.state.EntriesReport.length})</p>
                          <div
                            className="headingButton"
                            onClick={() =>
                              this.setState({
                                showentries: !this.state.showentries,
                              })
                            }
                          >
                            {this.state.showentries ? (
                              <FiChevronDown />
                            ) : (
                              <FiChevronLeft />
                            )}
                          </div>
                        </h1>
                      </div>
                      <div className="viewMenuContainer">
                        <div
                          className="Previewburgermenu"
                          data-html2canvas-ignore="true"
                        >
                          <div className="PreviewburgerIcon">
                            <button
                              style={{ backgroundColor: "transparent" }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CSSTransition
                      in={this.state.showentries ? true : false}
                      // in={true}
                      appear={true}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      <div className="PreviewEntries" id="PreviewEntries">
                        {/* <div className="viewMenuContainer" >
                      <div className="GraphHeader">
                        Entries
                    </div> */}
                        {/* <div className="Previewburgermenu" data-html2canvas-ignore="true">
                        <div className="PreviewburgerIcon">
                          <Switch
                            checked={this.state.showEntries}
                            onChange={() => this.onShowEntriesClick()}
                          />
                        </div>
                      </div> */}
                        {/* </div> */}
                        <div id="EntriesReport" className="EntriesReport">
                          <div className="ColumnHeadings">
                            <label className="headingdate">Date</label>
                            <label className="headingdate">Time</label>
                            <label className="headingdate">Billing</label>
                            <label>Customer</label>
                            <label>Service</label>
                            <label>Activity</label>
                            <label>Resource</label>
                            <label>Description</label>
                          </div>
                          <ReportsTable
                            data={EntriesReport.sort(function (a, b) {
                              // Turn your strings into dates, and then subtract them
                              // to get a value that is either negative, positive, or zero.
                              return new Date(a.date) - new Date(b.date);
                            })}
                            whichdata="entries"
                          />
                        </div>
                      </div>
                    </CSSTransition>
                  </>
                ) : null}
                <CSSTransition
                  in={this.state.showpreviewcalendar}
                  // in={true}
                  appear={true}
                  timeout={300}
                  classNames="fade"
                  unmountOnExit
                >
                  <div className="reportCalendarContainer">
                    {this.state.calmonthsEntries.map((monthdata, i) => {
                      return (
                        <PreviewCalendar
                          data={monthdata.data}
                          month={parseInt(monthdata.month) - 1}
                          indexOfContainer={i}
                        />
                      );
                    })}
                  </div>
                </CSSTransition>
              </div>
              <div></div>
            </section>
          </div>
        </>
      );
    }
  }
}

export default withAlert()(Preview);
