import React from 'react';
import Loading from '../components/loading';
import '../style/settings.scss';
import { CSSTransition } from 'react-transition-group';
import { AiOutlineLeft  } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { FiFilePlus } from "react-icons/fi";
import { FiPlusCircle } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { FiPlusSquare } from "react-icons/fi";
import { withAlert } from 'react-alert';
import { FaRegClone } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css"
import { AppContext } from '../appcontext'

class Settings extends React.Component {
    static contextType = AppContext;

    constructor() {
        super()
        this.state = {
            Loading: true,
            servicesList: [],
            activitiesList: [],
            selected: false,
            selectedService: {},
            editService: false,
            editActivity: false,
            cloneService: false,
            originalServices: [],
            originalActivities: [],
            newService: {
                name: "",
                description: "",
            },
            addService: false,
            addActivity: false,
            newActivity: {
                name: "",
                description: "",
            },
            selectedActivity: {},
            allActivitiesOnDatabase: [],
            showActivitiesList: false,
            searchedValueActivities: "",
            searchValueService: ""
        }
        this.multiselectArray = [];
        this.selectedService = {};
        this.newService = {};
        this.selectedActivities = []
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunctionForm, false);
        this.setState({
            Loading: true,
        })
        this.getServices();
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunctionForm, false);
    }

    escFunctionForm = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                addService: false,
                editService: false,
                addActivity: false,
                editActivity: false,
                cloneService: false
            })
        }
    }
    getServices = async () => {
        await this.context.sendrequest('getservices', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
            .then(response => {
                if (response.data.success) {
                    const originalServices = response.data.data
                    let array = response.data.data;
                    array.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    this.setState({
                        originalServices: originalServices,
                        servicesList: array,
                        Loading: false,
                        editService: false,
                        addActivity: false,
                        addService: false,
                    }, () => {
                        var element = document.getElementById("serviceSearchValue")
                        if (element)
                            element.focus()
                    })
                }
            })
    }
    //activities
    onEditActivity = () => {
        this.setState({
            editActivity: !this.state.editActivity
        })
    }
    onnewActivityName = (e) => {
        let activity = this.state.newActivity;
        activity.name = e.target.value;
        this.setState({
            newActivity: activity
        })
    }
    onnewActivityDescription = (e) => {
        let activity = this.state.newActivity;
        activity.description = e.target.value;
        this.setState({
            newActivity: activity
        })
    }
    onChangeActivityName = (e) => {
        var activity = this.newActivity;
        activity.name = e.target.value;
        this.setState({
            selectedActivities: activity
        })
    }
    onChangeActivityDescription = (e) => {
        var activity = this.state.selectedService;
        activity.description = e.target.value;
        this.setState({
            selectedActivities: activity
        })
    }


    //services

    onEditService = () => {
        this.setState({
            editService: !this.state.editService
        })
    }
    arrayforMulti(array) {
        let newArray = []
        array = array ? array : [];
        if (array[0]) {
            for (let a = 0; a < array.length; a++) {
                newArray.push({
                    id: array[a]._id,
                    label: array[a].name,
                    object: array[a]
                })
            }
            return newArray
        }
    }
    onChangeCloneActivites = (array) => this.selectedActivities = array
    onCloneService = async (service) => {
        this.setState({
            Loading: true
        })
        if (service.activities) {
            if (service.activities[0]) {
                var data = {
                    serviceActivities: service.activities
                }
                await this.context.sendrequest('getserviceactivities', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                    .then(async res => {
                        if (res.data.success) {
                            this.selectedActivities = res.data.data;
                            await this.context.sendrequest('getactivities', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                                .then(res => {
                                    if (res.data.success) {
                                        this.multiselectArray = res.data.data;
                                        this.setState({
                                            newService: service,
                                            selectedService: service,
                                            cloneService: true,
                                            Loading: false,
                                        })
                                    }
                                    else {
                                        this.setState({
                                            newService: service,
                                            selectedService: service,
                                            cloneService: true,
                                            Loading: false,
                                        })
                                    }
                                })
                        }
                    }).catch(this.setState({
                        Loading: false
                    }))
            }
            else {
                this.setState({
                    newService: service,
                    selectedService: service,
                    cloneService: true,
                    Loading: false,
                })
            }
        }
        else {
            this.setState({
                newService: service,
                selectedService: service,
                cloneService: true,
                Loading: false,
            })
        }
    }

    getSearchedResults = e => {
        e.target.value = e.target.value.toLowerCase()
        if (this.state.selected) {
            this.setState({
                searchedValueActivities: e.target.value,
            })
            this.getSearchedActivities(e)
        }
        else {
            this.setState({
                searchValueService: e.target.value,
            })
            this.getSearchedServices(e)
        }
    }

    getSearchedServices = e => {

        var services = [];
        if (e.target.value === "") {
            for (let z = 0; z < this.state.originalServices.length; z++) {
                services.push(this.state.originalServices[z]);
            }
        }
        else
            this.state.originalServices.forEach(function (service) {
                let spaces = 0;
                for (let a = 0; a < e.target.value.length; a++) {
                    if (e.target.value.charAt(a) === " " && a < e.target.value.length - 1)
                        spaces++;
                }
                if (spaces < 1) {
                    if (service.name.toLowerCase().indexOf(e.target.value.trim()) !== -1)
                        services.push(service)
                }
                else {
                    let found = false;
                    let strings = e.target.value.split(" ")
                    for (let b = 0; b < spaces + 1; b++) {
                        if (service.name.toLowerCase().indexOf(strings[b]) !== -1) {
                            found = true;
                        }
                        else {
                            found = false;
                            break;
                        }
                    }

                    if (found)
                        services.push(service)
                }
            })
        this.setState({
            servicesList: services
        })
    }

    getSearchedActivities = e => {
        var activities = [];
        if (e.target.value === "") {
            for (let z = 0; z < this.state.originalActivities.length; z++) {
                activities.push(this.state.originalActivities[z]);
            }
        }
        else
            this.state.originalActivities.forEach(function (activity) {
                let spaces = 0;
                for (let a = 0; a < e.target.value.length; a++) {
                    if (e.target.value.charAt(a) === " " && a < e.target.value.length - 1)
                        spaces++;
                }
                if (spaces < 1) {
                    if (activity.name.toLowerCase().indexOf(e.target.value.trim()) !== -1)
                        activities.push(activity)
                }
                else {
                    let found = false;
                    let strings = e.target.value.split(" ")
                    for (let b = 0; b < spaces + 1; b++) {
                        if (activity.name.toLowerCase().indexOf(strings[b]) !== -1) {
                            found = true;
                        }
                        else {
                            found = false;
                            break;
                        }
                    }

                    if (found)
                        activities.push(activity)
                }
            })
        this.setState({
            activitiesList: activities
        })
    }

    onSubmitCloneService = async () => {
        if (this.state.newService.name.trim() === "") {
            this.error("Service name required")
        }
        else if (this.state.newService.description.trim() === "") {
            this.error("Service description required")
        }
        else {
            this.setState({
                Loading: true,
            })
            var data = {
                name: this.state.newService.name,
                description: this.state.newService.description
            }
            await this.context.sendrequest('addservice', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                .then(async response => {
                    if (response.data.success) {
                        this.setState({
                            cloneService: false,
                            selectedService: response.data.data,
                            // selected : true,
                        })
                        let activitiesIds = []
                        let createdService = response.data.data
                        for (let a = 0; a < this.selectedActivities.length; a++)
                            activitiesIds.push(this.selectedActivities[a]._id)
                        var data = {
                            name: createdService.name,
                            description: createdService.description,
                            activities: activitiesIds,
                            _id: createdService._id
                        }
                        await this.context.sendrequest('updateservice', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                            .then(async response => {
                                if (response.data.success) {
                                    var data = {
                                        serviceActivities: response.data.data.activities
                                    }
                                    await this.context.sendrequest('getserviceactivities', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                                        .then(async response => {
                                            if (response.data.success) {
                                                this.setState({
                                                    activitiesList: response.data.data,
                                                    selected: true,
                                                    Loading: false,
                                                })
                                                await this.getServices();
                                                this.notification(response.data.message)
                                            }
                                        })
                                }
                            })
                        
                        await this.getServices();
                    }
                })
        }
    }

    onnewServiceName = (e) => {
        let service = this.state.newService;
        service.name = e.target.value;
        this.setState({
            newService: service
        })
    }

    notification(message) {
        const alert = this.props.alert
        alert.success(message)
    }
    error(message) {
        const alert = this.props.alert
        alert.error(message)
    }
    onnewServiceDescription = (e) => {
        let service = this.state.newService;
        service.description = e.target.value;
        this.setState({
            newService: service
        })
    }
    onChangeServiceName = (e) => {
        var service = this.newService;
        service.name = e.target.value;
        this.setState({
            selectedService: service
        })
    }
    onChangeServiceDescription = (e) => {
        var service = this.state.selectedService;
        service.description = e.target.value;
        this.setState({
            selectedService: service
        })
    }
    onServiceClick = (service) => {
        this.setState({
            Loading: true,
            selectedService: service,
            newService: service,
            searchValue: ""
        })
        this.selectedService = this.state.selectedService
        window.scrollTo(0, 0)
        if (service.activities) {
            if (service.activities.length) {
                if (service.activities[0] !== "") {
                    var data = {
                        serviceActivities: service.activities
                    }
                    this.context.sendrequest('getserviceactivities', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                        .then(response => {
                            if (response)
                                if (response.data.success) {
                                    response.data.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
                                    let originalActivities = [];
                                    response.data.data.forEach(activity => originalActivities.push(activity))
                                    this.setState({
                                        originalActivities: originalActivities,
                                        activitiesList: response.data.data,
                                        selected: true,
                                        selectedService: service,
                                        Loading: false,
                                    })
                                }
                        })
                }
                this.setState({
                    activitiesList: [],
                    selected: true,
                    selectedService: service,
                    Loading: false,
                })
            }
            else {
                this.setState({
                    activitiesList: [],
                    selected: true,
                    selectedService: service,
                    Loading: false,
                })
            }
        }
        else {
            this.setState({
                activitiesList: [],
                selected: true,
                selectedService: service,
                Loading: false,
            })
        }

        // var element = document.getElementById("activitySearchValue")
        // element.focus()
    }
    allServices = () => {
        return (
            this.state.servicesList.map(service => {
                return (
                    <div className="ServiceItem" >
                        <div className="SName" onClick={() => this.onServiceClick(service)} key={service.name}>
                            {service.name}
                        </div>
                        <div className="SDescription" onClick={() => this.onServiceClick(service)} key={service.description}>
                            {service.description}
                        </div>
                        <button className="CloneButton" title="Clone service" onClick={() => this.onCloneService(service)}><FaRegClone /></button>
                    </div>
                )
            })
        )
    }
    allActivities = () => {
        if (this.state.activitiesList.length === 0) {
            return <h1>No activities for this services.</h1>
        }
        else {
            return (
                this.state.activitiesList.map(activity => {
                    return (
                        <div className="ServiceItem" key={activity.name} onClick={() => this.setState({ selectedActivity: activity, editActivity: true })} >
                            <div className="SName">
                                {activity.name}
                            </div>
                            <div className="SDescription">
                                {activity.description}
                            </div>
                        </div>
                    )
                })
            )
        }
    }
    
    onUpdateService = () => {
        if (this.state.newService.name.trim() === "") {
            this.error("Service name required")
        }
        else if (this.state.newService.description.trim() === "") {
            this.error("Service description required")
        }
        else {
            this.setState({
                Loading: true,
            })
            var activitiesids = []
            if (typeof this.state.newService.activities === "string") {
                if (this.state.newService.activities === "")
                    activitiesids = null;
                else
                    activitiesids.push(this.state.newService.activities)
            }
            else
            if(this.state.newService.activities)
                for (let a = 0; a < this.state.newService.activities.length; a++) {
                    if (this.state.newService.activities[a] > "")
                        activitiesids.push(this.state.newService.activities)
                }
            var data = {
                name: this.state.newService.name,
                description: this.state.newService.description,
                activities: activitiesids,
                _id: this.state.newService._id
            }
            this.context.sendrequest('updateservice', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                .then(response => {
                    if (response.data.success) {
                        this.setState({
                            selectedService: response.data.data,
                        })
                        this.getServices();
                        this.notification(response.data.message)
                    }
                    else
                    this.setState({Loading:false})
                })
        }
    }

    onDeleteService = () => {
        this.setState({
            Loading: true,
        })
        var data = {
            _id: this.state.selectedService._id,
        }
        this.context.sendrequest('deleteservice', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        selected: false,
                        editService: false,
                    })
                    this.getServices();
                    this.notification(response.data.message);
                }
            })
    }

    onCreateService = () => {
        if (this.state.newService.name.trim() === "") {
            this.error("Service name required")
        }
        else if (this.state.newService.description.trim() === "") {
            this.error("Service description required")
        }
        else {
            this.setState({
                Loading: true,
            })
            var data = {
                name: this.state.newService.name,
                description: this.state.newService.description
            }
            this.context.sendrequest('addservice', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                .then(response => {
                    if (response.data.success) {
                        this.getServices();
                        this.notification(response.data.message)
                    }
                })
                .catch(err=>{
                     this.getServices();
                });
        }
    }
    onAddService = () => this.setState({ addService: !this.state.addService })
    onAddActivity = () => this.setState({ addActivity: !this.state.addActivity })

    onNewActivityName = (e) => this.setState({
        newActivity: {
            name: e.target.value,
            description: this.state.newActivity.description
        }
    })
    onNewActivityDescription = (e) => this.setState({
        newActivity: {
            name: this.state.newActivity.name,
            description: e.target.value
        }
    })
    onCreateActivity = () => {
        if (this.state.newActivity.name.trim() === "") {
            this.error("Activity name required")
        }
        else if (this.state.newActivity.description.trim() === "") {
            this.error("Activity description required")
        }
        else {
            this.setState({
                Loading: true,
            })
            var data = {
                name: this.state.newActivity.name,
                description: this.state.newActivity.description
            }
            this.context.sendrequest('addactivity', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                .then(response => {
                    if (response.data.success) {
                        this.setState({
                            addActivity: false,
                            newActivity : {
                                name : "",
                                description : ""
                            }, 
                            Loading: false,
                        })
                        this.notification(response.data.message)
                    }
                    else
                        this.setState({
                            Loading: false,
                        })
                })
        }
    }
    AssignActivity = async (selectedActivities) => {
        let selectedService = this.state.selectedService;
        selectedService.activities = [];
        for (let a = 0; a < selectedActivities.length; a++) {
            selectedService.activities.push(selectedActivities[a].id ? selectedActivities[a].id : selectedActivities[a]._id)
        }
        this.setState({
            selectedService: selectedService,
        })
        if (this.state.cloneService)
            this.setState({
                selectedService: {
                    name: this.state.newService.name,
                    description: this.state.newService.description,
                    activities: this.state.selectedService.activities,
                    _id: this.state.newService._id
                }
            })
        var newdata = {
            name: this.state.selectedService.name,
            description: this.state.selectedService.description,
            activities: this.state.selectedService.activities,
            _id: this.state.selectedService._id
        }

        await this.context.sendrequest('updateservice', newdata, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
            .then(response => {
                if (response.data.success) {
                    var data = {
                        serviceActivities: response.data.data.activities
                    }
                    this.context.sendrequest('getserviceactivities', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
                        .then(response => {
                            if (response.data.success) {
                                this.setState({
                                    activitiesList: response.data.data,
                                    selected: true,
                                    Loading: false,
                                })
                                this.getServices();
                            }
                        })
                }
            })
    }
    onShowActivities = () => {
        this.context.sendrequest('getactivities', null, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
            .then(response => {
                if (response.data.success) {
                    // response.data.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    let activities = response.data.data;
                    let activitiesMulti = [];
                    var singleActivity = {};
                    for (let a = 0; a < activities.length; a++) {
                        singleActivity = {
                            id: activities[a]._id,
                            label: activities[a].name,
                            description: activities[a].description,
                        };
                        activitiesMulti.push(singleActivity);
                    }
                    let activitiesList = this.state.activitiesList;
                    let selectedActivitiesMulti = [];
                    var singleSelectedActivity = {};
                    for (let a = 0; a < activitiesList.length; a++) {
                        singleSelectedActivity = {
                            id: activitiesList[a]._id,
                            label: activitiesList[a].name,
                            description: activitiesList[a].description,
                        };
                        selectedActivitiesMulti.push(singleSelectedActivity);
                    }
                    this.setState({
                        allActivitiesOnDatabase: activitiesMulti,
                        selectedActivitiesforService: selectedActivitiesMulti,
                        showActivitiesList: true,
                    })
                }
            })
    }
    onChangeServiceDescriptionEdit = (e) => {
        this.setState({
            newService: {
                description: e.target.value,
                name: this.state.newService.name,
                activities: this.state.newService.activities,
                _id: this.state.newService._id,
            }
        })
    }
    onnewServiceNameEdit = (e) => {
        this.setState({
            newService: {
                description: this.state.newService.description,
                name: e.target.value,
                activities: this.state.newService.activities,
                _id: this.state.newService._id,
            }
        })
    }
    onnewActivityDescriptionEdit = (e) => {
        this.setState({
            selectedActivity: {
                description: e.target.value,
                name: this.state.selectedActivity.name,
                _id: this.state.selectedActivity._id,
            }
        })
    }
    onnewActivityNameEdit = (e) => {
        this.setState({
            selectedActivity: {
                description: this.state.selectedActivity.description,
                name: e.target.value,
                _id: this.state.selectedActivity._id,
            }
        })
    }

    EditActivity = () => {
        this.setState({
            Loading: true
        })

        var data = this.state.selectedActivity;
        this.context.sendrequest('updateactivity', data, (boolean) => this.setState({ Loading: boolean }), this.props.alert.error)
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        editActivity: false,
                    })
                    this.onServiceClick(this.state.selectedService);

                    this.setState({
                        Loading: false,
                    })
                }
            })
    }
    render() {
        if (this.state.Loading)
            return <Loading />
        return (
            <div className="pageContainer">
                <CSSTransition
                    in={this.state.editService}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="editServiceForm">
                        <form>
                            <div className="editServiceFormHeader">
                                <h3>Edit Service Form</h3>
                                <button onClick={() => this.setState({ editService: false })} type="button">X</button>
                            </div>
                            <div className="editServiceFormContent">
                                <input type="text" placeholder="Service Name" value={this.state.newService.name} onChange={this.onnewServiceNameEdit} />
                                <textarea placeholder="Description" value={this.state.newService.description} onChange={this.onChangeServiceDescriptionEdit}></textarea>
                            </div>
                            <div className="editServiceFormSubmit">
                                <button onClick={() => this.onUpdateService()} type="button">Update</button>
                                <button onClick={() => this.onDeleteService()} type="button">Delete</button>
                            </div>
                        </form>
                    </div>
                </CSSTransition>
                {/*services form*/}
                <CSSTransition
                    in={this.state.editActivity}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="editServiceForm">
                        <form>
                            <div className="editServiceFormHeader">
                                <h3>Edit Acivity Form</h3>
                                <button onClick={() => this.setState({ editActivity: false })} type="button">X</button>
                            </div>
                            <div className="editServiceFormContent">
                                <input type="text" placeholder="Activity Name" value={this.state.selectedActivity.name} onChange={this.onnewActivityNameEdit} />
                                <textarea placeholder="Description" value={this.state.selectedActivity.description} onChange={this.onnewActivityDescriptionEdit}></textarea>
                            </div>
                            <div className="editServiceFormSubmit">
                                <button onClick={() => this.EditActivity()} type="button">Update</button>
                                {/* <button onClick={() => this.EditActivity()} type="button">Delete</button> */}
                            </div>
                        </form>
                    </div>
                </CSSTransition>

                {/*clone form*/}
                <CSSTransition
                    in={this.state.cloneService}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="editServiceForm">
                        <form>
                            <div className="editServiceFormHeader">
                                <h3>Clone Service Form</h3>
                                <button onClick={() => this.setState({ cloneService: false })} type="button">X</button>
                            </div>
                            <div className="editServiceFormContent">
                                <input type="text" placeholder="Service Name" value={this.state.newService.name} onChange={this.onnewServiceNameEdit} />
                                <textarea placeholder="Description" value={this.state.newService.description} onChange={this.onChangeServiceDescriptionEdit}></textarea>
                                <div className="MultiSelActviities">
                                    <MultiSelect
                                        items={this.arrayforMulti(this.multiselectArray)}
                                        showSelectedItems={true}
                                        onChange={this.onChangeCloneActivites}
                                        showSelectAll={true}
                                        selectedItems={this.arrayforMulti(this.selectedActivities)}
                                    />
                                </div>
                            </div>
                            <div className="editServiceFormSubmit">
                                <button onClick={() => this.onSubmitCloneService()} type="button">Submit</button>
                            </div>
                        </form>
                    </div>
                </CSSTransition>
                {
                    this.state.showActivitiesList ?
                        <div className="MultiSelect" id="close" onClick={(e) => {
                            if (e.target.className === "MultiSelect" || e.target.class === "kn-multi_select__wrapper___30BEc")
                                this.setState({
                                    showActivitiesList: false,
                                });
                        }}
                        >
                            <section>
                                <MultiSelect
                                    wrapperClassName="multisel"
                                    className="Multi"
                                    items={this.state.allActivitiesOnDatabase}
                                    showSelectedItems={false}
                                    onChange={this.AssignActivity}
                                    showSelectAll={true}
                                    selectedItems={this.state.selectedActivitiesforService}
                                />
                            </section>
                        </div> : null
                }
                <CSSTransition
                    in={this.state.addService}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="editServiceForm">
                        <form>
                            <div className="editServiceFormHeader">
                                <h3>Create Service</h3>
                                <button onClick={() => this.onAddService()} type="button">X</button>
                            </div>
                            <div className="editServiceFormContent">
                                <input type="text" placeholder="Service Name" value={this.state.newService.name} onChange={this.onnewServiceName} />
                                <textarea placeholder="Description" value={this.state.newService.description} onChange={this.onnewServiceDescription}></textarea>
                            </div>
                            <div className="editServiceFormSubmit">
                                <button onClick={() => this.onCreateService()} type="button">Submit</button>
                            </div>
                        </form>
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={this.state.addActivity}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="editServiceForm">
                        <form>
                            <div className="editServiceFormHeader">
                                <h3>Create Activity</h3>
                                <button onClick={() => this.onAddActivity()} type="button">X</button>
                            </div>
                            <div className="editServiceFormContent">
                                <input type="text" placeholder="Acivity Name" value={this.state.newActivity.name} onChange={this.onNewActivityName} />
                                <textarea placeholder="Description" value={this.state.newActivity.description} onChange={this.onNewActivityDescription}></textarea>
                            </div>
                            <div className="editServiceFormSubmit">
                                <button onClick={() => this.onCreateActivity()} type="button">Submit</button>
                            </div>
                        </form>
                    </div>
                </CSSTransition>
                <h1 id="Serviceheading" >
                    <div className="ServiceHeading">
                        {this.state.selected ? <button type="button" title='back' onClick={() => this.setState({ selected: false })}><AiOutlineLeft/></button> : null}
                        {this.state.selected ? 'Services | ' + this.state.selectedService.name + ' - Activities' : 'Services'}
                    </div>
                    <div className="SearchHeading">
                        {
                            this.state.selected ?
                                <input id="activitiesSearchValue" placeholder="Search Activities..." value={this.state.searchedValueActivities} onChange={this.getSearchedResults} />
                                :
                                <input id="serviceSearchValue" placeholder="Search Services..." value={this.state.searchValueService} onChange={this.getSearchedResults} />
                        }
                        <div className="searchIcon">
                            <FiSearch />
                        </div>
                    </div>
                </h1>
                <hr id="line" />
                <div className="ServiceListContainer">
                    {
                        this.state.selected ?
                            <div className="selecteddescription">
                                {this.state.selectedService.description}
                            </div>
                            : null
                    }
                    {
                        this.state.selected && !this.state.addService ?
                            <div className="morebuttons">
                                <button className="custom" onClick={this.onAddActivity} title="Create activity">
                                    <label>A</label> <FiPlusSquare />
                                </button>
                                <button className="custom" onClick={this.onShowActivities} title="Assign activity">
                                    <label>A</label>
                                    <FiSettings />
                                </button>
                                <button onClick={this.onEditService} title="Edit this service">
                                    <FiEdit3 />
                                </button>
                            </div>
                            :
                            <div className="morebuttons">
                                <button className="custom" onClick={this.onAddService} title="Create service">
                                    <label>S</label> <FiPlusCircle />
                                </button>
                                {/* <button className="custom" onClick={this.onAddActivity} title="Create activity">
                                    <label>A</label> <FiPlusSquare />
                                </button> */}
                            </div>
                    }
                    {
                        this.state.selected ?
                            <this.allActivities />
                            : <this.allServices />
                    }
                </div>
            </div>
        )
    }
}
export default withAlert()(Settings);