import React from 'react';
import Calendar from 'react-calendar';
import '../styles/smallCalendar.scss'
import moment from 'moment';

function SmallCalendar(props) {
    var {
        date, getEntries, changeDate, closeCalendar, escCalender
    } = props
    let selectedDate = moment(date, "YYYY/MM/DD")

    const onClickDay = (calDate) => {
        changeDate(moment(calDate).format("YYYY-MM-DD"))
        getEntries(moment(calDate).format("YYYY-MM-DD"))
    }


    let year = parseInt(selectedDate.format("YYYY"))
    let month = parseInt(selectedDate.format("M"))
    let day = parseInt(selectedDate.format("D"))


    return (
        <div className="calContainer"
            onClick={(e) => {
                if (e.target.className === "cal")
                    closeCalendar()
            }}>
            <div className="cal">
                <Calendar
                    escCalender={escCalender}
                    className="calendar"
                    value={new Date(year, month - 1, day)}
                    onClickDay={onClickDay}
                />
            </div>
        </div>
    )
}
export default SmallCalendar