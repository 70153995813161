import React, { Component } from 'react';
import axios from 'axios';
import * as  Cookies from 'js-cookie';

export const AppContext = React.createContext();
const qs = require('query-string');

class AppContextProvider extends Component {
  constructor() {
    super()
    this.state = {
      user: {},
      isAuthenticated: false,
      userType: 1,
      loadNavbar: false,
      selectedUser: {
        name: ""
      },
      report: {
        CustomerReport: [],
        ServiceReport: [],
        ActivityReport: [],
        UserReport: [],
        CustomerObject: {},
        dates: {
          startdate: "",
          enddate: "",
        }
      },
    }
    var LS = localStorage.getItem("storedValue")
    LS = JSON.parse(LS)
    if (LS) {
      this.state = LS;
    }
  }

  sendrequest = async (path, body, setFunc, notification) => {
    var data = {}

    if (body)
      data = body;
    data.token = Cookies.get("token")
    data = qs.stringify(data)
    if (body || path === "getuser" || path === "verifytoken") {
      return await axios.post(`${process.env.REACT_APP_BASE_URL}/${path}`, data)
        .then(res => {
          if (res.data.success) {
            return res
          }
          else {
            notification(res.data.message)
            return res
          }
        }).catch(e => {
          this.responseError(e, setFunc, notification)
        })
    }
    else {
      return await axios.get(`${process.env.REACT_APP_BASE_URL}/${path}`, data)
        .then(res => {
          if (res.data.success) {
            return res
          }
          else {
            notification(res.data.message)
          }
        }).catch(e => {
          this.responseError(e, setFunc, notification)
        })
    }
  }

  responseError = (e, setFunc, notification) => {
    if (e.response) {
      if (setFunc)
        setFunc(false)
      if (notification)
        notification("Something went wrong. Please try again")
    }
    else if (e.request) {
      if (setFunc)
        setFunc(false)
      if (notification)
        notification("The server is down. Please try again shortly")
    }
    else {
      if (setFunc)
        setFunc(false)
      if (notification)
        notification("Something went wrong. Please try again or contact support")
    }
  }

  setDates = (startdate, enddate) => {
    this.setState({
      dates: {
        startdate: startdate,
        enddate: enddate
      }
    })
    localStorage.setItem("storedValue", JSON.stringify(this.state))
    let orep = this.state.report
    orep.startdate = startdate
    orep.enddate = enddate
    localStorage.setItem("report", JSON.stringify(orep))
  }
  setCustomerObject = (customer) => {
    let oldreport = this.state.report;
    oldreport.CustomerObject = customer
    this.setState({
      report: oldreport
    })
    localStorage.setItem("storedValue", JSON.stringify(this.state))
    localStorage.setItem("report", JSON.stringify(oldreport))
  }
  setCustomerReport = (customerreport) => {
    let oldreport = this.state.report;
    oldreport.CustomerReport = customerreport;
    this.setState({
      report: oldreport
    })
    localStorage.setItem("storedValue", JSON.stringify(this.state))
    localStorage.setItem("report", JSON.stringify(oldreport))


  }
  setServiceReport = (servicereport) => {
    let oldreport = this.state.report;
    oldreport.ServiceReport = servicereport;
    this.setState({
      report: oldreport
    })
    localStorage.setItem("storedValue", JSON.stringify(this.state))
    localStorage.setItem("report", JSON.stringify(oldreport))


  }
  setActivityReport = (activityreport) => {
    let oldreport = this.state.report;
    oldreport.ActivityReport = activityreport;
    this.setState({
      report: oldreport
    })
    localStorage.setItem("storedValue", JSON.stringify(this.state))
    localStorage.setItem("report", JSON.stringify(oldreport))

  }
  setUserReport = (userreport) => {
    let oldreport = this.state.report;
    oldreport.UserReport = userreport;
    this.setState({
      report: oldreport
    })
    localStorage.setItem("storedValue", JSON.stringify(this.state))
    localStorage.setItem("report", JSON.stringify(oldreport))


  }
  setEntriesReport = (entries) => {
    let oldreport = this.state.report;
    oldreport.EntriesReport = entries;
    this.setState({
      report: oldreport
    })
    localStorage.setItem("storedValue", JSON.stringify(this.state))
    localStorage.setItem("report", JSON.stringify(oldreport))


  }
  selectedUser = (user) => {
    this.setState({ selectedUser: user })
    localStorage.setItem("storedValue", JSON.stringify(this.state))

  }
  authenticate = async (user) => {
    this.setState({ isAuthenticated: true, user: user, userType: 1, loadNavbar: true });
    localStorage.setItem("storedValue", JSON.stringify(this.state))
  }

  logout = async () => {
    const logoutAsync = async () => {
      Cookies.remove('token');
      this.setState({ isAuthenticated: false, user: {}, userType: 1 });
    }
    await logoutAsync();


    // Cookies.remove('tokenDev');
    // this.setState({ isAuthenticated: false, user: {}, userType: 1 });
    // // localStorage.removeItem("storedValue")
    // // localStorage.clear()
  }

  render() {
    return (
      <AppContext.Provider value={{
        ...this.state,
        authenticate: this.authenticate,
        logout: this.logout,
        setActivityReport: this.setActivityReport,
        setCustomerReport: this.setCustomerReport,
        setServiceReport: this.setServiceReport,
        setUserReport: this.setUserReport,
        setCustomerObject: this.setCustomerObject,
        setEntriesReport: this.setEntriesReport,
        setDates: this.setDates,
        setNav: this.setNav,
        sendrequest: this.sendrequest,
        responseError: this.responseError
      }}>
        {this.props.children}
      </AppContext.Provider>
    )
  }

}

export default AppContextProvider;
