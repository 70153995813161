import React, { Component } from 'react';
import Chart from 'react-apexcharts'
export default class PieChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: this.getData(),
            options: {
                labels: this.getNames(),
                chart: {
                    type: 'donut',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            toolbar: {
                                show: false
                            }
                        },
                        legend: {
                            fontSize: "32px"
                        },
                    }
                }]
            },


        };
    }

    getData() {
        let totalHours = []
        for (let a = 0; a < this.props.data.length; a++) {
            totalHours.push(this.props.data[a].totalHours)
        }
        return totalHours
    }

    getNames() {
        let names = []
        var data = this.props.data;
        for (let a = 0; a < data.length; a++) {
            names.push(
                data[a].serviceId ? data[a].serviceName + " - " + data[a].totalHours + (data[a].totalHours > 1 ? " hours" : " hour")
                    : data[a].activityId ? data[a].activityName + " - " + data[a].totalHours + (data[a].totalHours > 1 ? " hours" : " hour")
                        : data[a].userName + " - " + data[a].totalHours + (data[a].totalHours > 1 ? " hours" : " hour")
            )
        }
        return names
    }


    render() {
        return (
            <div className="chart" id={this.props.id}>
                <Chart options={this.state.options} series={this.state.series} type="donut" height="400px" width="700px" />
            </div>
        )
    }
}