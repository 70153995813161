import React from 'react';
import Tree from 'react-d3-tree';
import "../style/orgchart.scss";

class OrgChart extends React.Component {
  constructor() {
    super();
    this.state = {
      myTreeData: [
        {
          name: 'Top Level',
          children: [
            {
              name: 'Level 2: A',
            },
            {
              name: 'Level 2: B',
            },
            {
              name: 'Top Level',
              children: [
                {
                  name: 'Level 2: A',
                },
                {
                  name: 'Level 2: B',
                },
              ],
            },
          ],
        },

      ]
    }
  }
  render() {
    return (
      <div id="treeWrapper" >
        <div className="treeContainer" id="treeContainer" style={{ width: "100%" }}>
          <Tree
            data={this.state.myTreeData}
            orientation="vertical"
            translate={
              { x: window.innerWidth / 2.6, y: 20 }
            }
            pathFunc="elbow"
            nodeSvgShape={{
              shape: 'rect',
              shapeProps: {
                width: 20,
                height: 20,
                x: -10,
                y: -10,
              }
            }}
          />
        </div>

      </div>
    );
  }
}

export default OrgChart;