import React, { Component } from 'react';
import '../style/app.scss'
import Home from './Home';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../appcontext';
import Loading from '../components/loading'
import Login from './Login';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Register from './Register';
import Navbar from '../components/navbar';
import ShowProfile from '../components/ShowProfileCompany';
import Profile from './Profile';
import Users from './Users';
import Customers from './Customers';
import Assistant from './Assistant';
import ShowCompanyUser from '../components/showCompanyUsers';
import ShowUserProfile from '../components/showUserProfile';
import Landing from './Landing';
import * as  Cookies from 'js-cookie';
import axios from 'axios';
import Cal from '../components/calendar';
import MyAssistant from '../components/myAssistant';
import AssistantManager from '../components/assistantManager';
import assistantUser from '../components/assistantUsers';
import MyAssistantUsers from '../components/assistantMyUsers';
import Settings from './Settings';
import Reports from './Reports';
import Preview from '../components/preview';
import Email from '../components/Email';
import dialog from '../components/dialog';
import ContactProfile from '../components/contactProfile';
import PasswordReset from './passwordReset';
import JournalLeft from '../components/journalLeft';
import { getStoredState } from 'redux-persist';
import Timesheet from './Timesheet';
import ReportingSheets from './ReportingSheets';
import ResourceManagement from './ResourceManagement';
import ProjectIntegration from './ProjectIntegration';
import KnowledgeManagement from './KnowledgeManagement';
import { withAlert } from 'react-alert';

const qs = require('query-string');

class App extends Component {
     static contextType = AppContext;
     constructor() {
          super();
          this.state = {
               loadNavbar: false,
               data: qs.stringify({
                    token: Cookies.get("token"),
               }),
               showMore: false,
               token: ""
          }
     }

     onShowForm = () => {
          this.setState({
               showMore: false
          })
     }

     onChangeToken = (token) => {
          this.setState({
               token: token
          })
     }

     loadPage = () => {
          if (Cookies.get('token')) {
               const data = this.state.date;
               const alert = this.props.alert
               this.context.sendrequest('getuser', null, (boolean) => this.context.loadNavbar = true, alert.error)
                    .then(response => {
                         if (response) {
                              if (response.data.success === true && this.state.loadNavbar === false) {
                                   if (!this.context.isAuthenticated)
                                        this.context.authenticate(response.data.user)
                              }
                         }
                    })
               while (!this.context.loadNavbar) {
                    return (
                         <Loading />
                    )
               }
               if (this.context.loadNavbar) {
                    return (
                         < Route >
                              < Navbar data={this.state.data} user={this.context.user} OnShowForm={this.onShowForm} />
                              <Route path="/:name/reports/preview" exact component={(props) =>
                                   <Preview
                                        reportCustomerFullObject={props.location.reportCustomerFullObject}
                                        reportCustomerObject={props.location.reportCustomerObject}
                                        reportUserObject={props.location.reportUserObject}
                                        reportServiceObject={props.location.reportServiceObject}
                                        reportActivityObject={props.location.reportActivityObject}
                                        reportEntriesObject={props.location.reportEntriesObject}
                                   />
                              } />
                              {/* <Route component={() => <ButtonMenu showMore={this.state.showMore}/>}/> */}
                              <Route path="/:name/email" exact component={Email} />
                              <Route path="/:name/dialog" exact component={dialog} />
                              <Route path="/:name/reports" exact component={Reports} />
                              <Route path="/:name/settings" exact component={Settings} />
                              <Route path="/:name/JournalLeft" exact component={JournalLeft} />
                              <Route path="/:name/360assistant/myusers" exact component={assistantUser} />
                              <Route path="/:name/360assistant" exact component={() => <Assistant user={this.context.user} />} />
                              <Route path="/:name/360assistant/myusers/:name" exact component={(props) => <MyAssistantUsers user={props.location.employee} />} />
                              <Route path="/:name/360assistant/myassistant" exact component={() => <MyAssistant />} />
                              <Route path="/:name/360assistant/customers" exact component={() => <AssistantManager user={this.context.user} />} />
                              <Route path="/:name/myprofile" exact component={() => <Profile user={this.state.user} />} />
                              <Route path="/:name/users" exact component={() => <Users user={this.state.user} />} />
                              <Route path="/:name/users/:name" exact component={(props) =>
                                   <ShowUserProfile employee={props.location.employee} fromUsers={props.location.fromUsers} />
                              } />
                              <Route path="/:name/customers/:name/contact/:name" exact component={(props) =>
                                   <ContactProfile state={props.location.state} company={props.location.company} employee={props.location.employee} />
                              } />
                              <Route path="/:name/customers/:name" exact component={(props) =>
                                   <ShowProfile state={props.location.state} company={props.location.company} />
                              } />
                              <Route path="/:name/customers" exact component={() => <Customers user={this.state.user} />} />
                              <Route path="/:name/customers/users" exact component={ShowCompanyUser} />
                              <Route path="/:name" strict exact component={(props) => <Home user={this.context.user} state={props.location.state} notification={props.location.notification} />} />
                              <Route path="/:name/home" component={() => <Home />} />
                              <Route path="/:name/home/calendar" component={(props) => <Cal user={this.state.user} onClickDay={props.location.onClickDay} />} />
                              <Route path="/:name/error" component={() => { return (<h1>We were not able to connect you to the Condor360 server. Please try again in a shortly.</h1>) }} />
                         </Route >
                    )
               }
          }
          else {
               return <Redirect to='/' />
          }
     }
     render() {
          return (
               <Router>
                    <Switch>
                         <Route path="/register/:firstname/:token" exact component={Register} />
                         <Route path="/" exact component={Landing} />
                         <Route path="/timesheet" exact component={Timesheet} />
                         <Route path="/Reporting-sheets" exact component={ReportingSheets} />
                         <Route path="/Resource-management" exact component={ResourceManagement} />
                         <Route path="/Project-integration" exact component={ProjectIntegration} />
                         <Route path="/Knowledge-management" exact component={KnowledgeManagement} />
                         <Route path="/login" exact component={() => <Login props={this.props} />} />
                         <Route path="/passwordreset/:firstname/:token" exact component={PasswordReset} />
                         {/* <Route path="/:name/resetpassword" exact component={ResetPassword} /> */}
                         {this.loadPage()}
                         {/* <Route component={() => { return <h1>404</h1> }} /> */}
                    </Switch>
               </Router>
          );
     }
}
export default withAlert()(App);

