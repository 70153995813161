import React, { useState } from 'react';
import '../styles/timesheetLeft.scss'
import '../styles/timesheetRight.scss'



function TimeSheetRight(props) {
    var {
        entries,
        toggleEntry,
        sendrequest,
        getEntries,
        date,
        allentriesforcustomer
    } = props
    return (
        <div className="timesheetRight">
            <RightHours
                toggleEntry={toggleEntry}
                entries={entries}
                sendrequest={sendrequest}
                getEntries={getEntries}
                date={date}
                allentriesforcustomer={allentriesforcustomer}
            />
        </div>
    )
}

function RightHours(props) {
    var {
        toggleEntry,
        entries,
        sendrequest,
        getEntries,
        date,
        allentriesforcustomer
    } = props
    const deletesubmit = async (_id) => {
        var data = {
            entryId: _id
        }
        await sendrequest('deleteentry', data, null, alert.error)
            .then(async res => {
                if (res)
                    if (res.data.success) {
                        toggleEntry()
                        await getEntries(date)
                    }
            })
    }
    return entries.map((data, i) => {
        let timeslot;
        let array = []
        timeslot = i + 2 < 10 ? "1" + (i + 2) : 2 + "" + (i + 2 - 10)

        array = [
            timeslot + ":00",
            timeslot + ":15",
            timeslot + ":30",
            timeslot + ":45",
        ]
        if (data.length > 0) {
            data.forEach(entry => {
                var {
                    time, hours
                } = entry
                array.forEach((element, iarr) => {
                    if (time.timestarted.replace(/\s+/g, '') === element)
                        array.splice(iarr, 1, entry)
                    if ((time.timefinished.replace(/\s+/g, '') > element) && time.timestarted.replace(/\s+/g, '') < element) {
                        array.splice(iarr, 1, "")
                    }
                });
            })
        }

        return (
            <div className="slotContainer" id={i}>
                <div className="slotHour" style={{ color: timeslot > 17 ? "#bbb" : "#555" }}>
                    {timeslot}
                </div>
                <div className="slotBody" >
                    {
                        array.map((slotitem, index) => {
                            if (typeof slotitem === "string")
                                if (slotitem > "") {
                                    let entryindex = 0;
                                    let existingentry = {}
                                    let service = ""
                                    let clickable = true
                                    while (clickable & entryindex < allentriesforcustomer.length) {
                                        if (slotitem >= allentriesforcustomer[entryindex].time.timestarted && slotitem < allentriesforcustomer[entryindex].time.timefinished) {
                                            existingentry = allentriesforcustomer[entryindex]
                                            service = allentriesforcustomer[entryindex].service.serviceName
                                            clickable = false
                                        }
                                        else
                                            entryindex++
                                    }
                                    if (clickable)
                                        return (
                                            <div className="timeonly" onClick={() => toggleEntry(i + 12, slotitem, slotitem.split(":")[1])}>
                                                <label>
                                                    {slotitem}
                                                </label>
                                            </div>
                                        )
                                    else
                                        return (
                                            <div className="timeonly" id="slottaken" style={{ backgroundColor: "rgba(210, 210, 210, 0.2)" }}
                                                title={"This hour is already covered by your entry from " + existingentry.time.timestarted + " to " + existingentry.time.timefinished}
                                            >
                                                <label>
                                                    {slotitem}
                                                    <p className="serviceslot">
                                                        <div className={service.length > 18 ? "scrolling" :null}>
                                                            {service}
                                                        </div>
                                                    </p>
                                                </label>
                                            </div>
                                        )
                                }
                                else
                                    return ("")
                            else {
                                var {
                                    customer,
                                    service,
                                    activity,
                                    time,
                                    hours,
                                    description,
                                    billable,
                                    overtime,
                                    _id
                                } = slotitem
                                let scrolllength = 72 * (hours >= 1 ? 1 : hours)
                                let sizewidth = ""

                                let temptimestart = time.timestarted.replace(/\s+/g, '').split(":")
                                let tominutes = (hours * 60)
                                if (tominutes >= 60) {
                                    sizewidth = (60 - parseInt(temptimestart[1]))
                                    sizewidth = (((sizewidth / 15) * 10))
                                }
                                else {
                                    if (parseInt(temptimestart[1]) > 15) {
                                        if (parseInt(temptimestart[1]) === 30) {
                                            sizewidth = (60 * hours)
                                            sizewidth = (((sizewidth / 15) * 10))
                                        }
                                        else {
                                            sizewidth = (60 - parseInt(temptimestart[1]))
                                            sizewidth = (((sizewidth / 15) * 10))
                                        }
                                    }
                                    else {
                                        sizewidth = ((10 * (tominutes / 15)))
                                        sizewidth = sizewidth / 2
                                    }
                                }

                                sizewidth -= 0.2
                                sizewidth += "vw"
                                return (
                                    < div className="slotItemContainer"
                                        style={{ maxWidth: sizewidth, minWidth: sizewidth }}
                                        onClick={(e) => {
                                            if (e.target.className === "hoverbutton")
                                                toggleEntry(i + 12, null, null, slotitem)
                                        }}>
                                        <div className="slotcustomer">
                                            <div className="container">
                                                <div className={customer.customerName.length > scrolllength ? "scrolling" : null}>
                                                    {customer.customerName}
                                                </div>
                                            </div>

                                            <p className="slottime">
                                                - {time.timefinished}
                                            </p>
                                        </div>
                                        <div className="slotservice">
                                            <div className="container">

                                                <div className={service.serviceName.length > scrolllength ? "scrolling" : null}>
                                                    {service.serviceName}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slotactivity">
                                            <div className="container">

                                                <div className={activity.activityName.length > scrolllength ? "scrolling" : null}>
                                                    {activity.activityName}
                                                </div>
                                            </div>
                                        </div>

                                        {/* hover part */}

                                        <div className="hoveredCard">
                                            <div className="hovercustomercont">
                                                <div className="hovercust">{customer.customerName} </div>
                                                <div className="time"> {time.timestarted} - {time.timefinished} </div>
                                            </div>
                                            <div className="hoverservice"> {service.serviceName}</div>
                                            <div className="hoveractivity"> {activity.activityName}</div>
                                            <div className="hoverbilling"> {billable ? "Billable" : overtime ? "Overtime" : "Non-billable"}</div>
                                            <div className="hoverdescription"> {description}</div>
                                            <div className="hoveredit">
                                                <button type="button" className="hoverbutton" id="hoverbutton" onClick={() => deletesubmit(_id)}>delete</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div >
        )
    })
}
export default TimeSheetRight