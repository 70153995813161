import React, { Component } from 'react';
import '../styles/timesheet.scss'
import TimeSheetLeft from '../components/timesheetLeft';
import TimeSheetRight from '../components/timesheetRight';
import Entry from '../components/Entry';
import { CSSTransition } from 'react-transition-group';
import { AppContext } from '../../../appcontext'
import moment from 'moment';
import Timesheetheader from '../components/timesheetheader';
import Loading from '../../loading';
import { withAlert } from 'react-alert';
import ListUsers from '../components/userList';
import SmallCalendar from '../components/smallCalendar';
import Stats from '../../userStats';
import { BsGraphUp } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi'
import { FaRegCalendarCheck } from 'react-icons/fa';
import MonthEntries from '../components/MonthEntries';

class TimeSheet extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props)
        this.state = {
            showEntry: false,
            timestarted: "",
            timefinished: "",
            resourcecustomers: [],
            services: [],
            activities: [],
            date: "",
            loading: true,
            entriesLeft: [],
            entriesRight: [],
            hour: 0,
            entrydata: {},
            showUsers: false,
            showCalendar: false,
            selectedUser: {},
            allentries: [],
            showStatsData: {},
            showstats: false,
            user: {},
            allentriesforcustomer: [],
            showmonthentries: false,
            specificdate: "",
        }
    }
    escEntry = (e) => {
        if (e.keyCode === 27) {
            this.setState({
                showEntry: false
            })
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escCalender, false);
        document.addEventListener("keydown", this.escUserList, false);
        document.addEventListener("keydown", this.escUserStats, false);
        document.addEventListener("keydown", this.escEntry, false);
        this.setState({
            loading: true
        })
        this.onMount()
        this.setState({
            loading: false
        })
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escCalender, false);
        document.removeEventListener("keydown", this.escUserList, false);
        document.removeEventListener("keydown", this.escUserStats, false);
        document.removeEventListener("keydown", this.escEntry, false);
    }

    onMount = async () => {
        this.setState({
            resourcecustomers: this.props.resourcecustomers,
            services: this.props.services,
            activities: this.props.activities,
            user: this.context.user,
            date: moment().format("YYYY-MM-DD"),
        })


        var timestamp = localStorage.getItem("timestamp")
        if (timestamp) {
            if (JSON.parse(timestamp).date !== moment().format("YYYY-MM-DD"))
                await this.getuserreportdata()
        }
        else
            await this.getuserreportdata()

        await this.getEntries(null, this.context.user)
        // this.props.onChangeSelectedCustomer(this.props.resourcecustomers[0])
        this.onChangeSelectedCustomer(this.props.selectedCustomer)
    }


    getuserreportdata = async () => {
        if (this.props.resourcecustomers.length >= 1 && this.props.services.length >= 1) {
            let firstDayThisMonth = moment().startOf('month').format('YYYY-MM-DD');
            let lastDayThisMonth = moment().endOf('month').format('YYYY-MM-DD');
            let customerIds = [];
            let resourceIds = [this.state.user._id === this.context.user._id ? this.context.user._id : this.state.user._id]
            let serviceIds = [];
            let activityIds = []
            this.props.resourcecustomers.forEach(customer => {
                customerIds.push(customer._id)
            })
            this.props.services.forEach(service => {
                serviceIds.push(service._id)
            })
            await this.context.sendrequest('getactivities', null, null, this.props.alert.error)
                .then(res => {
                    if (res)
                        if (res.data.success)
                            res.data.data.forEach(activity => {
                                activityIds.push(activity._id)
                            })
                })
            const data = {
                customerIds: customerIds,
                serviceIds: serviceIds,
                activityIds: activityIds,
                userIds: resourceIds,
                dates: JSON.stringify({
                    startdate: firstDayThisMonth,
                    enddate: lastDayThisMonth
                })
            }
            await this.context.sendrequest("generatereport", data, null, this.props.alert.error)
                .then(res => {
                    if (res)
                        if (res.data.success)
                            this.setState({
                                showStatsData: res.data.data,
                                showstats: true
                            })
                })
            var lastloggedtime = {
                date: moment().format("YYYY-MM-DD")
            }

            localStorage.setItem("timestamp", JSON.stringify(lastloggedtime))
        }
    }

    changeLoading = boolean => this.setState({ loading: boolean })

    getEntries = async (date, user) => {
        let data = {
            date: date ? date : moment().format("YYYY-MM-DD"),
            user: user ? JSON.stringify(user) : JSON.stringify(this.state.user)
        }
        await this.context.sendrequest("getdateentries", data, null, this.props.alert.error)
            .then(res => {
                if (res)
                    if (res.data.success) {
                        this.setState({
                            allentries: res.data.data,
                            entriesLeft: [],
                            entriesRight: []
                        })
                        this.onChangeSelectedCustomer(this.props.selectedCustomer)
                    }
            })
    }

    toggleEntry = (hour, timestarted, minutes, data, specificdate, arrayslot) => {
        if (!hour && !timestarted && !minutes && !data) {
            if (this.state.showmonthentries) {
                // this.OnShowMonthEntries(false)
                this.OnShowMonthEntries(true)
            }
            this.setState({
                timestarted: "",
                timefinished: "",
                hour: 0,
                showEntry: false,
            })
        }
        else if (data) {
            this.setState({
                entrydata: {
                    description: data.description,
                    time: data.time,
                    billable: data.billable,
                    overtime: data.overtime,
                    service: data.service,
                    activity: data.activity,
                    entryId: data._id,
                    edit: true,
                    jira: data.Jira,
                    completed: data.completed,
                },
                specificdate: specificdate,
                timestarted: data.time.timestarted,
                timefinished: data.time.timefinished,
                hour: hour,
                showEntry: true,
            })
        }
        else if ((hour && timestarted && minutes) || (timestarted && minutes)) {
            let timefinishedhour;
            this.setState({
                showEntry: !this.state.showEntry,
                timestarted: timestarted,
                timefinished: timefinishedhour,
                entrydata: {},
                hour: hour ? hour : 0
            })
        }
        else {
            this.setState({
                entrydata: {},
                showEntry: !this.state.showEntry,
            })

        }


    }

    changeHour = hour => this.setState({ hour: hour })

    onCalendarArrow = async (arrow, olddate) => {
        //Gets the current date and converts the year, month and date to numbers
        let dateArray = olddate.split("-");
        let date = dateArray[2]
        let month = dateArray[1]
        let year = dateArray[0]
        let currentDate = year + "-" + month + "-" + date;
        let followingdate
        if (arrow === "left") {
            followingdate = moment(currentDate, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD")
        }
        else {
            followingdate = moment(currentDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD")
        }
        this.setState({ specificdate: followingdate, date: followingdate })
        await this.getEntries(followingdate, this.state.user)
    }

    onShowUsers = async () => {
        await this.context.sendrequest("getusers", null, (boolean) => this.setState({ isLoading: boolean }), this.props.alert.error)
            .then(res => {
                if (res)
                    if (res.data.success)
                        this.setState({
                            showUsers: !this.state.showUsers,
                            users: res.data.data.sort((a, b) => (a.firstname > b.firstname) ? 1 : (a.firstname === b.firstname) ? ((a.lastname > b.lastname) ? 1 : -1) : -1)
                        })
            })
    }
    onShowCalendar = () => { this.setState({ showCalendar: !this.state.showCalendar }) }

    changeUser = (user) => { this.setState({ user: user }) }
    onChangeSelectedCustomer = customer => {
        this.props.onChangeSelectedCustomer(customer)
        let allentriesforcustomer = []
        let entriesLeft = []
        for (let a = 0; a < 12; a++) {
            let nestedArrayLeft = []
            this.state.allentries.forEach(entry => {
                let timestarted = entry.time.timestarted
                let timeStartedNum = timestarted.split(":")
                if (parseInt(timeStartedNum[0]) === a) {
                    nestedArrayLeft.push(entry)
                    allentriesforcustomer.push(entry)
                }
            });
            entriesLeft.push(nestedArrayLeft)
        }
        let entriesRight = []
        for (let a = 12; a < 24; a++) {
            let nestedArrayRight = []
            this.state.allentries.forEach(entry => {
                let timestarted = entry.time.timestarted
                let timeStartedNum = timestarted.split(":")
                if (parseInt(timeStartedNum[0]) === a) {
                    nestedArrayRight.push(entry)
                    allentriesforcustomer.push(entry)
                }
            });
            entriesRight.push(nestedArrayRight)
        }
        this.setState({
            entriesLeft: entriesLeft,
            entriesRight: entriesRight,
            allentriesforcustomer: allentriesforcustomer
        })
    }

    toggleStats = (boolean) => {
        if (boolean) {
            this.getuserreportdata()
        }
        else {
            this.setState({
                showstats: boolean
            })
        }
    }
    changeDate = (date) => {
        this.setState({
            date: date,
            specificdate: date
        })

    }
    closeCalendar = () => this.setState({ showCalendar: false })

    escCalender = (e) => {
        if (e.keyCode === 27) {
            this.setState({
                showCalendar: false
            })
        }
    }
    escUserList = (e) => {
        if (e.keyCode === 27) {
            this.setState({
                showUsers: false
            })
        }
    }
    escUserStats = (e) => {
        if (e.keyCode === 27) {
            this.setState({
                showstats: false
            })
        }
    }

    OnShowMonthEntries = async (boolean) => {
        if (boolean === true) {
            
            let firstDayThisMonth = moment().startOf("month").format("YYYY-MM-DD");
            let lastDayThisMonth = moment().endOf("month").format("YYYY-MM-DD");
            let resourceIds = [this.state.user._id]
            let serviceIds = []
            let activityIds = []
            let customerIds = []
            this.props.resourcecustomers.forEach(customer => {
                customerIds.push(customer._id)
            })
            this.props.services.forEach(service => {
                serviceIds.push(service._id)
            })
            await this.context.sendrequest('getactivities', null, null, this.props.alert.error)
                .then(res => {
                    if (res)
                        if (res.data.success)
                            res.data.data.forEach(activity => {
                                activityIds.push(activity._id)
                            })
                })
            let data = {
                dates: JSON.stringify({
                    startdate: firstDayThisMonth,
                    enddate: lastDayThisMonth
                }),
                customerIds: customerIds,
                userIds: resourceIds,
                serviceIds: serviceIds,
                activityIds: activityIds
            }
            await this.context.sendrequest("getentries", data, this.changeLoading(false), this.props.alert.error)
                .then(res => {
                    if (res)
                        if (res.data.success) {
                            document.body.style.overflow = "hidden"
                            this.setState({
                                monthentries: res.data.data,
                                showmonthentries: true
                            })
                        }
                })
        }
        else {
            document.body.style.overflow = "visible"
            this.setState({
                showmonthentries: boolean
            })
        }
    }

    render() {
        var element = document.getElementById("description")
        if (element) {
            element.focus()
        }
        if (this.state.loading) {
            return <Loading />
        }
        else
            return (
                <div className="TimeSheetContainer">
                    <Timesheetheader
                        date={this.state.date}
                        onCalendarArrow={this.onCalendarArrow}
                        onShowCalendar={this.onShowCalendar}
                        onChangeSelectedCustomer={this.onChangeSelectedCustomer}
                        selectedCustomer={this.props.selectedCustomer}
                        resourcecustomers={this.props.resourcecustomers}
                        user={this.state.user}
                        loggeduser={this.context.user}
                    />
                    {
                        this.state.showCalendar ?

                            <SmallCalendar
                                date={this.state.date}
                                closeCalendar={this.closeCalendar}
                                getEntries={this.getEntries}
                                changeDate={this.changeDate}
                                escCalender={this.escCalender}
                                escUserList={this.escUserList}
                                escUserStats={this.escUserStats}
                                onShowCalendar={this.onShowCalendar} />
                            : null
                    }
                    <div className='TimeSheetContainerLeftandRight'>
                        <TimeSheetLeft
                            toggleEntry={this.toggleEntry}
                            entries={this.state.entriesLeft}
                            sendrequest={this.context.sendrequest}
                            getEntries={this.getEntries}
                            date={this.state.date}
                            allentriesforcustomer={this.state.allentriesforcustomer}
                        />

                        <TimeSheetRight
                            toggleEntry={this.toggleEntry}
                            entries={this.state.entriesRight}
                            sendrequest={this.context.sendrequest}
                            getEntries={this.getEntries}
                            date={this.state.date}
                            allentriesforcustomer={this.state.allentriesforcustomer}
                        />
                    </div>
                    <CSSTransition
                        in={this.state.showEntry}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <Entry
                            toggleEntry={this.toggleEntry}
                            selectedCustomer={this.props.selectedCustomer}
                            services={this.state.services}
                            activities={this.state.activities}
                            timestarted={this.state.timestarted}
                            timefinished={this.state.timefinished}
                            user={this.state.user}
                            date={this.state.date}
                            sendrequest={this.context.sendrequest}
                            getEntries={this.getEntries}
                            hour={this.state.hour}
                            changeHour={this.changeHour}
                            entrydata={this.state.entrydata}
                            changeLoading={this.changeLoading}
                            specificdate={this.state.specificdate}
                            allentriesforcustomer={this.state.allentriesforcustomer}
                            onsuccentry={this.onsuccentry}
                            escEntry={this.escEntry}
                            resourcecustomers={this.props.resourcecustomers}
                            onChangeSelectedCustomer={this.onChangeSelectedCustomer}
                        />
                    </CSSTransition>
                    <div className="timesheetMenu">
                        <div className="mainmenu">
                            {/* menu buttons go here */}
                            {
                                this.context.user.usertype > 1 ?
                                    <button type="button" title="Users" onClick={this.onShowUsers} ><FiUsers /></button>
                                    : null
                            }
                            <button type="button" title="Stats" onClick={() => this.toggleStats(true)} ><BsGraphUp /></button>
                            <button type="button" title="Month Entries" onClick={() => this.OnShowMonthEntries(true)} ><FaRegCalendarCheck /></button>
                            {/* <button type="button" title="Menu" onClick={this.showMenu} >
                                   </button> */}
                        </div>
                    </div>
                    {
                        this.state.showUsers ?
                            <ListUsers users={this.state.users}
                                changeUser={this.changeUser}
                                getEntries={this.getEntries}
                                date={this.state.date}
                                closeUserList={() => this.setState({ showUsers: false })}
                                userCxt={this.context.user}
                                getResourceCustomers={this.props.getResourceCustomers}
                            />
                            : null
                    }
                    <CSSTransition
                        in={this.state.showstats}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <Stats
                            data={this.state.showStatsData}
                            customers={this.state.resourcecustomers}
                            userObject={this.context.user._id === this.state.user._id ? this.context.user : this.state.user }
                            toggleStats={this.toggleStats}
                        />
                    </CSSTransition>
                    <CSSTransition
                        in={this.state.showmonthentries}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >

                        <MonthEntries
                            data={this.state.monthentries}
                            close={this.OnShowMonthEntries}
                            toggleEntry={this.toggleEntry}
                            changeDate={this.changeDate}
                        />
                    </CSSTransition>
                </div>
            )
    }
}

export default withAlert()(TimeSheet);