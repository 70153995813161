import React, { Component } from 'react';
import '../style/dialog.scss'

export default class dialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: !this.state.open
        })
    }
    
    delete = () => {
        this.setState({
            open:false
        })
        this.props.onEntryDelete(this.props.entryId);
        this.props.showWindow(false)
    }
    render() {
        return (
            <div className="mother">
                <div className="comfirmDialog">
                    <h4>Are you sure you want to delete this entry?</h4>
                    <div className="comfirmDialogButtons">
                        <button className="comfirmDialogCancel"type ="button" onClick ={() => this.props.showWindow(false)}>Cancel</button>
                        <button className="comfirmDialogDelete" type ="button" onClick ={() => this.delete()} >Confirm</button>
                    </div>
                </div>
            </div>
        );
    }
}


