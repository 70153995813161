import React, { Component } from 'react';
import Chart from "react-apexcharts";
import "../style/myassistant.scss";
import defaulticon from '../style/img/male.jpg';
import * as  Cookies from 'js-cookie';
import axios from 'axios';
import Loading from './loading';
const qs = require('query-string');
class MyAssistant extends Component {
    constructor() {
        super();
        var today = new Date();
        this.state = {
            date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
            options: {
                fill: { colors: ['#00ff55', "rgba(0,0,0,0.7)"] },
                labels: ["Complete", "Incomplete"],
                legend: {
                    labels: { colors: ["black"], position: "right" },
                    fontSize: '25px',
                    markers: {
                        radius: 0,
                        width: 60,
                        height: 30,
                        fillColors: ["#00ff33", "rgba(0,0,0,0.7)"]
                    }
                },

                tooltip: {
                    enabled: false
                },

                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '20px',
                        colors: ["rgba(0,0,0,0.7)", '#fff']
                    },
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        opacity: 0.45
                    }
                }
            },
            isLoading: false,
            user: ""
        }
    }

    componentDidMount() {
        var data = qs.stringify({
            token: Cookies.get("token"),
        });
        axios.post('https://localhost/api/getuser', data).then(response => {
            if (response.data.success) {
                this.setState({ user: response.data.user, isLoading: false });
            }
        })
    }

    render() {
        return (



            <div className="pageContainer">
                <h1 id="heading">360Assistant | {this.state.user.firstname} <div className="date"><div className="today">{this.state.date}</div></div></h1>
                <hr id="line" />
                {
                    this.state.isLoading ? <Loading /> :
                        <div className="report">
                            <div className="userDets">
                                <div className="imagery">
                                    <img src={defaulticon} alt="default profile" />
                                </div>

                                <div className="lists">

                                    <div className="Incomplete">
                                        <div className="IncompleteHeader">
                                            <h3>Incompleted Tasks</h3>
                                        </div>
                                        <div className="IncompleteList">
                                            <ul>
                                                <li>Task5</li>
                                                <li>Task5</li>
                                                <li>Task5</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="Complete">
                                        <div className="CompleteHeader">
                                            <h3>Completed Tasks</h3>
                                        </div>
                                        <div className="CompleteList">
                                            <ul>
                                                <li>Task5</li>
                                                <li>Task5</li>
                                                <li>Task5</li>
                                                <li>Task5</li>
                                                <li>Task5</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rhs">

                                <div className="dataCharts">
                                    <div className="donutChart">
                                        <div className="topLabel">

                                        </div>
                                        <Chart
                                            options={this.state.options}
                                            series={[71, 29]}
                                            type="donut"
                                            width="100%" height="500px"
                                        />
                                    </div>

                                </div>
                                <hr id="line" />
                                <div className="reportCard">

                                </div>
                            </div>
                        </div>
                }
            </div>

        )
    }
}

export default MyAssistant;