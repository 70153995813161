import React, { Component } from 'react';
import "../style/customers.scss";
import "../style/companycard.scss";
import CompanyCard from '../components/companycard';
import Trash from '../style/img/trash.png';
import ImageUploader from "react-images-upload";
import * as  Cookies from 'js-cookie';
import Loading from '../components/loading';
import { FiPlus } from "react-icons/fi";
import { withAlert } from 'react-alert';
import { CSSTransition } from 'react-transition-group';
import { FiSearch } from "react-icons/fi";
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { AppContext } from '../appcontext'

class Companies extends Component {
     static contextType = AppContext;
     constructor(props) {
          super(props);
          this.state = {
               searchValue: "",
               pictures: [],
               companyListActive: [],
               companyListInactive: [],
               showForm: false,
               showMore: false,
               showContacts: false,
               showResources: false,
               showDetails: true,
               showNewUser: false,
               showProfile: false,
               showPersonal: true,
               showTasks: false,
               showAssign: false,
               toggledUser: {
                    customername: 'default default',
                    position: '',
                    contact: '',
               },
               currentTab: "Details",
               name: '',
               description: '',
               street: '',
               building: '',
               area: '',
               region: '',
               code: '',
               country: '',
               industry: '',
               active: true,
               Heading: "Details",
               newUserSuccessful: '',
               isLoading: false,
               logo: [],
               picturesChosen: 0,
               cardtype: "grid",
               showCustomer: false,
               customerList: []
          };
          this.customerImage = [];
          this.logo = [];
     }
     getSearchedUsers = (e) => {
          this.setState({
               searchValue: e.target.value,
               // customerList: [],
          })

          var customer = [];
          var search = e.target.value.split(' ');
          if (search.length > 1) {
               if (e.target.value === "") {
                    for (let z = 0; z < this.state.customerList.length; z++) {
                         customer.push(this.state.customerList[z])
                    }
               }
               else {
                    this.state.customerList.forEach(function (person) {
                         if (person.customername.toLowerCase().indexOf(search[0]) !== -1 && person.customername.toLowerCase().indexOf(search[1]) !== -1) {
                              customer.push(person);
                         }
                    });
               }
          }
          else {
               if (e.target.value === "") {
                    for (let z = 0; z < this.state.customerList.length; z++) {
                         customer.push(this.state.customerList[z])
                    }
               }
               else {
                    this.state.customerList.forEach(function (person) {
                         if (person.customername.toLowerCase().indexOf(e.target.value) !== -1) {
                              customer.push(person);
                         }
                    });
               }
          }
          this.sortActiveandInactive(customer)
     }
     onDrop = (pictureFiles, pictureDataURLs) => {
          this.setState({
               logo: this.onChangeLogo(pictureDataURLs),
               picturesChosen: 1
          })
          this.customerImage = pictureDataURLs
          if (this.customerImage.length > 1) {
               this.customerImage.shift()
          }

     }

     onChangeLogo = (logo) => {
          this.logo = logo
          return this.logo
     }
     onChangeActive = (index, active) => {
          var list;
          var listItem;

          if (active) {
               list = this.state.companyListInactive;
               listItem = list[index];
               listItem.active = !active;
               list[index] = listItem;
               this.setState({
                    companyListInactive: list
               })
          }
          else {
               list = this.state.companyListActive;
               listItem = list[index];
               listItem.active = !active;
               list[index] = listItem;
               this.setState({
                    companyListActive: list
               })
          }
     }

     componentWillUnmount() {
          document.removeEventListener("keydown", this.escFunctionForm, false);
     }

     sortActiveandInactive(customers) {
          let active = []
          let inactive = []
          for (let a = 0; a < customers.length; a++) {
               if (customers[a].active) {
                    active.push(customers[a])
               }
               else {
                    inactive.push(customers[a])
               }
          }
          this.setState({
               companyListActive: active,
               companyListInactive: inactive
          })
     }
     componentDidMount() {
          document.addEventListener("keydown", this.escFunctionForm, false);
          this.setState({
               isLoading: true
          })

          const alert = this.props.alert
          this.context.sendrequest('getcustomers', null, (boolean) => this.setState({ isLoading: boolean }), alert.error)
               .then(response => {
                    if (response)
                         if (response.data.success) {
                              this.sortActiveandInactive(response.data.data)
                              this.setState({
                                   customerList: response.data.data,
                                   isLoading: false
                              })
                         }
               })
     }

     getcustomers = async () => {
          this.setState({
               isLoading: true
          })
          const alert = this.props.alert
          await this.context.sendrequest('getcustomers', null, (boolean) => this.setState({ isLoading: boolean }), alert.error)
               .then(response => {
                    if (response)
                         if (response.data.success) {
                              this.sortActiveandInactive(response.data.data)
                              this.setState({
                                   customerList: response.data.data,
                                   isLoading: false
                              })
                         }
               })
     }

     toggleAddCompanyButton = () => {
          this.setState({
               showForm: !this.state.showForm,
               name: '',
               description: '',
               street: '',
               building: '',
               area: '',
               region: '',
               code: '',
               country: '',
               industry: '',
               active: true,
          })
     }
     escFunctionForm = (event) => {
          if (event.keyCode === 27) {
               this.setState({
                    showForm: false,
                    name: '',
                    description: '',
                    street: '',
                    building: '',
                    area: '',
                    region: '',
                    code: '',
                    country: '',
                    industry: '',
                    active: false,
               })
          }
     }
     notification(message) {
          const alert = this.props.alert
          alert.success(message)
     }
     error(message) {
          const alert = this.props.alert
          alert.error(message)
     }

     currentUser = (customer) => {
          this.setState({
               toggledUser: customer
          })
     }

     onRegName = (event) => this.setState({ name: event.target.value });
     onRegDescription = (event) => this.setState({ description: event.target.value });
     onRegStreet = (event) => this.setState({ street: event.target.value });
     onRegBuilding = (event) => this.setState({ building: event.target.value });
     onRegArea = (event) => this.setState({ area: event.target.value });
     onRegRegion = (event) => this.setState({ region: event.target.value });
     onRegIndustry = (event) => this.setState({ industry: event.target.value });
     onRegCode = (event) => this.setState({ code: event.target.value });
     onRegCountry = (event) => this.setState({ country: event.target.value });
     onRegStatusActive = (value) => this.setState({ active: value === "true" ? "true" : "false" });

     onSubmitNewCompany = () => {
          this.setState({
               isLoading: true,
          })
          if (this.state.name.trim() === "") {
               this.error("Company name required")
          }
          else if (this.state.description.trim() === "") {
               this.error("Company description required")
          }
          else if (this.state.street.trim() === "") {
               this.error("Company street required")
          }
          else if (this.state.building.trim() === "") {
               this.error("Company building required")
          }
          else if (this.state.area.trim() === "") {
               this.error("Company area required")
          }
          else if (this.state.code.trim() === "") {
               this.error("Company address code required")
          }
          else if (this.state.country.trim() === "") {
               this.error("Company country required")
          }
          else {
               var data = {
                    customername: this.state.name,
                    description: this.state.description,
                    building: this.state.building,
                    area: this.state.area,
                    street: this.state.street,
                    region: this.state.region,
                    code: this.state.code,
                    country: this.state.country,
                    industry: this.state.industry,
                    contacts: this.state.contact,
                    active: this.state.active,
                    logo: this.customerImage[0],
                    resources: ''
               }
               const alert = this.props.alert
               this.context.sendrequest('savecustomer', data, (boolean) => this.setState({ isLoading: boolean }), alert.error)
                    .then(response => {
                         if (response)
                              if (response.data.success === true) {
                                   this.setState({
                                        name: '',
                                        description: '',
                                        street: '',
                                        building: '',
                                        area: '',
                                        region: '',
                                        code: '',
                                        country: '',
                                        industry: '',
                                        active: false,
                                   })
                                   this.notification(response.data.message)
                                   this.context.sendrequest('getcustomers', null, (boolean) => this.setState({ isLoading: boolean }), alert.error)
                                        .then(response => {
                                             if (response)
                                                  if (response.data.success) {
                                                       this.sortActiveandInactive(response.data.data)
                                                       this.setState({
                                                            isLoading: false,
                                                            showForm: false,
                                                       })
                                                  }
                                        });

                              }
                              else {
                                   this.setState({
                                        newUserSuccessful: false,
                                        isLoading: false,
                                        showForm: true,
                                   })
                                   this.error(response.data.message)
                              }
                    })
                    .catch(
                         this.setState({
                              isLoading: false,
                         })
                    )
          }
          this.setState({
               isLoading: false
          })
     }


     render() {
          document.body.style.overflow = this.state.Loading || this.state.showForm ? "hidden" : "visible"
          if (this.state.isLoading) {
               return (
                    <div cwssName="pageContainer">
                         <div className="loginContainer">
                              <Loading />
                         </div>
                    </div>
               )
          }
          else {
               return (
                    <>
                         <CSSTransition
                              in={this.state.showForm}
                              appear={true}
                              timeout={300}
                              classNames="fade"
                              unmountOnExit
                         >
                              <div className="AddCustomerForm" onClick={(e) => {
                                   if (e.target.className === "AddCustomerForm fade-enter-done")
                                        this.setState({ showForm: false })
                              }}>
                                   <section>
                                        <div className="FormHeader">
                                             <p>Add Customer <button className="CloseCus" onClick={this.toggleAddCompanyButton}>X</button></p>
                                        </div>
                                        <div className="FormContent">
                                             <form>

                                                  <input type="text" placeholder="Street" value={this.state.street} onChange={this.onRegStreet} />
                                                  <input type="text" placeholder="Building" value={this.state.building} onChange={this.onRegBuilding} />
                                                  <input type="text" placeholder="Area" value={this.state.area} onChange={this.onRegArea} />
                                                  <input type="text" placeholder="Region" value={this.state.region} onChange={this.onRegRegion} />
                                                  <input type="text" placeholder="Code" value={this.state.code} onChange={this.onRegCode} />
                                                  <input type="text" placeholder="Country" value={this.state.country} onChange={this.onRegCountry} />
                                                  <input type="text" checked={this.state.checked} placeholder="Industry" value={this.state.industry} onChange={this.onRegIndustry} />
                                                  <div className="userTypeOptions">
                                                       <RadioGroup onChange={this.onRegStatusActive} value={this.state.active ? "true" : "false"} horizontal>
                                                            <RadioButton value="true" pointColor="#00dd21">
                                                                 Active
                                                            </RadioButton>
                                                            <RadioButton value="false" pointColor="red">
                                                                 Inactive
                                                            </RadioButton>
                                                       </RadioGroup>
                                                  </div>
                                             </form>
                                             <div className="AddFormProfile">
                                                  <section>
                                                       <input type="text" placeholder="Name" value={this.state.name} onChange={this.onRegName} />
                                                       {/* <input type="text" placeholder="Description" value={this.state.description} onChange={this.onRegDescription} /> */}
                                                       <textarea placeholder="Description" value={this.state.description} onChange={this.onRegDescription}
                                                       ></textarea>
                                                  </section>
                                                  <div className="UploadImage">
                                                       {
                                                            this.state.picturesChosen === 0 ?
                                                                 <ImageUploader
                                                                      withIcon={false}
                                                                      withPreview={false}
                                                                      label=""
                                                                      buttonText="Add Logo"
                                                                      onChange={this.onDrop}
                                                                      imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg"]}
                                                                      maxFileSize={1048576}
                                                                      fileSizeError=" file size is too big"
                                                                      className="ImageUploader"
                                                                      singleImage={true}
                                                                 />
                                                                 :
                                                                 <div className="logo" >
                                                                      <div className="remove">
                                                                           <img src={Trash} alt="trash" onClick={() => this.setState({ picturesChosen: 0 })} />
                                                                      </div>
                                                                      <img src={this.customerImage[0]} alt="cutsomer" />
                                                                 </div>
                                                       }
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="SubmitContainer">
                                             <button className="CustomerSubmit" type="button" onClick={this.onSubmitNewCompany}>Submit</button>
                                        </div>
                                   </section>
                              </div>
                         </CSSTransition>
                         <div className="pageContainer">
                              <button className="addButton" onClick={this.toggleAddCompanyButton}>
                                   <FiPlus />
                              </button>
                              <h1 id="heading">Customers
                              <div className="searchContainer">
                                        <div className="searchContainer">
                                             <input placeholder="Search..." value={this.state.searchValue} onChange={this.getSearchedUsers} />
                                             <div className="searchIcon">
                                                  <FiSearch />
                                             </div>
                                        </div>
                                   </div>
                              </h1>
                              <hr id="line" />
                              <div className="columnContainer">
                                   <div className={this.state.cardtype === "list" ? "cardHolder" : "cardsCompany"}>
                                        <CSSTransition
                                             in={
                                                  this.state.companyListActive.length > 0 ? true : false
                                             }
                                             appear={true}
                                             timeout={300}
                                             classNames="fade"
                                             unmountOnExit
                                        >


                                             <CompanyCard
                                                  companyList={this.state.companyListActive}
                                                  whichUsers="currentUsers"
                                                  toggleCurrentUserDetails={this.currentUser}
                                                  toggleShowProfile={this.viewProfile}
                                                  state={this.state}
                                                  pathname="/companies/"
                                                  onChangeActive={this.onChangeActive}
                                                  cardtype={this.state.cardtype}
                                                  getcustomers={this.getcustomers}
                                             />
                                        </CSSTransition>
                                        <CSSTransition
                                             in={
                                                  this.state.companyListInactive.length > 0 ? true : false
                                             }
                                             appear={true}
                                             timeout={300}
                                             classNames="fade"
                                             unmountOnExit
                                        >
                                             <CompanyCard
                                                  companyList={this.state.companyListInactive}
                                                  whichUsers="currentUsers"
                                                  toggleCurrentUserDetails={this.currentUser}
                                                  toggleShowProfile={this.viewProfile}
                                                  state={this.state}
                                                  pathname="/companies/"
                                                  getcustomers={this.getcustomers}
                                                  onChangeActive={this.onChangeActive}
                                                  cardtype={this.state.cardtype}
                                             />
                                        </CSSTransition>
                                        <CSSTransition
                                             in={
                                                  this.state.companyListActive.length === 0 && this.state.companyListInactive.length === 0 ? true : false
                                             }
                                             appear={true}
                                             timeout={300}
                                             classNames="fade"
                                             unmountOnExit
                                        >
                                             <h4>No Customers Found</h4>
                                        </CSSTransition>
                                   </div>
                              </div>
                         </div>
                    </>
               );
          }
     }
}

export default withAlert()(Companies);

          //Download file (might need for download as pdf or csv)
     // download = (content, fileName, contentType) => {
     //      var a = document.createElement("a");
     //      var file = new Blob([content], {type: contentType});
     //      a.href = URL.createObjectURL(file);
     //      a.download = fileName;
     //      a.click();
     //  }