import React, { Component } from 'react';
import '../style/register.scss';
import { withAlert } from 'react-alert';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import hint1 from '../style/img/slides/hint1.jpg';
import hint2 from '../style/img/slides/hint2.jpeg';
import hint4 from '../style/img/slides/hint4.jpg';
import Loading from '../components/loading';

const qs = require('query-string');

class Register extends Component {
     constructor(props) {
          super(props);

          this.state = {
               regToken: '',
               regsuccess: false,
               firstname: '',
               position: '',
               confirmpassword: '',
               lastname: '',
               email: '',
               password: '',
               isTokenValid: false,
               isLoading: true,
               pendingUserId: '',
               usertype: "1",
               valid: true,
          };

     }

     validateEmail(email) {
          const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(email)
     }

     componentDidMount() {
          this.setState({ isLoading: true });
          var data = qs.stringify({
               firstname: this.props.match.params.firstname,
               token: this.props.match.params.token
          })

          axios.post(`${process.env.REACT_APP_BASE_URL}/verifyregtoken`, data).then(response => {
               if (response.data.success === true) {
                    this.notification(response.data.message)
                    this.setState({
                         email: response.data.data.email,
                         position: response.data.data.position,
                         pendingUserId: response.data.data._id,
                         usertype: response.data.data.usertype,
                         firstname: this.props.match.params.firstname
                    })
                    this.setState({ isLoading: false, isTokenValid: true })
               } else {
                    this.error(response.data.message)
                    this.setState({ isLoading: false })
               }
          })
     }

     handleSubmitButton = (event) => {
          event.preventDefault();
          if(this.state.firstname.trim() === "")
               this.error("first name required")
          if(this.state.lastname.trim() === "")
               this.error("last name required")
          if(this.state.email.trim() === "")
               this.error("email required")
          else if(this.validateEmail(this.state.email))
               this.error("Email format incorroect")
          if(this.state.email.trim() === "")
               this.error("email required")
          if(this.state.password.trim() === "")
               this.error("password required")
          if(this.state.confirmpassword.trim() === "")
               this.error("Confirm password")
          else if(this.state.password !== this.state.confirmpassword)
               this.error("password does not match")
          else{
               this.setState({ isLoading: true });
               var data = qs.stringify({
               firstname: this.state.firstname,
               lastname: this.state.lastname,
               usertype: this.state.usertype,
               email: this.state.email,
               password: this.state.password,
               confirmpassword: this.state.confirmpassword,
               pendingUserId: this.state.pendingUserId,
               position: this.state.position,
               active: true,

          })
          axios.post(`${process.env.REACT_APP_BASE_URL}/createuser`, data).then(response => {
               if (response.data.success === true) {
                    this.setState({
                         isLoading: false, regsuccess: true
                    })
                    this.notification(response.data.message)
               }
               else {
                    this.setState({
                         isLoading: false, isTokenValid: false
                    })
                    this.error(response.data.message)
               }
          })
               .catch(error => console.error('timeout exceeded'))
     }
     }

     onTextboxChangeFirstName = (event) => {
          this.setState({
               firstname: event.target.value,
          })
     }

     onTextboxChangeLastName = (event) => {
          this.setState({
               lastname: event.target.value,
          })
     }

     onTextboxChangeEmail = (event) => {
          const email = event.target.value
          const emailValid = this.validateEmail(email)
          this.setState({
               email: event.target.value,
               valid: emailValid
          })
     }
     validateEmail(email) {
          const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(email)
     }

     onTextboxChangePassword = (event) => {
          this.setState({
               password: event.target.value,
          })
     }

     onTextboxChangeConfirmPassword = (event) => {
          this.setState({
               confirmpassword: event.target.value,
          })
     }

     onTextboxChangePosition = (event) => {
          this.setState({
               position: event.target.value,
          })
     }
     notification = (message) => {
          const alert = this.props.alert
          alert.success(message)
     }
     error(message) {
          const alert = this.props.alert
          alert.error(message)
     }
     errorEmail = () => {
          const alert = this.props.alert
          alert.error("invalid email address")
     }
     errorFieldRequired = () => {
          const alert = this.props.alert
          alert.error("All fields are required")
     }
     errorPasswordsMatch = () => {
          const alert = this.props.alert
          alert.error("Passwords don't match")
     }
     HandleWarning = () => {
          const alert = this.props.alert
          alert.show("Fields are empty")
     }
     handleClear = () =>
          this.setState({
               lastname: '',
               firstname: '',
               email: '',
               password: '',
               confirmpassword: ''
          })

     render() {
          const slideImages = [hint1, hint2, hint4];

          const properties = {
               duration: 3000,
               transitionDuration: 500,
               infinite: true,
               indicators: false,
               arrows: true
          }

          var {
               isLoading,
               isTokenValid,
               regsuccess
          } = this.state;
          if (isLoading) {
               return (
                    <div className="pageContainer">
                         <div className="loginContainer">
                              <Loading />
                         </div>
                    </div>
               );
          }
          else if (!isLoading && !isTokenValid) {
               return (<Redirect to='/' />);
          }
          else if (!isLoading && regsuccess) {
               return (<Redirect to='/' />);
          }
          else if (!isLoading && isTokenValid && !regsuccess) {
               return (
                    <div class="register">
                         <div class="block">
                              {/* <img src={Logo} alt="Condorgreen" /> */}
                              <Slide {...properties}>
                                   <div className="each-slide">
                                        <div className="backgroundImage" style={{ 'backgroundImage': `url(${slideImages[0]})` }}>
                                             {/* <span>Slide 1</span> */}
                                        </div>
                                   </div>
                                   <div className="each-slide">
                                        <div className="backgroundImage" style={{ 'backgroundImage': `url(${slideImages[1]})` }}>
                                             {/* <span>Slide 2</span>  */}
                                        </div>
                                   </div>
                                   <div className="each-slide">
                                        <div className="backgroundImage" style={{ 'backgroundImage': `url(${slideImages[2]})` }}>
                                             {/* <span>Slide 3</span> */}
                                        </div>
                                   </div>
                              </Slide>
                         </div>
                         <div class="registrationForm">
                              <form>
                                   <h1>Register Condor360</h1>
                                   <div className="forForm">
                                        <h3>First Name</h3>
                                        <input type="text" placeholder="First Name..." onChange={this.onTextboxChangeFirstName} value={this.state.firstname} required />
                                        <h3>Last Name</h3>
                                        <input type="text" placeholder="Last Name..." value={this.state.lastname} onChange={this.onTextboxChangeLastName} required />
                                        <h3>E-mail address</h3>
                                        <input type="text" placeholder="Email..." onChange={this.onTextboxChangeEmail} value={this.state.email} required />
                                        <h3>Position</h3>
                                        <input type="text" placeholder={this.state.position} disabled />
                                        <h3>Password</h3>
                                        <input type="password" placeholder="Password" onChange={this.onTextboxChangePassword} value={this.state.password} required />
                                        <h3>Confirm Password</h3>
                                        <input type="password" placeholder="Confirm password" onChange={this.onTextboxChangeConfirmPassword} value={this.state.confirmpassword} required />
                                        <br />
                                        {/* <input type="checkbox" /><label>I agree to the terms and conditions</label> */}
                                        <br />
                                   </div>
                                   <div className="styleThem">
                                        
                               <button className="b1" type="button" onClick={this.handleSubmitButton}>Submit</button> 
                                        
                                        {
                                             this.state.firstname === "" && this.state.lastname === "" && this.state.email === "" && this.state.password === "" && this.state.confirmpassword === "" ?
                                                  <button className="b1" type="button" onClick={this.HandleWarning} >Clear</button> :
                                                  <button className="b1" type="button" onClick={this.handleClear}>Clear</button>

                                        }
                                   </div>

                              </form>
                         </div>
                    </div>
               )
          }
     }
}

export default withAlert()(Register);
