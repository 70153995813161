import React, { useState } from 'react'
import { FiChevronDown } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import { CSSTransition } from 'react-transition-group';
import Cards from '../components/cards';
import cx from 'classnames';
import defaultIcon from '../style/img/defaultUser.png';
import StatsCards from '../components/statscards';

function StructuredCards(props) {
    var {
        customer,
        state,
        currentUser,
        viewProfile,
        filter
    } = props
    const [toggledrop, changetoggledrop] = useState(false);
    return (
        <>
            <h1 id="headingforsub" onClick={() => changetoggledrop(!toggledrop)}> {customer.customername} ({customer.users.length})
                       <div className="headingButton">
                    {
                        toggledrop ?
                            <FiChevronDown />
                            : <FiChevronLeft />
                    }
                </div>
            </h1>
            <section className={cx('toggler', {
                'toggler--active': toggledrop,
            })}>
                <CSSTransition
                    in={toggledrop}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="structureContainer" style={{ flexDirection: state.view === "list" ? "column" : "row" }}>
                        {
                            customer.users.length > "0" ?
                                state.view === "stats" ?
                                    <div className="statscards" >
                                        <StatsCards
                                            users={customer.users}
                                            filter={props.filter}
                                        />
                                    </div>
                                    :
                                    <Cards
                                        whichUsers="currentUsers"
                                        toggleCurrentUserDetails={currentUser}
                                        toggleShowProfile={viewProfile}
                                        users={customer.users}
                                        defaultIcon={defaultIcon}
                                        state={state}
                                        pathname="ourStructure"
                                        view={state.view}
                                    />
                                :
                                <section>
                                    <h4>There are no users to display</h4>
                                </section>
                        }
                    </div>
                </CSSTransition>
            </section>
        </>
    )
}

export default StructuredCards
