import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
// import '../style/newstyle.css';
import '../../src/style/landing.scss';
import * as  Cookies from 'js-cookie';
import Loading from '../components/loading';
import { AppContext } from '../appcontext'
import Login from './Login';
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';
import Sidebar from "react-sidebar";
import { AiOutlineLogin } from 'react-icons/ai';
import { RiPagesLine } from 'react-icons/ri';
import { RiContactsBook2Line } from 'react-icons/ri';
import { AiOutlineTool } from 'react-icons/ai';
import '../style/sidebar.scss';
import { withAlert } from 'react-alert';

const qs = require('query-string');
class Landing extends Component {
    static contextType = AppContext;
    constructor() {
        super();
        this.state = {
            isLoading: true,
            isAuth: false,
            user: "",
            showLogin: true,
            forgotpasswordShow: false,
            emailSentForm: false,
            useSidepanel: false,
            sidebarOpen: false
        }
    }
    escFunctionForm = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                showLogin: true
            })
        }
    }
    handleResize = () => {
        if (window.innerWidth < 1000)
            this.setState({
                useSidepanel: true
            })
        else
            this.setState({
                useSidepanel: false,
                sidebarOpen: false
            })
    }
    onSetSidebarOpen = (open) => {
        this.setState({ sidebarOpen: open });
    }

    LinkClick = () => {
        this.setState({
            sidebarOpen: false
        })
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunctionForm, false);
    }
    componentDidMount() {
        document.addEventListener("keydown", this.escFunctionForm, false);
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
        if (Cookies.get('token')) {
            this.setState({
                isLoading: true
            })
            const alert = this.props.alert
            this.context.sendrequest("getuser", null, (boolean) => this.setState({ isLoading: boolean }), alert.error)
                .then(response => {
                    if (response)
                        if (response.data.success === true) {
                            if (!this.context.isAuthenticated)
                                this.context.authenticate(response.data.user);
                            this.setState({
                                isLoading: false,
                            })
                            return <Redirect to={`/${this.context.user.firstname}/home`} />
                        }
                        else {
                            Cookies.remove('token');
                            this.setState({ isLoading: false })
                        }
                })
        }
        else {
            this.context.isAuthenticated = false;
            this.setState({ isLoading: false })
        }

    }
    onShowForgotPassword = () => {
        this.setState({ forgotpasswordShow: !this.state.forgotpasswordShow })
    }
    onSignIn = () => {
        this.setState({ showLogin: !this.state.showLogin })
        if (!this.state.showLogin) { this.setState({ sidebarOpen: false }) }
    }

    onShowLogin = (e) => {
        this.setState({ showLogin: false })
    }
    onEmailSent = () => { this.setState({ emailSentForm: !this.state.emailSentForm }) }

    render() {
        if (!this.context.isAuthenticated) {
            return (
                <body>
                    <CSSTransition
                        in={this.state.showLogin}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        < Login showLogin={this.state.showLogin} onShowLogin={this.onShowLogin} />
                    </CSSTransition>

                    <CSSTransition
                        in={this.state.forgotpasswordShow}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        < Login forgotpasswordShow={this.state.forgotpasswordShow} onShowForgotPassword={this.onShowForgotPassword} />
                    </CSSTransition>
                    <CSSTransition
                        in={this.state.emailSentForm}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        < Login emailSentForm={this.state.emailSentForm} onEmailSent={this.onEmailSent} />
                    </CSSTransition>
                    <div className="backImage">
                        <div>
                            <img src={require('../style/img/header-logo.png')} alt="Condor Green Logo" />
                        </div>
                    </div>
                </body>
            )
        }
        else {
            return <Redirect to={`/${this.context.user.firstname}/home`} />
        }
    }
}
export default withAlert()(Landing)