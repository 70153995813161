import React,{Component} from 'react';
import Loading from './loading';
import Cards from './cards';
import defaultIcon from '../style/img/defaultUser.png'
import axios from 'axios';

export default class assistantUser extends Component{
    constructor(){
        super();
        this.state = {
            isLoading : false,
            users:[],
            user:{}
        }
    }

    componentDidMount(){
        this.setState({
            isLoading : true
        })
        axios.get('https://localhost/api/getuser').then(response => {
            if(response.data.success){
                this.setState({
                    user : response.data.user,
                })
            }
        })

        axios.get('https://localhost/api/getusers').then(response => {
            if(response.data.success){
                this.setState({
                    users : response.data.data,
                    isLoading : false,
                })
            }
        })
    }

    render(){
        if(this.state.isLoading)
            return <Loading/>
        else{
            return(
                <div className="pageContainer">
                    <h1 id="heading">360Assistant | Users</h1>
                    <hr id="line"/>
                    <div className="structureContainer">
                         <Cards
                              whichUsers="360Users"
                              toggleCurrentUserDetails={this.currentUser}
                              toggleShowProfile={this.viewProfile}
                              users={this.state.users}
                              defaultIcon={defaultIcon}
                              state={this.state}
                              pathname="ourStructure"
                         />
                    </div>
               </div>
            )
        }
    }
}