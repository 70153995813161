import React from 'react';
import ReactDOM from 'react-dom';
import App from './layouts/App';
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider from './appcontext';
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
    timeout: 3000,
    position: positions.BOTTOM_LEFT
  };  

ReactDOM.render(
        <BrowserRouter> 
            <AppContextProvider>
            <Provider template={AlertTemplate} {...options}>
                <App /> 
            </Provider>
            </AppContextProvider>    
        </BrowserRouter>,
 document.getElementById('root'));
