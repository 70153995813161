import React from 'react';
import '../style/newstyle.css';

function ResourceManagement() {
    return (
        <div class="wrapper">
            <header class="site-header">
                <div class="container">
                    <div class="row">
                        <div class="logo">
                            <a href="/"><img src={require('../style/img/header-logo.png')} alt="Condor Green Logo" /></a>
                            <button class="mobile-toggle" onClick={() => this.onSetSidebarOpen(true)}>Menu</button>
                        </div>
                        <div class="header-right">
                            <nav class="menu-bar">
                            <ul>
                                    {/* <li><a href="#">Case Studies</a></li> */}
                                    <li><a href="#">Register/Demo</a></li>
                                    <li><a href="mailto:sales@condor360.online">Contact Sales</a></li>
                                    <li><a href="mailto:support@condor360.online">Support</a></li>
                                    <li><a href="#">Sign In <img src={require('../style/img/login-icon.png')} alt="login" /></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <section class="inner-section">
                <div class="container">
                    <h2 class="section-heading">Resource <strong>Management</strong></h2>
                    <div class="watermark-box">
                        <div class="content-wrap">
                            <p>Timesheets are a valuable tool that allows a Service to be quantified. Customers like to see what they are paying for and its more that meeting deliverables. Rich reporting allows a customers to be presented with reports and analysis that shows the value of a service being rendered.</p>
                            <p>Many users work way beyond the scope of the Service being rendered. monitoring and measuring the service allows management to compensate users that exceed their deliverables.</p>
                            <p>User "burn out" is a challenge that the IT industry faces. Reporting helps to identify conditions that could lead to "burn out", thereby enabling management to take corrective steps.</p>
                            <p>timesheet analysis tracks a Users efficiency by comparing the amount of time they work against output they produce. Timesheet analysis helps spot employees that perform poorly and cost your company money. Used in a positive manner timesheets can identify areas where training is needed.</p>
                            <p>Approve timesheets and time-off requests.</p>
                            <p>Employees should be able to request time off and managers should be able to approve or deny the request.</p>
                            <p>Many customers prefer a Service that is charged by the hour, tracking time precisely is top priority. </p>
                            <p>Condor360 can be used to track and report of Service, from a team level to a single user.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div class="sub-footer">
                <div class="row d-flex">
                    <div class="col-6">
                        <h3><span>Want</span> To Learn More ?</h3>
                        <p>The First Draft Is Written and Reviewed to Create</p>
                    </div>
                    <div class="col-4">
                        <div class="text-right">
                            <a href="#" class="default-btn prime-btn">Contact Us Today >></a>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="main-footer">
                <div class="container">
                    <div class="row d-flex">
                        <div class="col-3">
                            <div class="foot-widget f-widget-1">
                                <div class="let-talk-btn"><a href="#">LET’S TALK! <img src="images/foot-arrow.jpg" alt="" /></a></div>
                                <p>There is no better time than now</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="foot-widget  f-widget-2">
                                <h4 class="title">Address</h4>
                                <p>4th Floor, West Tower, Canal Walk, Century City, Cape Town, 7435<br></br>South Africa</p>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="foot-widget f-widget-3">
                                <h4 class="title">Contact Detail</h4>
                                <p>Phone: (+27) 21 555 2013</p>
                                <p>Email: sales@condor360.online </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copyright">
                    <div class="container">
                        <p>&copy; Copyright 2020 Condogreen Infotech | All Rghts Reserved</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default ResourceManagement