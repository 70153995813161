import React, { Component } from 'react';
import Calendar from 'react-calendar';
import '../style/calendar.scss';
import {AppContext} from '../appcontext';
export default class Cal extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        var today = new Date();
        this.state = {
            date: new Date(),
            dateforentries: today.toString().split(' ')[2] + '-' +
                this.setMonthToNum(today.toString().split(' ')[1]) + '-' +
                today.toString().split(' ')[3]
        }
    }

    onClickDay = date => {
        this.t = date.toString().split(' ')[3] + '-' + this.setMonthToNum(date.toString().split(' ')[1]) + '-' + date.toString().split(' ')[2]
        this.user = this.props.selectedUser === this.context.user ?  this.context.user : this.props.selectedUser
        this.props.onNewDate(this.t, this.user)
    }

    componentDidMount() {
    }

    setDate(date) {
        let d = new Date();
        let newDate;
        if (date === d)
            return date
        else {
            newDate = date.split('-');
            newDate = new Date(newDate[0], newDate[1]-1, newDate[2])
            return newDate
        }
    }

    setMonthToNum = (month) => {
        var monthNames = [
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct",
            "Nov", "Dec"
        ];

        for (let a = 0; a < monthNames.length; a++) {
            if (monthNames[a] === month) {
                var monthIndex = monthNames.indexOf(month) + 1
                monthIndex = monthIndex < 10 ? '0' + monthIndex : monthIndex
            }
        }

        return monthIndex
    }


    render() {
        return (
            <div className="calContainer">
                <div className="cal" onClick={(e) => {
                         if (e.target.className === "cal")
                              this.props.onClickCal()
                    }}>
                    <Calendar
                        className="calendar"
                        value={this.setDate(this.props.selectedDate)}
                        onClickDay={this.onClickDay}
                    />
                </div>
            </div>
        )
    }
}