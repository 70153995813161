import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import { AiOutlinePieChart, AiOutlineMenu } from "react-icons/ai";
import { AiOutlineTable } from "react-icons/ai";
import { AiOutlineBarChart } from "react-icons/ai";
import ColumnChart from '../../columnChart.js';
import ReportsTable from '../../reportsTable';
import PieChart, { achart } from '../../pieChart';
import { AiOutlineCheckCircle } from "react-icons/ai";

function ReportContent(props) {
    var {
        data,
        UserReportColumn,
        showpreviewcalendar,
        view,
        onViewChangeBar,
        onViewChangeTable,
        onViewChangePie,
        enabledViews,
        heading,
        id2,
        piecharttype,
        whichdata,
        togglepdf
    } = props
    const [showMenu, changeshowMenu] = useState(false)
    return (
        <CSSTransition
            in={showpreviewcalendar ? false : true}
            // in={true}
            appear={true}
            timeout={300}
            classNames="fade"
            unmountOnExit
        >
            <div className={"ContentContainer"} id={"ContentContainer"}>
                {/* COLUMN */}
                <CSSTransition
                    in={enabledViews.includes("table") ? true : false}
                    // in={true}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="subContentContainer" >
                        {/* <button onClick={() => togglepdf('table')}><AiOutlineCheckCircle style={{ color: enabledViews.includes("table") ? "lime" : "#aaa" }} /></button> */}
                        <div id="UserReportTable" id={heading + "Table"} className="UserReportTable">
                            <div className="ColumnHeadings">
                                <label>{whichdata}</label>
                                <label>Billable</label>
                                <label>Non Billable</label>
                                <label>Overtime</label>
                            </div>
                            <ReportsTable data={data} whichdata={whichdata.toLowerCase()} />
                        </div>
                    </div>
                </CSSTransition>
                {/* PIE */}
                <CSSTransition
                    in={enabledViews.includes("column") ? true : false}
                    // in={true}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="subContentContainer" >
                        <div className={id2} id={heading + "Chart"}>
                            {
                                UserReportColumn.map((userreports, i) => {
                                    return (
                                        <ColumnChart
                                            datums={userreports.length}
                                            reportObject={userreports}
                                            dataType={heading}
                                            id={heading + "Chart" + i}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </CSSTransition>
                {/* TABLE */}
                <CSSTransition
                    in={enabledViews.includes("pie") ? true : false}
                    // in={true}
                    appear={true}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="subContentContainer">
                        <PieChart data={data} id={heading + "Pie"} />
                    </div>
                </CSSTransition>
                

                {/* <div className="viewMenuContainer" >
                    <div className="GraphHeader">
                    {heading}
                    </div>
                    <div className="Previewburgermenu" data-html2canvas-ignore="true">
                        <div className="PreviewburgerIcon">
                            <button onClick={() => changeshowMenu(!showMenu)}><AiOutlineMenu title="Change view" /></button>
                        </div>
                    </div>
                    <CSSTransition
                        in={showMenu}
                        // in={true}
                        appear={true}
                        timeout={300}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div className="viewMenu">
                            <button className="addButton" onClick={onViewChangeBar}><AiOutlineBarChart title="Bar graph" /></button>
                            <button className="addButton" onClick={onViewChangeTable}><AiOutlineTable title="Table" /></button>
                            <button className="addButton" onClick={onViewChangePie}><AiOutlinePieChart title="Pie chart" /></button>
                        </div>
                    </CSSTransition>
                </div> */}
                {/* {
                    view === "column" ?
                        <div className={id2}>
                            {
                                UserReportColumn.map(userreports => {
                                    return (
                                        <ColumnChart
                                            datums={userreports.length}
                                            reportObject={userreports}
                                            dataType={heading}
                                        />
                                    )
                                })
                            }
                        </div>
                        :
                        view === "table" ?
                            <div id="UserReportTable" className="UserReportTable">
                                <div className="ColumnHeadings">
                        <label>{whichdata}</label>
                                    <label>Billable</label>
                                    <label>Non Billable</label>
                                    <label>Overtime</label>
                                </div>
                                <ReportsTable data={data} whichdata={whichdata.toLowerCase()} />
                            </div>
                            :
                            view === "pie" ?
                                <PieChart data={data} id={piecharttype} /> : null
                } */}
            </div>
        </CSSTransition>

    )
}

export default ReportContent