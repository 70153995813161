import React, { Component } from 'react';
import Chart from 'react-apexcharts'
export default class columnChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [
                {
                    name: "Billable",
                    data: this.setBillableHours()
                },
                {
                    name: "Non Billable",
                    data: this.setNonBillableHours()
                },
                {
                    name: "Overtime",
                    data: this.setOvertimeHours()
                },
                // {
                //     name: "total",
                //     data: this.setTotalHours()
                // }
            ],
            options: {
                chart: {
                    type: 'bar',
                    // height: 430,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                max: 100
                            }
                        }]
                    }
                },

                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                        // barHeight: '70%',
                    }
                },
                dataLabels: {
                    enabled: true,
                    // offsetX: -6,
                    style: {
                        // fontSize: '20px',
                        fontSize: '15px',
                        // colors: ['#fff']
                    },
                    background: {
                        enabled: true,
                        foreColor: '#333',
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 1,
                        borderColor: '#fff',
                        opacity: 0.65,
                        dropShadow: {
                            enabled: false,
                            top: 1,
                            left: 1,
                            blur: 1,
                            color: '#fff',
                            opacity: 0.45
                        }
                    },
                },
                stroke: {
                    show: true,
                    width: 1,
                    height: 40,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: this.setNames(),
                },
            },
        };
    }
    setBillableHours = () => {
        let billableHours = [];
        for (let a = 0; a < this.props.reportObject.length; a++) {
            billableHours.push(this.props.reportObject[a].billableHours);
        }
        return billableHours
    }
    setNonBillableHours = () => {
        let nonBillableHours = [];
        for (let a = 0; a < this.props.reportObject.length; a++) {
            nonBillableHours.push(this.props.reportObject[a].nonBillableHours);
        }
        return nonBillableHours
    }
    setOvertimeHours = () => {
        let overtimeHours = [];
        for (let a = 0; a < this.props.reportObject.length; a++) {
            overtimeHours.push(this.props.reportObject[a].overtimeHours);
        }
        return overtimeHours
    }
    setTotalHours = () => {
        let totalHours = [];
        for (let a = 0; a < this.props.reportObject.length; a++) {
            totalHours.push(this.props.reportObject[a].totalHours);
        }
        return totalHours
    }
    setNames = () => {
        let names = [];
        if (this.props.dataType === "Services")
            for (let a = 0; a < this.props.reportObject.length; a++)
                names.push(this.props.reportObject[a].serviceName);
        else if (this.props.dataType === "Activities")
            for (let a = 0; a < this.props.reportObject.length; a++)
                names.push(this.props.reportObject[a].activityName);

        else if (this.props.dataType === "Resources")
            for (let a = 0; a < this.props.reportObject.length; a++)
                names.push(this.props.reportObject[a].userName);
        // for (let a = 0; a < this.props.reportObject.length; a++)
        //     names.push(
        //         this.props.reportObject[a].userName[0] + ". " +
        //         this.props.reportObject[a].userName.split(" ")[1] + " " +
        //         (this.props.reportObject[a].userName.split(" ")[2] ? this.props.reportObject[a].userName.split(" ")[2] : "")
        //     );
        return names;
    }
    render() {
        return (
            <div className="chartColumn" id={this.props.id}>
                <Chart options={this.state.options} series={this.state.series} type="bar" />
            </div>
        )
    }
}