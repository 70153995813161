import React, { Component } from 'react';
import '../style/assistantManager.scss';

export default class assistantManager extends Component {
    constructor() {
        super()
        var date = new Date();
        this.state = {
            today: date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear(),
            customers: [
                {
                    name: 'Woolworths',
                    overall: {
                        complete: 10,
                        incomplete: 90
                    }
                },
                {
                    name: 'Pep',
                    overall: {
                        complete: 0,
                        incomplete: 100
                    }
                },
                {
                    name: 'BCC',
                    overall: {
                        complete: 70,
                        incomplete: 30
                    }
                },
                {
                    name: 'Checkers',
                    overall: {
                        complete: 15,
                        incomplete: 85
                    }
                },
                {
                    name: 'CrazyStore',
                    overall: {
                        complete: 50,
                        incomplete: 50
                    }
                },
                {
                    name: 'TekkieTown',
                    overall: {
                        complete: 20,
                        incomplete: 80
                    }
                },
                {
                    name: 'Sterkinekor',
                    overall: {
                        complete: 75,
                        incomplete: 25
                    }
                },
                {
                    name: 'McDonald\'s',
                    overall: {
                        complete: 80,
                        incomplete: 20
                    }
                },
                {
                    name: 'CPUT',
                    overall: {
                        complete: 40,
                        incomplete: 60
                    }
                },
                {
                    name: 'CIPC',
                    overall: {
                        complete: 50,
                        incomplete: 50
                    }
                },
                {
                    name: 'IBM',
                    overall: {
                        complete: 20,
                        incomplete: 80
                    }
                },
                {
                    name: 'Sanlam',
                    overall: {
                        complete: 40,
                        incomplete: 60
                    }
                },
                {
                    name: 'Shoprite',
                    overall: {
                        complete: 70,
                        incomplete: 30
                    }
                },
                {
                    name: 'Vodacom',
                    overall: {
                        complete: 77,
                        incomplete: 23
                    }
                },
            ],
            currentCustomer: {
                name: String,
                overall: {
                    complete: Number,
                    incomplete: Number
                }
            },
            options: {
                fill: { colors: ['#00ff55', "rgba(0,0,0,0.7)"] },
                labels: ["Complete", "Incomplete"],
                legend: {
                    labels: { colors: ["black"], position: "right" },
                    fontSize: '25px',
                    markers: {
                        radius: 0,
                        width: 60,
                        height: 30,
                        fillColors: ["#00ff33", "rgba(0,0,0,0.7)"]
                    }
                },

                tooltip: {
                    enabled: false
                },

                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '20px',
                        colors: ["rgba(0,0,0,0.7)", '#fff']
                    },
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        opacity: 0.45
                    }
                }
            },
            showAll: true,
            categories: [],
            data: [],
            dataIN: [],
        }
    }

    onShowAll = () => this.setState({ showAll: true, currentCustomer: { name: "All Customers" } });

    componentDidMount() {
        this.setState({
            currentCustomer: this.state.customers[0]
        });
        // var name;
        // var data;
        // var dataIN;
        // this.state.customers.map(customer => {
        //     name = customer.name;
        //     data = customer.overall.complete;
        //     dataIN = customer.overall.incomplete;
        //     this.state.categories.push(name);
        //     this.state.data.push(data);
        //     this.state.dataIN.push(dataIN);
        // })       
    }
    onCurrentCustomer = (cust) => {
        this.setState({
            currentCustomer: cust,
            showAll: false
        })
    }
    render() {
        var currentcust = this.onCurrentCustomer
        return (
            <div className="pageContainer">
                {/* <button className="editButton" onClick={this.onShowAll}></button> */}
                <h1 id="heading">360Assistant | Customers</h1>
                <hr id="line" />
                {/* <CustomerStats categories={this.state.categories} data={this.state.data} dataIN={this.state.dataIN}/> */}
                <div className="assistantManagerContainer">

                    <div className="customers">
                        {
                            this.state.customers.map(function (customer, key) {
                                return (
                                    <button className="customer" type="button" onClick={() => currentcust(customer)}>
                                        <h1>{customer.name}</h1>
                                    </button>
                                )
                            })
                        }
                    </div>
                    <div className="stats">
                        <h1>{this.state.currentCustomer.name}</h1>
                        {/* <div className="dataCharts">
                        
                                <div className="donutChart">
                                    <Chart
                                        options={this.state.options}
                                        series={[this.state.currentCustomer.overall.complete,this.state.currentCustomer.overall.incomplete]}    
                                        type="donut"
                                        width="98%" 
                                        height="80%" 
                                        />
                
                                </div>
                                </div> */}
                        <div className="report">
                            <div className="options">
                                <button type="button">
                                    {this.state.today}
                                </button>
                                <button type="button">
                                    {this.state.today}
                                </button>
                                <select>
                                    <option>Carl</option>
                                    <option>Caydon</option>
                                    <option>Coffee</option>
                                </select>
                                <select>
                                    <option>Activity1</option>
                                    <option>Activity2</option>
                                    <option>Activity3</option>
                                </select>
                                <select>
                                    <option>Service1</option>
                                    <option>Service2</option>
                                    <option>Service3</option>
                                </select>
                            </div>
                            <div className="generated-reports">
                                <div className="subcontainer">
                                    <div className="timeLine">
                                        <div className="timeLineEntry">
                                            <div className="timeLineItem">
                                                <p className="time"></p>
                                                <div className="content">
                                                    <div className="title"></div>
                                                    <p></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
