import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../style/img/logo.png';
import Logo1 from '../style/img/logo1.png';
import '../style/nav.scss';
import { withAlert } from 'react-alert';
import { AppContext } from '../appcontext';
import * as  Cookies from 'js-cookie';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Sidebar from "react-sidebar";
import '../style/sidebar.scss';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoIosJournal } from 'react-icons/io';
import { GoReport } from 'react-icons/go';
import { BsBuilding } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { RiProfileLine } from 'react-icons/ri';
import { GrServices } from 'react-icons/gr';
import { AiOutlineLogout } from 'react-icons/ai';
import 'react-dropdown/style.css';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment'
import { IoIosBusiness } from 'react-icons/io';
import Loading from './loading';

const qs = require('query-string');

class Navbar extends Component {
     static contextType = AppContext;
     constructor(props) {
          super(props)

          this.state = {
               data: this.props.data,
               type: '',
               useSidepanel: false,
               sidebarOpen: false,
               profileCard: false,
               onshowReportmenu: false,
               isLoading: false,
               customers: [],
               services: [],
               activities: [],
               genReport: false,
               loading: false
          }
     }
     escFunctionProfile = (event) => {
          if (event.keyCode === 27) {
               this.setState({
                    profileCard: false

               })
          }
     }
     handProfileCard = () => { this.setState({ profileCard: !this.state.profileCard }) }


     handleResize = () => {
          if (window.innerWidth < 1000)
               this.setState({
                    useSidepanel: true
               })
          else
               this.setState({
                    useSidepanel: false,
                    sidebarOpen: false
               })
     }
     componentWillUnmount() {
          document.removeEventListener("keydown", this.escFunctionProfile, false);
     }

     componentDidMount() {
          this.setState({
               isLoading: true
          })
          this.handleResize()
          document.addEventListener("keydown", this.escFunctionProfile, false);
          window.addEventListener('resize', this.handleResize)
          if (this.context.user.usertype === 1) {
               this.setState({
                    type: "User"
               })
          }
          else if (this.context.user.usertype === 2) {
               this.setState({
                    type: "Manager"
               })
          }
          else if (this.context.user.usertype === 3) {
               this.setState({
                    type: "Admin"
               })
          }
          else if (this.context.user.usertype === 4) {
               this.setState({
                    type: "System Administrator"
               })
          }
          else {
               this.setState({
                    type: ""
               })
          }
          let data = {
               resourceId: this.context.user._id
          }
          this.context.sendrequest('getcustomer/resourcecustomers', data, (boolean) => this.setState({ showChooseLoading: boolean }), this.props.alert.error)
               .then(async res => {
                    if (res) {
                         if (res.data.success) {
                              this.setState({
                                   customers: res.data.data

                              })
                              await this.context.sendrequest('getservices', null, (boolean) => this.setState({ showChooseLoading: boolean }), this.props.alert.error)
                                   .then(async res => {
                                        if (res)
                                             if (res.data.success) {
                                                  this.setState({
                                                       services: res.data.data
                                                  })
                                                  await this.context.sendrequest('getactivities', null, (boolean) => this.setState({ showChooseLoading: boolean }), this.props.alert.error)
                                                       .then(res => {
                                                            if (res)
                                                                 if (res.data.success) {
                                                                      this.setState({
                                                                           activities: res.data.data
                                                                      })
                                                                 }
                                                       })
                                             }
                                   })
                         }
                    }
               })

     }

     onLogout = async () => {
          var data = qs.stringify({
               token: Cookies.get('token')
          })
          await axios.post(`${process.env.REACT_APP_BASE_URL}/logout`, data).then(response => {
               if (response.data.success) {
                    Cookies.remove('token');
                    // var report = localStorage.getItem("report")
                    this.context.logout();
                    localStorage.clear();

                    const alert = this.props.alert;
                    alert.success(response.data.message)
                    return <Redirect to="/" />
               }
          }).catch(e => {
               if (e.response) {
                    this.props.alert.error("Something went wrong. Please try again")
               }
               else if (e.request) {
                    this.props.alert.error("The server is down. Please try again shortly")
               }
               else {
                    this.props.alert.error("Something went wrong. Please try again or contact support")
               }
          })
     }

     onSetSidebarOpen = (open) => {
          this.setState({ sidebarOpen: open });
     }

     LinkClick = () => {
          this.setState({
               sidebarOpen: false
          })
     }

     SideBarNav = () => {
          var {
               firstname,
               lastname,
               email,
               image,
               lastSeen
          } = this.context.user
          return (
               <div className="SidebarContainer">
                    <div className="sidebarprofile" onClick={() => this.LinkClick}>
                         <div className="sidebarimage">
                              <img src={image} alt="LoggedImage" />
                         </div>
                         <div className="sidebarusername">
                              <p>
                                   {firstname} {lastname} | {this.state.type}
                              </p>
                              <p>
                                   {email}
                              </p>
                         </div>
                    </div>
                    <div className="sidebarnav">
                         {
                              this.context.user.usertype > 1 ?
                                   <ul>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/home`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <IoIosJournal />
                                             </div>
                                             <p>
                                                  Home
                                   </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/reports`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <GoReport />
                                             </div>
                                             <p>
                                                  Reports
                                   </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/customers`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <BsBuilding />
                                             </div>
                                             <p>
                                                  Customers
                                   </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/users`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <FiUsers />
                                             </div>
                                             <p>
                                                  Users
                                   </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/myprofile`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <RiProfileLine />
                                             </div>
                                             <p>
                                                  Profile
                                   </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/settings`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <GrServices />
                                             </div>
                                             <p>
                                                  Services
                                   </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} onClick={this.onLogout} to='/'><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <AiOutlineLogout />
                                             </div>
                                             <p>
                                                  Signout
                                   </p>
                                        </li></NavLink>
                                   </ul>
                                   :
                                   <ul>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/home`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <IoIosJournal />
                                             </div>
                                             <p>
                                                  Home
                              </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/users`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <FiUsers />
                                             </div>
                                             <p>
                                                  Users
                              </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} to={`/${firstname}/myprofile`}><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <RiProfileLine />
                                             </div>
                                             <p>
                                                  Profile
                              </p>
                                        </li></NavLink>
                                        <NavLink style={{ textDecoration: "none" }} onClick={this.onLogout} to='/'><li onClick={() => this.LinkClick()}>
                                             <div className="navIcon">
                                                  <AiOutlineLogout />
                                             </div>
                                             <p>
                                                  Signout
                              </p>
                                        </li></NavLink>
                                   </ul>
                         }
                    </div>
                    <div className="Poweredby">
                         Powered by Condorgreen Infotech
                         </div>
               </div>
          )
     }
     getCustomer = async (id) => {
          let customer = {};
          for (let a = 0; a < this.state.customers.length; a++) {
               if (this.state.customers[a]._id === id) {
                    customer = this.state.customers[a];
               }
          }
          return customer;
     }
     onGenerateReport = async (id, customer) => {
          this.setState({
               loading: true
          })
          let customerId = [id];
          let userId
          if (this.context.user.usertype > 1)
               userId = customer.resources
          else
               userId = [this.context.user._id];
          let serviceId = [];
          let activityId = [];

          for (let a = 0; a < this.state.services.length; a++)
               serviceId.push(this.state.services[a]._id)

          for (let a = 0; a < this.state.activities.length; a++)
               activityId.push(this.state.activities[a]._id)

          let startdate = moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD")
          let enddate = moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD")
          var data = {
               dates: JSON.stringify({
                    startdate: startdate,
                    enddate: enddate,
               }),
               customerIds: customerId,
               serviceIds: serviceId,
               activityIds: activityId,
               userIds: userId,
          }

          await this.context.sendrequest('generatereport', data, null, this.props.alert.error)
               .then(async response => {
                    if (response)
                         if (response.data.success && response.data.data.customerReport.length > 0) {
                              var data = [];
                              await response.data.data.customerReport.map(async customer => {
                                   data.push(await this.getCustomer(customer.customerId))
                              })

                              this.setState({
                                   reportCustomerObject: response.data.data.customerReport,
                                   reportUserObject: response.data.data.userReport,
                                   reportServiceObject: response.data.data.serviceEntries,
                                   reportActivityObject: response.data.data.activityEntries,
                                   reportEntriesObject: response.data.data.entries,
                                   reportCustomerFullObject: data
                              })
                              this.context.setCustomerReport(this.state.reportCustomerObject);
                              this.context.setServiceReport(this.state.reportServiceObject);
                              this.context.setActivityReport(this.state.reportActivityObject);
                              this.context.setUserReport(this.state.reportUserObject);
                              this.context.setEntriesReport(this.state.reportEntriesObject);
                              this.context.setCustomerObject(this.state.reportCustomerFullObject);
                              this.context.setDates(startdate, enddate)

                              // window.location.href = `/${this.context.user.firstname}/reports/preview`
                              this.setState({
                                   gotopreview: true,
                                   loading: false
                              })

                         }
                         else {
                              this.setState({
                                   gotopreview: false,
                                   loading: false
                              })
                         }
                    else {
                         this.setState({
                              gotopreview: false,
                              loading: false
                         })
                    }
               })
     }

     render() {
          var { user } = this.context;
          if (this.state.gotopreview) {
               this.setState({
                    gotopreview: false,
                    profileCard: false
               })
               return <Redirect to={{
                    pathname: `/${this.context.user.firstname}/reports/preview`,
                    reportCustomerFullObject: this.state.reportCustomerFullObject,
                    reportCustomerObject: this.state.reportCustomerObject,
                    reportUserObject: this.state.reportUserObject,
                    reportServiceObject: this.state.reportServiceObject,
                    reportActivityObject: this.state.reportActivityObject,
                    reportEntriesObject: this.state.reportEntriesObject,
               }} />
          }
          else if (this.state.loading)
               return <Loading />
          else
               return (
                    <>
                         <div className="navContainer">
                              <NavLink to={`/${user.firstname}/home`}>
                                   <img src={Logo} id="bird" alt="Condorgreen" />
                                   <img id="nameBrand" src={Logo1} alt="Condorgreen" />
                              </NavLink>
                              {
                                   this.state.useSidepanel ?
                                        null
                                        :
                                        <div className="user-info">
                                             <div className="info">
                                                  <h4>{user.firstname + " "}{user.lastname} </h4>
                                             </div>
                                             {/* <NavLink style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/${this.context.user.firstname}/myprofile`}> */}
                                             <div className="userImage" onClick={this.handProfileCard}>
                                                  {
                                                       this.context.user.image ?
                                                            <img title={this.context.user.firstname} src={this.context.user.image} alt={this.context.user.email} />
                                                            :
                                                            <Avatar title={this.context.user.firstname}><p>{this.context.user.firstname.charAt(0).toUpperCase()}</p></Avatar>
                                                  }
                                             </div>
                                             {/* </NavLink> */}
                                        </div>
                              }
                              {
                                   this.state.profileCard ?
                                        <div className="UserProfileForm" onClick={(e) => {
                                             if (e.target.className === "UserProfileForm")
                                                  this.handProfileCard()
                                        }}>
                                             <form>
                                                  <div className="profileCardContainer">
                                                       <div className="forImage">
                                                            {
                                                                 this.context.user.image ?
                                                                      <img title={this.context.user.firstname} src={this.context.user.image} alt={this.context.user.email} />
                                                                      :
                                                                      <Avatar title={this.context.user.firstname}><p>{this.context.user.firstname.charAt(0).toUpperCase()}</p></Avatar>
                                                            }
                                                       </div>
                                                       <div className="forName">
                                                            <h2>{this.context.user.firstname + " "}{this.context.user.lastname}</h2>
                                                            <h3>{this.context.user.email}</h3>
                                                            <h3>Last activity on {user.lastSeen.date} @ {user.lastSeen.hour}</h3>
                                                            <hr />
                                                       </div>
                                                  </div>
                                                  <div className="profileAndSignout">
                                                       <div className="toProfile" style={{ marginTop: "5px" }} onClick={event => window.location.href = `/${this.context.user.firstname}/myprofile`}>
                                                            <div className="forIcon"><RiProfileLine /></div>
                                                            <div className="forHeading"><h3>Profile</h3></div>
                                                       </div>
                                                       <div className="toProfile" style={{ marginTop: "5px" }} onClick={() => this.setState({ onshowReportmenu: !this.state.onshowReportmenu })}>
                                                            <div className="forIcon"><GoReport /></div>
                                                            <div className="forHeading"><h3>Quick Report ({moment().subtract(1, 'month').format("MMMM YYYY")})</h3></div>
                                                       </div>
                                                       <CSSTransition
                                                            in={this.state.onshowReportmenu}
                                                            appear={true}
                                                            timeout={300}
                                                            classNames="fade"
                                                            unmountOnExit
                                                       >
                                                            <>
                                                                 {
                                                                      this.state.customers.map(customer => {
                                                                           return (
                                                                                <div className="toProfile" style={{ marginLeft: "40px", marginTop: "5px" }} onClick={() => this.onGenerateReport(customer._id, customer)}>
                                                                                     <img className="forIcon" src={customer.logo} alt={customer.customername} />
                                                                                     <div className="cusName"><h3 >{customer.customername}</h3></div>
                                                                                </div>
                                                                           )
                                                                      })
                                                                 }
                                                            </>
                                                       </CSSTransition>

                                                       <div className="toProfile" style={{ marginTop: "5px" }} onClick={this.onLogout}>
                                                            <div className="forIcon"><AiOutlineLogout /></div>
                                                            <div className="forHeading"><h3 >Signout</h3></div>
                                                       </div>

                                                  </div>
                                             </form>
                                        </div> : null
                              }
                         </div>
                         {
                              this.state.useSidepanel ?
                                   <Sidebar
                                        sidebar={<this.SideBarNav />}
                                        open={this.state.sidebarOpen}
                                        onSetOpen={this.onSetSidebarOpen}
                                        pullRight={true}
                                        styles={{ sidebar: { position: "fixed" } }}
                                   >
                                        <button className="sideBarButton" onClick={() => this.onSetSidebarOpen(true)}>
                                             <GiHamburgerMenu />
                                        </button>
                                   </Sidebar>
                                   :
                                   <div className="navBar">
                                        {
                                             user.usertype === 1 ?//Users
                                                  <ul>
                                                       <NavLink to={`/${user.firstname}/home`}><li>Home</li></NavLink>
                                                       <NavLink to={`/${user.firstname}/reports`}><li>Reports</li></NavLink>
                                                       {/* <NavLink to={`/${user.firstname}/360assistant/myassistant`}><li>360Assistant</li></NavLink> */}
                                                       <NavLink to={`/${user.firstname}/users`}><li>Users</li></NavLink>
                                                       {/* <NavLink to={`/${user.firstname}/myprofile`}><li>Profile</li></NavLink> */}
                                                       <NavLink onClick={this.onLogout} to='/'><li>Signout</li></NavLink>
                                                  </ul> : null
                                        }

                                        {
                                             user.usertype === 2 ?
                                                  <ul>
                                                       <NavLink to={`/${user.firstname}/home`}><li>Home</li></NavLink>
                                                       {/* <NavLink to={`/${user.firstname}/360assistant`}><li>360Assistant</li></NavLink> */}
                                                       <NavLink to={`/${user.firstname}/reports`}><li>Reports</li></NavLink>
                                                       <NavLink to={`/${user.firstname}/customers`}><li>Customers</li></NavLink>
                                                       <NavLink to={`/${user.firstname}/users`}><li>Users</li></NavLink>
                                                       {/* <NavLink to={`/${user.firstname}/myprofile`}><li>Profile</li></NavLink> */}
                                                       <NavLink to={`/${user.firstname}/settings`}><li>Services</li></NavLink>
                                                       <NavLink onClick={this.onLogout} to='/'><li>Signout</li></NavLink>
                                                  </ul> : null
                                        }

                                        {
                                             user.usertype === 3 ?//Admin
                                                  <ul>
                                                       <NavLink to={`/${user.firstname}/home`}><li>Home</li></NavLink>
                                                       {/* <NavLink to={`/${user.firstname}/360assistant`}><li>360Assistant</li></NavLink> */}
                                                       <NavLink to={`/${user.firstname}/reports`}><li>Reports</li></NavLink>
                                                       <NavLink to={`/${user.firstname}/customers`}><li>Customers</li></NavLink>
                                                       <NavLink to={`/${user.firstname}/users`}><li>Users</li></NavLink>
                                                       {/* <NavLink to={`/${user.firstname}/myprofile`}><li>Profile</li></NavLink> */}
                                                       <NavLink to={`/${user.firstname}/settings`}><li>Services</li></NavLink>
                                                       <NavLink onClick={this.onLogout} to='/'><li>Signout</li></NavLink>
                                                  </ul> : null
                                        }

                                        {
                                             user.usertype === 4 ?//SysAdmin
                                                  <ul>
                                                       <NavLink to={`/${user.firstname}/home`}><li>Home</li></NavLink>
                                                       {/* <NavLink to={`/${user.firstname}/360assistant`}><li>360Assistant</li></NavLink> */}
                                                       <NavLink to={`/${user.firstname}/customers`}><li>Customers</li></NavLink>
                                                       <NavLink to={`/${user.firstname}/users`}><li>Users</li></NavLink>
                                                       {/* <NavLink to={`/${user.firstname}/myprofile`}><li>Profile</li></NavLink> */}
                                                       <NavLink to={`/${user.firstname}/settings`}><li>Services</li></NavLink>
                                                       <NavLink onClick={this.onLogout} to="/"><li>Signout</li></NavLink>
                                                  </ul> : null
                                        }

                                        {
                                             user.usertype === '' ?//Login
                                                  <ul>
                                                       <NavLink to='/'><li>Back</li></NavLink>
                                                  </ul> : null
                                        }

                                   </div>
                         }
                    </>
               )
     }
}
export default withAlert()(Navbar);
