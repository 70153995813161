import React, { Component } from 'react';
import Trash from '../style/img/trash.png';
import ImageUploader from "react-images-upload";
import "../style/profile.scss";
import Loading from '../components/loading';
import axios from 'axios';
import { AppContext } from '../appcontext';
import "@kenshooui/react-multi-select/dist/style.css"
import * as  Cookies from 'js-cookie';
import defaultImage from '../style/img/default-user-image.jpeg';
import { FiEdit3 } from "react-icons/fi";
import { FiPhoneCall } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { FiMapPin } from "react-icons/fi";
import { FiArrowLeft } from 'react-icons/fi';
import { Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withAlert } from 'react-alert';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import SkillCards from '../components/skillcards';
import { IoIosArrowBack } from "react-icons/io";


const qs = require('query-string');

class ContactProfile extends Component {
     static contextType = AppContext;
     constructor(props) {
          super(props);
          this.state = {
               isLoading: true,
               user: {},
               showUpdateForm: false,
               picturesChosen: 0,
               updated: {},
               editemail: false,
               oldemail: "",
               showEditButton: false,
               position: "",
               email: "",
               firstname: "",
               lastname: "",
               bio: "",
               comments: "",
               image: "",
               keyperson: false,
               landline: "",
               mobile: "",
               fax: "",
               _id: "",
          }
          this.profileImage = [];
     }
     onActiveChange = (value) => this.setState({ keyperson: value === "true" ? true : false })
     onDrop = (pictureFiles, pictureDataURLs) => {
          this.setState({
               picturesChosen: 1
          })
          this.profileImage = pictureDataURLs
          if (this.profileImage.length > 1) {
               this.profileImage.shift()
          }

     }
     escFunctionForm = (event) => {
          if (event.keyCode === 27) {
               this.setState({
                    showUpdateForm: false
               })
          }
     }
     componentWillUnmount() {
          document.removeEventListener("keydown", this.escFunctionForm, false);
     }
     componentDidMount() {
          document.addEventListener("keydown", this.escFunctionForm, false);
          this.setState({
               isLoading: true,
          })

          var LS = localStorage.getItem("previous-selected-contact");
          LS = JSON.parse(LS)
          var prevprops = {
               employee: this.props.employee ? this.props.employee : LS.employee,
               fromUsers: LS ? LS.fromUsers : this.props.fromUsers
          }
          // localStorage.setItem("previous-selected-contact", JSON.stringify(prevprops));
          if (prevprops.employee) {
               this.previousprofile = prevprops.employee;
               this.previousprofile.skills = prevprops.employee.skills ? prevprops.employee.skills.length > 0 ? prevprops.employee.skills : [] : []
               this.profileImage[0] = prevprops.employee.image;
               prevprops.employee.keyperson = typeof prevprops.employee.keyperson === "string" ? prevprops.employee.keyperson === "true" ? true : false : prevprops.employee.keyperson;
               this.setState({
                    position: prevprops.employee.position,
                    email: prevprops.employee.email,
                    firstname: prevprops.employee.firstname,
                    comments: prevprops.employee.comments,
                    lastname: prevprops.employee.lastname,
                    bio: prevprops.employee.bio,
                    image: prevprops.employee.image,
                    landline: prevprops.employee.landline,
                    mobile: prevprops.employee.mobile,
                    fax: prevprops.employee.fax,
                    keyperson: prevprops.employee.keyperson,
                    _id: prevprops.employee._id,
                    fromUsers: prevprops.fromUsers,
                    showEditButton: prevprops.employee.email === this.context.user.email || this.context.user.usertype > 1 ? true : false,
                    picturesChosen: prevprops.employee.image ? 1 : 0,
                    isLoading: false,
               })
               prevprops = {
                    employee: {
                         position: prevprops.employee.position,
                         email: prevprops.employee.email,
                         firstname: prevprops.employee.firstname,
                         lastname: prevprops.employee.lastname,
                         bio: prevprops.employee.bio,
                         image: prevprops.employee.image,
                         comments: prevprops.employee.comments,
                         landline: prevprops.employee.landline,
                         mobile: prevprops.employee.mobile,
                         fax: prevprops.employee.fax,
                         keyperson: prevprops.employee.keyperson,
                         _id: prevprops.employee._id,
                    }
               }
               localStorage.setItem("previous-selected-contact", JSON.stringify(prevprops));
          }
     }


     getUsertype = (usertype) => {
          if (usertype === 1)
               return "User"
          else if (usertype === 2)
               return "Manager"
          else if (usertype === 3)
               return "Administrator"
          else if (usertype === 4)
               return "System Administrator"
          else
               return "User"
     }

     onBioChange = (e) => {
          this.setState({
               bio: e.target.value
          })
     }
     onCommentsChange = e => this.setState({ comments: e.target.value })

     onFaxChange = (e) => {
          this.setState({
               fax: e.target.value
          })
     }
     onEmailChange = (e) => {
          this.setState({
               email: e.target.value,
               editemail: true,
               oldemail: this.state.email,
          })
     }
     onMobileChangePrimary = (e) => {
          this.setState({
               mobile: e.target.value
          })
     }
     onMobileChangeSecondary = (e) => {
          this.setState({
               mobile: [this.state.mobile[0], e.target.value,],
          })
     }
     onSkillsChange = (e) => {
          let newskills = e.target.value.trim().split(',');
          this.setState({
               skills: newskills
          })
     }
     onLandlineChange = (e) => {
          this.setState({
               landline: e.target.value,
          })
     }
     onLastNameChange = (e) => {
          this.setState({
               lastname: e.target.value,
          })
     }
     onPositionChange = (e) => {
          this.setState({
               position: e.target.value,
          })
     }

     onKeypersonChange = (e) => this.setState({ keyperson: e.target.value })
     onFirstNameChange = (e) => {
          this.setState({
               firstname: e.target.value,
          })
     }
     onHomeaddressChange = (e) => {
          this.setState({
               homeaddress: e.target.value,
          })
     }
     onCurrentPassword = (e) => {
          this.setState({
               oldPassword: e.target.value,
          })
     }
     onChangePasswordConfirm = (e) => {
          this.setState({
               confirmPassword: e.target.value,
          })
     }
     onNewPassword = (e) => {
          this.setState({
               newPassword: e.target.value,
          })
     }
     setRedirect = () => {
          if (this.state.Heading === "Profile") {
               this.toggleShowDetails()
          }
          else {
               this.setState({
                    redirect: true
               })
          }
     }
     renderRedirect = () => {
          if (this.state.redirect) {
               return <Redirect to={`/${this.context.user.firstname}/users`} />
          }
     }

     notification(message) {
          const alert = this.props.alert
          alert.success(message)
     }

     error(message) {
          const alert = this.props.alert
          alert.error(message)
     }
     validateEmail(email) {
          const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(email)
     }
     onUpdateUser = async () => {
          if (this.state.firstname.trim() === "")
               this.error("First name required");
          else if (this.state.lastname.trim() === "")
               this.error("Last name required");
          else if (this.state.email.trim() === "")
               this.error("Email required")
          else if(!this.validateEmail(this.state.email))
               this.error("Email format incorrect")
          else {
               this.state.isLoading = true
               this.setState({
                    isLoading: true
               })
               var data = {
                    bio: this.state.bio,
                    fax: this.state.fax,
                    email: this.state.email,
                    mobile: this.state.mobile,
                    position: this.state.position,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    image: this.state.picturesChosen === 1 ? this.profileImage[0] : "",
                    keyperson: this.state.keyperson,
                    comments: this.state.comments,
                    landline: this.state.landline,
                    _id: this.state._id
               }
               await this.context.sendrequest('updatecontact', data)
               .then(async response => {
                    if (response.data.success) {
                         this.setState({
                              showUpdateForm: false,
                              picturesChosen: response.data.data.image > "" ? 1 : 0,
                              position: response.data.data.position,
                              email: response.data.data.email,
                              firstname: response.data.data.firstname,
                              lastname: response.data.data.lastname,
                              bio: response.data.data.bio,
                              comments: response.data.data.comments,
                              image: response.data.data.image,
                              landline: response.data.data.landline,
                              mobile: response.data.data.mobile,
                              fax: response.data.data.fax,
                              keyperson: response.data.data.keyperson,
                              _id: response.data.data._id,
                              picturesChosen: this.previousprofile.image > "" ? 1 : 0,
                         })
                         var prevprops = {
                              employee: {
                                   position: response.data.data.position,
                                   email: response.data.data.email,
                                   firstname: response.data.data.firstname,
                                   lastname: response.data.data.lastname,
                                   bio: response.data.data.bio,
                                   image: response.data.data.image,
                                   landline: response.data.data.landline,
                                   comments: response.data.data.comments,
                                   mobile: response.data.data.mobile,
                                   keyperson: response.data.data.keyperson,
                                   fax: response.data.data.fax,
                                   _id: response.data.data._id,
                              },
                              fromUsers: localStorage.getItem("previous-selected-contact") ? JSON.parse(localStorage.getItem("previous-selected-contact")).fromUsers : false
                         }
                         this.previousprofile = {
                              position: response.data.data.position,
                              email: response.data.data.email,
                              firstname: response.data.data.firstname,
                              lastname: response.data.data.lastname,
                              bio: response.data.data.bio,
                              image: response.data.data.image,
                              landline: response.data.data.landline,
                              comments: response.data.data.comments,
                              mobile: response.data.data.mobile,
                              fax: response.data.data.fax,
                              keyperson: response.data.data.keyperson,
                              _id: response.data.data._id,
                         }
                         this.profileImage[0] = response.data.data.image;
                         localStorage.removeItem("previous-selected-contact")
                         localStorage.setItem("previous-selected-contact", JSON.stringify(prevprops));
                         this.setState({
                              isLoading: false,
                         })
                         this.notification(response.data.message)
                    }

                    else {
                         this.setState({
                              showUpdateForm: true,
                         })
                         this.error(response.data.message)
                    }
               })
                    .catch(
                         this.setState({
                              isLoading: false
                         })
                    )
          }
          this.setState({
               isLoading: false
          })
     }

     mapSkills() {
          if (this.previousprofile.skill) {
               if (typeof this.previousprofile.skills === "string") {
                    return this.previousprofile.skills
               }
               else {
                    return this.previousprofile.skills.map(skill => {
                         return " " + skill + " "
                    })
               }
          }
          else {
               return ""
          }
     }

     render() {
          if (this.state.isLoading) {
               return <Loading />
          }
          else {
               return (
                    <div className="pageContainer">
                         {/* {
                              this.state.showEditButton ?
                                   <button className="addButton" onClick={() => this.setState({ showUpdateForm: true })} title="Edit profile">
                                        <FiEdit3 />
                                   </button>
                                   : null
                         } */}
                      <h1 id="heading">
                              {/* {
                                   this.previousprofile.email !== this.context.user.email ?
                                        <button className="backButton" onClick={event => window.location.href = `/${this.context.user.firstname}/customers`}>
                                             <IoIosArrowBack title="Back"/>
                                        </button> : null
                              } */}
                               Profile | {this.previousprofile.firstname} {this.previousprofile.lastname}
                         </h1>
                         <hr id="line" />
                         <CSSTransition
                              in={true}
                              appear={true}
                              timeout={300}
                              classNames="fade"
                              unmountOnExit
                         >
                              <div className="popUpWindows">
                                   <section>
                                        {/* <div className="updateFormHeader">
                                             <p>Update contact<button className="CloseCus" onClick={() => this.setState({ showUpdateForm: false })}>X</button></p>
                                        </div> */}
                                        <div className="updateFormContent">
                                             <form className="updateformContainer">
                                                  <input type="text" placeholder="First Name" value={this.state.firstname} onChange={this.onFirstNameChange} />
                                                  <input type="text" placeholder="Last Name" value={this.state.lastname} onChange={this.onLastNameChange} />
                                                  <input type="text" placeholder="Position" value={this.state.position} onChange={this.onPositionChange} />
                                                  {/* <input type="text" placeholder="Address" value={this.state.homeaddress} onChange={this.onHomeaddressChange} /> */}
                                                  {/* <input type="text" placeholder="Key person" value={this.state.keyperson} onChange={this.onKeypersonChange} /> */}
                                                  <input type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} />
                                                  <input type="text" placeholder="Landline" value={this.state.landline} onChange={this.onLandlineChange} />
                                                  <input type="text" placeholder="Mobile" value={this.state.mobile} onChange={this.onMobileChangePrimary} />
                                                  <input type="text" placeholder="Fax" value={this.state.fax} onChange={this.onFaxChange} />
                                                  <div className="userTypeOptions">
                                                       <RadioGroup onChange={this.onActiveChange} value={this.state.keyperson + ""} horizontal>
                                                            <RadioButton value={"true"} pointColor="#00dd21">
                                                                 Key person
                                                       </RadioButton>
                                                            <RadioButton value={"false"} pointColor="red">
                                                                 Non key person
                                                       </RadioButton>
                                                       </RadioGroup>
                                                  </div>

                                             </form>
                                             <div className="updateFormProfile">
                                                  <section className="sectionUpdateContact">
                                                       <textarea className="contactBio" placeholder="Bio" value={this.state.bio} onChange={this.onBioChange}></textarea>
                                                       <textarea className="contactBio" placeholder="Comments" value={this.state.comments} onChange={this.onCommentsChange}></textarea>
                                                       {/* <div className="skillContainer">
                                                            <SkillCards skills={this.state.skills} onChangeSkills={(skills) => this.setState({ skills: skills })} />
                                                       </div> */}
                                                  </section>
                                                  <div className="rightside">
                                                       <div className="UploadImage">
                                                            {
                                                                 this.state.picturesChosen === 0 ?
                                                                      <ImageUploader
                                                                           withIcon={false}
                                                                           withPreview={false}
                                                                           label=""
                                                                           buttonText="Add Image"
                                                                           onChange={this.onDrop}
                                                                           imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg", ".jpeg"]}
                                                                           maxFileSize={1048576}
                                                                           fileSizeError=" file size is too big"
                                                                           className="ImageUploader"
                                                                           singleImage={true}
                                                                      />
                                                                      :
                                                                      <div className="logo" >
                                                                           <div className="remove">
                                                                                <img src={Trash} alt="trash" onClick={() => this.setState({ picturesChosen: 0 })} />
                                                                           </div>
                                                                           <img src={this.profileImage[0]} alt="cutsomer" />
                                                                      </div>
                                                            }
                                                       </div>

                                                  </div>
                                             </div>
                                        </div>
                                        <div className="SubmitContainer">
                                             <button className="CustomerSubmit" type="button" onClick={this.onUpdateUser}>Submit</button>
                                        </div>
                                   </section>
                              </div>
                         </CSSTransition>
                       
                       
                    </div>
               );
          }
     }
}

export default withAlert()(ContactProfile);
