import React, { Component } from 'react';
import { AppContext } from '../appcontext'
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import axios from 'axios';
import defaultLogo from '../style/img/default-company-logo.png';
import { MdDone } from 'react-icons/md';
import { FcCancel } from 'react-icons/fc';
import { BsPencilSquare } from 'react-icons/bs';
import { withAlert } from 'react-alert';


const qs = require('query-string');
class CompanyCard extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            enablequickedit: false,
            _id: "",
            customername: "",
            description: "",
            building: "",
            area: "",
            street: "",
            region: "",
            code: "",
            country: "",
            industry: "",
            active: "",
            logo: "",
            contacts: "",
            resources: "",
        }
    }

    customernamechange = e => this.setState({ customername: e.target.value });
    areachange = e => this.setState({ area: e.target.value })
    descriptionchange = e => this.setState({ description: e.target.value });
    streetchange = e => this.setState({ street: e.target.value })
    buildingchange = e => this.setState({ building: e.target.value })
    regionchange = e => this.setState({ region: e.target.value })
    countrychange = e => this.setState({ country: e.target.value })
    codechange = e => this.setState({ code: e.target.value })



    QuickEdit = (company) => {
        this.setState({
            enablequickedit: true,
            customername: company.customername,
            description: company.description,
            building: company.building,
            area: company.area,
            street: company.street,
            region: company.region,
            code: company.code,
            country: company.country,
            industry: company.industry,
            active: company.active,
            logo: company.logo,
            _id: company._id,
            contacts: company.contacts.length > 0 ? company.contacts : [],
            resources: company.resources
        }, () => {
            var element = document.getElementById("companyName");
            element.focus()
        })
    }

    cancelEdit = () => {
        this.setState({
            enablequickedit: false,
            _id: "",
            customername: "",
            description: "",
            building: "",
            area: "",
            street: "",
            region: "",
            code: "",
            country: "",
            industry: "",
            active: "",
            logo: "",
            contacts: "",
            resources: "",
        })
    }

    updatecustomer = async () => {
        var data = {
            customername: this.state.customername,
            description: this.state.description,
            building: this.state.building,
            area: this.state.area,
            street: this.state.street,
            region: this.state.region,
            code: this.state.code,
            country: this.state.country,
            industry: this.state.industry,
            active: this.state.active,
            logo: this.state.logo,
            _id: this.state._id,
            contacts: this.state.contacts,
            resources: this.state.resources,
        }
        this.context.sendrequest('updatecustomer', data, (boolean) => this.setState({ isLoading: boolean }))
            .then(res => {
                if (res.data.success) {
                    this.props.getcustomers()
                    this.cancelEdit()
                }
            })
    }


    componentDidMount() {
        this.setState(this.props.state)
        this.setState({
            enablequickedit: false,
        })
    }
    handleChange = checked => this.setState({ checked })
    render() {
        // var state = this.props.state;
        var currentUser = this.props.toggleCurrentUserDetails;
        var QuickEdit = this.QuickEdit
        var {
            enablequickedit,
            _id,
            customername,
            description,
            building,
            area,
            street,
            region,
            code,
            country,
            industry,
            active,
            logo,
            contacts,
            resources,
        } = this.state
        var sr = this.context.sendrequest
        let ctx = this.context
        var st = this.state;
        var customernamechange = this.customernamechange
        var areachange = this.areachange
        var descriptionchange = this.descriptionchange
        var streetchange = this.streetchange
        var buildingchange = this.buildingchange
        var regionchange = this.regionchange
        var codechange = this.codechange
        var countrychange = this.countrychange
        var cancelEdit = this.cancelEdit
        var updatecustomer = this.updatecustomer
        return (
            this.props.companyList.map(function (company, key) {
                var sendrequest = sr
                // var state = st
                let context = ctx
                return (
                    <>
                        <section className={
                            enablequickedit && company._id === _id ?
                                "clickedcompanycard"
                                :
                                "companyCardContainer"
                        } key={key} >
                            {
                                enablequickedit && company._id === _id ?
                                    <div className="quickeditmenu">
                                        <button onClick={updatecustomer}>
                                            <MdDone />
                                        </button>
                                        <button onClick={cancelEdit}>
                                            <FcCancel />
                                        </button>
                                    </div>
                                    :
                                    <div className="QuickEdit" title="Quick Edit" onClick={() => QuickEdit(company)}>
                                        <BsPencilSquare />
                                    </div>

                            }
                            <Link
                                key={key}
                                onClick={(e) => {
                                    if (enablequickedit)
                                        e.preventDefault()
                                    currentUser(company)
                                }}
                                to={{
                                    pathname: `/${context.user.firstname}/customers/${company.customername}`,
                                    state: st, company: company,
                                }}
                                style={{ color: 'inherit', textDecoration: 'none' }}
                            >
                                <div className="companyCard" >
                                    <div className="companyMiniCard">
                                        <div className="companyImage">
                                            <img src={company.logo ? company.logo : defaultLogo} alt="logo" />
                                        </div>
                                        {
                                            enablequickedit && company._id === _id ?
                                                <div className="leftside">
                                                    <input id="companyName" className="companyName" placeholder="Customer Name" onChange={customernamechange} value={customername} />
                                                    <input className="companyArea" value={area} placeholder="Area" onChange={areachange} />
                                                    <textarea className="companyDesc" value={description} placeholder="Description" onChange={descriptionchange}></textarea>
                                                </div>

                                                :
                                                <div className="leftside">
                                                    <div className="companyName">{company.customername}</div>
                                                    <div className="companyArea">{company.area}</div>
                                                    <div className="companyDesc">{company.description}</div>
                                                </div>
                                        }
                                        <div className="rightside">
                                            {
                                                enablequickedit && company._id === _id ?
                                                    <div className="companyAddr">
                                                        <input placeholder="Street" value={street} onChange={streetchange} />
                                                        <input placeholder="Building" onChange={buildingchange} value={building.charAt(0).toUpperCase() + building.substring(1)} />
                                                        <input placeholder="Region" onChange={regionchange} value={region} />
                                                        <input placeholder="Country" onChange={countrychange} value={country} />
                                                        <input placeholder="Postal Code" onChange={codechange} value={code} />
                                                    </div>
                                                    :
                                                    <div className="companyAddr">
                                                        <p>{company.street}<br /></p>
                                                        <p>{company.building.charAt(0).toUpperCase() + company.building.substring(1)}</p>
                                                        <p>{company.region}, {company.country}</p>
                                                        <p>{company.code}</p>
                                                    </div>
                                            }
                                            <div className="statusButton">
                                                <Switch checked={company.active} onChange={() => {
                                                    company.active = !company.active
                                                    var data = {
                                                        customername: company.customername,
                                                        description: company.description,
                                                        building: company.building,
                                                        area: company.area,
                                                        street: company.street,
                                                        region: company.region,
                                                        code: company.code,
                                                        country: company.country,
                                                        industry: company.industry,
                                                        active: company.active,
                                                        logo: company.logo,
                                                        _id: company._id,
                                                        contacts: company.contacts,
                                                        resources: company.resources
                                                    }
                                                    context.sendrequest('updatecustomer', data, {}, (boolean) => this.setState({ isLoading: boolean }))
                                                }
                                                } />
                                            </div>
                                        </div>
                                    </div>
                                    {company.active ? <div style={{ backgroundColor: '#71f38b' }} className="isActive" ></div> : <div style={{ backgroundColor: "red" }} className="isActive"></div>}
                                </div>
                            </Link>
                        </section>
                    </>
                )
            })
        );
    }
}


export default CompanyCard;
